import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { CatalogQuickView } from 'Common/components/catalog';
import * as types from 'Common/types';
import { useAction } from 'Common/hooks';
import { product, order } from '~features';
import { CartEditProductView as EditProductView } from '~components/cart';
import { PartialDetailsLinks } from '~components/product';

const NOOP = () => null;

export default function CartQuickView({
    className,
    onClose,
    quantity,
    shipmentId,
    variantData,
    originalItemCode,
    ...props
}) {
    const setItemDetails = useAction(product.actions.setItemDetails);
    const onClearProduct = useAction(product.actions.clearItemDetails);
    const setChild = useAction(product.actions.selectChildAttributes);
    const updateItem = useAction(order.actions.updateItem);
    const currentProduct = useSelector(product.selectors.getProduct);
    const totalQty = useSelector(order.selectors.getTotalQuantity);
    const link = React.useMemo(() => (currentProduct ? currentProduct.ContentUrl : null), [currentProduct]);

    const onSetProduct = React.useCallback(
        (Product, child) => {
            setItemDetails({ Product });
            if (child) setChild(child);
        },
        [setItemDetails, setChild]
    );

    const onUpdate = React.useCallback(
        (newItemCode) =>
            updateItem(shipmentId, originalItemCode, newItemCode, undefined, {
                expand: ['forms', 'ordertotals'],
                update: ['lineitems', 'promotions'],
            }),
        [updateItem, shipmentId, originalItemCode]
    );

    return (
        <CatalogQuickView
            {...props}
            shipmentId={shipmentId}
            originalItemCode={originalItemCode}
            className={cx('CartQuickView ProductView', className)}
            Product={NOOP}
            onSetProduct={onSetProduct}
            variantData={variantData}
            onClearProduct={onClearProduct}
            onClose={onClose}
            productProps={{ totalQty }}
        >
            <EditProductView
                desktopBreakpoint="lg"
                Links={<PartialDetailsLinks productLink={link} />}
                quantity={quantity}
                onCancel={onClose}
                originalItemCode={originalItemCode}
                onUpdate={onUpdate}
            />
        </CatalogQuickView>
    );
}

CartQuickView.propTypes = {
    productData: types.Product,
    className: px.string,
    onClose: px.func,
    quantity: px.number,
    variantData: types.Product,
    shipmentId: px.oneOfType([px.number, px.string]),
    originalItemCode: px.string,
    OOS: px.bool,
};
