import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { logger } from 'Common/core';
import { useAction, useAddressVerification, useScript, useCatalogEntities } from 'Common/hooks';
import { CheckoutPage as CheckoutPageView } from 'Common/components/checkout';
import { order } from '~features';
import { CheckoutUserMessage } from '~components/customer';
import { NODE_ENV } from '$env';

const cartApiOptions = {
    expand: ['forms', 'ordertotals', 'savedshippingaddresses', 'shippingmethods', 'savedpayments'],
    update: ['lineitems', 'shipping', 'payment', 'promotions'],
};

export default function CheckoutPage({
    accountEmail,
    cartPageLink,
    googleRecaptchaV3Sitekey,
    homePage = '/',
    orderConfirmationPage,
    useFullBillingAddress = true,
    useRecaptcha,
    ...props
}) {
    const loadedRef = React.useRef(false);
    const [signup, setSignup] = React.useState(false);
    const isUserAuthenticated = React.useMemo(() => !!accountEmail, [accountEmail]);
    const [isOrdering, setIsOrdering] = React.useState(false);
    const hasCart = useSelector(order.selectors.hasCart);
    const cartId = useSelector(order.selectors.getCartId);
    const loadCart = useAction(order.actions.loadCart);
    const forms = useSelector(order.selectors.getCartForms);
    const isUpdating = useSelector(order.selectors.isCartUpdating);
    const qty = useSelector(order.selectors.getTotalQuantity);
    const orderEmail = useSelector((s) => accountEmail || order.selectors.getOrderEmail(s));
    const shipments = useSelector(order.selectors.getAllShipments);
    const lineItems = useSelector(order.selectors.getAllLineItems);
    const savedShippingAddresses = useSelector(order.selectors.getSavedShippingAddresses);
    const orderSummaryData = useSelector(order.selectors.getCartSummaryData);
    const currency = useSelector(order.selectors.getCurrency);
    const promoError = useSelector(order.selectors.getPromoCodeValidationIssue);
    const paymentMethods = useSelector(order.selectors.getPaymentMethods);
    const orderPaymentMethods = useSelector(order.selectors.getOrderPayments);
    const promotions = useSelector(order.selectors.getPromoCodes);
    const orderGiftCards = useSelector(order.selectors.getOrderGiftCards);
    const marketId = useSelector(order.selectors.getMarketId);
    const catalogEntities = useCatalogEntities(lineItems);

    const recaptchSrc = React.useMemo(
        () =>
            useRecaptcha === 'True'
                ? `https://www.google.com/recaptcha/api.js?render=${googleRecaptchaV3Sitekey}`
                : null,
        [googleRecaptchaV3Sitekey, useRecaptcha]
    );

    const grecaptcha = useScript(recaptchSrc, {
        globalNamespace: 'grecaptcha',
        destroyOnDismount: false,
        delay: 100,
    });

    const validateUser = React.useCallback(() => {
        return new Promise((res) =>
            grecaptcha.ready(() => {
                grecaptcha
                    .execute(googleRecaptchaV3Sitekey, { action: 'submit' })
                    .then((reCaptchaToken) => res(reCaptchaToken));
            })
        );
    }, [googleRecaptchaV3Sitekey, grecaptcha]);

    const onUpdateShipmentDetails = useAction(order.actions.updateShipmentDetails);
    const addPromotion = useAction(order.actions.addPromotion);
    const removePromotion = useAction(order.actions.removePromotion);
    const addPaymentMethod = useAction(order.actions.addPayment);
    const removePaymentMethod = useAction(order.actions.removePayment);
    const fetchShippingMethods = useAction(order.actions.fetchShippingMethods);
    const placeOrder = useAction(order.actions.placeOrder);
    const updateEmail = useAction(order.actions.updateOrderEmail);
    const requiredPaymentAmount = useSelector(order.selectors.getRequiredPaymentAmount);
    const deleteCart = useAction(order.actions.clearCart);

    const { verification, onValidateShipment, onConfirmShipment, onCancelVerification } = useAddressVerification({
        onUpdate: onUpdateShipmentDetails,
    });

    const onPlaceOrder = React.useCallback(
        (token) => {
            setIsOrdering(true);
            return placeOrder(orderConfirmationPage, token, cartId, () => setIsOrdering(false));
        },
        [orderConfirmationPage, placeOrder, cartId]
    );

    const onUpdateEmail = React.useCallback(
        (form) => {
            setSignup(form.signup);
            updateEmail(form.email, form.signup);
            return true;
        },
        [updateEmail, setSignup]
    );

    React.useEffect(() => {
        if (hasCart && !qty) {
            window.location.replace(homePage);
        }
    }, [hasCart, qty, homePage]);

    React.useEffect(() => {
        if (!loadedRef.current && !isUpdating) {
            loadedRef.current = true;
            loadCart(cartApiOptions);
        }
    }, [loadCart, isUpdating]);

    React.useEffect(() => {
        if (NODE_ENV.startsWith('dev')) {
            window.DELETE_CART = deleteCart;
            logger.debug('You may use DELETE_CART() to clear your cart while in development');
        }
    }, [deleteCart]);

    return (
        <>
            <CheckoutPageView
                {...props}
                isOrdering={isOrdering}
                accountEmail={orderEmail}
                onUpdatePayment={addPaymentMethod}
                addPromotion={addPromotion}
                confirmShipment={!!verification?.unverifiedAddress}
                currency={currency}
                forms={forms}
                isLoading={
                    isUpdating ||
                    !lineItems?.length ||
                    savedShippingAddresses == null ||
                    paymentMethods?.[0]?.SavedPaymentInfo == null
                }
                isUserAuthenticated={isUserAuthenticated}
                catalogEntities={catalogEntities}
                lineItems={lineItems}
                loggedIn={!!accountEmail}
                onConfirmShipment={onConfirmShipment}
                onPlaceOrder={onPlaceOrder}
                onUpdateEmail={onUpdateEmail}
                onValidateShipment={onValidateShipment}
                orderGiftCards={orderGiftCards}
                orderSummaryData={orderSummaryData}
                paymentMethods={paymentMethods}
                payments={orderPaymentMethods}
                requiredPaymentAmount={requiredPaymentAmount}
                promotions={promotions}
                removePayment={removePaymentMethod}
                removePromotion={removePromotion}
                savedShippingAddresses={savedShippingAddresses}
                shipments={shipments}
                signup={signup}
                cartPageLink={cartPageLink}
                marketId={marketId}
                unverifiedAddress={verification.unverifiedAddress}
                verifiedAddresses={verification.verifiedAddresses}
                onCancelVerification={onCancelVerification}
                useFullBillingAddress={useFullBillingAddress}
                fetchShippingMethods={fetchShippingMethods}
                promoError={promoError}
                validateUser={validateUser}
                useRecaptcha={useRecaptcha === 'True'}
            />
            <CheckoutUserMessage />
        </>
    );
}

CheckoutPage.propTypes = {
    accountEmail: px.string,
    cartPageLink: px.string,
    googleRecaptchaV3Sitekey: px.string,
    homePage: px.string,
    orderConfirmationPage: px.string,
    useFullBillingAddress: px.bool,
    useRecaptcha: px.string,
};
