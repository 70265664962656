import React from 'react';
import cx from 'classnames';
import px from 'prop-types';

const Section = ({ header, sidebarLeft, sidebarRight, children, className, style }) => (
    <section
        className={cx(
            className,
            'Section',
            sidebarLeft && sidebarRight ? 'sidebars' : sidebarLeft || sidebarRight ? 'sidebar' : 'no-sidebar'
        )}
        style={style}
    >
        {header ? <header>{header}</header> : null}
        {sidebarLeft ? <div className="Section__sidebarLeft">{sidebarLeft}</div> : null}
        <div className="Section__content">{children}</div>
        {sidebarRight ? <div className="Section__sidebarRight">{sidebarRight}</div> : null}
    </section>
);

Section.propTypes = {
    className: px.string,
    style: px.object,
    header: px.node,
    sidebarLeft: px.node,
    sidebarRight: px.node,
    children: px.node.isRequired,
};

export default Section;
