import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { Formatter } from 'Common/utils';
import { useViewport } from 'Common/hooks';
import { Translation } from '../localization';
import { NumberInput } from '../ui';

const MOBILE_BREAKPOINT = 'md';

export default function RMARequestTable({
    className,
    Forms = [],
    isLoading,
    onChangeQty,
    onReasonSelect,
    currency = 'gbp',
    returnRequest,
    rmaReasonCodes,
}) {
    const id = React.useRef(`RMARequest_${v4()}`);
    const viewport = useViewport();
    const itemCount = React.useMemo(
        () =>
            Forms.reduce(
                (acc, form) => acc + form.Shipments.reduce((sum, shipment) => sum + shipment.LineItems.length, 0),
                0
            ),
        [Forms]
    );

    const onQuantityUpdate = (returnQtyEvent, lineItemQty, code, salePrice, returnedQuantity) => {
        let qty = returnQtyEvent;
        const returnQuantity = returnQtyEvent;
        const returnQuantityLimit = returnedQuantity ? lineItemQty - returnedQuantity : lineItemQty;

        if (returnQuantity > returnQuantityLimit) qty = returnQuantityLimit;
        else if (returnQuantity < 0) qty = 0;
        onChangeQty(qty, code, salePrice);
    };

    return (
        <div className={cx('RMARequestTable CartTable', className)}>
            <div className="table container">
                <div className={cx('row tablehead d-none', `d-${MOBILE_BREAKPOINT}-flex`)}>
                    <div className="col-4">
                        <span>
                            <Translation id="Commerce.Order.Cart.Item.Label" />
                        </span>
                    </div>
                    <div className="col-1 text-center">
                        <span>
                            <Translation id="Commerce.Order.Cart.Quantity.Label" />
                        </span>
                    </div>
                    <div className="col-1 text-center p-0">
                        <span>
                            <Translation id="Commerce.Order.Cart.ReturnQuantity.Label" />
                        </span>
                    </div>
                    <div className="col-2 text-center">
                        <span>
                            <Translation id="Commerce.Order.Cart.Subtotal.Label" />
                        </span>
                    </div>
                    <div className="col-2 text-center">
                        <span>
                            <Translation id="Commerce.Order.Return.Quantity.Label" />
                        </span>
                    </div>
                    <div className="col-2 text-center">
                        <span>
                            <Translation id="Commerce.Order.Return.Reason.Label" />
                        </span>
                    </div>
                </div>
                {itemCount
                    ? Forms.map((form) =>
                          form.Shipments.map((shipment) =>
                              shipment.LineItems.map((item) => (
                                  <div key={`cart-0-${shipment.ShipmentId}-${item.LineItemId}`} className={cx()}>
                                      {viewport.is.lt(MOBILE_BREAKPOINT) ? (
                                          <div className="row tablerow">
                                              <div className="col-4 tablecell image p-0">
                                                  <div className="position-relative h-auto">
                                                      {item.ReturnQuantity >= item.Quantity ? (
                                                          <p className="overlay-text paragraph-1 font-weight-bold text-center">
                                                              <Translation id="Commerce.Order.Return.LineItem.Image.Overlay.Text" />
                                                          </p>
                                                      ) : null}
                                                      <img
                                                          className={cx(
                                                              'img-responsive',
                                                              item.ReturnQuantity >= item.Quantity ? 'overlay' : null
                                                          )}
                                                          src={`${item.CatalogEntity?.DefaultImageUrl}?height=200&width=200&bgcolor=white`}
                                                          alt={item.CatalogEntity?.DisplayName}
                                                      />
                                                  </div>
                                              </div>
                                              <div className="col-8">
                                                  <div className="col-12 tablecell name">
                                                      <span className="subheader">
                                                          {item.CatalogEntity?.DisplayName}
                                                      </span>
                                                  </div>
                                                  <div className="col-12 tablecell price text-left">
                                                      <p className="paragraph-2">
                                                          {Formatter.currency(item.OrderDiscountedSalePrice, currency)}
                                                      </p>
                                                  </div>
                                                  {!item.ReturnableQuantity ? (
                                                      <div className="col-12 tablecell">
                                                          <Translation id="Commerce.Order.Return.NonReturnable.Label" />
                                                      </div>
                                                  ) : (
                                                      <div className="col-12 tablecell">
                                                          <div className="col-12 tablecell quantity text-left px-3">
                                                              <span className="paragraph-2">
                                                                  <Translation id="Commerce.Order.Mobile.Quantity.Label" />
                                                              </span>
                                                              <p className="paragraph-2 d-inline font-weight-bold">
                                                                  {item.Quantity}
                                                              </p>
                                                          </div>
                                                          <div className="col-12 tablecell quantity text-left px-3">
                                                              <span className="paragraph-2">
                                                                  <Translation id="Commerce.Order.Cart.ReturnQuantity.Label" />
                                                                  :
                                                              </span>
                                                              <p className="paragraph-2 d-inline font-weight-bold">
                                                                  {item.ReturnQuantity}
                                                              </p>
                                                          </div>
                                                          <div className="col-12 tablecell quantity text-left px-3">
                                                              <span className="paragraph-2">
                                                                  <Translation id="Commerce.Order.Return.Mobile.Quantity.Label" />
                                                              </span>
                                                              <NumberInput
                                                                  min={0}
                                                                  max={item.Quantity - item.ReturnQuantity}
                                                                  noDecimal
                                                                  disabled={
                                                                      isLoading || item.ReturnQuantity >= item.Quantity
                                                                  }
                                                                  value={
                                                                      returnRequest.LineItems?.find(
                                                                          (lineItem) => lineItem.Code === item.Code
                                                                      )?.Quantity ?? 0
                                                                  }
                                                                  onChange={(e) =>
                                                                      onQuantityUpdate(
                                                                          e,
                                                                          item.Quantity,
                                                                          item.Code,
                                                                          item.OrderDiscountedSalePrice,
                                                                          item.ReturnQuantity
                                                                      )
                                                                  }
                                                              />
                                                          </div>
                                                          <div className="col-12 pl-3 tablecell reason">
                                                              <div className={cx('ReasonCodeDropdown', className)}>
                                                                  <button
                                                                      className={cx('btn btn-sm')}
                                                                      type="button"
                                                                      id={`${id.current}_SortDropdown`}
                                                                      data-toggle="dropdown"
                                                                      aria-haspopup="true"
                                                                      aria-expanded="false"
                                                                      disabled={
                                                                          isLoading ||
                                                                          item.ReturnQuantity >= item.Quantity
                                                                      }
                                                                  >
                                                                      <p className="text paragraph-3">
                                                                          {returnRequest.LineItems?.find(
                                                                              (lineItem) => lineItem.Code === item.Code
                                                                          )?.ReasonText ?? (
                                                                              <Translation id="Commerce.Order.Return.Reason.Button.Label" />
                                                                          )}
                                                                      </p>
                                                                      <i
                                                                          className="fa fa-chevron-down ml-2"
                                                                          aria-hidden="true"
                                                                      ></i>
                                                                  </button>
                                                                  <div
                                                                      className="dropdown-menu mr-3"
                                                                      aria-labelledby={`${id.current}_SortDropdown`}
                                                                  >
                                                                      {rmaReasonCodes.map((code) => (
                                                                          <p
                                                                              key={code.Value}
                                                                              onClick={() =>
                                                                                  onReasonSelect(code, item.Code)
                                                                              }
                                                                              className={cx(
                                                                                  'paragraph-3 reason d-block',
                                                                                  returnRequest.LineItems?.find(
                                                                                      (lineItem) =>
                                                                                          lineItem.Code === item.Code
                                                                                  )?.ReasonCode === code.Value
                                                                                      ? 'selected'
                                                                                      : null
                                                                              )}
                                                                          >
                                                                              {code.Text}
                                                                          </p>
                                                                      ))}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  )}
                                              </div>
                                          </div>
                                      ) : (
                                          <div className="row tablerow">
                                              <div className="col-4 tablecell item">
                                                  <div className="row">
                                                      <div className="col-6 image p-0">
                                                          {item.ReturnQuantity >= item.Quantity ? (
                                                              <p className="overlay-text paragraph-1 font-weight-bold text-center">
                                                                  <Translation id="Commerce.Order.Return.LineItem.Image.Overlay.Text" />
                                                              </p>
                                                          ) : null}
                                                          <img
                                                              className={cx(
                                                                  'img-responsive',
                                                                  item.ReturnQuantity >= item.Quantity
                                                                      ? 'overlay'
                                                                      : null
                                                              )}
                                                              src={`${item.CatalogEntity?.DefaultImageUrl}?height=200&width=200&bgcolor=white`}
                                                              alt={item.CatalogEntity?.DisplayName}
                                                          />
                                                      </div>
                                                      <div className="col-6 name">
                                                          <span className="subheader">
                                                              {item.CatalogEntity?.DisplayName}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-1 tablecell quantity">
                                                  <p className="paragraph-2 d-inline font-weight-bold">
                                                      {item.Quantity}
                                                  </p>
                                              </div>
                                              <div className="col-1 tablecell quantity">
                                                  <p className="paragraph-2 d-inline font-weight-bold">
                                                      {item.ReturnQuantity}
                                                  </p>
                                              </div>
                                              <div className="col-2 tablecell subtotal price">
                                                  <p className="paragraph-2">
                                                      {Formatter.currency(
                                                          item.OrderDiscountedSalePrice * item.Quantity,
                                                          currency
                                                      )}
                                                  </p>
                                              </div>
                                              {!item.ReturnableQuantity ? (
                                                  <div className="col-4 tablecell">
                                                      <Translation id="Commerce.Order.Return.NonReturnable.Label" />
                                                  </div>
                                              ) : (
                                                  <div className="col-4 tablecell">
                                                      <div className="col-6 tablecell quantity">
                                                          <NumberInput
                                                              min={0}
                                                              max={item.Quantity - item.ReturnQuantity}
                                                              noDecimal
                                                              disabled={
                                                                  isLoading || item.ReturnQuantity >= item.Quantity
                                                              }
                                                              value={
                                                                  returnRequest.LineItems?.find(
                                                                      (lineItem) => lineItem.Code === item.Code
                                                                  )?.Quantity ?? 0
                                                              }
                                                              onChange={(e) =>
                                                                  onQuantityUpdate(
                                                                      e,
                                                                      item.Quantity,
                                                                      item.Code,
                                                                      item.OrderDiscountedSalePrice,
                                                                      item.ReturnQuantity
                                                                  )
                                                              }
                                                          />
                                                      </div>
                                                      <div className="col-6 tablecell reason">
                                                          <div className={cx('ReasonCodeDropdown', className)}>
                                                              <button
                                                                  className={cx('btn btn-sm')}
                                                                  type="button"
                                                                  id={`${id.current}_SortDropdown`}
                                                                  data-toggle="dropdown"
                                                                  aria-haspopup="true"
                                                                  aria-expanded="false"
                                                                  disabled={
                                                                      isLoading || item.ReturnQuantity >= item.Quantity
                                                                  }
                                                              >
                                                                  <p className="text paragraph-3 font-weight-bold">
                                                                      {returnRequest.LineItems?.find(
                                                                          (lineItem) => lineItem.Code === item.Code
                                                                      )?.ReasonText ?? (
                                                                          <Translation id="Commerce.Order.Return.Reason.Button.Label" />
                                                                      )}
                                                                  </p>
                                                                  <i
                                                                      className="fa fa-chevron-down ml-2"
                                                                      aria-hidden="true"
                                                                  ></i>
                                                              </button>
                                                              <div
                                                                  className="dropdown-menu"
                                                                  aria-labelledby={`${id.current}_SortDropdown`}
                                                              >
                                                                  {rmaReasonCodes.map((code) => (
                                                                      <p
                                                                          key={code.Value}
                                                                          onClick={() =>
                                                                              onReasonSelect(code, item.Code)
                                                                          }
                                                                          className={cx(
                                                                              'paragraph-3 reason d-block',
                                                                              returnRequest.LineItems?.find(
                                                                                  (lineItem) =>
                                                                                      lineItem.Code === item.Code
                                                                              )?.ReasonCode === code.Text
                                                                                  ? 'selected'
                                                                                  : null
                                                                          )}
                                                                      >
                                                                          {code.Text}
                                                                      </p>
                                                                  ))}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              )}
                                          </div>
                                      )}
                                  </div>
                              ))
                          )
                      )
                    : null}
            </div>
        </div>
    );
}

RMARequestTable.propTypes = {
    className: px.string,
    Forms: px.arrayOf(
        px.shape({
            Shipments: px.arrayOf(
                px.shape({
                    ShipmentId: px.number,
                    LineItems: px.arrayOf(
                        px.shape({
                            LineItemId: px.number,
                            DisplayName: px.string,
                            Quantity: px.number,
                            SalePrice: px.number,
                            OrderDiscountedSalePrice: px.number,
                            MinQuantity: px.number,
                            MaxQuantity: px.number,
                        })
                    ),
                })
            ),
        })
    ),
    onChangeQty: px.func,
    currency: px.string,
    isLoading: px.bool,
    onReasonSelect: px.func,
    returnRequest: px.shape({
        OrderNumber: px.string,
        Notes: px.string,
        LineItems: px.arrayOf(
            px.shape({
                Code: px.string,
                ReasonCode: px.string,
                Notes: px.string,
                Quantity: px.number,
            })
        ),
    }),
    rmaReasonCodes: px.arrayOf(
        px.shape({
            Disabled: px.bool,
            Group: px.object,
            Selected: px.bool,
            Text: px.string,
            Value: px.string,
        })
    ),
};
