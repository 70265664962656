import * as React from 'react';
import px from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { OrderHistory as History } from 'Common/components/customer';
import { customer } from '~features';

export default function OrderHistory({ className, orderDetailPage, trackingUrlTarget }) {
    const dispatch = useDispatch();
    const orderTableData = useSelector(customer.selectors.getOrderTableData);
    const orderPagination = useSelector(customer.selectors.getOrderPagination);
    const onUpdatePage = (page) => dispatch(customer.actions.fetchOrdersByEmail(page));
    const onSearchOrder = (orderNumber) => dispatch(customer.actions.queryOrder(orderNumber));

    return (
        <History
            className={className}
            onSearchOrder={onSearchOrder}
            onUpdatePage={onUpdatePage}
            orderDetailPage={orderDetailPage}
            orderPagination={orderPagination}
            orderTableData={orderTableData}
            trackingUrlTarget={trackingUrlTarget}
        />
    );
}

OrderHistory.propTypes = {
    className: px.string,
    orderDetailPage: px.string,
    trackingUrlTarget: px.string,
};
