import px, { arrayOf } from 'prop-types';
import Facet from './Facet';

export const ProductShape = {
    TypeId: px.string,
    DisplayName: px.string,
    Name: px.string,
    Code: px.string,
    ContentLink: px.string,
    ContentUrl: px.string,
    DefaultImageUrl: px.string,
    Description: px.string,
    DescriptionShort: px.string,
    ProductName: px.string,
    ProductTypeDescription: px.string,
    Attributes: arrayOf(px.shape(AttributeShape)),
    CommerceMedia: arrayOf(px.shape(CommerceMediaShape)),
    Price: px.shape(Price),
    StockStatus: px.string,
    ItemStatus: px.string,
    CatagoryMedia: arrayOf(px.shape(MediaShape)),
    GalleryMedia: arrayOf(px.shape(MediaShape)),
    Options: px.shape(Option),
    Rating: px.number,
    ReviewsCount: px.number,
    Features: arrayOf(px.string),
    Facets: arrayOf(Facet),
    Children: arrayOf(px.shape(Children)),
    ChildRelationQuantity: px.number,
    UPC: px.string,
    ManufacturerSKU: px.string,
    OversizeClass: px.string,
    HazmatCode: px.string,
    HazmatDescription: px.string,
    ShippingNotification: px.string,
    SchemaOrgJson: px.string,
    ProductRecommendationId: px.oneOfType([px.string, px.number]),
    ReviewProviderEntityId: px.string,
};

// ATTRIBUTE SHAPE
export const AttributeShape = {
    AttributeName: px.string,
    AttributeDisplayName: px.string,
    AttributeDefinition: px.string,
    IsSpecification: px.bool,
    Values: arrayOf(px.string),
};

// COMMERCE MEDIA
export const CommerceMediaShape = {
    AssetLink: px.string,
    AssetType: px.string,
    GroupName: px.string,
    SortOrder: px.number,
};

// PRICE
export const Price = {
    SalePrice: px.shape(Money),
    ListPrice: px.shape(Money),
    IsPriceDiscounted: px.bool,
    MinSalePrice: px.shape(Money),
    MinListPrice: px.shape(Money),
    IsMinPriceDiscounted: px.bool,
    MaxSalePrice: px.shape(Money),
    MaxListPrice: px.shape(Money),
    IsMaxPriceDiscounted: px.bool,
    QtySalePrice: px.objectOf(px.type),
};

export const Money = {
    Amount: px.number,
    Currency: px.string,
};

// CATAGORY MEDIA
export const MediaShape = {
    MediaType: px.string,
    MediaGroup: px.string,
    Url: px.string,
    FileType: px.string,
    Order: px.number,
    IsDefault: px.bool,
};

// OPTIONS
export const Option = {
    ShowBulkBuy: px.string,
    ShowSpecifications: px.string,
    DescriptionBackground: px.string,
    DetailGridAttributeName: px.string,
};

// CHILDREN
export const Children = ProductShape;

export default px.shape(ProductShape);
