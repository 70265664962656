import px from 'prop-types';

export const PageInfoShape = {
    count: px.number,
    total: px.number,
    page: px.number,
    totalPages: px.number,
    pageSize: px.number,
    sortValue: px.number,
    from: px.number,
    to: px.number,
    next: px.shape({
        from: px.number,
        to: px.number,
    }),
};

export default px.shape(PageInfoShape);
