import { createSelector } from 'reselect';

export const getCatalog = (state) => state.catalog;

export const getFilteredItems = createSelector(getCatalog, (catalog = {}) => catalog.filteredItems);

export const hasChanged = createSelector(getCatalog, (catalog = {}) => catalog.dirty);

export const lastUpdate = createSelector(getCatalog, (catalog = {}) => catalog.lastUpdate);

export const getSearchQuery = createSelector(getFilteredItems, (filteredItems = {}) => filteredItems.SearchQuery);

export const getFilteredFacets = createSelector(getFilteredItems, (q = {}) => q.Facets);

export const getSelectedAttributes = createSelector(getFilteredFacets, (facets = []) =>
    facets
        .map((f) => ({
            name: f.PropertyName,
            values: f.Values.map((v) => v.Selected && v.Value).filter(Boolean),
        }))
        .filter((a) => a.values.length)
);

export const getPageInfo = createSelector(getFilteredItems, getSearchQuery, (filteredItems = {}, searchQuery = {}) => {
    const page = filteredItems.Page;
    const pageSize = filteredItems.PageSize;
    const total = filteredItems.TotalItems;
    const pageTotal = filteredItems.TotalPages;
    const count = filteredItems.PageItems;
    const to = count;
    const from = 1;
    const nextTo = filteredItems.Items?.length + pageSize <= total ? filteredItems.Items?.length + pageSize : total;
    const nextFrom =
        filteredItems.Items?.length + 1 < total ? filteredItems.Items?.length + 1 : filteredItems.Items?.length;

    const next =
        filteredItems.Items?.length < total && page < pageTotal
            ? {
                  from: nextFrom,
                  to: nextTo,
              }
            : null;

    return {
        sortValue: searchQuery.SortOrder,
        totalPages: searchQuery.TotalPages ? searchQuery.TotalPages - 1 : 0,
        count,
        total,
        page,
        pageSize,
        to,
        from,
        next,
    };
});

export const getPaginatedPageInfo = createSelector(
    getFilteredItems,
    getSearchQuery,
    (filteredItems = {}, searchQuery = {}) => {
        const page = filteredItems.Page;
        const pageSize = filteredItems.PageSize;
        const total = filteredItems.TotalItems;
        const pageTotal = filteredItems.TotalPages;
        const count = filteredItems.PageItems;
        const from = (page - 1) * pageSize + 1;
        const to = from + count - 1;
        const nextTo = filteredItems.Items?.length + pageSize <= total ? filteredItems.Items?.length + pageSize : total;
        const nextFrom =
            filteredItems.Items?.length + 1 < total ? filteredItems.Items?.length + 1 : filteredItems.Items?.length;

        const next =
            filteredItems.Items?.length < total && page < pageTotal
                ? {
                      from: nextFrom,
                      to: nextTo,
                  }
                : null;

        return {
            sortValue: searchQuery.SortOrder,
            totalPages: searchQuery.TotalPages ? searchQuery.TotalPages - 1 : 0,
            count,
            total,
            page,
            pageSize,
            to,
            from,
            next,
        };
    }
);

export const getFilteredProducts = createSelector(getFilteredItems, (filteredItems = {}) => filteredItems.Items);
