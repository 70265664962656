import * as types from './types';
import * as api from './api';
import $ from 'jquery';

function setCompareList(items) {
    return {
        type: types.SET_COMPARE_ITEMS,
        payload: items,
    };
}

function setAlert(translationKey, params = {}, type = 'success') {
    return {
        type: types.COMPARE_SET_ALERT,
        payload: { translationKey, params, type },
    };
}

function startLoading() {
    return {
        type: types.COMPARE_START_LOAD,
    };
}

function triggerError() {
    return {
        type: types.COMPARE_ERROR,
    };
}

export function addToCompareList(code) {
    return async (dispatch) => {
        dispatch(startLoading());
        const response = await api.addToCompare(code);

        if (response) {
            dispatch(setCompareList(response.LineItems));
            dispatch(setAlert('Commerce.Product.Compare.Alert.AddSuccess', {}, 'success'));
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        } else {
            dispatch(setAlert('Commerce.Product.Compare.Alert.AddFailure', {}, 'failure'));
            dispatch(triggerError());
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        }
    };
}

export function getCompareList() {
    return async (dispatch) => {
        dispatch(startLoading());
        const response = await api.getCompareList();

        if (response?.LineItems) {
            dispatch(setCompareList(response.LineItems));
        } else {
            dispatch(triggerError());
        }
    };
}

export function removeFromCompareList(code) {
    return async (dispatch) => {
        dispatch(startLoading());
        const response = await api.removeFromCompareList(code);

        if (response) {
            dispatch(setCompareList(response.LineItems));
        } else {
            dispatch(triggerError());
        }
    };
}

export function clearCompareList() {
    return async (dispatch) => {
        dispatch(startLoading());
        const response = await api.clearCompareList();

        if (response) {
            dispatch(setCompareList(response.LineItems));
        } else {
            dispatch(triggerError());
        }
    };
}
