import * as React from 'react';
import px from 'prop-types';
import { default as DefaultAddressForm } from './AddressForm';
import { default as AlternateAddressForm } from './AlternateAddressForm';
import { default as DigitalAddressForm } from './DigitalAddressForm';
import { default as ConnectedAddressForm } from './ConnectedAddressForm';
import { default as HiddenAddressForm } from './HiddenAddressForm';

export { default as SavedAddressSelector } from './SavedAddressSelector';

export default function AddressForm({
    alternate = false,
    hidden = false,
    connected = false,
    digital = false,
    ...props
}) {
    if (hidden) {
        return <HiddenAddressForm {...props} />;
    }

    if (digital) {
        return <DigitalAddressForm {...props} />;
    }

    if (connected) {
        return <ConnectedAddressForm Form={alternate ? AlternateAddressForm : DefaultAddressForm} {...props} />;
    }

    if (alternate) {
        return <AlternateAddressForm {...props} />;
    }

    return <DefaultAddressForm {...props} />;
}

AddressForm.propTypes = {
    alternate: px.bool,
    connected: px.bool,
    digital: px.bool,
    hidden: px.bool,
};
