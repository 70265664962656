import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { HTMLRender, Social } from 'Common/components/ui';
import { useBEM } from 'Common/hooks';
import * as PRODUCT from '~config/product';
import { Translation } from 'Common/components/localization';

/**
 *
 * @param {{ className: string, style: Object, result: import('./SearchProvider').ContentSearchResult}}} param0
 * @returns {JSX.Element}
 */
export default function ContentSearchResult({ className, style, showFake = false, result }) {
    const [b, em] = useBEM('ContentSearchResult', className);
    const img = React.useMemo(
        () =>
            result.imageUrl?.includes('ytimg.com')
                ? result.imageUrl
                : `${result.imageUrl}?format=png&width=360&height=360${
                      PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                  }`,
        [result]
    );

    return showFake ? (
        <FakeResult />
    ) : (
        <a
            className={cx(b, em(null, `type-content`))}
            href={result.url}
            style={{ ...style, backgroundImage: `url('${img}')` }}
        >
            <div className={em('title')}>
                <h4>{result.title}</h4>
            </div>
            <>
                <div className={em('backdrop')}></div>

                {result.shortDescription ? (
                    <div className={em('excerpt')}>
                        <p>{result.shortDescription}</p>
                    </div>
                ) : result.description ? (
                    <div className={em('excerpt')}>
                        <HTMLRender content={`<p>${result.description}</p>`} />
                    </div>
                ) : null}

                <div className={em('bottom-row')}>
                    <button
                        className="btn btn-read-more btn-primary btn-angled btn-half-pad btn-offset-top"
                        role="button"
                    >
                        <Translation id="ProStaffPage.ReadMoreLabel" />
                    </button>

                    <div className={em('bottom-row__social-links')}>
                        <Social.Share.Facebook link={result.url} />
                        <Social.Share.Twitter link={result.url} name={result.title} />
                    </div>
                </div>
            </>
        </a>
    );
}

const FakeResult = () => {
    return (
        <div className="ContentSearchResult__fake">
            <div className="ContentSearchResult__fake--image" />
            <div className="ContentSearchResult__fake--title" />
            <div className="ContentSearchResult__fake--button" />
        </div>
    );
};

ContentSearchResult.propTypes = {
    style: px.object,
    className: px.string,
    result: px.object,
    showFake: px.bool,
};
