import React, { useCallback, useEffect, useMemo, useState } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { SearchFacet } from './SearchFacet';
import { Drawer, Icon, Panel, Stack } from 'Common/components/ui';
import { useTranslation, useViewport } from 'Common/hooks';
import { Translation } from 'Common/components/localization';
import { clearAllFacets } from './SearchFacet/utils/';

export default function SearchFiltersMenu({ className, style, facets, onChange, hide = false, children }) {
    const viewport = useViewport();
    const [openMenu, setOpenMenu] = useState(false);
    const [pendingFacets, setPendingFacets] = useState(facets);
    const lblClose = useTranslation('Commerce.CatalogNode.Filters.MobileFilterBy');

    const drawerSize = useMemo(() => (viewport.is.lt('md') ? '100vw' : viewport.is.lt('xl') ? '50vw' : '33vw'), [
        viewport,
    ]);

    const showClear = useMemo(() => facets.some((f) => f.options.some((o) => o.selected)), [facets]);

    useEffect(() => {
        setPendingFacets(facets);
    }, [facets]);

    const toggleMenu = useCallback(() => {
        setOpenMenu((x) => !x);
    }, []);

    const closeMenu = useCallback(() => {
        setPendingFacets(facets);
        setOpenMenu(false);
    }, [facets]);

    const handleChange = useCallback(
        (idx) => (facet) => {
            setPendingFacets([...pendingFacets.slice(0, idx), facet, ...pendingFacets.slice(idx + 1)]);
        },
        [pendingFacets]
    );

    const applyFilters = useCallback(() => {
        if (pendingFacets != null) {
            onChange?.(pendingFacets);
        }
        setOpenMenu((x) => !x);
    }, [onChange, pendingFacets]);

    return (
        <>
            <button onClick={toggleMenu} className={cx('SearchFiltersMenu__Open btn', hide && 'hidden')}>
                <Translation id="Search.Results.Filter.Button.Text" className={'SearchFiltesrMenu__Open__Text'} />
                <Icon name="caret-down" className="ml-2" rotate={-90} />
            </button>
            <Drawer
                size={drawerSize}
                anchor="right"
                className="SearchFiltersDrawer"
                open={openMenu}
                onClickOutside={closeMenu}
                backdrop
            >
                <Panel style={style} className={cx(className, 'SearchFiltersMenu')}>
                    <header className={'SearchFiltersMenu__Header'}>
                        <h3>
                            <Translation id="Commerce.CatalogNode.Filters.MobileFilterBy" />
                        </h3>
                        <button
                            className={'SearchFiltersMenu__Header__Close btn'}
                            onClick={closeMenu}
                            aria-labelledby={lblClose}
                        >
                            <Icon className="fas fa-times" />
                        </button>
                    </header>
                    <Stack gap={3} dividers>
                        <div className="SearchFiltersMenu__Filters">
                            {pendingFacets?.map((f, idx) => (
                                <SearchFacet facet={f} key={idx} onChange={handleChange(idx)} />
                            ))}
                        </div>
                        {children ?? null}
                        <button onClick={applyFilters} className={'SearchFiltersMenu__Apply btn btn-primary'}>
                            <Translation id="Search.Results.Filter.Apply.Button.Text" />
                        </button>
                        {showClear ? (
                            <button
                                onClick={() => onChange?.(clearAllFacets(facets))}
                                className={'SearchFiltersMenu__Clear btn btn-dark'}
                            >
                                <Translation id="Commerce.CatalogNode.Filters.ClearFilters" />
                            </button>
                        ) : null}
                    </Stack>
                </Panel>
            </Drawer>
        </>
    );
}

SearchFiltersMenu.propTypes = {
    style: px.object,
    className: px.string,
    facets: px.array,
    onChange: px.func,
    hide: px.bool,
    children: px.node,
};
