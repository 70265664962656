import * as React from 'react';
import { useSelector } from 'react-redux';
import { ProductOptions } from 'Common/components/product';
import { useAction } from 'Common/hooks';
import { product } from '~features';

export default function ProductFilters(props) {
    const productInfo = useSelector(product.selectors.getProduct);
    const productChildren = useSelector(product.selectors.getChildren);
    const currentChildren = useSelector(product.selectors.getCurrentChildren);
    const selectedChild = useSelector(product.selectors.getSelectedChild);
    const facets = useSelector(product.selectors.getFacets);
    const disabledFacets = useSelector(product.selectors.getDisabledFacetValues);
    const onFacetSelect = useAction(product.actions.selectFacet);
    const onSelectChild = useAction(product.actions.selectChild);
    const clearFacets = useAction(product.actions.clearFacets);

    return (
        <ProductOptions
            {...props}
            clearFacets={clearFacets}
            currentChildren={currentChildren}
            disabledFacets={disabledFacets}
            facets={facets}
            onFacetSelect={onFacetSelect}
            onSelectChild={onSelectChild}
            productChildren={productChildren}
            productCode={productInfo.Code}
            selectedChild={selectedChild}
        />
    );
}

ProductFilters.propTypes = {};
