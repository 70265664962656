import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'Common/hooks';
import { Translation } from 'Common/components/localization';

const sharedPropTypes = {
    className: px.string,
    promotions: px.arrayOf(px.string),
    Promotions: px.elementType,
    promoError: px.shape({ message: px.string, timestamp: px.number, reference: px.string, type: px.string }),
    addPromotion: px.func,
    removePromotion: px.func,
    disabled: px.bool,
};

export function DefaultPromotions({ promotions, onRemovePromo }) {
    const lblRemove = useTranslation('Commerce.Order.RemovePromo.Label');

    return promotions?.map((promo) => (
        <div key={promo} className="applied container mb-3">
            <div className="row d-flex align-items-center">
                <div className="col-8 pr-0">
                    <span>
                        <button
                            onClick={onRemovePromo(promo)}
                            className="btn p-0 mr-1"
                            aria-label={lblRemove}
                            title={lblRemove}
                        >
                            <i className="fa fa-times-circle"></i>
                        </button>
                    </span>
                    <span className="value paragraph-3">{promo}</span>
                </div>
                <div className="applied__label btn btn-sm col-4 px-0">
                    <span>
                        <i className="fa fa-check mr-1" aria-hidden="true"></i>
                    </span>
                    <span>
                        <Translation id="Commerce.Order.AddPromo.Applied.Label" />
                    </span>
                </div>
            </div>
        </div>
    ));
}

DefaultPromotions.propTypes = {
    onRemovePromo: px.func,
    promotions: px.arrayOf(px.string),
};

export function PromoCode({
    className,
    Promotions = DefaultPromotions,
    promotions = [],
    promoError,
    code,
    addPromotion,
    removePromotion,
    onCodeChanged,
    disabled = false,
}) {
    const placeholder = useTranslation('Commerce.Order.AddPromo.Input.PlaceHolder');
    const lastLength = React.useRef(promotions.length);
    const [showError, setShowError] = React.useState(false);
    const handleRemovePromotion = React.useCallback(
        (promo) => () => {
            if (removePromotion) {
                removePromotion(promo);
            }
        },
        [removePromotion]
    );

    const handleCodeChanged = React.useCallback(
        (e) => {
            if (onCodeChanged) {
                onCodeChanged(e.target.value);
            }
        },
        [onCodeChanged]
    );

    const handleAddPromotion = React.useCallback(() => {
        if (!promotions?.includes(code)) {
            if (addPromotion) {
                addPromotion(code);
            }
        } else {
            onCodeChanged('');
        }
    }, [code, addPromotion, onCodeChanged, promotions]);

    React.useEffect(() => {
        if (promotions.length > lastLength.current) {
            lastLength.current = promotions.length;
            onCodeChanged('');
        }
    }, [onCodeChanged, promotions]);

    React.useEffect(() => {
        if (promoError) {
            setShowError(true);
            const handle = setTimeout(() => {
                setShowError(false);
            }, 5000);
            return () => { if (handle != null) clearTimeout(handle); }
        } else {
            setShowError(false);
        }

    }, [promoError]);

    return (
        <div className={cx('PromoCode pt-4', className)}>
            <div className="subheader mb-1">
                <Translation id="Commerce.Order.AddPromo.Label" />
            </div>
            <Promotions promotions={promotions} onRemovePromo={handleRemovePromotion} />
            <div className="apply container">
                <div className="row">
                    <input
                        className="col-9"
                        onChange={handleCodeChanged}
                        type="text"
                        value={code}
                        placeholder={placeholder}
                        disabled={disabled}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddPromotion();
                            }
                        }}
                    ></input>
                    <button
                        onClick={handleAddPromotion}
                        disabled={disabled || !code}
                        className="btn btn-sm btn-secondary col-3 px-0 w-100"
                    >
                        <Translation id="Commerce.Order.AddPromo.Button.Label" />
                    </button>
                </div>
                {showError ? (
                    <div className="row">
                        <div className="px-3 py-2 mt-3 alert alert-danger">
                            <p className="paragraph-2 mb-0">
                                <Translation id={promoError?.message} params={{ code: promoError?.reference }} />
                            </p>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

PromoCode.propTypes = {
    ...sharedPropTypes,
    onCodeChanged: px.func,
};

export default function ConnectedPromoCode(props) {
    const [code, setCode] = React.useState('');

    return <PromoCode onCodeChanged={setCode} code={code} {...props} />;
}

ConnectedPromoCode.propTypes = sharedPropTypes;
