import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import Spinner from './Spinner';

export default function Loader({ fitToContainer = false, className, noOverlay = false, ...props }) {
    return (
        <div
            className={cx('Loader', { Overlay: !noOverlay, fitToContainer }, className)}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            {...props}
        >
            <Spinner />
        </div>
    );
}

Loader.propTypes = {
    noOverlay: px.bool,
    className: px.string,
    fitToContainer: px.bool,
};
