import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { RelatedProducts as Related } from 'Common/components/product';
import { product } from '~features';

export default function RelatedProducts({ className, ...props }) {
    const fetched = React.useRef(null);
    const currentCode = useSelector(product.selectors.getSelectedCode);
    const relatedProducts = useSelector(product.selectors.getRelatedProducts);
    const fetchRelatedProducts = useAction(product.actions.fetchRelatedProducts);

    React.useEffect(() => {
        if (!relatedProducts && currentCode && fetched.current !== currentCode) {
            fetched.current = currentCode;
            fetchRelatedProducts(currentCode);
        }
    }, [relatedProducts, fetchRelatedProducts, currentCode]);

    return relatedProducts?.length ? (
        <Related {...props} className={className} relatedProducts={relatedProducts?.slice(0, 3)} />
    ) : null;
}

RelatedProducts.propTypes = {
    className: px.string,
    hideRelatedProducts: px.bool,
};
