import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import { CreditCard as CC } from 'Common/utils';
import * as types from 'Common/types';
import { useExpDateFormat } from 'Common/hooks/payment';
import { Translation } from 'Common/components/localization';
import { Field } from 'Common/components/forms';
import { CreditCard as CardForm } from 'Common/components/forms/Payment/types';
import { PAYMENT } from 'Common/constants/fields';
import PaymentBlock from '../PaymentBlock';
import CheckoutFormContext from '../CheckoutFormContext';

function isCard(a, b) {
    return a?.Token && a.Token === b?.Token;
}

function HiddenPmt({ payment }) {
    return <Field hidden name="pmt" value={JSON.stringify(payment)} />;
}

HiddenPmt.propTypes = {
    payment: px.objectOf(px.any),
};

export default function CreditCard({
    className,
    options,
    payment,
    savedPayments,
    defaultCountry,
    onAddSavedPayment,
    id: idStr = `CheckoutCreditCard_${v4()}`,
    isUserAuthenticated,
    shippingAddress,
    useFullBillingAddress = false,
    paymentMethod,
    paymentType,
    siteId,
}) {
    const formCtx = React.useContext(CheckoutFormContext);
    const formatter = useExpDateFormat();
    const clrPmt = React.useRef(false);
    const id = React.useRef(idStr);
    const [pmt, setPmt] = React.useState(payment?.PaymentType === paymentType ? payment : undefined);
    const clearPayment = React.useCallback(() => {
        const el = document.getElementById(`${id.current}_creditcard_drawer`);

        if (el) el.classList.remove('show');
        setPmt(undefined);
    }, []);

    const newPayment = React.useCallback(() => {
        clrPmt.current = true;
        clearPayment();
    }, [clearPayment]);

    const selectSavedPayment = React.useCallback(
        (savedPayment) => () =>
            onAddSavedPayment(
                CC.paymentToForm({
                    payment: savedPayment,
                    method: paymentMethod,
                    formatter,

                    savePayment: true,
                })
            ),
        [paymentMethod, onAddSavedPayment, formatter]
    );

    React.useLayoutEffect(() => {
        if (!pmt && clrPmt.current) {
            clrPmt.current = false;
            formCtx.setValues({
                [PAYMENT.cardNumber]: '',
                [PAYMENT.cardCvv]: '',
                [PAYMENT.cardDate]: '',
                [PAYMENT.token]: '',
                [PAYMENT.expMonth]: 0,
                [PAYMENT.expYear]: 0,
                [PAYMENT.customerName]: '',
            });
            if (formCtx.setTouched) {
                formCtx.validate();
                formCtx.setTouched(PAYMENT.cardNumber, false);
                formCtx.setTouched(PAYMENT.cardCvv, false);
                formCtx.setTouched(PAYMENT.cardDate, false);
            }
        }
    }, [formCtx, pmt, paymentMethod, formatter]);

    React.useEffect(() => {
        setPmt(payment?.PaymentType === paymentType ? payment : undefined);
    }, [payment, paymentType]);

    return (
        <div className={cx('CreditCard', 'row', className)}>
            {isUserAuthenticated && savedPayments?.length ? (
                <div className="dropdown mt-2 ml-2 col-12 row">
                    <div className="accordion col-12 col-lg-6" id={`${id.current}_accordion`}>
                        <div className="card-header w-100" id={`${id.current}_creditcard_header`}>
                            <button
                                className="btn d-flex justify-content-between align-items-center w-100"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#${id.current}_creditcard_drawer`}
                                aria-expanded="false"
                                aria-controls={`${id.current}_creditcard_drawer`}
                            >
                                <p className="paragraph-2">
                                    <Translation id="Commerce.Order.Checkout.SavedCreditCard.Dropdown.Label" />
                                </p>
                                <i className="fa fa-chevron-down" aria-hidden="true" />
                            </button>
                        </div>
                        <div
                            id={`${id.current}_creditcard_drawer`}
                            className="collapse show"
                            aria-labelledby={`${id.current}_creditcard_header`}
                            data-parent={`#${id.current}_accordion`}
                        >
                            <div className="drawer container">
                                <div className="col mt-1 p-1">
                                    <button onClick={newPayment} className="btn p-0 row d-flex justify-content-between">
                                        <p className="paragraph-2">
                                            <span>
                                                <span
                                                    className={cx('mr-1 generic d-block', CC.getIcon())}
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            <span>
                                                <Translation id="Commerce.Order.Checkout.SavedCreditCard.Dropdown.AddNew.Label" />
                                            </span>
                                        </p>
                                    </button>
                                </div>
                                {pmt ? (
                                    <>
                                        <PaymentBlock
                                            payment={pmt}
                                            paymentMethod={paymentMethod}
                                            className="col row mt-1 p-1"
                                            style={{ backgroundColor: '#00000040' }}
                                        />
                                        <HiddenPmt payment={pmt} />
                                    </>
                                ) : null}
                                {savedPayments.map((savedPayment) =>
                                    isCard(savedPayment, pmt) ? null : (
                                        <PaymentBlock
                                            paymentMethod={paymentMethod}
                                            payment={savedPayment}
                                            key={`saved-pmt-${savedPayment.PaymentId ?? savedPayment.Token}`}
                                            className="col row mt-1 p-1"
                                            onClick={selectSavedPayment(savedPayment)}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {pmt && (!isUserAuthenticated || !savedPayments?.length) ? (
                <div className="col-12 ml-2 row">
                    <p className="mb-2 mt-2">
                        <Translation id="Commerce.Order.Checkout.Payments.ClearPayment.Label" />
                    </p>
                    <PaymentBlock
                        className="col-12"
                        payment={payment}
                        onClick={newPayment}
                        paymentMethod={paymentMethod}
                    />
                    <HiddenPmt payment={pmt} />
                </div>
            ) : null}
            {pmt ? null : (
                <CardForm
                    options={options}
                    isUserAuthenticated={isUserAuthenticated}
                    shippingAddress={shippingAddress}
                    useFullBillingAddress={useFullBillingAddress}
                    siteId={siteId}
                    // formCtx={formCtx}
                    defaultCountry={defaultCountry}
                    connected
                />
            )}
        </div>
    );
}

CreditCard.propTypes = {
    className: px.string,
    id: px.string,
    payment: types.Payment,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    onAddSavedPayment: px.func,
    savedPayments: px.arrayOf(px.any),
    options: px.shape({ EnabledCardTypes: px.string, EnvKey: px.string }),
    paymentType: px.string,
    shippingAddress: types.Shipment,
    useFullBillingAddress: px.bool,
    defaultCountry: px.string,
    paymentMethod: types.PaymentMethod,
    siteId: px.string,
};
