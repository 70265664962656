import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';

function stopProp(e) {
    e.stopPropagation();
}

export default function Drawer({
    className,
    children,
    open = false,
    backdrop = false,
    onClickOutside = null,
    size,
    anchor = 'left',
}) {
    const classes = React.useMemo(
        () => cx(`Drawer anchor-${anchor}`, open && 'open', backdrop && 'backdrop', className),
        [className, open, backdrop, anchor]
    );

    const style = React.useMemo(() => {
        const s = {};

        if (size) {
            if (anchor === 'left' || anchor === 'right') {
                s.width = size;
                s.maxWidth = size;
                s.minWidth = size;
            } else {
                s.height = size;
                s.maxHeight = size;
                s.minHeight = size;
            }
        }
        return s;
    }, [size, anchor]);

    return (
        <div className={classes} onClick={onClickOutside}>
            <div className={cx(`Drawer__slider content anchor-${anchor}`)} onClick={stopProp} style={style}>
                {children}
            </div>
        </div>
    );
}

Drawer.propTypes = {
    className: px.string,
    children: px.node,
    open: px.bool,
    anchor: px.oneOf(['left', 'top', 'bottom', 'right']),
    backdrop: px.bool,
    onClickOutside: px.func,
    size: px.string,
};
