import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import { Icon } from 'Common/components/ui';
import { youtubeThumbnailResolver } from 'Common/utils';
import { useBEM, useTranslation } from 'Common/hooks';
import * as PRODUCT from '~config/product';

/**
 *
 * @param {{ className: string, style: Object, result: import('./SearchProvider').ContentSearchResult}}} param0
 * @returns {JSX.Element}
 */
export default function VideoSearchResult({ className, style, result, onPlayVideo = () => null, showFake = false }) {
    const [id] = React.useState(() => `video-${v4()}`);
    const [b, em] = useBEM('ContentSearchResult', className);
    const lblPlay = useTranslation('Commerce.Product.Video.Subtitle');
    const videoData = React.useMemo(() => ({ url: result.url, playing: true, showControls: result.controllable }), [
        result,
    ]);

    const img = React.useMemo(
        () =>
            result.imageUrl?.includes('ytimg.com')
                ? result.imageUrl
                : `${result.imageUrl}?format=png&width=360&height=360${
                      PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                  }`,
        [result]
    );

    const onClick = React.useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            onPlayVideo(videoData)(e);
        },
        [videoData, onPlayVideo]
    );

    React.useLayoutEffect(() => {
        youtubeThumbnailResolver(`#${id}`);
    }, [img, id]);

    return showFake ? (
        <FakeResult />
    ) : (
        <a
            className={cx(b, em(null, `type-${result.type}`))}
            href={result.url}
            id={id}
            onClick={onClick}
            style={{ ...style, backgroundImage: `url('${img}')` }}
        >
            <div className={em('title')}>
                <h4>{result.title}</h4>
            </div>
            <button className={cx(em('playbtn'), 'btn')} title={lblPlay}>
                <Icon icon="far fa-play-circle" aria-hidden="true" />
            </button>
            <div className={cx(em(null, 'gradientOverlay'))} style={{ ...style }}></div>
        </a>
    );
}

const FakeResult = () => {
    return (
        <div className="VideoSearchResult__fake">
            <div className="VideoSearchResult__fake--image" />
            <div className="VideoSearchResult__fake--content">
                <div className="VideoSearchResult__fake--title" />
                <div className="VideoSearchResult__fake--description" />
            </div>
        </div>
    );
};

VideoSearchResult.propTypes = {
    style: px.object,
    className: px.string,
    result: px.object,
    onPlayVideo: px.func,
    showFake: px.bool,
};
