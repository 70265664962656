import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useFieldState } from 'informed';
import { Translation } from '../localization';

export default function ErrorDisplay({ className, field, fieldId, collapse = false }) {
    const { error, touched } = useFieldState(field);

    return (touched && typeof error === 'string' && error) || !collapse ? (
        <small id={`${fieldId}-i18n-error`} role="alert" className={cx('ErrorDisplay', className)}>
            {touched && typeof error === 'string' && error ? <Translation id={error} /> : <span>&nbsp;</span>}
        </small>
    ) : null;
}

ErrorDisplay.propTypes = {
    className: px.string,
    field: px.string,
    collapse: px.bool,
    fieldId: px.string,
};
