import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useBEM } from 'Common/hooks';
import { ProductBadge, ProductPrice } from 'Common/components/product';
import { Translation } from 'Common/components/localization';
import { ProductInfo } from 'Common/models';

export default function ProductSearchResult({
    className,
    style,
    showFake,
    result,
    active = false,
    openQuickview,
    closeQuickview,
}) {
    const [b, em] = useBEM('ProductSearchResult', className);
    const isDisplayOnly = ProductInfo.isDisplayOnly(result?.product?.catalogEntity);
    
    return showFake ? (
        <FakeResult />
    ) : (
        <a
            className={cx(b, { [em(null, 'selected')]: active })}
            href={result.product.url}
            aria-label={result.product.title}
            style={style}
        >
            <ProductBadge className={em('badges')} badges={result.product.badges} />
        <div 
            
                className={em('image')}
                aria-hidden="true">
                <img
                src={result.product.defaultImage}
                alt={result.product.title} />
                </div>
            <div className={em('title')}>
                <h4>{result.product.title}</h4>
            </div>
            <div className={em('bottom-row')}>
                <button
                    className="btn btn-md btn-primary btn-half-pad btn-offset-top btn-angled"
                    onClick={
                        active
                            ? (e) => {
                                  closeQuickview();
                                  e.preventDefault();
                              }
                            : (e) => {
                                  openQuickview();
                                  e.preventDefault();
                              }
                    }
                    role="button"
                    href="#"
                >
                    <span>
                        <Translation id={isDisplayOnly ? 'Search.Results.Product.OpenDisplayOnlyQuickView.Label' : 'Search.Results.Product.OpenQuickView.Label'} />
                    </span>
                </button>

                <ProductPrice
                    className={cx(em('bottom-row__price'), 'a b c')}
                    priceInfo={{ price: result.product.price, reducedPrice: result.product.reducedPrice }}
                    skipPriceTransform
                />
            </div>
        </a>
    );
}

ProductSearchResult.propTypes = {
    style: px.object,
    className: px.string,
    result: px.object,
    showFake: px.bool,
    active: px.bool,
    openQuickview: px.func,
    closeQuickview: px.func,
};

const FakeResult = () => {
    return (
        <div className="ProductSearchResult__fake">
            <div className="ProductSearchResult__fake--image" />
            <div className="ProductSearchResult__fake--title" />
            <div className="ProductSearchResult__fake--price" />
        </div>
    );
};
