import React from 'react';
import px from 'prop-types';
import { ProductListView } from 'Common/components/product';
import { Product } from 'Common/types';
import { ProductPartialView, ProductQuickviewContainer } from 'Common/containers/product';
import { Icon } from 'Common/containers';
import { useTranslation } from 'Common/hooks';
import { Spinner } from 'Common/components/ui';

const quickview = {
    List: List,
    Filter: Filter,
};

export default function ProductQuickview({
    className,
    style,
    viewType,
    product,
    showLoader = false,
    onClose,
    inputStatusTooltip,
    ...props
}) {
    const Component = React.useMemo(() => quickview[viewType] ?? quickview['List'], [viewType]);

    return showLoader ? (
        <div className="row justify-content-center">
            <Spinner />
        </div>
    ) : (
        <Component
            className={className}
            style={style}
            product={product}
            onClose={onClose}
            inputStatusTooltip={inputStatusTooltip}
            viewType={viewType}
            {...props}
        />
    );
}

function List({ product, inputStatusTooltip, ...props }) {
    return (
        <ProductQuickviewContainer
            Component={ProductListView}
            variants={product.catalogEntity?.Children}
            productInfo={product.catalogEntity}
            stretchBody
            inputStatusTooltip={inputStatusTooltip}
            {...props}
        />
    );
}

List.propTypes = {
    product: Product,
    inputStatusTooltip: px.string,
};

function Filter({ product, onClose, inputStatusTooltip, ...props }) {
    const lblClose = useTranslation('Commerce.CatalogNode.QuickView.Close');

    return (
        <>
            {onClose ? (
                <button
                    className="ResultsGrid__expansion-close btn"
                    role="close"
                    onClick={onClose}
                    aria-label={lblClose}
                >
                    <Icon className= "CloseExpansion_Hover" name="times-outlined" />
                </button>
            ) : null}
            <ProductQuickviewContainer
                Component={ProductPartialView}
                productData={{ Product: product.catalogEntity }}
                stretchBody
                itemStatusTooltip={inputStatusTooltip}
                {...props}
            />
        </>
    );
}

Filter.propTypes = {
    product: Product,
    onClose: px.func,
    inputStatusTooltip: px.string,
};

ProductQuickview.propTypes = {
    style: px.object,
    className: px.string,
    viewType: px.string,
    product: px.object,
    onLoadProductData: px.func,
    showLoader: px.bool,
    onClose: px.func,
    inputStatusTooltip: px.string,
};
