import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useFormApi, useFieldState } from 'informed';
import * as types from 'Common/types';
import { ADDRESS, EMPTY_ADDRESS } from 'Common/constants/fields';
import { Translation } from 'Common/components/localization';
import { AccordionDropdown } from 'Common/components/ui';

export default function SavedAddressSelector({ className, addresses, scope, defaultExpanded = false }) {
    const ref = React.useRef();
    const formApi = useFormApi();
    const scopeStr = React.useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const { value: line1 } = useFieldState(`${scopeStr}${ADDRESS.line1}`);
    const { value: city } = useFieldState(`${scopeStr}${ADDRESS.city}`);
    const { value: state } = useFieldState(`${scopeStr}${ADDRESS.state}`);
    const { value: firstName } = useFieldState(`${scopeStr}${ADDRESS.firstName}`);
    const { value: lastName } = useFieldState(`${scopeStr}${ADDRESS.lastName}`);
    const { value: addressId } = useFieldState(`${scopeStr}${ADDRESS.id}`);
    const { value: country } = useFieldState(`${scopeStr}${ADDRESS.country}`);
    const usingSavedAddress = React.useMemo(
        () => (addressId && addresses.find((a) => a[ADDRESS.id] === addressId)) || null,
        [addressId, addresses]
    );

    const onSelect = React.useCallback(
        async (address = { ...EMPTY_ADDRESS, [ADDRESS.country]: country }) => {
            for (const k of Object.keys(ADDRESS)) {
                formApi.setValue(`${scopeStr}${ADDRESS[k]}`, address[ADDRESS[k]]);
            }

            if (!address[ADDRESS.id]) formApi.validate();
            ref.current.close();
        },
        [formApi, scopeStr, country]
    );

    const addressName = React.useMemo(() => [firstName, lastName].filter(Boolean).join(' '), [firstName, lastName]);

    const addressCityStatePostal = React.useMemo(() => [line1, city, state].filter(Boolean).join(', '), [
        line1,
        city,
        state,
    ]);

    return (
        <AccordionDropdown
            className={cx('AddressSelector', className)}
            title="Commerce.Order.Checkout.SavedShipments.Dropdown.Label"
            ref={ref}
            defaultExpanded={defaultExpanded}
        >
            <button
                type="button"
                role="button"
                onClick={() => onSelect()}
                className="btn btn-select"
                disabled={!usingSavedAddress}
            >
                <span>
                    <Translation id="Commerce.Order.Checkout.SavedShipments.NewAddress.Label" />
                </span>
            </button>
            {usingSavedAddress ? (
                <button type="button" role="button" className="btn btn-select disabled" disabled>
                    {addressName ? <div>{addressName}</div> : null}
                    {addressCityStatePostal ? <div>{addressCityStatePostal}</div> : null}
                </button>
            ) : null}
            {addresses.map((a) =>
                a[ADDRESS.id] === addressId ? null : (
                    <button
                        type="button"
                        role="button"
                        className="btn btn-select"
                        key={a[ADDRESS.id]}
                        onClick={() => onSelect(a)}
                    >
                        <div>
                            {a[ADDRESS.firstName]} {a[ADDRESS.lastName]}
                        </div>
                        <div>
                            {a[ADDRESS.line1]}, {a[ADDRESS.city]}, {a[ADDRESS.state]}
                        </div>
                    </button>
                )
            )}
        </AccordionDropdown>
    );
}

SavedAddressSelector.propTypes = {
    addresses: px.arrayOf(types.Address),
    scope: px.string,
    className: px.string,
    defaultExpanded: px.bool,
};
