import React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Form } from 'informed';
import { Translation } from 'Common/components/localization';
import { GIFTCARDS as FIELDS } from 'Common/constants/fields';
import { Loader, Modal, Icon } from 'Common/components/ui';
import { useTranslation } from 'Common/hooks';

const NO_ID = 0;

export default function CustomerGiftCardsGrid({
    className,
    giftCards,
    onFetchGiftCards,
    onRemoveGiftCard,
    onSaveGiftCard,
}) {
    const defaultError = useTranslation('Account.ErrorMessage');
    const giftCardCodePlaceholder = useTranslation('Commerce.Account.CardNumberLabel');
    const lblRemove = useTranslation('Commerce.Order.RemoveGiftCard.Label');
    const [isAdding, setIsAdding] = React.useState(false);
    const [giftCardCode, setGiftCardCode] = React.useState('');
    const [cardIdToDelete, setCardIdToDelete] = React.useState(NO_ID);
    const [operationIsInProgress, setOperationInProgress] = React.useState(false);
    const [formMessage, setFormMessage] = React.useState('');

    const wrapUpdate = React.useCallback(async (fn) => {
        setOperationInProgress(true);
        await fn();
        setOperationInProgress(false);
    }, []);

    const initialFetch = React.useRef(() => wrapUpdate(onFetchGiftCards));

    const handleClose = React.useCallback(() => {
        setFormMessage('');
        setGiftCardCode('');
        setIsAdding(false);
    }, [setIsAdding]);

    const handleSubmit = React.useCallback(async () => {

            setFormMessage('');

            const response = await onSaveGiftCard({ GiftCardNumber: giftCardCode });

            if (response?.success) {
                setGiftCardCode('');
                setIsAdding(false)
            } else if (response?.message) {
                setFormMessage(response.message);
            } else {
                setFormMessage(defaultError);
        }
    }, [onSaveGiftCard, giftCardCode, defaultError]);

    const handleDelete = React.useCallback(() => {
        if (cardIdToDelete) {
            onRemoveGiftCard(cardIdToDelete);
        }
    }, [onRemoveGiftCard, cardIdToDelete]);

    const onChange = React.useCallback(
        (e) => {
            setGiftCardCode(e.target.value);
        },
        [setGiftCardCode]
    );

    const onNewCard = React.useCallback(() => setIsAdding(true), []);

    React.useEffect(() => {
        initialFetch.current();
    }, []);

    return (
        <div className={cx('CustomerGiftCardsGrid', className)}>
            {operationIsInProgress ? <Loader /> : null}
            <button
                onClick={onNewCard}
                disabled={isAdding}
                className={cx('btn', 'btn-primary', {
                    disabled: isAdding,
                })}
                type="button"
            >
                <Icon icon="fa fa-plus fa-fw" />
                <strong>
                    <Translation id="Commerce.Account.AddGiftCardLabel" />
                </strong>
            </button>
            {isAdding ? (
                <div>
                    <Form onSubmit={() => wrapUpdate(handleSubmit)} className="container pt-3">
                        <div className="row GiftCardForm">
                            <input
                                className="col-12 col-lg-6"
                                name={FIELDS.cardNumber}
                                placeholder={giftCardCodePlaceholder}
                                required
                                value={giftCardCode}
                                onChange={onChange}
                                type="text"
                            />
                        </div>
                        <div className="row py-2">
                            <button disabled={!giftCardCode} type="submit" className="btn btn-primary mr-1">
                                <strong>
                                    <Translation id="Commerce.Account.SaveLabel" />
                                </strong>
                            </button>
                            <button type="button" onClick={handleClose} className="btn btn-secondary ml-1 mr-1">
                                <strong>
                                    <Translation id="Commerce.Account.CancelLabel" />
                                </strong>
                            </button>
                        </div>
                        <div className="row">
                            {formMessage ? <p className="alert alert-danger py-2 d-flex">{formMessage}</p> : null}
                        </div>
                    </Form>
                </div>
            ) : null}
            <div className="card-group pt-4">
                {giftCards?.length ? (
                    giftCards.map((card) => (
                        <div className="col-12 p-0" key={card?.CardNumber}>
                            <div className="card container d-inline-block border-0 pb-5">
                                <div className="row">
                                    <div className="col-6 col-md-4">
                                        <Translation id="Commerce.Account.Card.Number" />
                                    </div>
                                    <div className="col-6 col-md-8">
                                        <strong>{card?.CardNumber}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4">
                                        <Translation id="Commerce.Account.Card.Balance" />
                                    </div>
                                    <div className="col-6 col-md-8">
                                        <strong>{`${card?.FormattedBalance} ${card?.Currency}`}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4">
                                        <Translation id="Commerce.Account.Card.IssueDate" />
                                    </div>
                                    <div className="col-6 col-md-8">
                                        <strong>{card?.FormattedIssuedDateUtc}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4">
                                        <Translation id="Commerce.Account.Card.ExpirationDate" />
                                    </div>
                                    <div className="col-6 col-md-8">
                                        <strong>{card?.FormattedExpirationDateUtc}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4">
                                        <Translation id="Commerce.Account.Card.Status" />
                                    </div>
                                    <div className="col-6 col-md-8">
                                        <strong>{card?.Status}</strong>
                                    </div>
                                </div>
                                <button
                                    disabled={isAdding}
                                    onClick={() => setCardIdToDelete(card?.CardNumber)}
                                    data-toggle="modal"
                                    data-target="#deleteGiftCardModal"
                                    className="mt-2 btn btn-sm p-1"
                                    aria-label={lblRemove}
                                    title={lblRemove}
                                >
                                    <Icon icon="fa fa-trash" />
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>
                        <Translation id="Commerce.Account.NoCardsSavedLabel" />
                    </p>
                )}
            </div>
            <Modal
                onConfirm={handleDelete}
                onClose={() => setCardIdToDelete(NO_ID)}
                closeLabel={<Translation id="Commerce.Account.CancelLabel" />}
                confirmLabel={<Translation id="Commerce.Account.ConfirmLabel" />}
                header={<Translation id="Commerce.Account.DeleteModalLabel" />}
                title={<Translation id="Commerce.Account.DeleteModalLabel" />}
                body={<Translation id="Commerce.Account.DeleteCardModalBodyText" />}
                id="deleteGiftCardModal"
            />
        </div>
    );
}

CustomerGiftCardsGrid.propTypes = {
    className: px.string,
    giftCards: px.arrayOf(px.object),
    onFetchGiftCards: px.func,
    onRemoveGiftCard: px.func,
    onSaveGiftCard: px.func,
};
