import * as React from 'react';
import { useSelector } from 'react-redux';
import { UserMessage } from 'Common/components/ui';
import { order } from '~features';

export default function CartUserMessage() {
    const props = useSelector(order.selectors.getCartValidationError); 

    return <UserMessage {...props} />;
}
