import * as React from 'react';
import px from 'prop-types';
import { useFormApi } from 'informed';
import * as types from 'Common/types';
import { useRegionSelection } from 'Common/hooks';
import { ADDRESS } from 'Common/constants/fields';
import AddressForm from './AddressForm';
import SavedAddressSelector from './SavedAddressSelector';

export default function ConnectedAddressForm({
    Form = AddressForm,
    waitForMount = false,
    regionEnabled = true,
    scope,
    siteId,
    savedAddresses,
    savedAddressSelectorClassName,
    savedAddressDefaultExpanded,
    ...props
}) {
    const scopedFields = React.useMemo(
        () => (scope ? Object.keys(ADDRESS).reduce((o, k) => ({ ...o, [k]: `${scope}.${ADDRESS[k]}` }), {}) : ADDRESS),
        [scope]
    );

    const [postalRegex, setPostalRegex] = React.useState(null);
    const formApi = useFormApi();
    const getRegion = React.useCallback(() => formApi.getValue(scopedFields.state), [formApi, scopedFields]);
    const getCountry = React.useCallback(() => formApi.getValue(scopedFields.country), [formApi, scopedFields]);
    const enabledRef = React.useRef(regionEnabled && !waitForMount);

    const setCountry = React.useCallback(
        (v, { PostalValidationRegex } = {}) => {
            formApi.setValue(scopedFields.country, v);
            if (PostalValidationRegex) setPostalRegex(new RegExp(PostalValidationRegex, 'i'));
        },
        [formApi, scopedFields]
    );

    const setRegion = React.useCallback((v) => formApi.setValue(scopedFields.state, v), [formApi, scopedFields]);

    const { countryList, regionOptions, fetchRegions } = useRegionSelection({
        getCountry,
        getRegion,
        setCountry,
        setRegion,
        siteId,
        enabledRef,
        enabled: regionEnabled,
    });

    const onCountryChange = React.useCallback(
        (code) => {
            const { PostalValidationRegex } = countryList?.find((c) => c.CountryCode === code) || {};

            if (PostalValidationRegex) setPostalRegex(new RegExp(PostalValidationRegex, 'i'));
            fetchRegions(code);
        },
        [countryList, fetchRegions]
    );

    React.useEffect(() => {
        enabledRef.current = regionEnabled;
    }, [regionEnabled]);

    return (
        <>
            {savedAddresses?.length ? (
                <SavedAddressSelector
                    className={savedAddressSelectorClassName}
                    scope={scope}
                    addresses={savedAddresses}
                    defaultExpanded={savedAddressDefaultExpanded}
                />
            ) : null}
            <Form
                {...props}
                countryList={countryList}
                regionOptions={regionOptions}
                onCountryChange={onCountryChange}
                postalRegex={postalRegex}
                scope={scope}
            />
        </>
    );
}

ConnectedAddressForm.propTypes = {
    scope: px.string,
    waitFormMount: px.bool,
    siteId: px.string,
    Form: px.elementType,
    savedAddressSelectorClassName: px.string,
    savedAddresses: px.arrayOf(types.Address),
    savedAddressDefaultExpanded: px.bool,
    regionEnabled: px.bool,
    ...AddressForm.propTypes,
};
