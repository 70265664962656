import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { AccordionTable } from 'Common/components/ui';
import { useTranslation, useViewport } from 'Common/hooks';
import ProductPrice from '../../ProductPrice';

export default function ProductIncludes({
    className,
    facetTableData,
    typeId,
    id,
    Price = ProductPrice,
    orderLastUpdate,
    onAddItemToCart,
    forceMobile = false,
    showAddToCart = true,
    labelledBy = id ? id.replace(/content/g, 'tab') : undefined,
}) {
    const viewport = useViewport();
    const [isLoading, setIsLoading] = React.useState(false);
    const [rows, setRows] = React.useState(
        facetTableData.reduce((acc, data) => ({ ...acc, [data.id]: { quantity: data.childRelationQuantity } }), {})
    );

    const updateRow = React.useCallback(
        (rowId, state) => {
            setRows({ ...rows, [rowId]: state });
        },
        [rows]
    );

    const addToCartLabel = useTranslation('Form.Button.AddToCart.Label');
    const quantityLabel = useTranslation('Commerce.Product.Tabs.BuyInBulk.Quantity.Label');
    const priceLabel = useTranslation('Commerce.Product.Tabs.Price.Label');
    const typeIdLabel = useTranslation('Commerce.Product.Tabs.TypeId.Label');
    const skuLabel = useTranslation('Commerce.Product.Tabs.Sku.Label');

    const accordionHeaders = React.useMemo(() => {
        let columnHeaders = [typeIdLabel, skuLabel];

        if (forceMobile || viewport.is.mobile) return columnHeaders;
        if (typeId === 'Bundle') columnHeaders = columnHeaders.concat([priceLabel]);

        return showAddToCart
            ? columnHeaders.concat([quantityLabel, addToCartLabel])
            : columnHeaders.concat([quantityLabel]);
    }, [
        viewport,
        typeId,
        addToCartLabel,
        priceLabel,
        quantityLabel,
        skuLabel,
        typeIdLabel,
        forceMobile,
        showAddToCart,
    ]);

    const onAddCart = React.useCallback(
        (code, quantity, gtmPayload) => {
            if (gtmPayload.ecommerce.items.length) {
                gtmPayload.ecommerce.items[0].quantity = quantity;
            }
            setIsLoading(true);
            onAddItemToCart(code, quantity, { gtmPayload });
        },
        [onAddItemToCart]
    );

    React.useEffect(() => {
        setIsLoading(false);
    }, [orderLastUpdate]);

    const accordionData = React.useMemo(() => {
        return facetTableData.map((data) => {
            if (forceMobile || viewport.is.mobile) {
                let content = [];

                if (typeId === 'Bundle') {
                    content = content.concat([
                        {
                            label: priceLabel,
                            values: [
                                {
                                    node: <Price priceInfo={data.priceInfo} quantity={rows[data.id]?.quantity} />,
                                    id: priceLabel,
                                },
                            ],
                        },
                        {
                            label: quantityLabel,
                            values: [
                                {
                                    node: showAddToCart ? (
                                        <input
                                            onChange={(e) => updateRow(data.id, { quantity: e.target.value })}
                                            value={rows[data.id]?.quantity}
                                            type="number"
                                            min={1}
                                        />
                                    ) : (
                                        rows[data.id]?.quantity
                                    ),
                                    id: quantityLabel,
                                },
                            ],
                        },
                    ]);
                } else {
                    content = content.concat([
                        {
                            label: quantityLabel,
                            values: [
                                {
                                    node: <p className="paragraph-2 mb-0">{data.childRelationQuantity}</p>,
                                    id: quantityLabel,
                                },
                            ],
                        },
                    ]);
                }

                return {
                    id: data.id,
                    content: showAddToCart
                        ? content.concat([
                              {
                                  label: null,
                                  values: [
                                      {
                                          node: (
                                              <button
                                                  disabled={isLoading || !data.hasStock}
                                                  onClick={() =>
                                                      onAddCart(data.id, rows[data.id]?.quantity, {
                                                          gtmPayload: data.gtaPayload,
                                                      })
                                                  }
                                                  className="btn w-100 btn-primary btn-sm"
                                              >
                                                  {addToCartLabel}
                                              </button>
                                          ),
                                          id: 'addToCart',
                                      },
                                  ],
                              },
                          ])
                        : content,
                    rowValues: [
                        {
                            value: (
                                <img
                                    className="thumbnail mr-3 mr-md-0"
                                    src={`${data.imageUrl}?height=49&width=82&bgcolor=white`}
                                    aria-hidden
                                    alt=""
                                />
                            ),
                            id: data.imageUrl,
                        },
                        {
                            value: (
                                <a className="paragraph-2" href={data.childLink}>
                                    {data.id}
                                </a>
                            ),
                            id: data.id,
                        },
                    ],
                };
            }

            let rowValues = [
                {
                    value: (
                        <img
                            className="thumbnail mr-3 mr-md-0"
                            src={`${data.imageUrl}?height=49&width=82&bgcolor=white`}
                            aria-hidden
                            alt=""
                        />
                    ),
                    id: data.imageUrl,
                },
                {
                    value: (
                        <a className="paragraph-2" href={data.childLink}>
                            {data.id}
                        </a>
                    ),
                    id: data.id,
                },
            ];

            // only for bundle we show price and an editable quantity.
            if (typeId === 'Bundle') {
                rowValues = rowValues.concat([
                    {
                        value: <Price priceInfo={data.priceInfo} quantity={rows[data.id]?.quantity} />,
                        id: 'Commerce.Product.Tabs.Price.Label',
                    },
                    {
                        value: showAddToCart ? (
                            <input
                                onChange={(e) => updateRow(data.id, { quantity: e.target.value })}
                                value={rows[data.id]?.quantity}
                                type="number"
                                min={1}
                            />
                        ) : (
                            rows[data.id]?.quantity
                        ),
                        id: 'Commerce.Product.Tabs.BuyInBulk.Quantity.Label',
                    },
                ]);
            } else {
                rowValues = rowValues.concat([
                    {
                        value: <p className="paragraph-2 px-3 mb-0">{data.childRelationQuantity}</p>,
                        id: 'Commerce.Product.Tabs.BuyInBulk.Quantity.Label',
                    },
                ]);
            }

            return {
                id: data.id,
                rowValues: showAddToCart
                    ? rowValues.concat({
                          value: (
                              <button
                                  disabled={isLoading || !data.hasStock}
                                  onClick={() =>
                                      onAddCart(data.id, rows[data.id]?.quantity, { gtmPayload: data.gtaPayload })
                                  }
                                  className="btn btn-primary btn-sm"
                              >
                                  {addToCartLabel}
                              </button>
                          ),
                          id: 'Form.Button.AddToCart.Label',
                      })
                    : rowValues,
            };
        });
    }, [
        facetTableData,
        viewport,
        onAddCart,
        typeId,
        updateRow,
        rows,
        addToCartLabel,
        priceLabel,
        quantityLabel,
        isLoading,
        showAddToCart,
        forceMobile,
    ]);

    return (
        <div
            id={id}
            role="tabpanel"
            aria-labelledby={labelledBy}
            className={cx('ProductIncludes tab-pane fade show', className)}
        >
            <AccordionTable accordionHeaders={accordionHeaders} accordionData={accordionData} />
        </div>
    );
}

ProductIncludes.propTypes = {
    className: px.string,
    facetTableData: px.arrayOf(
        px.shape({
            id: px.string,
            imageUrl: px.string,
            priceInfo: px.object,
            hasStock: px.bool,
            gtaPayload: px.object,
        })
    ),
    Price: px.elementType,
    orderLastUpdate: px.number,
    typeId: px.string,
    id: px.string,
    labelledBy: px.string,
    forceMobile: px.bool,
    onAddItemToCart: px.func,
    showAddToCart: px.bool,
};
