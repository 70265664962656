import * as React from 'react';
import px from 'prop-types';
import { useFormApi, useFieldState } from 'informed';
import { logger } from 'Common/core';
import { CreditCard } from 'Common/utils';
import { PAYMENT } from 'Common/constants/fields';
import { useExpDateFormat } from 'Common/hooks/payment';
import VALIDATION_STRINGS from 'Common/constants/validation';
import Field from 'Common/components/forms/Field';

export default function ExpDateForm({
    defaultFormat = 'M / YY',
    placeholder,
    required,
    initializeValueIfPristine,
    scope,
    ...rest
}) {
    const scopeStr = React.useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const api = useFormApi();
    const { masks = [], format, parseLoose, formatStr } = useExpDateFormat(defaultFormat);
    const masksRef = React.useRef(masks.join(masks.length));
    const { value: date = '' } = useFieldState(`${scopeStr}${PAYMENT.cardDate}`);
    const { value: month = '' } = useFieldState(`${scopeStr}${PAYMENT.expMonth}`);
    const { value: year = '' } = useFieldState(`${scopeStr}${PAYMENT.expYear}`);

    const [mask, setMask] = React.useState(
        !date || masks.length < 2 ? masks[0] : masks[Math.max(masks.length - masks[0].length + date.length - 1, 0)]
    );

    const maskRef = React.useRef(mask);
    const dateRef = React.useRef(date);
    const monthRef = React.useRef(month);
    const yearRef = React.useRef(year);

    const updateMonthYear = React.useCallback(
        ({ month: m, year: y }) => {
            monthRef.current = m;
            yearRef.current = y;
            api.setValue(`${scopeStr}${PAYMENT.expMonth}`, m);
            api.setValue(`${scopeStr}${PAYMENT.expYear}`, y);
        },
        [api, scopeStr]
    );

    const validateDate = React.useCallback(
        (v) => {
            try {
                const { year: y, month: m } = parseLoose ? parseLoose(v) : { year: 5, month: 5 };

                return CreditCard.validateExpiration(m, y);
            } catch (e) {
                return VALIDATION_STRINGS.invalidCCDate;
            }
        },
        [parseLoose]
    );

    const transform = React.useCallback(
        (v) => {
            if (/^\d\d?20\d\d$/.test(v)) {
                return v.replace('20', '');
            } else if (/^\d\d?\s*(\/|-|\.|\s+)\s*20\d\d$/.test(v)) {
                const { month: m, year: y } = parseLoose(v);

                return format(m, y);
            }
            return v;
        },
        [parseLoose, format]
    );

    React.useEffect(() => {
        const nextRef = masks.join(masks.length);

        if (dateRef.current !== date || masksRef.current !== nextRef) {
            dateRef.current = date;
            masksRef.current = nextRef;

            const nextMask =
                !date || masks.length < 2
                    ? masks[0]
                    : masks[Math.max(masks.length - masks[0].length + date.length - 1, 0)];

            if (nextMask !== maskRef.current) {
                setMask(nextMask);
            }

            if (parseLoose && date.length >= nextMask.length) {
                try {
                    updateMonthYear(parseLoose(date));
                } catch (e) {
                    logger.debug(`Failed to set month/year from expDate string:`, e);
                }
            }
        }
    }, [date, masks, parseLoose, updateMonthYear]);

    React.useEffect(() => {
        if (mask !== maskRef.current) {
            maskRef.current = mask;
            api.setValue(`${scopeStr}${PAYMENT.cardDate}`, api.getValue(`${scopeStr}${PAYMENT.cardDate}`));
        }
    }, [mask, api, scopeStr]);

    React.useEffect(() => {
        if (month !== monthRef.current || year !== yearRef.current) {
            monthRef.current = month;
            yearRef.current = year;
            if (format) api.setValue(`${scopeStr}${PAYMENT.cardDate}`, month && year ? format(month, year) : '');
        }
    }, [month, year, scopeStr, api, format]);

    return (
        <>
            <Field
                required={required}
                name={`${scopeStr}${PAYMENT.cardDate}`}
                validate={validateDate}
                transform={transform}
                formatter={mask}
                placeholder={placeholder ?? formatStr}
                initializeValueIfPristine={initializeValueIfPristine}
                autocomplete="cc-exp"
                transformDelay
                {...rest}
            />
            <Field
                hidden
                required={required}
                name={`${scopeStr}${PAYMENT.expMonth}`}
                initializeValueIfPristine={initializeValueIfPristine}
            />
            <Field
                hidden
                required={required}
                name={`${scopeStr}${PAYMENT.expYear}`}
                initializeValueIfPristine={initializeValueIfPristine}
            />
        </>
    );
}

ExpDateForm.propTypes = {
    masks: px.arrayOf(px.string),
    required: px.bool,
    scope: px.string,
    defaultFormat: px.string,
    placeholder: px.string,
    initializeValueIfPristine: px.bool,
};
