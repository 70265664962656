import React, { useState } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import useWindowResize from 'Common/hooks/useWindowResize';

export default function ResultsGrid({ className, style, children, gap = 0, expandedResultIndex, expandedChildren }) {
    const containerRef = React.useRef(null);

    const [resultsPerRow, setResultsPerRow] = useState(1);

    const rowInsertionIndex = Math.floor(expandedResultIndex / resultsPerRow) + 1;

    const recomputeResultsPerRow = React.useCallback(() => {
        const containerEl = containerRef.current;

        // Do nothing if container hasn't rendered.
        if (containerEl == null) return;

        const firstResultEl = containerEl.childNodes[0];

        // Do nothing if container hasn't rendered any results.
        if (firstResultEl == null) return;

        const resultWidth = firstResultEl.getBoundingClientRect()?.width;
        const containerWidth = containerEl.getBoundingClientRect()?.width;

        if (resultWidth == null || containerWidth == null) return;

        setResultsPerRow(Math.floor(containerWidth / resultWidth));
    }, []);

    useWindowResize(recomputeResultsPerRow, [recomputeResultsPerRow]);
    React.useEffect(recomputeResultsPerRow, [children, expandedResultIndex, expandedChildren, recomputeResultsPerRow]);

    return (
        <div ref={containerRef} style={style} className={cx(className, 'ResultsGrid', `gap--${gap}`)}>
            {React.Children.map(children, (result, i) => (
                <div className="ResultsGrid__result">{result}</div>
            ))}
            {expandedChildren ? (
                <div key="expansion" className="ResultsGrid__expansionCell" style={{ gridRow: rowInsertionIndex + 1 }}>
                    <div className="ResultsGrid__expansion">{expandedChildren}</div>
                </div>
            ) : null}
        </div>
    );
}

ResultsGrid.propTypes = {
    style: px.object,
    className: px.string,
    children: px.arrayOf(px.node),
    expandedResultIndex: px.number,
    expandedChildren: px.node,
    gap: px.number,
};
