import * as React from 'react';
import px from 'prop-types';
import { useFormApi } from 'informed';
import * as types from 'Common/types';
import { ADDRESS } from 'Common/constants/fields';
import Field from '../Field';

export default function HiddenAddressForm({ scope = '', address = null }) {
    const handler = React.useRef();
    const scopeStr = React.useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const api = useFormApi();

    React.useEffect(() => {
        if (address && api?.setValue) {
            handler.current = setTimeout(() => api.setValue(scope, { ...address }));
        }
        return () => {
            clearTimeout(handler);
        };
    }, [address, scope, api]);

    return address ? (
        <>
            <Field
                required
                hidden
                name={`${scopeStr}${ADDRESS.firstName}`}
                value={address[ADDRESS.firstName]}
                forceValue
            />
            <Field
                required
                hidden
                name={`${scopeStr}${ADDRESS.lastName}`}
                value={address[ADDRESS.lastName]}
                forceValue
            />
            <Field hidden name={`${scopeStr}${ADDRESS.company}`} value={address[ADDRESS.company]} forceValue />
            <Field required hidden name={`${scopeStr}${ADDRESS.line1}`} value={address[ADDRESS.line1]} forceValue />
            <Field hidden name={`${scopeStr}${ADDRESS.line2}`} value={address[ADDRESS.line2]} forceValue />
            <Field required hidden name={`${scopeStr}${ADDRESS.city}`} value={address[ADDRESS.city]} forceValue />
            <Field required hidden name={`${scopeStr}${ADDRESS.state}`} value={address[ADDRESS.state]} forceValue />
            <Field required hidden name={`${scopeStr}${ADDRESS.zip}`} value={address[ADDRESS.zip]} forceValue />
            <Field required hidden name={`${scopeStr}${ADDRESS.country}`} value={address[ADDRESS.country]} forceValue />
            <Field required hidden name={`${scopeStr}${ADDRESS.phone}`} value={address[ADDRESS.phone]} forceValue />
        </>
    ) : (
        <>
            <Field required hidden name={`${scopeStr}${ADDRESS.firstName}`} />
            <Field required hidden name={`${scopeStr}${ADDRESS.lastName}`} />
            <Field hidden name={`${scopeStr}${ADDRESS.company}`} />
            <Field required hidden name={`${scopeStr}${ADDRESS.line1}`} />
            <Field hidden name={`${scopeStr}${ADDRESS.line2}`} />
            <Field required hidden name={`${scopeStr}${ADDRESS.city}`} />
            <Field required hidden name={`${scopeStr}${ADDRESS.state}`} />
            <Field required hidden name={`${scopeStr}${ADDRESS.zip}`} />
            <Field required hidden name={`${scopeStr}${ADDRESS.country}`} />
            <Field required hidden name={`${scopeStr}${ADDRESS.phone}`} />
        </>
    );
}

HiddenAddressForm.propTypes = {
    scope: px.string,
    address: types.Address,
};
