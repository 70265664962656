import * as React from 'react';
import px from 'prop-types';
import * as types from 'Common/types';
import { PAYMENT } from 'Common/constants/fields';
import PaymentBlock from '../PaymentBlock';

export default function ReadOnlyContents({ orderPayment, paymentAmount, paymentMethod, orderGiftCards, currency }) {
    const type = React.useMemo(() => (paymentMethod ?? orderPayment ?? {})[PAYMENT.paymentType], [
        paymentMethod,
        orderPayment,
    ]);

    return (
        <div className="ReadOnlyPayment container">
            <div className="row">
                {orderGiftCards?.length ? (
                    <PaymentBlock
                        payment={orderGiftCards}
                        currency={currency}
                        className="col-12 col-sm-12 col-md-6 col-lg-4 pl-0"
                        paymentMethod={paymentMethod}
                    />
                ) : null}
                {orderPayment && paymentAmount > 0 && type !== 'GiftCard' ? (
                    <PaymentBlock
                        payment={orderPayment}
                        className="col-12 col-sm-12 col-md-6 col-lg-4 pl-0"
                        paymentMethod={paymentMethod}
                    />
                ) : null}
            </div>
        </div>
    );
}

ReadOnlyContents.propTypes = {
    orderGiftCards: px.arrayOf(px.any),
    orderPayment: px.objectOf(px.any),
    currency: px.string,
    paymentAmount: px.number,
    paymentMethod: types.PaymentMethod,
};
