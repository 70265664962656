import px from 'prop-types';

export const PromotionShape = {
    CouponCode: px.string,
    Description: px.string,
    DiscountType: px.string,
    Name: px.string,
    PromotionGuid: px.string,
    SavedAmount: px.number,
};

export default px.shape(PromotionShape);
