import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { CatalogDetailGrid as Grid } from 'Common/components/catalog';
import { NotificationSubscription } from 'Common/containers/customer';
import { catalog, order } from '~features';

export default function CatalogDetailGrid({ gtmListValue, noBuyNow, singleFacet, ...props }) {
    const items = useSelector(catalog.selectors.getFilteredProducts);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const onLoadMore = useAction(catalog.actions.loadMore);
    const onAddItemToCart = useAction(order.actions.addItem);
    const onAddMultipleToCart = useAction(order.actions.addItems);
    const totalQty = useSelector(order.selectors.getTotalQuantity);

    return (
        <Grid
            {...props}
            NotifyMe={NotificationSubscription}
            gtmListValue={gtmListValue}
            pageInfo={pageInfo}
            products={items}
            onLoadMore={onLoadMore}
            onAddItemToCart={onAddItemToCart}
            onAddMultipleToCart={onAddMultipleToCart}
            noBuyNow={noBuyNow}
            singleFacet={singleFacet}
            totalQty={totalQty}
        />
    );
}

CatalogDetailGrid.propTypes = {
    gtmListValue: px.string,
    viewDetails: px.string,
    noBuyNow: px.bool,
    singleFacet: px.string,
};
