import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { CustomerPaymentManager as Mgr } from 'Common/components/customer';
import { Loader } from 'Common/components/ui';
import { customer, order } from '~features';
import CustomerError from './CustomerError';

export default function CustomerPaymentManager({ useFullBillingAddress = true, ...props }) {
    const loadedRef = React.useRef(false);
    const savedPayments = useSelector(customer.selectors.getCustomerPaymentMethods);
    const onFetchPayments = useAction(customer.actions.fetchPaymentMethods);
    const onSetDefault = useAction(customer.actions.setDefaultCustomerPaymentMethod);
    const onRemovePayment = useAction(customer.actions.removeCustomerPaymentMethod);
    const onAddPayment = useAction(customer.actions.addCustomerPaymentMethod);
    const loadCart = useAction(order.actions.loadCart);
    const isUpdating = useSelector(order.selectors.isCartUpdating);
    const paymentMethod = useSelector(order.selectors.getCreditCardPaymentMethod);

    React.useEffect(() => {
        if (!paymentMethod && !loadedRef.current && !isUpdating) {
            loadedRef.current = true;
            loadCart({ expand: ['forms'] });
        }
    }, [loadCart, paymentMethod, isUpdating]);

    return (
        <>
            {paymentMethod ? null : <Loader />}
            <Mgr
                {...props}
                savedPayments={savedPayments}
                onFetchPayments={onFetchPayments}
                onSetDefault={onSetDefault}
                onRemovePayment={onRemovePayment}
                onAddPayment={onAddPayment}
                paymentMethod={paymentMethod}
                useFullBillingAddress={useFullBillingAddress}
            />
            <CustomerError />
        </>
    );
}

CustomerPaymentManager.propTypes = {
    useFullBillingAddress: px.bool,
};
