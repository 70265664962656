import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

export default function Selector({
    type = '',
    onSelect,
    value = null,
    label = null,
    leftIcon = null,
    rightIcon = null,
    selected = false,
    tooltip,
    color,
    className,
    disabled = false,
}) {
    const ctrl = React.useMemo(() => {
        if (type && type.includes('rb')) return 'radio';
        if (type && type.includes('cb')) return 'checkbox';
        if ((type && type.includes('color')) || color?.uri || color?.hex) return 'color';
        return 'button';
    }, [type, color]);

    const tooltipProps = React.useMemo(() => {
        const { title, placement = 'top' } = tooltip || {};

        return title ? { title, ['data-placement']: placement, ['data-toggle']: 'toggle' } : null;
    }, [tooltip]);

    switch (ctrl) {
        case 'radio':
        case 'checkbox':
            return (
                <label
                    className={cx('Selector', ctrl, className, { disabled, selected })}
                    onClick={disabled ? null : onSelect}
                    {...tooltipProps}
                >
                    {leftIcon || null}
                    <div className={cx(`input-${ctrl}`, { checked: selected, disabled })} disabled={disabled} />
                    <span>{label || value}</span>
                    {rightIcon || null}
                </label>
            );
        case 'color':
            return (
                <button
                    role="button"
                    className={cx('Selector', ctrl, 'btn', { disabled, selected })}
                    onClick={disabled ? null : onSelect}
                    disabled={disabled}
                    aria-label={label || tooltipProps.title}
                    {...tooltipProps}
                >
                    {color?.uri ? <img src={color.uri} alt={color.uri} /> : null}
                    {color?.hex && !color.uri ? <div style={{ backgroundColor: color.hex }} /> : null}
                    {!color?.hex && !color?.uri ? <i className="fa fa-question" /> : null}
                </button>
            );
        default:
            return (
                <button
                    role="button"
                    className={cx('Selector', ctrl, 'btn', className, { disabled, selected })}
                    onClick={disabled ? null : onSelect}
                    disabled={disabled}
                >
                    {leftIcon || null}
                    <span>{label || value}</span>
                    {rightIcon || null}
                </button>
            );
    }
}

Selector.propTypes = {
    className: px.string,
    disabled: px.bool,
    selected: px.bool,
    onSelect: px.func,
    label: px.node,
    type: px.string,
    rightIcon: px.node,
    value: px.any,
    color: px.shape({ uri: px.string, hex: px.string }),
    leftIcon: px.node,
    tooltip: px.shape({ title: px.string, placement: px.string }),
    colorValue: px.shape({ uri: px.string, hex: px.string }),
};
