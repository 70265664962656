import px from 'prop-types';
// import Product from './Product';

export const CartLineItemShape = {
    LineItemId: px.number,
    Code: px.string,
    SiteId: px.string,
    SiteIdDisplayName: px.string,
    ParentCode: px.string,
    IsPreorder: px.bool,
    PreorderAvailabilityDate: px.string,
    IsGift: px.bool,
    ParentLineItemId: px.number,
    SortOrder: px.number,
    HazmatCode: px.any,
    DisplayName: px.string,

    Quantity: px.number,
    ReturnLineItems: px.any,
    ReturnQuantity: px.number,
    ReturnableQuantity: px.number,

    OrderDiscountedSalePrice: px.number,
    Tax: px.number,
    Total: px.number,

    ValidationMessage: px.string,
    AdditionalInfo: px.arrayOf(px.any),
    // CatalogEntity: Product,
};

export default px.shape(CartLineItemShape);
