import * as React from 'react';
import { useSelector } from 'react-redux';
import { UserMessage } from 'Common/components/ui';
import { order } from '~features';

export default function CartLineItemUserMessage() {
    const error = useSelector(order.selectors.getLineItemValidationError);
    const lineItems = useSelector(order.selectors.getAllFauxLineItems);
    const isGift = React.useMemo(() => lineItems.find(item => item.Code === error?.reference)?.IsGift, [lineItems, error]);

    return <UserMessage {...error} isGiftMessage={isGift} />;
}
