import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useViewport } from 'Common/hooks';

export default function ProductFeatures({
    className,
    forceMobile,
    features,
    id,
    labelledBy = id ? id.replace(/content/g, 'tab') : undefined,
}) {
    const viewport = useViewport();

    return (
        <div
            id={id}
            role="tabpanel"
            aria-labelledby={labelledBy}
            className={cx('ProductFeatures tab-pane fade show container', className)}
        >
            {forceMobile || viewport.is.mobile ? (
                <ul className="ul-lg flex-wrap">
                    {features.map((feature) => (
                        <li key={feature}>{feature}</li>
                    ))}
                </ul>
            ) : (
                // Split list into two columns for desktop
                <div className="row">
                    <div className="col-6">
                        <ul className="ul-lg flex-wrap">
                            {features.slice(0, Math.ceil(features.length / 2)).map((feature) => (
                                <li key={feature}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul className="ul-lg flex-wrap">
                            {features.slice(Math.ceil(features.length / 2)).map((feature) => (
                                <li key={feature}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
}

ProductFeatures.propTypes = {
    className: px.string,
    features: px.arrayOf(px.string),
    labelledBy: px.string,
    id: px.string,
    forceMobile: px.bool,
};