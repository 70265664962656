import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useBEM } from 'Common/hooks';
import * as PRODUCT from '~config/product';
import { Translation } from 'Common/components/localization';
import { Icon } from 'Common/components/ui';
import { ProductImage } from 'Common/utils';
import { ProductBadge } from 'Common/components/product';

export default function CatalogExploreCard({ displayImgFirst, className, product }) {
    const [block, em] = useBEM('CatalogExploreCard', className);

    const image = React.useMemo(
        () =>
            ProductImage.getGlamOrDefaultImageUrl(
                product.CatalogMedia,
                product.DefaultImageUrl,
                `format=png&height=400&width=600${PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''}`
            ),
        [product]
    );

    const badge = React.useMemo(() => {
        const badges = product.MarketingBadges?.map((b) => b.toUpperCase());

        return badges?.length ? badges.slice(0, 1) : badges;
    }, [product.MarketingBadges]);

    return (
        <div className={cx(block, 'px-3 row')}>
            <div className={cx(em('Details'), 'col-12 col-md-6', displayImgFirst ? 'order-2' : 'order-1')}>
                <div className={em('Details__Content')}>
                    <ProductBadge badges={badge} />
                    <p className={cx(em('Details__Content__Subtitle'), 'paragraph-2')}>
                        {product.ProductTypeDescription}
                    </p>
                    <h4 className={em('Details__Content__Title')}>{product.ProductName}</h4>
                    <p className={cx(em('Details__Content__Description'), 'paragraph-3')}>{product.DescriptionShort}</p>
                    <a href={product.ContentUrl} className={em('Details__Content__Link')}>
                        <div className={em('Details__Content__Link__Icon')}>
                            <div className={em('Details__Content__Link__Icon__Circle')} />
                            <Icon name="arrow-right" />
                        </div>
                        <Translation id="Commerce.CatalogNode.CatalogExplore.ProductLink.Text" />
                    </a>
                </div>
            </div>
            <div className={cx(em('Image'), 'col-12 col-md-6', displayImgFirst ? 'order-1' : 'order-2')}>
                <img src={image} alt={product.ProductName} />
            </div>
        </div>
    );
}

CatalogExploreCard.propTypes = {
    className: px.string,
    displayImgFirst: px.bool,
    product: types.Product,
};
