import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Formatter } from 'Common/utils';
import { Translation } from '../localization';
import { useTranslation } from 'Common/hooks';
import CheckoutSummary from '../checkout/CheckoutSummary';
import { ReadOnlyContents as OrderPaymentInfo } from '../checkout/paymentsection';
import OrderDetailTable from './OrderDetailTable';

function flatten(arr) {
    if (typeof arr?.flat === 'function') return arr.flat();
    return [].concat.apply([], arr);
}

function getLineItemsByShipmentId(forms, shipmentId) {
    return shipmentId
        ? forms.reduce(
              (outer, form) =>
                  outer.concat(
                      form.Shipments.reduce(
                          (inner, shipment) =>
                              shipment.ShipmentId === shipmentId ? inner.concat(flatten(shipment.LineItems)) : inner,
                          []
                      )
                  ),
              []
          )
        : [];
}

export default function OrderDetail({ className, orderDetail, rmaPage }) {
    const summaryTitle = useTranslation('Commerce.Order.Detail.Summary.Title');
    const totalLabel = useTranslation('Commerce.Order.Detail.Summary.Total.Label');
    const subtotalLabel = useTranslation('Commerce.Order.Detail.Summary.Subtotal.Label');
    const handlingLabel = useTranslation('Commerce.Order.Detail.Summary.Handling.Label');
    const shippingLabel = useTranslation('Commerce.Order.Detail.Summary.Shipping.Label');
    const shippingDiscountLabel = useTranslation('Commerce.Order.Detail.Summary.ShippingDiscount.Label');
    const orderDiscountTotalLabel = useTranslation('Commerce.Order.Detail.Summary.OrderDiscount.Label');
    const taxLabel = useTranslation('Commerce.Order.Detail.Summary.Tax.Label');
    const costs = React.useMemo(
        () => [
            {
                label: subtotalLabel,
                key: 'subtotal',
                value: orderDetail?.SubTotal,
            },
            orderDetail?.HandlingTotal > 0
                ? {
                      label: handlingLabel,
                      key: 'handling',
                      value: orderDetail?.HandlingTotal,
                  }
                : null,
            {
                label: shippingLabel,
                key: 'shippingSubTotal',
                value: orderDetail?.ShippingSubTotal,
            },
            orderDetail?.ShippingDiscountTotal > 0
                ? {
                      label: shippingDiscountLabel,
                      key: 'shippingDiscount',
                      value: orderDetail?.ShippingDiscountTotal * -1,
                      isPositive: false,
                  }
                : null,
            orderDetail?.OrderDiscountTotal > 0
                ? {
                      label: orderDiscountTotalLabel,
                      key: 'orderDiscount',
                      value: orderDetail?.OrderDiscountTotal * -1,
                  }
                : null,
            {
                label: taxLabel,
                key: 'tax',
                value: orderDetail?.TaxTotal,
            },
        ],
        [
            handlingLabel,
            orderDetail,
            orderDiscountTotalLabel,
            shippingDiscountLabel,
            shippingLabel,
            subtotalLabel,
            taxLabel,
        ]
    );

    const discounts = React.useMemo(
        () =>
            orderDetail?.Forms?.reduce(
                (arr, form) =>
                    arr.concat(
                        form.Promotions.reduce((promos, p) => {
                            const existing = promos.find(({ id }) => id === p.PromotionGuid);

                            if (existing) {
                                existing.value += p.SavedAmount;
                            } else {
                                promos.push({
                                    value: p.SavedAmount,
                                    label: p.Description || p.CouponCode,
                                    id: p.PromotionGuid,
                                    code: p.CouponCode,
                                });
                            }

                            return promos;
                        }, [])
                    ),
                []
            ) ?? [],
        [orderDetail]
    );

    const giftCards = React.useMemo(
        () =>
            orderDetail?.Forms?.find((form) => form.Payments)
                ?.Payments.filter((payment) => payment.PaymentType && payment.PaymentType === 'GiftCard')
                .map(({ Amount, CardNumber, Balance }) => ({ Amount, CardNumber, Balance })),
        [orderDetail]
    );

    const nonGiftCardPayment = React.useMemo(
        () =>
            orderDetail?.Forms?.find((form) => form.Payments)?.Payments.filter(
                (payment) => payment.PaymentType && payment.PaymentType !== 'GiftCard'
            ),
        [orderDetail]
    );

    const orderSummary = React.useMemo(() => {
        const giftCardDiscount = giftCards?.reduce((t, { Amount }) => t + (Amount || 0), 0);

        return {
            discounts,
            giftCardDiscount,
            total: (orderDetail?.Total || 0) - giftCardDiscount,
        };
    }, [orderDetail, discounts, giftCards]);

    return orderDetail ? (
        <div className={cx('OrderDetail', className)}>
            <div className="OrderDetailHead container mb-4">
                <div className="row d-flex justify-content-between">
                    <div className="col-12 col-md-6 d-flex align-items-center p-0">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h5 className="d-inline">
                                            <Translation id="Commerce.Order.History.Table.OrderNum.Column" />:
                                        </h5>
                                    </td>
                                    <td className="pl-2">
                                        <h5 className="d-inline">{`${orderDetail.OrderNumber}`}</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="pl-2">
                                        <h5 className="d-inline">{Formatter.dateFormat(orderDetail.Created)}</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <a href={rmaPage} className="btn btn-md btn-primary float-left float-md-right">
                            <Translation id="Commerce.Order.Detail.StartReturn.Button.Label" />
                        </a>
                    </div>
                </div>
            </div>
            {orderDetail.Forms.map((form) =>
                form.Shipments.map((shipment, index) => (
                    <div key={shipment.ShipmentId} className={cx('container', index > 0 ? 'mt-3' : null)}>
                        <div className="row">
                            <h5 className="OrderShipmentLabel">
                                {form.Shipments.length > 1 ? (
                                    <Translation
                                        id="Commerce.Order.Checkout.Shipment.Split.Title"
                                        params={{ index: index + 1 }}
                                    />
                                ) : (
                                    <Translation id="Commerce.Order.Detail.Shipment.Label" />
                                )}
                            </h5>
                            {shipment.OrderShipmentStatus ? (
                                <span className="OrderShipmentStatus">
                                    <span>
                                        <Translation id="Commerce.Order.Detail.Shipment.Status.Label" />:{' '}
                                        {shipment.OrderShipmentStatus}
                                    </span>
                                </span>
                            ) : null}
                        </div>
                        <div className="divider row" />
                        <div className="row">
                            <div className="OrderDetail__address col-12 col-md-6 p-0">
                                <h6>
                                    <Translation id="Commerce.Order.Detail.Address.Label" />
                                </h6>
                                <div className="OrderDetail__address__shipment">
                                    <p>
                                        {shipment.ShippingAddress.FirstName} {shipment.ShippingAddress.LastName}
                                    </p>
                                    {shipment.ShippingAddress.Organization ? (
                                        <p>{shipment.ShippingAddress.Organization}</p>
                                    ) : null}
                                    {shipment.IsDigitalShipment ? (
                                        <div>
                                            <p>{shipment.ShippingAddress.Email}</p>
                                        </div>
                                    ) : (
                                        <div>
                                            <p>
                                                {shipment.ShippingAddress.Line1}{' '}
                                                {shipment.ShippingAddress.Line2 ? (
                                                    <span>{shipment.ShippingAddress.Line2}</span>
                                                ) : null}
                                            </p>
                                            <p>
                                                {shipment.ShippingAddress.City},{' '}
                                                {shipment.ShippingAddress.RegionCode || ''}{' '}
                                                {shipment.ShippingAddress.PostalCode}
                                            </p>
                                            <p>
                                                {shipment.ShippingAddress.CountryName ||
                                                    shipment.ShippingAddress.CountryCode}
                                            </p>
                                            {shipment.ShippingAddress.DaytimePhoneNumber ? (
                                                <p>{shipment.ShippingAddress.DaytimePhoneNumber}</p>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="OrderDetail__address col-12 col-md-6 p-0">
                                <h6>
                                    <Translation id="Commerce.Order.Detail.ShippingMethod.Label" />
                                </h6>
                                <div key={shipment.ShipmentId} className="OrderDetail__address__shipment">
                                    <p>
                                        {shipment.ShippingMethodDisplayName}, {shipment.ShippingMethodDescription}
                                    </p>
                                    {shipment.ShipmentTrackingNumbers?.length ? (
                                        <div>
                                            <p className="d-inline mr-1">
                                                <Translation id="Commerce.Order.Detail.ShippingMethod.TrackingNum.Label" />
                                            </p>
                                            {shipment.ShipmentTrackingNumbers.map((tracking) => (
                                                <a
                                                    key={tracking.TrackingNumber}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={tracking.TrackingUrl}
                                                    className="d-inline paragraph-2 tracking"
                                                >
                                                    {tracking.TrackingNumber}
                                                    {index < shipment.ShipmentTrackingNumbers.length - 1 ? ', ' : null}
                                                </a>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <OrderDetailTable
                                LineItems={getLineItemsByShipmentId(orderDetail.Forms, shipment.ShipmentId)}
                                currency={orderDetail.Currency}
                                shipmentId={shipment.ShipmentId}
                            />
                        </div>
                    </div>
                ))
            )}
            {orderDetail.Forms.map((form, index) => (
                <div key={form.OrderFormId} className="container my-4">
                    <div className="row">
                        <h5>
                            {form.Payments.length > 1 ? (
                                <Translation
                                    id="Commerce.Order.Detail.Payment.Plural.Label"
                                    params={{ index: index + 1 }}
                                />
                            ) : (
                                <Translation id="Commerce.Order.Detail.Payment.Singular.Label" />
                            )}
                        </h5>
                    </div>
                    <div className="divider row" />
                    {nonGiftCardPayment?.length ? (
                        nonGiftCardPayment.map((payment) => (
                            <OrderPaymentInfo
                                key={payment.PaymentId}
                                orderPayment={payment}
                                currency={orderDetail.Currency}
                                paymentAmount={payment.Amount}
                                orderGiftCards={giftCards}
                                paymentType={payment.PaymentType}
                            />
                        ))
                    ) : (
                        <OrderPaymentInfo
                            currency={orderDetail.Currency}
                            orderGiftCards={giftCards}
                            paymentType="GiftCard"
                        />
                    )}
                </div>
            ))}
            <CheckoutSummary
                costs={costs}
                currency={orderDetail?.Currency}
                orderSummary={orderSummary}
                summaryTitle={summaryTitle}
                totalLabel={totalLabel}
            />
            <a href={rmaPage} className="btn btn-md btn-primary my-4">
                <Translation id="Commerce.Order.Detail.StartReturn.Button.Label" />
            </a>
        </div>
    ) : null;
}

OrderDetail.propTypes = {
    className: px.string,
    orderDetail: px.object,
    rmaPage: px.string,
};
