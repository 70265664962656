import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation, useScript } from 'Common/hooks';
import { Email } from 'Common/utils';
import { Field } from 'Common/components/forms';
import { EMAIL } from 'Common/constants/fields';
import { subscribe } from 'Common/features/customer/api.js';
import { customer } from 'Common/features';
import { Form } from 'informed';
import { Icon, PortalPopup, RecaptchaDetails } from 'Common/components/ui';
import { Translation } from 'Common/components/localization';

export default function NotificationSubscription({ className, code, googleRecaptchaV3Sitekey, useRecaptcha }) {
    const userEmail = useSelector(customer.selectors.getUserEmail);
    const [isLoading, setIsLoading] = React.useState(false);
    const buttonRef = React.useRef();
    const [showPopup, setShowPopup] = React.useState(false);
    const [email, setEmail] = React.useState();
    const [response, setResponse] = React.useState();
    const placeholder = useTranslation('Notification.Subscription.Email.Input.Placeholder');
    const closeLbl = useTranslation('Notification.Subscription.Popup.CloseButton.Label');
    const emailRef = React.useRef();
    const shouldValidate = React.useMemo(() => useRecaptcha?.toString()?.toLowerCase() === 'true', [useRecaptcha]);

    const recaptchSrc = React.useMemo(
        () => (shouldValidate ? `https://www.google.com/recaptcha/api.js?render=${googleRecaptchaV3Sitekey}` : null),
        [googleRecaptchaV3Sitekey, shouldValidate]
    );

    const grecaptcha = useScript(recaptchSrc, {
        globalNamespace: 'grecaptcha',
        destroyOnDismount: false,
    });

    const validateUser = React.useCallback(() => {
        return new Promise((res, rej) =>
            grecaptcha.ready(() => {
                grecaptcha
                    .execute(googleRecaptchaV3Sitekey, { action: 'submit' })
                    .then((reCaptchaToken) => res(reCaptchaToken))
                    .catch(rej);
            })
        );
    }, [googleRecaptchaV3Sitekey, grecaptcha]);

    const onNotifyMe = React.useCallback(
        (evt) => {
            if (evt?.stopPropagation) {
                evt.stopPropagation();
            }
            if (evt?.preventDefault) {
                evt.preventDefault();
            }

            if (userEmail && response) {
                setShowPopup(true);
                return;
            }

            const e = userEmail ?? email;

            if (e) {
                if (emailRef.current?.getFormState()?.invalid) return;
                setIsLoading(true);
                const p = shouldValidate
                    ? validateUser().then((reCaptchaToken) => subscribe(e, code, reCaptchaToken))
                    : subscribe(e, code);

                p.then((r) => {
                    setIsLoading(false);
                    if (r) {
                        setResponse(r);
                        setShowPopup(true);
                    }
                });
            } else {
                setShowPopup(true);
            }
        },
        [userEmail, response, email, validateUser, code, shouldValidate]
    );

    const onClose = React.useCallback((evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        setShowPopup(false);
    }, []);

    return (
        <span className={cx(className, 'NotificationSubscription')}>
            <button
                ref={buttonRef}
                className="NotificationSubscription__notify-link btn p-0"
                onClick={onNotifyMe}
                role="button"
            >
                <Icon className="mr-2" name="envelope" />
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Translation id="Commerce.Catalog.Notify.LinkText" />
                </span>
            </button>
            <PortalPopup
                className="NotificationSubscription__popup"
                showPopup={showPopup}
                anchorRef={buttonRef}
                style={{ width: '300px' }}
            >
                <div className="NotificationSubscription__popup__close">
                    <button className="btn" onClick={onClose} aria-label={closeLbl}>
                        <Icon name="times" />
                    </button>
                </div>
                {userEmail ? (
                    <h6
                        className={cx(
                            'NotificationSubscription__popup__message paragraph-3 m-0',
                            !response?.Success && 'error'
                        )}
                    >
                        {response?.Message}
                    </h6>
                ) : (
                    <>
                        <p className="NotificationSubscription__popup__title paragraph-1">
                            <Translation id="Notification.Subscription.Popup.Title" params={{ code }} />
                        </p>
                        <Form formApiRef={emailRef} onSubmit={onNotifyMe}>
                            <fieldset>
                                <Field
                                    type="email"
                                    disabled={isLoading}
                                    onInput={(v) => setEmail(v)}
                                    name={`${EMAIL.email}`}
                                    placeholder={placeholder}
                                    validate={Email.getValidationError}
                                    validateOnBlur
                                    required
                                />
                            </fieldset>
                        </Form>
                        {response ? (
                            <p
                                className={cx(
                                    'NotificationSubscription__popup__message paragraph-3',
                                    !response.Success && 'error'
                                )}
                            >
                                {response.Message}
                            </p>
                        ) : null}
                        <button
                            onClick={onNotifyMe}
                            disabled={isLoading || !email}
                            className="NotificationSubscription__popup__submit btn btn-sm btn-primary"
                        >
                            <Translation id="Notification.Subscription.Subscribe.Label" />
                        </button>
                    </>
                )}
                <RecaptchaDetails className="NotificationSubscription__popup__recaptcha mt-3" />
            </PortalPopup>
        </span>
    );
}

NotificationSubscription.propTypes = {
    className: px.string,
    code: px.string,
    googleRecaptchaV3Sitekey: px.string,
    useRecaptcha: px.bool,
};
