import * as React from 'react';
import { useSelector } from 'react-redux';
import { ProductFeatures } from 'Common/components/product/ProductTabContent/TabContent';
import { product } from '~features';

export default function ProductDescriptionContent(props) {
    const features = useSelector(product.selectors.getFeatures);

    return features ? <ProductFeatures features={features} {...props} /> : null;
}
