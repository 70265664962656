import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useViewport, useTranslation } from 'Common/hooks';
import { Translation } from 'Common/components/localization';
import { ProductTitle, ProductOptions, ProductPrice, ChildDetail } from 'Common/components/product';

export default function EditProductView({
    className,
    productTitle,
    priceInfo,
    productInfo,
    productChildren,
    currentItemStatus,
    currentChildren,
    selectedChild,
    quantity,
    facets,
    disabledFacets,
    onFacetSelect,
    clearFacets,
    onSelectChild,
    Links,
    onCancel,
    originalItemCode,
    onUpdate,
}) {
    const viewport = useViewport();
    const addToCartToolTip = useTranslation('Form.Button.AddToCart.ToolTip.Label');
    const onUpdateVariant = React.useMemo(
        () => (onUpdate && currentChildren.length === 1 ? () => onUpdate(currentChildren[0].Code) : null),
        [onUpdate, currentChildren]
    );

    const buttonState = React.useMemo(
        () => (currentItemStatus?.hasStock && currentItemStatus.code !== originalItemCode ? 'active' : 'disabled'),
        [currentItemStatus, originalItemCode]
    );

    const showTip = React.useCallback(
        (tip) => (viewport.is.touch && buttonState === 'disabled' && tip ? () => alert(tip) : null),
        [viewport, buttonState]
    );

    return productInfo ? (
        <div className={cx('ProductDetails col-12 mt-4 text-center text-md-left p-0', className)}>
            <ProductTitle productTitle={productTitle} />
            {productInfo.TypeId === 'Product' && productChildren && productChildren.length ? (
                <ProductOptions
                    clearFacets={clearFacets}
                    currentChildren={currentChildren}
                    disabledFacets={disabledFacets}
                    facets={facets}
                    onFacetSelect={onFacetSelect}
                    onSelectChild={onSelectChild}
                    productChildren={productChildren}
                    productCode={productInfo.Code}
                    selectedChild={selectedChild}
                />
            ) : null}
            <h6 className="w-100 pt-3">
                <ProductPrice
                    className="col-12 p-0"
                    priceInfo={priceInfo}
                    productInfo={productInfo}
                    quantity={quantity}
                />
            </h6>
            <ChildDetail
                className="col-12 p-0 text-md-left text-center"
                currentItemStatus={currentItemStatus}
                productCode={productInfo.Code}
                productTypeId={productInfo.TypeId}
            />
            <div className="container submit-btns">
                <div className="mt-3 justify-content-center justify-content-md-start EditProductView__submit-btns">
                    <div className="p-0">
                        <button className="btn btn-primary w-100 px-0" onClick={onCancel}>
                            <span className="subheader">
                                <Translation id="Commerce.Order.Cart.EditVariant.ButtonCancel.Label" />
                            </span>
                        </button>
                    </div>
                    <div className="p-0" onClick={showTip(addToCartToolTip)}>
                        <button
                            className={cx('btn btn-update btn-primary w-100 px-0', buttonState)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={buttonState === 'disabled' ? addToCartToolTip : null}
                            disabled={buttonState === 'disabled'}
                            onClick={onUpdateVariant}
                        >
                            <span className="subheader">
                                <Translation id="Commerce.Order.Cart.EditVariant.ButtonUpdate.Label" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container">{Links}</div>
        </div>
    ) : null;
}

EditProductView.propTypes = {
    className: px.string,
    productTitle: px.string,
    priceInfo: px.object,
    productInfo: px.object,
    productChildren: px.arrayOf(px.object),
    currentItemStatus: px.shape({
        hasStock: px.bool,
        statusDisplayName: px.string,
        code: px.string,
    }),
    currentChildren: px.arrayOf(px.object),
    selectedChild: px.object,
    facets: px.arrayOf(types.Facet),
    disabledFacets: px.object,
    onFacetSelect: px.func,
    onSelectChild: px.func,
    quantity: px.number,
    clearFacets: px.func,
    onCancel: px.func,
    originalItemCode: px.string,
    onUpdate: px.func,
    Links: px.node,
};
