import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { LinkItem } from 'Common/types';
import { TAGS } from 'Common/constants/gtm';
import { ProductGridView as GridView } from 'Common/components/product';
import { NotificationSubscription } from 'Common/containers/customer';
import { product, order } from '~features';

export default function ProductGridView(props) {
    const onAddItemToCart = useAction(order.actions.addItem);
    const variants = useSelector(product.selectors.getChildren);
    const facets = useSelector(product.selectors.getFacets);
    const totalQty = useSelector(order.selectors.getTotalQuantity);
    const title = React.useMemo(
        () =>
            facets.length
                ? facets[0].AttributeFacetLabel ||
                  facets[0].DisplayName ||
                  facets[0].AttributeDisplayName ||
                  facets[0].Attribute
                : 'Commerce.CatalogAttribute.Variation.FacetLabel',
        [facets]
    );

    return (
        <GridView
            onAddToCart={onAddItemToCart}
            variants={variants}
            title={title}
            gtmListValue={TAGS.PDP}
            NotifyMe={NotificationSubscription}
            totalQty={totalQty}
            {...props}
        />
    );
}

ProductGridView.propTypes = {
    Links: px.node,
    storeLocatorLink: LinkItem,
};
