import React, { Children } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'Common/hooks';

function getKey(prefix, index) {
    return `${prefix}_${index}`;
}

export default function PagingButtons({
    BtnPrev = <i className="fas fa-chevron-left" aria-hidden="true" />,
    BtnNext = <i className="fas fa-chevron-right" aria-hidden="true" />,
    onSelect,
    value,
    pageCount,
    className,
    style,
    children,
}) {
    const prevLbl = useTranslation('PagingButtons.PreviousPage.Label');
    const nextLbl = useTranslation('PagingButtons.NextPage.Label');
    const scrollLeft = React.useCallback(() => {
        onSelect(Math.max(0, value - 1));
    }, [onSelect, value]);

    const scrollRight = React.useCallback(() => {
        onSelect(Math.min(pageCount - 1, value + 1));
    }, [onSelect, value, pageCount]);

    return (
        <>
            <button
                style={style}
                className={cx(className, 'PagingButtons', 'btn left')}
                onClick={scrollLeft}
                disabled={!value}
                aria-label={prevLbl}
            >
                {BtnPrev}
            </button>
            {children}
            <button
                style={style}
                className={cx(className, 'PagingButtons', 'btn right')}
                onClick={scrollRight}
                disabled={value == null || value >= pageCount - 1}
                aria-label={nextLbl}
            >
                {BtnNext}
            </button>
        </>
    );
}

PagingButtons.propTypes = {
    BtnNext: px.node,
    BtnPrev: px.node,
    style: px.object,
    className: px.string,
    onSelect: px.func,
    value: px.number,
    pageCount: px.number,
    children: px.node,
};
