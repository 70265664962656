import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Product } from 'Common/types';

export default function ProductBadge({ className, badges: badgeOverride, product, noTopMargin, marginRight, style }) {
    const badges = React.useMemo(
        () => badgeOverride || [...new Set(product?.MarketingBadges?.map((badge) => badge.toUpperCase()))],
        [product, badgeOverride]
    );

    return badges?.length ? (
        <div
            className={cx('ProductBadge flex-wrap', { [`mr-${marginRight}`]: !!marginRight }, className)}
            style={style}
        >
            {badges.map((badge, index) => (
                <div
                    key={badge}
                    className={cx('badge', {
                        'mt-1': !noTopMargin,
                        'mr-2': index < badges.length - 1 && badges.length > 1,
                    })}
                >
                    {badge}
                </div>
            ))}
        </div>
    ) : null;
}

ProductBadge.propTypes = {
    style: px.objectOf(px.any),
    className: px.string,
    noTopMargin: px.bool,
    marginRight: px.string,
    badges: px.arrayOf(px.string),
    product: Product,
};
