import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { useTranslation } from 'Common/hooks';

export default function DropdownMenuSelector({
    className,
    options = [],
    selectedValues = [],
    title = '',
    onSelect,
    outOfStockToolTip,
    notAvailableToolTip,
    disabledMap,
    definition = null,
}) {
    const id = React.useRef(`dropdownmenu_${v4()}`);
    const lblChoose = useTranslation('Commerce.Product.Selector.Choose.Label');

    const optionIsDisabled = React.useCallback(
        (value) =>
            disabledMap && (disabledMap === true || disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid),
        [disabledMap]
    );

    const onClick = React.useCallback(
        (value) =>
            onSelect
                ? (e) => {
                      e.preventDefault();
                      onSelect(value);
                  }
                : null,
        [onSelect]
    );

    const additionalLabel = React.useCallback(
        (value) => {
            if (disabledMap && (disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid))
                return ` (${notAvailableToolTip})`;
            else if (disabledMap && !disabledMap[value]?.hasStock) return ` (${outOfStockToolTip})`;

            return '';
        },
        [outOfStockToolTip, notAvailableToolTip, disabledMap]
    );

    const buttonTitle = React.useCallback((value) => `${value}${additionalLabel(value)}`, [additionalLabel]);

    return (
        <div className={cx('DropdownMenuSelector', className)}>
            <p className="mb-0 strong">{title}</p>
            <div className="dropdown">
                <button
                    className={cx(
                        'btn dropdown-toggle',
                        !!selectedValues[0] && !optionIsDisabled(selectedValues[0]) && 'btn-primary'
                    )}
                    type="button"
                    id={id.current}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {selectedValues[0] ? buttonTitle(selectedValues[0]) : lblChoose}
                </button>
                <div className="dropdown-menu" aria-labelledby={id.current}>
                    {options.map((option) => (
                        <a
                            key={option.value}
                            title={buttonTitle(option.value)}
                            className={cx('dropdown-item', {
                                selected: selectedValues.includes(option.value),
                                disabled: optionIsDisabled(option.value),
                            })}
                            onClick={onClick(option.value)}
                            href="#"
                        >
                            {`${option.label}${additionalLabel(option.value)}`}
                        </a>
                    ))}
                </div>
            </div>
            {definition ? (
                <div className="definition mt-2">
                    <p className="light-sm">{definition}</p>
                </div>
            ) : null}
        </div>
    );
}

DropdownMenuSelector.propTypes = {
    className: px.string,
    title: px.string,
    options: px.arrayOf(px.shape({ label: px.string, value: px.string })),
    selectedValues: px.arrayOf(px.oneOfType([px.string, px.number])),
    outOfStockToolTip: px.string,
    notAvailableToolTip: px.string,
    disabledMap: px.objectOf(px.object),
    onSelect: px.func,
    definition: px.node,
};
