import * as React from 'react';
import px from 'prop-types';
import { v4 } from 'uuid';
import cx from 'classnames';
import { Input, useFormApi, useConditional, useFieldState } from 'informed';
import { logger } from 'Common/core';
import { validation as VALIDATION_STRINGS } from 'Common/constants';
import ErrorDisplay from './ErrorDisplay';
import { useTranslation } from 'Common/hooks';

export default function Field({
    Component = Input,
    hideError = false,
    labelInline = false,
    labelLast = false,
    placeholder,
    placeholderParams,
    name,
    className,
    required = false,
    label = null,
    validate,
    children,
    inlineChildren = false,
    hidden = false,
    forceValue = false,
    value,
    validateOnBlur,
    validateOnValueChange,
    formatterDependsOnState,
    formatterDependencies,
    disabled: disabledProp,
    disabledConditionEval,
    disabledConditionWhen,
    onInput: inputFn,
    mask,
    formatter = mask,
    collapseError = false,
    hookAutofill = false,
    trimOnInput = false,
    transform,
    transformDelay = false,
    ...props
}) {
    const id = React.useRef(`${name}-${v4()}`);
    const lastValue = React.useRef();
    const formApi = useFormApi();
    const { touched, error, value: formValue } = useFieldState(name);
    const translatedPlaceholder = useTranslation(placeholder, placeholderParams);
    const onValidate = React.useMemo(
        () =>
            required || validate
                ? (v, values) => {
                      if (required && (!v || (typeof v === 'string' && !v.trim()))) return VALIDATION_STRINGS.required;
                      return validate ? validate(v, values) : undefined;
                  }
                : null,
        [validate, required]
    );

    const formatObj = React.useMemo(() => {
        if (typeof formatter === 'function') {
            logger.warn(
                `${name} Field received deprecated formatter. Field mask/format property must be a formatter object or string. See: https://teslamotors.github.io/informed/?path=/story/formatting--formatter`
            );
            return undefined;
        }

        return formatter;
    }, [formatter, name]);

    const formatDeps = React.useMemo(
        () => (formatterDependencies ?? formatterDependsOnState ? [formValue] : undefined),
        [formValue, formatterDependsOnState, formatterDependencies]
    );

    const { disabled } = useConditional({
        evaluate: disabledConditionEval,
        evaluateWhen: disabledConditionWhen,
    });

    const onInput = React.useMemo(
        () =>
            hookAutofill || inputFn || trimOnInput || transform
                ? (e) => {
                      let v = trimOnInput && e.target.value ? e.target.value.trim() : e.target.value;

                      if (transform) {
                          const next = transform(v);

                          if (v !== next && formApi) {
                              if (transformDelay) setTimeout(() => formApi.setValue(name, next));
                              else formApi.setValue(name, next);
                              return undefined;
                          }
                      }

                      if (hookAutofill && formApi) {
                          formApi.setValue(name, v);
                          if (onValidate && !validateOnBlur) {
                              formApi.setError(name, onValidate(v, formApi.getFormState()?.values));
                          }
                          formApi.setTouched(name, true);
                      }
                      if (inputFn) inputFn(v);
                      if (hookAutofill && validateOnValueChange && v && formApi) formApi.validate();
                  }
                : null,
        [
            formApi,
            name,
            onValidate,
            validateOnBlur,
            trimOnInput,
            transform,
            hookAutofill,
            validateOnValueChange,
            transformDelay,
            inputFn,
        ]
    );

    const setValue = React.useCallback(
        (v) => {
            if (v !== lastValue.current) {
                lastValue.current = v;
                if (formApi?.setValue) {
                    formApi.setValue(name, v);
                } else if (formApi?.setInitialValue) {
                    formApi.setInitialValue(name, v);
                }
                if (validateOnValueChange && formApi?.validate) formApi.validate();
            }
        },
        [formApi, name, validateOnValueChange]
    );

    React.useEffect(() => {
        setValue(value);
    }, [value, setValue]);

    React.useEffect(() => {
        if (forceValue && lastValue.current !== formValue) setValue(value);
    }, [forceValue, formValue, value, setValue]);

    return labelInline ? (
        <div className={cx('Field inline', { hidden, invalid: touched && error }, className)}>
            <label className="w-100" htmlFor={id.current}>
                {labelLast ? null : (
                    <>
                        {label}
                        {required ? <div className="Field__Required" /> : null}
                    </>
                )}
                <Component
                    hidden={hidden}
                    id={id.current}
                    name={name}
                    validate={onValidate}
                    validateOn={validateOnBlur ? 'blur' : 'change'}
                    onInput={onInput}
                    formatter={formatObj}
                    formatterDependencies={formatDeps}
                    placeholder={translatedPlaceholder}
                    disabled={disabledProp || disabled}
                    {...props}
                >
                    {inlineChildren ? null : children}
                </Component>
                {inlineChildren ? children : null}
                {labelLast ? (
                    <>
                        {label}
                        {required ? <div className="Field__Required" /> : null}
                    </>
                ) : null}
            </label>
            {hideError ? null : <ErrorDisplay fieldId={id.current} field={name} collapse={collapseError} />}
        </div>
    ) : (
        <div className={cx('Field', { hidden, invalid: touched && error }, className)}>
            {labelLast || !label ? null : (
                <label className="w-100" htmlFor={id.current}>
                    {label}
                    {required ? <div className="Field__Required" /> : null}
                </label>
            )}
            <Component
                hidden={hidden}
                id={id.current}
                name={name}
                validate={onValidate}
                validateOn={validateOnBlur ? 'blur' : 'change'}
                formatter={formatObj}
                formatterDependencies={formatDeps}
                placeholder={translatedPlaceholder}
                onInput={onInput}
                disabled={disabledProp || disabled}
                {...props}
            >
                {inlineChildren ? null : children}
            </Component>
            {inlineChildren ? children : null}
            {labelLast && label ? (
                <label className="w-100" htmlFor={id.current}>
                    {label}
                    {required ? <div className="Field__Required" /> : null}
                </label>
            ) : null}
            {hideError ? null : <ErrorDisplay field={name} fieldId={id.current} collapse={collapseError} />}
        </div>
    );
}

Field.propTypes = {
    className: px.string,
    label: px.node,
    labelInline: px.bool,
    labelFirst: px.bool,
    name: px.string,
    hideError: px.bool,
    Component: px.elementType,
    validateOnValueChange: px.bool,
    validateOnBlur: px.bool,
    required: px.bool,
    validate: px.func,
    collapseError: px.bool,
    hidden: px.bool,
    labelLast: px.bool,
    hookAutofill: px.bool,
    children: px.node,
    onInput: px.func,
    placeholder: px.string,
    trimOnInput: px.bool,
    value: px.any,
    mask: px.oneOfType([px.func, px.string]),
    formatter: px.oneOfType([px.func, px.string]),
    transform: px.func,
    formatterDependencies: px.arrayOf(px.any),
    formatterDependsOnState: px.bool,
    placeholderParams: px.any,
    disabled: px.bool,
    disabledConditionEval: px.func,
    disabledConditionWhen: px.arrayOf(px.string),
    inlineChildren: px.bool,
    forceValue: px.bool,
    transformDelay: px.bool,
};
