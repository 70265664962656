import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import $ from 'jquery';
import { v4 } from 'uuid';

function stopProp(e) {
    e.stopPropagation();
    e.preventDefault();
}

export default function Modal({
    className,
    onClose,
    onConfirm,
    onOpen,
    confirmLabel = 'OK',
    header,
    footer,
    closeLabel = 'Close',
    children,
    body = children,
    id: idProp = `modal-${v4()}`,
    showModal = false,
    modalSize = 'md',
    noDefaultButtons = false,
    noOptimization = false,
}) {
    const id = React.useRef(idProp);
    const init = React.useRef(false);
    const lastShow = React.useRef();
    const onCloseRef = React.useRef(onClose);
    const noopt = React.useRef(noOptimization);

    React.useEffect(() => {
        noopt.current = noOptimization;
    }, [noOptimization]);

    React.useEffect(() => {
        onCloseRef.current = onClose;
    }, [onClose]);

    React.useLayoutEffect(() => {
        if (noopt.current || showModal !== lastShow.current) {
            const el = $(`#${id.current}`);

            lastShow.current = showModal;
            if (!init.current) {
                init.current = true;
                el.on('shown.bs.modal', () => {
                    if (onOpen) onOpen();
                    el.on('click', (e) => {
                        if (e.target === e.currentTarget) {
                            if (onCloseRef.current) onCloseRef.current();
                            el.modal('hide');
                        }
                    });
                });
            }
            el.modal(showModal ? 'show' : 'hide');
        }
    }, [showModal, onOpen]);

    return (
        <div
            className={cx('Modal modal fade', className)}
            id={id.current}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                className={cx('modal-dialog modal-dialog-centered', `modal-${modalSize}`)}
                role="document"
                onClick={stopProp}
            >
                <div className="modal-content">
                    {header ? (
                        <div className="modal-header">
                            {header}
                            {onClose && !noDefaultButtons ? (
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label={closeLabel}
                                    onClick={onClose}
                                >
                                    <i className="fa fa-times" />
                                </button>
                            ) : null}
                        </div>
                    ) : null}
                    {body ? <div className="modal-body">{body}</div> : null}
                    {footer || ((onConfirm || onClose) && !noDefaultButtons) ? (
                        <div className="modal-footer">
                            {footer}
                            {onClose && !noDefaultButtons ? (
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={onClose}
                                >
                                    {closeLabel}
                                </button>
                            ) : null}
                            {onConfirm && !noDefaultButtons ? (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={onConfirm}
                                >
                                    {confirmLabel}
                                </button>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    className: px.string,
    onClose: px.func,
    onConfirm: px.func,
    onOpen: px.func,
    header: px.node,
    confirmLabel: px.node,
    closeLabel: px.node,
    body: px.node,
    footer: px.node,
    showModal: px.bool,
    id: px.string,
    modalSize: px.string,
    children: px.node,
    noOptimization: px.bool,
    noDefaultButtons: px.bool,
};
