import React, { useCallback, useMemo } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Facet } from 'Common/types';
import { clearAllFacets, clearFacetValue } from './utils';
import { Icon, Section } from 'Common/components/ui';
import { Translation } from 'Common/components/localization';

export default function SearchFacetBreadcrumbs({ className, style, facets, onChange }) {
    const handleChange = useCallback(
        (facet) => {
            const index = facets.findIndex((f) => f.title === facet.title);

            return onChange?.([...facets.slice(0, index), facet, ...facets.slice(index + 1)]);
        },
        [facets, onChange]
    );

    const show = useMemo(() => facets.some((f) => f.options.some((o) => o.selected)), [facets]);

    return show ? (
        <Section
            className={cx('container', className)}
            style={style}
            sidebarLeft={
                <div className={`${className}__Title`}>
                    <Translation id="Commerce.CatalogNode.Filters.MobileFilterBy" />
                </div>
            }
            sidebarRight={
                <button
                    className={cx('btn btn-sm', `${className}__ClearAll`)}
                    onClick={() => onChange?.(clearAllFacets(facets))}
                >
                    <Translation id="Commerce.CatalogNode.Filters.ClearFilters" />
                </button>
            }
        >
            {facets.map((f) =>
                f.options.map((o, idx) =>
                    o.selected ? (
                        <button
                            key={o.value}
                            name={`${o.title}_Breadcrumb`}
                            className={cx('btn btn-sm', `${className}__Breadcrumb`)}
                            onClick={() => handleChange(clearFacetValue(idx, f))}
                        >
                            <Icon className={cx('fas fa-times-circle', `${className}__Breadcrumb__Icon`)} />
                            {`${f.title}: ${o.title}`}
                        </button>
                    ) : null
                )
            )}
        </Section>
    ) : null;
}

SearchFacetBreadcrumbs.propTypes = {
    style: px.object,
    className: px.string,
    facets: px.arrayOf(Facet),
    onChange: px.func,
};
