import * as React from 'react';
import px from 'prop-types';
import { Checkbox, useFormState, Relevant, useFormApi } from 'informed';
import * as types from 'Common/types';
import { Translation } from 'Common/components/localization';
import { PAYMENT, ADDRESS, EMPTY_ADDRESS } from 'Common/constants/fields';
import { Field } from 'Common/components/forms';
import AddressForm from 'Common/components/forms/Address';
import CardForm from './CardForm';

// Remove address ID when using shipping for billing to ensure a unique record will be created (in backend).
function mapBillingAddress(address) {
    if (!address) return address;

    return {
        ...address,
        [ADDRESS.id]: null,
    };
}

export default function ConnectedCardForm({
    shippingAddress,
    isUserAuthenticated,
    autosave,
    defaultCountry,
    useFullBillingAddress = false,
    siteId,
    scope = '',
    Form = CardForm,
    ...props
}) {
    const switchId = React.useMemo(
        () => `__useShippingAddress_${Date.now().toString(36)}_${Math.random().toString(36)}`.replace(/[.[\]]+/g, ''),
        []
    );

    const formApi = useFormApi();
    const scopeStr = React.useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const { values: { [switchId]: useShippingAddress = !!shippingAddress } = {} } = useFormState();

    const lastUseSwitch = React.useRef(useShippingAddress);

    const usingShippingAddress = React.useCallback(
        ({ formState: { values: { [switchId]: use = !!shippingAddress } = {} } = {} } = {}) => !!use,
        [shippingAddress, switchId]
    );

    const notUsingShippingAddress = React.useCallback(
        ({ formState: { values: { [switchId]: use = !!shippingAddress } = {} } = {} } = {}) => !use,
        [shippingAddress, switchId]
    );

    const address = React.useMemo(() => mapBillingAddress(shippingAddress) || {}, [shippingAddress]);

    React.useEffect(() => {
        if (useFullBillingAddress && lastUseSwitch.current !== useShippingAddress) {
            lastUseSwitch.current = useShippingAddress;
            if (shippingAddress && !useShippingAddress) {
                for (const [k, v] of Object.entries(EMPTY_ADDRESS)) {
                    if (k === ADDRESS.country) {
                        if (defaultCountry) formApi.setValue(`${scopeStr}${PAYMENT.address}.${k}`, defaultCountry);
                    } else {
                        formApi.setValue(`${scopeStr}${PAYMENT.address}.${k}`, v);
                    }
                }
                formApi.validate();
                for (const k of Object.keys(EMPTY_ADDRESS)) {
                    formApi.setTouched(`${scopeStr}${PAYMENT.address}.${k}`, false);
                }
            }
        }
    }, [shippingAddress, useShippingAddress, formApi, scopeStr, defaultCountry, useFullBillingAddress]);

    return (
        <>
            <Form {...props} hideExtendedFields={useFullBillingAddress} />
            {shippingAddress && useFullBillingAddress ? (
                <div className="container mt-2">
                    <Field
                        Component={Checkbox}
                        className="col-12"
                        label={<Translation id="Commerce.Order.Checkout.Payments.UseShippingAddress.Label" />}
                        name={switchId}
                        initialValue={true}
                        labelInline
                        labelLast
                        hideError
                    />
                </div>
            ) : (
                <Field hidden name={switchId} value={false} />
            )}

            <Relevant when={usingShippingAddress}>
                <AddressForm hidden scope={`${scopeStr}${PAYMENT.address}`} address={address} />
            </Relevant>

            <Relevant when={notUsingShippingAddress}>
                <AddressForm scope={`${scopeStr}${PAYMENT.address}`} siteId={siteId} connected />
            </Relevant>

            {isUserAuthenticated ? (
                <div className="container mt-2">
                    <Field
                        className="col-12"
                        Component={Checkbox}
                        name={`${scopeStr}${PAYMENT.savePayment}`}
                        label={<Translation id="Commerce.Order.Checkout.Payments.SavePayment.Label" />}
                        labelInline
                        labelLast
                        hideError
                    />
                </div>
            ) : null}

            {!isUserAuthenticated && autosave ? (
                <Field hidden required name={PAYMENT.savePayment} value={true} forceValue />
            ) : null}
        </>
    );
}

ConnectedCardForm.propTypes = {
    shippingAddress: types.Address,
    useFullBillingAddress: px.bool,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    autosave: px.bool,
    siteId: px.string,
    defaultCountry: px.string,
    Form: px.elementType,
    scope: px.string,
    formCtx: px.shape({
        setTouched: px.func,
        setValue: px.func,
        setValues: px.func,
        getValues: px.func,
        validate: px.func,
    }),
};
