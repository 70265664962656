import React from 'react';
import px from 'prop-types';
import { ProductImage, Formatter } from 'Common/utils';
import * as Product from 'Common/config/product';
/**
 * Sort options.
 * @typedef {Object} SortOption
 * @property {number} value
 * @property {string} label
 * @property {boolean} selected
 */

/**
 * Facets.
 * @typedef {Object} Facet
 * @property {string} id
 * @property {string} title
 * @property {string} type
 * @property {FacetOption[]} options
 */

/**
 * Facet Options.
 * @typedef {Object} FacetOption
 * @property {string} title
 * @property {string} value
 * @property {boolean} selected
 * @property {count} count
 */

/**
 * Update Facet
 * @typedef {Object} UpdateFacet
 * @property {string} id
 * @property {string} value
 */

/**
 * @typedef {string} HTML
 */

/**
 * Product Search Result
 * @typedef {Object} ProductSearchResult
 * @property {'product'} type
 * @property {string} title
 * @property {HTML} description
 * @property {string} url
 * @property {string} imageUrl
 * @property {string} shortDescription
 * @property {Product} product
 */

/**
 * @typedef {Object} Product
 * @property {string} defaultImage
 * @property {string} secondaryImage
 * @property {string} code
 * @property {string} title
 * @property {number} rating
 * @property {string} reviewProviderEntityId
 * @property {string} url
 * @property {string[]} badges
 * @property {string} PrimaryMarketingTag
 * @property {number} price
 * @property {number} reducedPrice
 * @property {boolean} hasChildren
 * @property {Object} catalogEntity
 */

/**
 * Content Search Result
 * @typedef {Object} ContentSearchResult
 * @property {'content'} type
 * @property {string} title
 * @property {HTML} description
 * @property {string} url
 * @property {?string} imageUrl
 * @property {string} shortDescription
 */

/**
 * Content Search Result
 * @typedef {Object} VideoSearchResult
 * @property {'video'} type
 * @property {string} title
 * @property {HTML} description
 * @property {string} url
 * @property {?string} imageUrl
 * @property {boolean} controllable
 * @property {string} shortDescription
 */

/**
 * @typedef {ProductSearchResult | ContentSearchResult | VideoSearchResult} SearchResult
 */

/**
 * Search context for a search page.
 * @typedef {Object} SearchContext
 * @property {string} query - Current query.
 * @property {number} sortPropertyValue - Property to use for sorting.
 * @property {SortOption[]} sortOptions - Options available for sorting.
 * @property {Facet[]} facets - Facets available for user filtering.
 * @property {SearchResult[]} results - Search results displayed to user.
 */

/** Set property action
 * @typedef {Object} SetQueryAction
 * @property {'setQuery'} type
 * @property {string} payload
 */

/** Set sort options action
 * @typedef {Object} SetSortOptionsAction
 * @property {'setSortOptions'} type
 * @property {SortOption[]} payload
 */

/** Set Facets action
 * @typedef {Object} SetFacetsAction
 * @property {'setFacets'} type
 * @property {Facet[]} payload
 */

/** Set Facet Option action
 * @typedef {Object} SetFacetOptionAction
 * @property {'selectFacetOption'} type
 * @property {UpdateFacet[]} payload
 */

/** Set entire context action
 * @typedef {Object} SetContextAction
 * @property {'setContext'} type
 * @property {SearchContext} payload
 */

/**
 * Search context for a search page.
 * @typedef {SetQueryAction | SetSortOptionsAction | SetContextAction} SearchContextAction
 */

export const SearchContext = {
    /**
     * Creates a search context.
     * @property {SearchContext} props
     * @returns {SearchContext} A new search context.
     */
    create(props) {
        return {
            query: '',
            sortPropertyName: '',
            sortOptions: [],
            facets: [
                {
                    id: 'SearchCategory',
                    type: 'tabs',
                    canSelectMultiple: false,
                    options: [
                        {
                            value: 'product',
                            selected: true,
                        },
                    ],
                },
            ],
            results: [],
            pageInfo: {
                page: 1,
            },
            defaultPageSize: 1,
            ...props,
        };
    },
    /**
     *
     * @param {SearchContext} context
     * @param {Facet[]} facets
     * @param {function} ignoreCondition
     * @returns {SearchContext} The updated search context.
     */
    setFacets(context, facets, ignoreCondition) {
        return {
            ...context,
            facets: [...context.facets.filter((f) => (ignoreCondition ? ignoreCondition(f) : false)), ...facets],
        };
    },
    /**
     *
     * @param {SearchContext} context
     * @param {function} condition
     * @param {function} map
     * @returns {SearchContext} The updated search context.
     */
    updateFacet(context, condition, map) {
        return {
            ...context,
            facets: context.facets.map((f) => (condition(f) ? map(f) : f)),
        };
    },
    /**
     *
     * @param {SearchContext} context
     * @param {boolean} reset
     * @returns {SearchContext} The updated search context.
     */
    updatePage(context, reset = false) {
        return {
            ...context,
            pageInfo: {
                ...context.pageInfo,
                page: reset
                    ? 1
                    : context.pageInfo.page + 1 >= context.pageInfo.totalPages
                    ? context.pageInfo.totalPages
                    : context.pageInfo.page + 1,
            },
        };
    },
    /**
     *
     * @param {SearchContext} context
     * @param {SortOption} option
     * @returns {SearchContext} The updated search context.
     */
    selectSort(context, option) {
        return {
            ...context,
            sortOptions: [
                ...context.sortOptions.map((o) => (o.sort === option.sort ? { ...option, selected: true } : o)),
            ],
        };
    },
    /**
     * Updates the context with new query and returns context.
     * @param {SearchCon} context
     * @param {string} query
     * @returns
     */
    updateQuery(context, query) {
        return {
            ...context,
            query: query,
        };
    },
    /**
     * Clears the results array within the SearchContext
     * @param {SearchContext} context
     * @returns
     */
    clearResults(context) {
        return {
            ...context,
            results: [],
        };
    },
    /**
     *
     * @param {SearchContext} context
     * @param {SearchContextAction} action
     * @returns {SearchContext} The updated search context.
     */
    reducer(context, action) {
        switch (action.type) {
            case 'setQuery':
                return {
                    ...context,
                    query: action.payload,
                };
            case 'setSortOptions':
                return {
                    ...context,
                    sortOptions: action.payload,
                };
            case 'setFacets':
                return SearchContext.setFacets(context, action.payload);
            case 'selectFacetOptions':
                return {
                    ...context,
                    facets: {
                        ...context.facets?.map((f) =>
                            action.payload?.find((facet) => facet.id === f.id)
                                ? {
                                      ...f,
                                      options: {
                                          ...f.options?.map((o) =>
                                              o.value === action.payload.value
                                                  ? { ...o, selected: true }
                                                  : { ...o, selected: false }
                                          ),
                                      },
                                  }
                                : f
                        ),
                    },
                };
            case 'setResults':
                return {
                    ...context,
                    results: action.payload.results,
                    pageInfo: action.payload.pageInfo,
                };
            case 'setContext':
                return action.payload;
            default:
                return context;
        }
    },
    /**
     * Executes a search
     * @param {SearchContext} context
     * @returns {PromiseLike<SearchContext>} Returns new search context.
     */
    async search(context) {
        // do the search query

        return context;
    },

    /**
     *
     * @param {Object} response
     * @returns {SearchContext}
     */
    fromSearchApiResponse(context, response, appendResults = false) {
        const newContext = {
            ...context,
            results: appendResults
                ? [...context.results, ...response.searchResults.ContentItems.Items.map(MapSearchResult)]
                : response.searchResults.ContentItems.Items.map(MapSearchResult),
            query: response.searchResults.ContentItems.SearchQuery.TextQuery,
            facets: MapSearchFacets(response.searchResults),
            pageInfo: MapPageInfo(response.searchResults.ContentItems),
            sortOptions: MapSortOptions(response.sortOptions),
        };

        return SearchContext.create(newContext);
    },

    /**
     *
     * @param {Object} response
     * @returns {SearchContext}
     */
    fromProductApiResponse(context, response) {
        const newContext = {
            ...context,
            results: context.results.map((r) =>
                r.product.code === response.Code ? { ...r, product: MapProductData(response) } : r
            ),
        };

        return SearchContext.create(newContext);
    },
};

function MapSortOptions(sortOptions) {
    return sortOptions.map((o) => ({ sort: o.Value, text: o.Text, selected: o.Selected }));
}

function MapPageInfo(contentItems) {
    return {
        count:
            contentItems.PageSize * contentItems.Page > contentItems.TotalItems
                ? contentItems.TotalItems
                : contentItems.PageSize * contentItems.Page,
        total: contentItems.TotalItems,
        page: contentItems.Page,
        totalPages: contentItems.TotalPages,
        pageSize: contentItems.PageSize,
        from: 1,
        to: contentItems.PageSize * contentItems.Page,
        next: {
            from: contentItems.PageSize * contentItems.Page,
            to: contentItems.PageSize * contentItems.Page + contentItems.PageSize,
        },
    };
}

function MapSearchFacets(searchResults) {
    const contentFacets = searchResults.ContentItems?.Facets ?? [];
    const catalogFacets = searchResults.CatalogItems?.Facets ?? [];
    const responseFacets = [...contentFacets, ...catalogFacets];

    return responseFacets.map((f) => ({
        id: f.PropertyName,
        type: MapFacetType(f.SelectorType),
        title: f.DisplayName,
        canSelectMultiple: f.SelectorType.includes('-multiple'),
        options: f.Values.map((v) => ({
            title: v.Label ?? v.Value,
            value: v.Value,
            selected: v.Selected,
            count: v.Count,
        })),
    }));
}

function MapFacetType(type) {
    switch (type) {
        case 'rbl-single':
        case 'rbl-multiple':
            return 'radio';
        case 'bl-single':
        case 'bl-multiple':
            return 'button';
        case 'ddl-multiple':
        case 'ddl-single':
            return 'dropdown';
        case 'tabination':
            return 'tabs';
        case 'cbl-single':
        case 'cbl-multiple':
        default:
            return 'checkbox';
    }
}

function MapSearchResult(result) {
    switch (result.SearchCategory) {
        case 'video':
            return MapVideoSearchResult(result);
        case 'product':
            return MapProductSearchResult(result);
        default:
            return MapContentSearchResult(result);
    }
}

function MapContentSearchResult(result) {
    return {
        title: result.Title,
        url: result.Url,
        type: 'content',
        description: result.Excerpt,
        shortDescription: result.ShortDescription,
        ...(result.DefaultImageUrl ? { imageUrl: result.DefaultImageUrl } : {}),
    };
}

function MapVideoSearchResult(result) {
    return {
        title: result.Title,
        url: result.Url,
        imageUrl: result.ThumbnailUrl ?? result.DefaultImageUrl,
        type: 'video',
        description: result.Excerpt,
        shortDescription: result.ShortDescription,
        controllable: result.Url?.includes('.mp4'),
    };
}

function MapProductPrice(price) {
    const retail = [price.MinListPrice.Amount, price.MaxListPrice.Amount];
    const sale = [price.MinSalePrice.Amount, price.MaxSalePrice.Amount];
    const retailStr = retail ? Formatter.currency(retail, price.ListPrice.Currency) : '';
    const saleStr = sale ? Formatter.currency(sale, price.SalePrice.Currency) : '';

    if (!retail[1] && !sale[1]) return null;
    if ((retail[1] && !sale[1]) || retailStr === saleStr) return { price: retailStr };
    if (sale[1] && !retail[1]) return { price: saleStr };
    return { price: retailStr, reducedPrice: saleStr };
}

function MapProductSearchResult(result) {
    return {
        title: result.Title,
        url: result.Url,
        imageUrl: result.DefaultImageUrl,
        type: 'product',
        description: result.Excerpt,
        shortDescription: result.ShortDescription,
        product: MapProductData(result.ProductInfo),
    };
}

function MapProductData(catalogEntity) {
    return {
        defaultImage: ProductImage.getGlamOrDefaultImageUrl(
            catalogEntity.CatalogMedia,
            catalogEntity.DefaultImageUrl,
            `format=png&width=430&height=430${Product.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''}`
        ),
        secondaryImage: ProductImage.getSecondaryImageUrl(
            catalogEntity.GalleryMedia,
            catalogEntity.DefaultImageUrl,
            `format=png&width=430&height=430${Product.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''}`
        ),
        code: catalogEntity.Code,
        title: catalogEntity.DisplayName,
        rating: catalogEntity.Rating,
        reviewProviderEntityId: catalogEntity.ReviewProviderEntityId,
        url: catalogEntity.ContentUrl,
        badges: catalogEntity.MarketingBadges?.map((badge) => badge.toUpperCase()),
        primaryMarketingTag: catalogEntity.PrimaryMarketingTag,
        ...MapProductPrice(catalogEntity.Price),
        hasChildren: catalogEntity.Children?.length > 0,
        // only doing this to reuse components which were written based on backend catalogentity properties. This should be depricated once those components are refactored
        catalogEntity,
    };
}

const context = React.createContext(null);

/**
 * Retrieves the current search context.
 *
 * @returns {SearchContext} A search context.
 */
export const useSearchContext = () => {
    const searchContext = React.useContext(context);

    if (searchContext == null) {
        throw new Error('Use Search Context needs be used in a search provider.');
    }

    return searchContext;
};

export default function SearchProvider({ children, initialValue, onSearch }) {
    const state = React.useState(initialValue ?? true);

    return (
        <SearchContext.Provider onSearch={onSearch} value={state}>
            {children}
        </SearchContext.Provider>
    );
}

SearchProvider.propTypes = {
    children: px.node,
    initialValue: px.object,
    onSearch: px.func,
};
