import * as React from 'react';
import get from 'lodash.get';
import { useFormState } from 'informed';
import getCountryISO2 from 'country-iso-3-to-2';
import { useTranslation } from 'Common/hooks';
import { ADDRESS as FIELDS } from 'Common/constants/fields';
import VALIDATION_STRINGS from 'Common/constants/validation';
import { Translation } from 'Common/components/localization';

export function getCode(country) {
    return country?.length === 3 ? getCountryISO2(country) : country;
}

export function postalCodeValidator(countryList, code) {
    return countryList?.length
        ? (v = '') => {
              const regexString = countryList.filter((c) => c.CountryCode === code)[0]?.PostalValidationRegex;

              if (!regexString) return undefined;

              const regex = new RegExp(regexString, 'i');

              if (!v) return VALIDATION_STRINGS.required;

              const match = v.match(regex);

              if (!match || match[0] !== v) return VALIDATION_STRINGS.invalidPostalCode;
              return undefined;
          }
        : undefined;
}

export function useAddressForm({
    scope,
    onCountryChange,
    onRegionChange,
    onLocationChange,
    usePlaceholders,
    countryList,
}) {
    const scopeStr = React.useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const { values } = useFormState();
    const country = get(values, `${scopeStr}${FIELDS.country}`);
    const region = get(values, `${scopeStr}${FIELDS.state}`);
    const lblCountry = useTranslation('Commerce.Order.Checkout.Address.SelectCountryLabel');
    const lblRegion = useTranslation('Commerce.Order.Checkout.Address.SelectRegionLabel');
    const lastCountry = React.useRef();
    const lastRegion = React.useRef();
    const [defaultCountry, setDefaultCountry] = React.useState(getCode(country));
    const zipValidator = React.useRef(() => undefined);
    const validateZip = React.useCallback((v) => (zipValidator.current ? zipValidator.current(v) : undefined), []);

    const labelProps = React.useCallback(
        (id, params) =>
            usePlaceholders
                ? { placeholder: id, placeholderParams: params }
                : { label: <Translation id={id} params={params} /> },
        [usePlaceholders]
    );

    React.useEffect(() => {
        zipValidator.current = postalCodeValidator(countryList, country);
    }, [countryList, country]);

    React.useEffect(() => {
        let changed = false;

        if (country !== lastCountry.current) {
            lastCountry.current = country;
            if (country) setDefaultCountry(getCode(country));
            if (onCountryChange) onCountryChange(country);
            changed = true;
        }

        if (region !== lastRegion.current) {
            lastRegion.current = region;
            if (onRegionChange) onRegionChange(region);
            changed = true;
        }

        if (changed && onLocationChange) onLocationChange(country, region);
    }, [onCountryChange, onRegionChange, onLocationChange, country, region]);

    return { prefix: scopeStr, labelProps, defaultCountry, lblRegion, validateZip, lblCountry };
}
