import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import $ from 'jquery';
import { v4 } from 'uuid';
import { Translation } from 'Common/components/localization';
import { Icon } from 'Common/components/ui';

function AccordionDropdown({
    id: idProp,
    className,
    children,
    title,
    header,
    hideChevron = false,
    defaultExpanded = false,
    open = false,
    controlled = false,
    onHeaderClick,
    headerClass,
    bodyClass,
    ctrlRef,
    disabled = false,
}) {
    const id = React.useRef(idProp || `accordion_${v4()}`);
    const showRef = React.useRef(defaultExpanded);
    const controlledRef = React.useRef(controlled);
    const openRef = React.useRef(false);

    const ctrl = React.useRef({
        get id() {
            return id.current;
        },
        open() {
            $(`#${id.current}_drawer`).collapse('show');
            openRef.current = true;
        },
        close() {
            $(`#${id.current}_drawer`).collapse('hide');
            openRef.current = false;
        },
    });

    const onClick = React.useMemo(
        () =>
            controlled
                ? (e) => {
                      if (onHeaderClick) onHeaderClick(e);
                      e.preventDefault();
                  }
                : onHeaderClick,
        [controlled, onHeaderClick]
    );

    React.useEffect(() => {
        controlledRef.current = controlled;
    }, [controlled]);

    React.useEffect(() => {
        if (ctrlRef && !ctrlRef.current) {
            ctrlRef.current = ctrl.current;
        }
    }, [ctrlRef]);

    React.useLayoutEffect(() => {
        if (showRef.current) {
            ctrl.current.open();
        }
    }, []);

    React.useLayoutEffect(() => {
        if (controlledRef.current) {
            if (open && !openRef.current) ctrl.current.open();
            else if (openRef.current) ctrl.current.close();
        }
    }, [open, title]);

    return (
        <div className={cx('AccordionDropdown accordion', { disabled }, className)} id={`${id.current}`}>
            <div className="card">
                <div className="card-header" id={`${id.current}_header`}>
                    <button
                        className={cx('btn', headerClass)}
                        type="button"
                        role="button"
                        data-toggle={controlled ? undefined : 'collapse'}
                        data-target={`#${id.current}_drawer`}
                        aria-expanded="false"
                        aria-controls={`${id.current}_drawer`}
                        onClick={onClick}
                        disabled={disabled}
                    >
                        {header || (
                            <>
                                <span>{typeof title === 'string' ? <Translation id={title} /> : title}</span>
                                {hideChevron ? null : <Icon icon="fa fa-chevron-down" />}
                            </>
                        )}
                    </button>
                </div>
                <div
                    id={`${id.current}_drawer`}
                    className="collapse drawer"
                    aria-labelledby={`${id.current}_header`}
                    data-parent={`#${id.current}`}
                >
                    <div className={cx('card-body', bodyClass)}>{children}</div>
                </div>
            </div>
        </div>
    );
}

AccordionDropdown.propTypes = {
    className: px.string,
    defaultExpanded: px.bool,
    id: px.string,
    ctrlRef: px.shape({ current: px.any }),
    title: px.oneOfType([px.string, px.node]),
    header: px.node,
    children: px.node,
    hideChevron: px.bool,
    onHeaderClick: px.func,
    open: px.bool,
    controlled: px.bool,
    headerClass: px.string,
    bodyClass: px.string,
    disabled: px.bool,
};

const Forwarded = React.forwardRef((props, ref) => <AccordionDropdown {...props} ctrlRef={ref} />);

Forwarded.propTypes = AccordionDropdown.propTypes;
Forwarded.displayName = 'AccordionDropdown';

export default Forwarded;
