export default {
    userEmail: null,
    addresses: [],
    giftCards: [],
    paymentMethods: [],
    customerError: {
        ts: 0,
        message: null,
        type: null,
        translationParams: null,
    },
    customerMessage: {
        ts: 0,
        message: '',
        success: false,
        type: '',
        position: '',
        translationParams: null,
    },
    customerOrders: {
        lastUpdate: 0,
        rmaRequestSuccess: false,
        rmaRequestMessage: null,
        rmaRequestReference: null,
        orders: [],
    },
    notifications: {},
    mailchimp: {},
};
