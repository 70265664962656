import { Facet, FacetList } from '../../models';
import * as types from './types';

export function updateFilteredItems(state, withFilteredItems) {
    return {
        ...state,
        dirty: true,
        lastUpdate: Date.now(),
        filteredItems: state.filteredItems
            ? {
                  ...state.filteredItems,
                  ...withFilteredItems(state.filteredItems),
              }
            : state.filteredItems,
    };
}

export function updateSearchQuery(state, withSearchQuery) {
    return updateFilteredItems(state, (filteredItems) => ({
        SearchQuery: {
            ...filteredItems.SearchQuery,
            ...withSearchQuery(filteredItems.SearchQuery),
        },
    }));
}

export default {
    [types.SET_FILTERED_ITEMS]: (state, filteredItems) => ({
        ...state,
        dirty: false,
        lastUpdate: Date.now(),
        filteredItems,
    }),
    [types.SELECT_FACET]: (state, { facet, value } = {}) => {
        return updateFilteredItems(state, (p) => {
            const facets = FacetList.exclusivelyToggle(p.Facets, Facet.attribute(facet), value.Value);

            return {
                Facets: facets,
                SearchQuery: {
                    ...p.SearchQuery,
                    Facets: facets,
                    Page: 1,
                },
            };
        });
    },
    [types.SELECT_MULTI_FACET]: (state, { facet, value } = {}) => {
        return updateFilteredItems(state, (p) => {
            const facets = FacetList.toggle(p.Facets, Facet.attribute(facet), value.Value);

            return {
                Facets: facets,
                SearchQuery: {
                    ...p.SearchQuery,
                    Facets: facets,
                    Page: 1,
                },
            };
        });
    },
    [types.UPDATE_FACETS]: (state, Facets) =>
        updateFilteredItems(state, (p) => ({ Facets, SearchQuery: { ...p.SearchQuery, Facets, Page: 1 } })),
    [types.SET_PAGE_SIZE]: (state, PageSize) => updateSearchQuery(state, () => ({ PageSize, Page: 1 })),
    [types.SET_PAGE_SORT]: (state, SortOrder) => updateSearchQuery(state, () => ({ SortOrder, Page: 1 })),
    [types.CLEAR_FACET_VALUE]: (state, { facet, value } = {}) => {
        return updateFilteredItems(state, (p) => {
            const facets =
                facet && value
                    ? FacetList.updateFacet(p.Facets, Facet.attribute(facet), (f) =>
                          Facet.deselectValue(f, value.Value)
                      )
                    : FacetList.deselectAll(p.Facets);

            return {
                SearchQuery: {
                    ...p.SearchQuery,
                    Facets: facets,
                    Page: 1,
                },
                Facets: facets,
            };
        });
    },
};
