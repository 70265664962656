import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import DropdownSelector from './DropdownSelector';
import DropdownMenuSelector from './DropdownMenuSelector';
import ColorSelector from './ColorSelector';
import CheckboxSelector from './CheckboxSelector';
import RadioSelector from './RadioSelector';
import ButtonSelector from './ButtonSelector';
import { useTranslation } from 'Common/hooks';

export const SELECTORS = {
    'color-1-single': {
        Comp: ColorSelector,
        props: (facet, onChange, outOfStockToolTip, notAvailableToolTip) => ({
            onSelect: onChange,
            options: facet.FacetValues.map((v) => ({ label: v.Label, value: v.Value })),
            colorValues: facet.FacetColors.map((c) => ({ value: c.Name, uri: c.Swatch, hex: c.Hex })),
            selectedValues: facet.FacetValues.filter((v) => v.Selected).map((v) => v.Value),
            title: facet.AttributeFacetLabel || facet.DisplayName || facet.AttributeDisplayName || facet.Attribute,
            definition: facet.AttributeDefinition,
            notAvailableToolTip: notAvailableToolTip,
            outOfStockToolTip: outOfStockToolTip,
        }),
    },
    'cbl-single': {
        Comp: CheckboxSelector,
        props: (facet, onChange, outOfStockToolTip, notAvailableToolTip) => ({
            onSelect: onChange,
            options: facet.FacetValues.map((v) => ({ label: v.Label, value: v.Value })),
            selectedValues: facet.FacetValues.filter((v) => v.Selected).map((v) => v.Value),
            title: facet.AttributeFacetLabel || facet.DisplayName || facet.AttributeDisplayName || facet.Attribute,
            definition: facet.AttributeDefinition,
            outOfStockToolTip: outOfStockToolTip,
            notAvailableToolTip: notAvailableToolTip,
        }),
    },
    'rbl-single': {
        Comp: RadioSelector,
        props: (facet, onChange, outOfStockToolTip, notAvailableToolTip) => ({
            onSelect: onChange,
            options: facet.FacetValues.map((v) => ({ label: v.Label, value: v.Value })),
            selectedValues: facet.FacetValues.filter((v) => v.Selected).map((v) => v.Value),
            title: facet.AttributeFacetLabel || facet.DisplayName || facet.AttributeDisplayName || facet.Attribute,
            definition: facet.AttributeDefinition,
            notAvailableToolTip: notAvailableToolTip,
            outOfStockToolTip: outOfStockToolTip,
        }),
    },
    'bl-single': {
        Comp: ButtonSelector,
        props: (facet, onChange, outOfStockToolTip, notAvailableToolTip) => ({
            onSelect: onChange,
            options: facet.FacetValues.map((v) => ({ label: v.Label, value: v.Value })),
            selectedValues: facet.FacetValues.filter((v) => v.Selected).map((v) => v.Value),
            title: facet.AttributeFacetLabel || facet.DisplayName || facet.AttributeDisplayName || facet.Attribute,
            definition: facet.AttributeDefinition,
            notAvailableToolTip: notAvailableToolTip,
            outOfStockToolTip: outOfStockToolTip,
        }),
    },
    'ddl-single': {
        Comp: DropdownSelector,
        props: (facet, onChange, outOfStockToolTip, notAvailableToolTip) => ({
            onSelect: onChange,
            options: facet.FacetValues.map((v) => ({ label: v.Label, value: v.Value })),
            selectedValues: facet.FacetValues.filter((v) => v.Selected).map((v) => v.Value),
            title: facet.AttributeFacetLabel || facet.DisplayName || facet.AttributeDisplayName || facet.Attribute,
            definition: facet.AttributeDefinition,
            notAvailableToolTip: notAvailableToolTip,
            outOfStockToolTip: outOfStockToolTip,
        }),
    },
    'ddm-single': {
        Comp: DropdownMenuSelector,
        props: (facet, onChange, outOfStockToolTip, notAvailableToolTip) => ({
            onSelect: onChange,
            options: facet.FacetValues.map((v) => ({ label: v.Label, value: v.Value })),
            selectedValues: facet.FacetValues.filter((v) => v.Selected).map((v) => v.Value),
            title: facet.AttributeFacetLabel || facet.DisplayName || facet.AttributeDisplayName || facet.Attribute,
            definition: facet.AttributeDefinition,
            notAvailableToolTip: notAvailableToolTip,
            outOfStockToolTip: outOfStockToolTip,
        }),
    },
    default: {
        Comp: () => null,
        props: () => ({}),
    },
};

export default function FacetFilter({ className, facet, disabled, onSelect, extraProps }) {
    const outOfStockToolTip = useTranslation('FacetFilter.Value.OutOfStock.ToolTip');
    const notAvailableToolTip = useTranslation('FacetFilter.Value.NotAvailable.ToolTip');
    const onChange = React.useCallback(
        (value) =>
            onSelect(
                facet,
                facet.FacetValues.find((v) => v.Value === value)
            ),
        [facet, onSelect]
    );

    const selector = React.useMemo(
        () => SELECTORS[facet.SelectorControlType || facet.SelectorType] || SELECTORS.default,
        [facet]
    );

    const props = React.useMemo(() => selector.props(facet, onChange, outOfStockToolTip, notAvailableToolTip), [
        selector,
        facet,
        onChange,
        outOfStockToolTip,
        notAvailableToolTip,
    ]);

    return (
        <div className={cx('FacetFilter', className)}>
            <selector.Comp {...props} {...extraProps} disabledMap={disabled} />
        </div>
    );
}

FacetFilter.propTypes = {
    className: px.string,
    facet: types.Facet,
    onSelect: px.func,
    onUpdate: px.func,
    disabled: px.oneOfType([px.bool, px.object]),
    extraProps: px.object,
};
