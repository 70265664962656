import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { product } from '~features';
import { SparePartsFinder as SpareParts } from 'Common/components/product/SpareParts';

export default function SparePartsFinder({ ...props }) {
    const partsLoadCount = useRef(0);
    const [partsLoading, setPartsLoading] = useState(false);

    const startLoader = useCallback(() => {
        partsLoadCount.current += 1;
        setPartsLoading(true);
    }, []);

    const stopLoader = useCallback(() => {
        partsLoadCount.current -= 1;
        if (!partsLoadCount.current) setPartsLoading(false);
    }, []);

    const fetchProductsByNode = useAction(product.actions.fetchProductsByNode, startLoader, stopLoader);
    const setSelectedCode = useAction(product.actions.setSelectedCode);
    const setItemDetails = useAction(product.actions.setItemDetails);
    const clearItemDetails = useAction(product.actions.clearItemDetails);
    const fetchProductDetails = useAction(product.actions.fetchProductDetails, startLoader, stopLoader);
    const fetchSpareParts = useAction(product.actions.fetchSpareParts, startLoader, stopLoader);
    const getProductsByNode = useSelector(product.selectors.getProductsByNode);
    const getChildren = useSelector(product.selectors.getChildren);
    const currentProduct = useSelector(product.selectors.getProduct);
    const selectedCode = useSelector(product.selectors.getSelectedCode);
    const selectedProduct = useSelector(product.selectors.getSelectedProduct);
    const getSpareParts = useSelector(product.selectors.getSpareParts);

    return (
        <SpareParts
            fetchProducts={fetchProductsByNode}
            setSelected={setSelectedCode}
            setItemDetails={setItemDetails}
            clearItemDetails={clearItemDetails}
            fetchProductDetails={fetchProductDetails}
            fetchSpareParts={fetchSpareParts}
            products={getProductsByNode}
            variants={getChildren}
            currentProduct={currentProduct}
            selectedCode={selectedCode}
            selectedProduct={selectedProduct}
            spareParts={getSpareParts}
            partsLoading={partsLoading}
            {...props}
        />
    );
}
