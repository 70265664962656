import { Translation } from 'Common/components/localization';
import * as React from 'react';
import px from 'prop-types';
import { Formatter } from 'Common/utils';

export default function ProductChildTable({ productChildren }) {

    const isPriceExists = productChildren.filter(variation => variation.Price.ListPrice.Amount > 0).length > 0;

    return (
        <div className="w-100 d-flex flex-row justify-content-center d-lg-block">
            <table className="ProductChildListing">
                <thead>
                    <tr>
                        <th className="text-left">
                            <Translation id="Commerce.Product.ChildrenTable.Code" />
                        </th>
                        <th className="text-left">
                            <Translation id="Commerce.Product.ChildrenTable.Description" />
                        </th>
                        {
                            isPriceExists ? <th className="text-right pr-2">
                                <Translation id="Commerce.Product.ChildrenTable.Price" />
                            </th> : null
                        }
                    </tr>
                </thead>
                <tbody>
                    {productChildren.map((variation) => (
                        <tr key={variation.Code}>
                            <td className="text-left pr-4">{variation.Code}</td>
                            <td className="text-left">{variation.DisplayName}</td>
                            {
                                variation.Price.ListPrice.Amount > 0 ? <td className="text-right pl-4 pr-2"> {Formatter.currency(variation.Price.ListPrice.Amount, variation.Price.ListPrice.Currency)}</td> : null
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

ProductChildTable.propTypes = {
    productChildren: px.arrayOf(
        px.shape({
            Code: px.string,
            DisplayName: px.string
        })
    ),
};
