import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Translation } from 'Common/components/localization';

export default function ChildDetail({ className, currentItemStatus, productCode, productTypeId }) {
    const showDetail = React.useMemo(
        () =>
            productTypeId === 'Product'
                ? currentItemStatus && currentItemStatus?.code !== productCode
                : currentItemStatus?.code === productCode,
        [currentItemStatus, productCode, productTypeId]
    );

    const showDivider = React.useMemo(() => currentItemStatus?.statusDisplayName && currentItemStatus?.code, [
        currentItemStatus,
    ]);

    return showDetail ? (
        <p className={cx('ChildDetail paragraph-3', className)}>
            {currentItemStatus.statusDisplayName}
            {showDivider ? ' | ' : null}
            <Translation id="Commerce.Product.Sku.Label" params={{ sku: currentItemStatus.code }} />
        </p>
    ) : null;
}

ChildDetail.propTypes = {
    className: px.string,
    currentItemStatus: px.shape({
        hasStock: px.bool,
        statusDisplayName: px.string,
        code: px.string,
    }),
    productCode: px.string,
    productTypeId: px.string,
};
