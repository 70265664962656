import * as React from 'react';
import $ from 'jquery';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import debounce from 'lodash.debounce';
import { useResize, useTranslation } from 'Common/hooks';

const SCROLL_TOLERANCE = 10;

function pxToNum(str = '') {
    const [, n = '0'] = str.match(/^([\d.]+)[a-z]*$/) || [];

    return Number(n);
}

export function DefaultCarouselItem({ index, selected }) {
    return <span className={cx({ selected })}>{index}</span>;
}

DefaultCarouselItem.propTypes = {
    index: px.number,
    selected: px.bool,
};

export default function Carousel({
    className,
    items = [],
    keyBy = (x) => x,
    onSelectItem,
    Item = DefaultCarouselItem,
    scrollToSelected = false,
    style,
    BtnScrollLeft = <i className="fas fa-chevron-left" aria-hidden="true" />,
    BtnScrollRight = <i className="fas fa-chevron-right" aria-hidden="true" />,
    selectedIndex = 0,
    id = v4(),
    itemProps = {},
    animateScroll = false,
    ...props
}) {
    const dimensions = useResize();
    const idRef = React.useRef(id);
    const firstItem = React.useRef();
    const itemsContainer = React.useRef();
    const innerContainer = React.useRef();
    const mainContainer = React.useRef();
    const [viewState, setViewState] = React.useState({
        itemWidth: 0,
        itemElementWidth: 'auto',
        containerWidth: '100%',
        height: 0,
        viewWidth: 0,
        listWidth: 0,
        scrollLeft: 0,
        canScrollLeft: false,
        canScrollRight: false,
    });

    const [selected, setSelected] = React.useState(selectedIndex);
    const selectedRef = React.useRef(selected);
    const leftLbl = useTranslation('Carousel.Button.ScrollLeft.Label');
    const rightLbl = useTranslation('Carousel.Button.ScrollRight.Label');
    const getKey = React.useCallback(
        (item, idx) =>
            typeof keyBy === 'function'
                ? keyBy(item)
                : typeof keyBy === 'string' && item && keyBy in item
                ? item[keyBy]
                : `${idRef.current}_${idx}`,
        [keyBy]
    );

    const scrollToIndex = React.useCallback(
        (idx) => {
            const pos = Math.max(0, Math.min(viewState.listWidth - viewState.viewWidth, idx * viewState.itemWidth));

            if (animateScroll) {
                $(innerContainer.current).animate({ scrollLeft: pos }, 350);
            } else {
                innerContainer.current.scrollLeft = pos;
            }
        },
        [viewState, animateScroll]
    );

    const selectIndex = React.useRef((idx) => {
        if (scrollToSelected) scrollToIndex(idx);
        setSelected(idx);
    });

    const onClickItem = React.useCallback(
        (item, idx) => () => {
            selectIndex.current(idx);
            if (onSelectItem) onSelectItem(item, idx);
        },
        [onSelectItem]
    );

    const onItemKeyPress = React.useCallback(
        (item, idx) => (e) => {
            if (e.key === 'Enter') {
                selectIndex.current(idx);
                if (onSelectItem) onSelectItem(item, idx);
            }
        },
        [onSelectItem]
    );

    const onKeyPress = React.useCallback(
        (e) => {
            if (e.key === 'ArrowLeft' && selectedRef.current > 0) {
                selectIndex.current(selectedRef.current - 1);
                if (onSelectItem) onSelectItem(selectedRef.current - 1);
                e.stopPropagation();
                e.preventDefault();
            } else if (e.key === 'ArrowRight' && selectedRef.current < items.length - 1) {
                selectIndex.current(selectedRef.current + 1);
                if (onSelectItem) onSelectItem(selectedRef.current + 1);
                e.stopPropagation();
                e.preventDefault();
            }
        },
        [items, onSelectItem]
    );

    const calcState = React.useRef(() => {
        if (firstItem.current && itemsContainer.current && innerContainer.current && mainContainer.current) {
            const child = firstItem.current.children[0];
            const childStyle = getComputedStyle(child);
            let styles = getComputedStyle(firstItem.current);
            const itemElementWidth =
                child.offsetWidth +
                pxToNum(styles.getPropertyValue('padding-left')) +
                pxToNum(styles.getPropertyValue('padding-right')) +
                pxToNum(styles.getPropertyValue('border-left-width')) +
                pxToNum(styles.getPropertyValue('border-right-width')) +
                pxToNum(childStyle.getPropertyValue('margin-left')) +
                pxToNum(childStyle.getPropertyValue('margin-right'));

            const itemWidth =
                itemElementWidth +
                pxToNum(styles.getPropertyValue('margin-left')) +
                pxToNum(styles.getPropertyValue('margin-right'));

            styles = getComputedStyle(itemsContainer.current);
            const height =
                itemsContainer.current.offsetHeight +
                pxToNum(styles.getPropertyValue('margin-top')) +
                pxToNum(styles.getPropertyValue('margin-bottom'));

            const listWidth =
                itemsContainer.current.offsetWidth +
                pxToNum(styles.getPropertyValue('margin-left')) +
                pxToNum(styles.getPropertyValue('margin-right'));

            const viewWidth = innerContainer.current.offsetWidth;
            const scrollLeft = innerContainer.current.scrollLeft;

            styles = getComputedStyle(mainContainer.current);
            const containerWidth =
                Math.floor(
                    (mainContainer.current.clientWidth -
                        pxToNum(styles.getPropertyValue('padding-left')) -
                        pxToNum(styles.getPropertyValue('padding-right'))) /
                        itemWidth
                ) * itemWidth;

            const canScrollLeft = scrollLeft > SCROLL_TOLERANCE;
            const canScrollRight = scrollLeft < listWidth - viewWidth - SCROLL_TOLERANCE;
            const firstIndex = Math.round((scrollLeft + SCROLL_TOLERANCE) / itemWidth);

            setViewState({
                itemElementWidth,
                containerWidth,
                itemWidth,
                height,
                listWidth,
                viewWidth,
                scrollLeft,
                canScrollLeft,
                canScrollRight,
                firstIndex,
            });
        }
    });

    const debouncedCalc = React.useRef(debounce(calcState.current, 25));
    const debouncedReselect = React.useRef(
        debounce(() => setTimeout(() => selectIndex.current(selectedRef.current), 10), 25)
    );

    const scrollLeft = React.useCallback(() => {
        scrollToIndex(viewState.firstIndex - 1);
    }, [scrollToIndex, viewState]);

    const scrollRight = React.useCallback(() => {
        scrollToIndex(viewState.firstIndex + 1);
    }, [scrollToIndex, viewState]);

    const listOffset = React.useMemo(
        () => (viewState.viewWidth > viewState.listWidth ? (viewState.viewWidth - viewState.listWidth) / 2 : 0),
        [viewState]
    );

    React.useEffect(() => {
        selectIndex.current = (idx) => {
            if (scrollToSelected) scrollToIndex(idx);
            setSelected(idx);
        };
    }, [scrollToSelected, scrollToIndex]);

    React.useEffect(() => {
        selectIndex.current(selectedIndex);
    }, [selectedIndex]);

    React.useEffect(() => {
        selectedRef.current = selected;
    }, [selected]);

    React.useLayoutEffect(() => {
        debouncedCalc.current();
        debouncedReselect.current();
    }, [dimensions]);

    React.useEffect(() => {
        setTimeout(calcState.current, 10);
        setTimeout(calcState.current, 50);
    }, []);

    return items.length ? (
        <div
            className={cx('Carousel', className)}
            id={id}
            ref={mainContainer}
            style={{ ...style, height: viewState.height }}
            {...props}
        >
            {viewState.canScrollLeft ? (
                <button className="btn left" onClick={scrollLeft} aria-label={leftLbl}>
                    {BtnScrollLeft}
                </button>
            ) : null}
            {viewState.canScrollRight ? (
                <button className="btn right" onClick={scrollRight} aria-label={rightLbl}>
                    {BtnScrollRight}
                </button>
            ) : null}
            <div
                className="Carousel__container"
                ref={innerContainer}
                onScroll={debouncedCalc.current}
                style={{ width: viewState.containerWidth }}
            >
                <div
                    className="Carousel__container__list"
                    ref={itemsContainer}
                    onKeyUp={onKeyPress}
                    tabIndex="-1"
                    style={{ left: listOffset }}
                >
                    {items.map((item, i) => (
                        <div
                            className={cx('CarouselButton', { selected: selected === i })}
                            data-index={i}
                            style={{ width: viewState.itemElementWidth }}
                            ref={i ? undefined : firstItem}
                            onClick={onClickItem(item, i)}
                            onKeyPress={onItemKeyPress(item, i)}
                            role="button"
                            tabIndex="0"
                            key={getKey(item, i)}
                        >
                            <Item {...itemProps} item={item} index={i} selected={selected === i} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : null;
}

Carousel.propTypes = {
    className: px.string,
    onSelectItem: px.func,
    items: px.arrayOf(px.any),
    keyBy: px.oneOfType([px.func, px.string]),
    Item: px.elementType,
    id: px.string,
    scrollToSelected: px.bool,
    style: px.object,
    BtnScrollLeft: px.node,
    BtnScrollRight: px.node,
    selectedIndex: px.number,
    itemProps: px.objectOf(px.any),
    animateScroll: px.bool,
};
