import React, { useState, useCallback } from 'react';
import { getCountryRegionSelectionData } from 'Common/features/order/api';
import useCountryList from './useCountryList';

export default function useRegionSelection({
    getCountry,
    setCountry,
    setRegion,
    getRegion,
    siteId,
    enabledRef = null,
    enabled = !enabledRef,
}) {
    const countryList = useCountryList({ siteId, enabledRef, enabled });
    const [regionOptions, setRegionOptions] = useState();

    const fetchRegions = useCallback(
        async (countryCode) => {
            if (countryCode && countryCode !== regionOptions?.CountryCode) {
                const region = getRegion();
                const response = await getCountryRegionSelectionData(siteId, countryCode);

                if (response) {
                    setRegionOptions(response);
                    if (region && !Object.keys(response.Regions).includes(region)) setRegion(null);
                } else {
                    setRegion(null);
                    setRegionOptions({ CountryCode: null });
                }
            }
        },
        [setRegion, siteId, regionOptions, getRegion]
    );

    React.useEffect(() => {
        const current = getCountry();

        if (countryList?.length) {
            const v = countryList[0] || null;

            if (current || v) {
                if (!current) setCountry(v.CountryCode, v);
                fetchRegions(current || v.CountryCode);
            } else {
                setRegion(null);
            }
        } else if (countryList) {
            setCountry(null);
            setRegion(null);
        }
    }, [countryList, getCountry, fetchRegions, setCountry, setRegion]);

    return { countryList, regionOptions: regionOptions?.Regions, fetchRegions };
}
