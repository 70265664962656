import React from 'react';
import px from 'prop-types';
import cx from 'classnames';

const drawerTypes = {
    float: 'float',
    relative: 'relative',
};

export default function Dropdown({
    className,
    style,
    icon,
    label,
    isOpen = false,
    toggleOpen,
    selectedValue,
    children,
    drawerType = drawerTypes.float,
    disabled = false,
    hasFacetsDropdown = false,
}) {
    const drawerId = React.useRef();

    React.useLayoutEffect(() => {
        if (!drawerId.current || drawerType == drawerTypes.float) return;
        if (!isOpen) {
            drawerId.current.style.height = '0px';
            return;
        }

        const child = drawerId.current.firstChild;

        drawerId.current.style.height = `${child.offsetHeight}px`;
    }, [drawerType, isOpen]);

    return (
        <div style={style} className={cx(className, 'Dropdown')}>
            {label && <label>{label}</label>}
            <div className={hasFacetsDropdown ? cx('FilterDropdown', { isOpen } ) : "Dropdown__container"}>
                <button onClick={toggleOpen} className="Dropdown__button btn" disabled={disabled}>
                    <div>{selectedValue}</div>
                    {icon}
                </button>
                <div ref={drawerId} className={cx('Dropdown__drawer', isOpen ? 'open' : null, drawerType)}>
                    <div>{children}</div>
                </div>
            </div>
        </div>
    );
}

Dropdown.propTypes = {
    style: px.object,
    className: px.string,
    label: px.string,
    icon: px.node,
    isOpen: px.bool.isRequired,
    selectedValue: px.node,
    toggleOpen: px.func.isRequired,
    children: px.node.isRequired,
    drawerType: px.oneOf([drawerTypes.float, drawerTypes.relative]),
    disabled: px.bool,
    hasFacetsDropdown: px.bool
};
