import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useCurrentIssuer, useAvailableIssuers } from 'Common/hooks/payment';
import { CreditCard } from 'Common/utils';
import { Icon } from 'Common/components/ui';

export default function CreditCardIssuers({ options, scope, className, ...rest }) {
    const availableIssuers = useAvailableIssuers(options);
    const currentIssuer = useCurrentIssuer(scope);

    return (
        <div {...rest} className={cx('CreditCardIssuers', className)}>
            {availableIssuers.map((issuer) => (
                <Icon
                    key={issuer}
                    className={cx({ active: CreditCard.isEqual(currentIssuer, issuer) })}
                    icon={CreditCard.getIcon(issuer)}
                />
            ))}
        </div>
    );
}

CreditCardIssuers.propTypes = {
    scope: px.string,
    options: px.shape({
        EnabledCardTypes: px.string,
    }),
    className: px.string,
};
