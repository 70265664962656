import px from 'prop-types';
import { ADDRESS } from 'Common/constants/fields';

export const AddressShape = {
    [ADDRESS.id]: px.oneOfType([px.number, px.string]),
    [ADDRESS.firstName]: px.string,
    [ADDRESS.lastName]: px.string,
    [ADDRESS.company]: px.string,
    [ADDRESS.line1]: px.string,
    [ADDRESS.line2]: px.string,
    [ADDRESS.city]: px.string,
    [ADDRESS.state]: px.string,
    [ADDRESS.country]: px.string,
    [ADDRESS.phone]: px.string,
    [ADDRESS.zip]: px.string,
    [ADDRESS.defaultAddress]: px.bool,
    [ADDRESS.email]: px.string,
    [ADDRESS.isStreetAddress]: px.bool,
};

export default px.shape(AddressShape);
