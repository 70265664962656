import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useTranslation } from 'Common/hooks';
import { FilterDropdown } from '../ui';
import MobileFiltersMenu from './MobileFiltersMenu';
import { Translation } from '../localization';

const PAGE_OPTIONS = [{ Value: 2 }, { Value: 10 }, { Value: 25 }, { Value: 50 }, { Value: 0 }];

const SORT_OPTIONS = [{ Value: 1, Text: 'A to Z' }];

function convertPaginationOptions(options = [], pageInfo = {}) {
    return options?.map((p) => ({
        value: p.Value === 0 ? pageInfo.total : p.Value,
        label: p.label || p.Text || p.value || p.Value,
    }));
}

function convertSortOptions(options = []) {
    return options.map((p) => ({
        value: p.value || p.Value,
        label: p.label || p.Text || p.value || p.Value,
    }));
}

export default function MobileCatalogFilters({
    className,
    facets = [],
    hidePagination = false,
    hideSort = false,
    onUpdateFacets,
    onUpdatePageSize,
    onUpdateSort,
    pageInfo = {},
    paginationOptions = PAGE_OPTIONS,
    sortOptions = SORT_OPTIONS,
}) {
    const mobileSortBy = useTranslation('Commerce.CatalogNode.Filters.MobileSortBy');
    const [showMenu, setShowMenu] = React.useState(false);
    const pageOpts = React.useMemo(
        () => convertPaginationOptions(paginationOptions, pageInfo),
        [paginationOptions, pageInfo]
    );

    const sortOpts = React.useMemo(() => convertSortOptions(sortOptions), [sortOptions]);
    const curPageOpt = React.useMemo(() => pageOpts.find((p) => p.value === pageInfo.pageSize), [pageOpts, pageInfo]);
    const onApplyFilters = React.useCallback(
        (Facets) => {
            onUpdateFacets(Facets);
            setShowMenu(false);
        },
        [setShowMenu, onUpdateFacets]
    );

    return (
        <div className={cx('MobileCatalogFilters Mobile CatalogFilters', className)}>
            {facets?.length ? (
                <>
                    <MobileFiltersMenu
                        open={showMenu}
                        facets={facets}
                        onApplyFilters={onApplyFilters}
                        onCancel={() => setShowMenu(false)}
                    />
                    <div className="Mobile CatalogFilters__menuBtn">
                        <button
                            className="Mobile CatalogFilters__btnFilterMenu btn btn-sm"
                            onClick={() => setShowMenu(true)}
                        >
                            <i className="fas fa-ellipsis-v" aria-hidden="true" style={{ fontSize: '.8em' }} />
                            &nbsp;
                            <Translation id="Commerce.CatalogNode.Filters.MobileFilterBtn" />
                        </button>
                    </div>
                    <div className="divider" />
                </>
            ) : null}
            {!hidePagination ? (
                <div className="Mobile CatalogFilters__paging">
                    <FilterDropdown
                        title={curPageOpt?.label || pageInfo.pageSize}
                        selectedValues={[pageInfo.pageSize]}
                        options={pageOpts}
                        onSelect={onUpdatePageSize}
                    />
                </div>
            ) : null}
            {!hidePagination && !hideSort ? <div className="divider" /> : null}
            {!hideSort ? (
                <div className="Mobile CatalogFilters__sort">
                    <FilterDropdown
                        anchor="right"
                        title={mobileSortBy}
                        selectedValues={[pageInfo.sortValue]}
                        options={sortOpts}
                        onSelect={onUpdateSort}
                    />
                </div>
            ) : null}
        </div>
    );
}

MobileCatalogFilters.propTypes = {
    className: px.string,
    facets: px.arrayOf(types.Facet),
    hidePagination: px.bool,
    hideSort: px.bool,
    onUpdateFacets: px.func,
    onUpdateSort: px.func,
    onUpdatePageSize: px.func,
    pageInfo: types.PageInfo,
    paginationOptions: px.arrayOf(
        px.shape({
            Text: px.string,
            Value: px.number,
        })
    ),
    sortOptions: px.arrayOf(
        px.shape({
            Text: px.string,
            Value: px.number,
        })
    ),
};
