export default {
    items: [],
    isLoading: false,
    isError: false,
    alert: {
        message: {
            translationKey: undefined,
            params: {},
            type: '',
        },
        timestamp: undefined,
    },
};
