import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Url } from 'Common/utils';
import VideoPlayer from './VideoPlayer';
import Icon from './Icon';

export default function MediaConverter({
    autoPlayVideo = false,
    className,
    imageResizeSearchQuery = '',
    media,
    onSelectSpin,
    onSelectPhoto,
    renderVideoAsThumb = false,
    renderSpinAsThumb = false,
}) {
    if (!(media && media.Url && media.FileType)) return null;
    if (media.FileType === 'spin') {
        return renderSpinAsThumb ? (
            media.ThumbnailUrl ? (
                <div onClick={onSelectSpin} className={cx('MediaConverter spin', className)}>
                    <div className="spin-thumb">
                        <Icon name="rotate-360" />
                    </div>
                    <img src={media.ThumbnailUrl} aria-hidden alt="" />
                </div>
            ) : null
        ) : (
            <div className={cx('MediaConverter', 'Sirv')} data-src={media.Url}></div>
        );
    }
    if (media.MediaType && media.Url) {
        if (media.MediaType === 'image') {
            return (
                <img
                    className={cx('MediaConverter', className)}
                    onClick={onSelectPhoto}
                    src={Url.addToRelativeUrl(media.Url, imageResizeSearchQuery)}
                    aria-hidden
                    alt=""
                />
            );
        }
        if (media.MediaType === 'video') {
            return renderVideoAsThumb ? (
                media.ThumbnailUrl ? (
                    <div className={cx('MediaConverter video', className)}>
                        <div className="vid-thumb">
                            <i className="fas fa-play-circle"></i>
                        </div>
                        <img
                            src={Url.addToRelativeUrl(media.ThumbnailUrl, imageResizeSearchQuery)}
                            aria-hidden
                            alt=""
                        />
                    </div>
                ) : null
            ) : (
                <VideoPlayer
                    className={`MediaConverter ${className}`}
                    url={media.Url}
                    previewImage={media.FileType === 'mp4' ? media.ThumbnailUrl || null : null}
                    playing={autoPlayVideo}
                    showControls
                />
            );
        }
    }
    return null;
}

MediaConverter.propTypes = {
    autoPlayVideo: px.bool,
    className: px.string,
    imageResizeSearchQuery: px.string,
    media: px.shape({
        MediaType: px.string,
        MediaGroup: px.string,
        Url: px.string,
        FileType: px.string,
        Order: px.number,
        IsDefault: px.bool,
        ThumbnailUrl: px.string,
    }),
    onSelectSpin: px.func,
    onSelectPhoto: px.func,
    renderVideoAsThumb: px.bool,
    renderSpinAsThumb: px.bool,
};
