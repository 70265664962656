import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

export default function FilterDropdown({
    anchor = 'left',
    autoClose = true,
    className,
    ctrlType = 'button',
    disabled = false,
    hasFacetsDropdown = false,
    includeCloseIcon = false,
    onSelect,
    options = [],
    selectedValues = [],
    startExpanded = false,
    title = '',
}) {
    const [expanded, setExpanded] = React.useState(startExpanded);
    const clickedRef = React.useRef(false);

    const onClick = React.useCallback(
        (value) => (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onSelect) onSelect(value);
            if (autoClose) setExpanded(false);
        },
        [onSelect, autoClose]
    );

    const btnType = React.useMemo(() => {
        if (ctrlType.includes('cb')) return 'checkbox';
        if (ctrlType.includes('rb')) return 'radio';
        return 'button';
    }, [ctrlType]);

    const toggleFilter = React.useCallback(() => {
        clickedRef.current = !expanded;
        setExpanded(clickedRef.current);
    }, [expanded]);

    React.useEffect(() => {
        function onWindowClick() {
            if (expanded && !clickedRef.current && !startExpanded) {
                setExpanded(false);
            }

            clickedRef.current = false;
        }

        window.addEventListener('click', onWindowClick);
        return () => window.removeEventListener('click', onWindowClick);
    }, [expanded, startExpanded]);

    return (
        <div className={cx('FilterDropdown', className, { expanded })}>
            <button
                className={hasFacetsDropdown ? 'facet-btn btn btn-sm' : "btn btn-sm"}
                type="button"
                role="dropdown"
                aria-expanded={expanded}
                aria-controls="dropdown-selector"
                onClick={startExpanded ? () => setExpanded(!expanded) : toggleFilter}
            >
                <span>{title}</span>
                <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} aria-hidden="true" />
            </button>
            {expanded ? (
                <div className="FilterDropdown__button-list" style={{ [ anchor ]: 0 }}>
                    {btnType === 'button'
                        ? options.map((option) => (

                            <button
                                key={option?.value || option}
                                type="button"
                                role="button"
                                className={cx('btn btn-sm', {
                                    disabled: disabled === true || (disabled && disabled[option?.value || option]),
                                    selected: selectedValues.includes(option?.value || option),
                                })}
                                onClick={onClick(option?.value || option)}
                            >
                                {selectedValues.includes(option.value) && includeCloseIcon ? (
                                    <span className="mx-1">
                                        <i className="fa fa-times"></i>
                                    </span>
                                ) : null}
                                <span>{option?.label || option}</span>
                            </button>
                        ))
                        : options.map((option) => (
                            <label
                                key={option?.label || option}
                                className={cx({
                                    disabled: disabled === true || (disabled && disabled[option?.value || option]),
                                })}
                                onClick={onClick(option?.value || option)}
                            >
                                <input
                                    key={Date.now()}
                                    type={btnType}
                                    checked={selectedValues.includes(option?.value || option)}
                                    onChange={() => {}}
                                />
                                {selectedValues.includes(option.value) && includeCloseIcon ? (
                                    <span className="mx-1">
                                        <i className="fa fa-times"></i>
                                    </span>
                                ) : null}
                                <span>{option?.label || option}</span>
                            </label>
                        ))}
                </div>
            ) : null}
        </div>
    );
}

FilterDropdown.propTypes = {
    anchor: px.string,
    autoClose: px.bool,
    className: px.string,
    ctrlType: px.string,
    disabled: px.oneOfType([px.bool, px.objectOf(px.bool)]),
    hasFacetsDropdown: px.bool,
    includeCloseIcon: px.bool,
    onSelect: px.func,
    options: px.arrayOf(px.any),
    selectedValues: px.arrayOf(px.oneOfType([px.string, px.number])),
    startExpanded: px.bool,
    title: px.node,
};
