import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { ProductRecommendations as Recommendations } from 'Common/components/product';
import { useAction } from 'Common/hooks';
import { RecommendedProductCard as ProductCard, ProductPartialView } from '~components/product';
import { order, product } from '~features';
import { Translation } from 'Common/components/localization';

export default function ProductRecommendations({ className, isUserAuthenticated, checkoutPageLink, useRelatedEntries=false, ...props }) {
    const trackProductQuickviewClick = useAction(product.actions.trackProductQuickviewClick);
    const products = useSelector(product.selectors.getEpiRecommedationCrossSellProducts);
    const totalQty = useSelector(order.selectors.getTotalQuantity);

    const fetched = React.useRef(null);
    const currentCode = useSelector(product.selectors.getSelectedCode);
    const relatedProducts = useSelector(product.selectors.getRelatedProducts);
    const fetchRelatedProducts = useAction(product.actions.fetchRelatedProducts);

    React.useEffect(() => {
        if (!relatedProducts && currentCode && fetched.current !== currentCode) {
            fetched.current = currentCode;
            fetchRelatedProducts(currentCode);
        }
    }, [relatedProducts, fetchRelatedProducts, currentCode]);   

    const displayNone = React.useMemo(() => {
        return (useRelatedEntries && !relatedProducts?.length) || (!useRelatedEntries && !products?.length);
        }, [useRelatedEntries, relatedProducts, products]);

    return displayNone ? null : (
        <>
            {useRelatedEntries ? (
                <div className="ProductRecommendations__header mb-4">
                    <Translation id="Commerce.Product.Recommended.Crossell.Title" />
                </div>
            ) : (
                <h6 className="mb-4">
                    <Translation id="Commerce.Product.Recommended.Products.Title" />
                </h6>
            )}
            
            <Recommendations
                className={className}
                checkoutPageLink={checkoutPageLink}
                isUserAuthenticated={isUserAuthenticated === 'True'}
                ProductPartialView={ProductPartialView}
                products={useRelatedEntries ? relatedProducts : products}
                RecommendedProductCard={ProductCard}
                trackProductQuickviewClick={trackProductQuickviewClick}
                totalQty={totalQty}
                useRelatedEntries={useRelatedEntries}
                {...props}
            />
        </>
    ) 
}

ProductRecommendations.propTypes = {
    className: px.string,
    checkoutPageLink: px.string,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    useRelatedEntries: px.bool
};
