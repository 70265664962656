import useTranslation from '../useTranslation';
import { Formatter } from 'Common/utils';
import { useMemo } from 'react';

export default function useExpDateFormat(defaultFormat = 'MM / YY') {
    const dateFormat = useTranslation('Commerce.Order.Checkout.ExpirationDate.Format', null, {
        notFoundValue: defaultFormat,
    });

    return useMemo(() => (dateFormat ? Formatter.createMonthYearFormatter(dateFormat) : { formatStr: dateFormat }), [
        dateFormat,
    ]);
}
