import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { CartSummary as Summary } from 'Common/components/cart';
import { order } from '~features';

export default function CartSummary({ bottomSeparator, checkoutLink }) {
    const cartSummaryData = useSelector(order.selectors.getCartSummaryData);
    const currency = useSelector(order.selectors.getCurrencyCode);
    const totalQty = useSelector(order.selectors.getAllItemQty);
    const availablePaymentTypes = useSelector(order.selectors.getAvailablePaymentTypes);

    return (
        <Summary
            disabled={!totalQty}
            cartSummaryData={cartSummaryData}
            currency={currency}
            bottomSeparator={bottomSeparator}
            checkoutLink={checkoutLink}
            availablePaymentTypes={availablePaymentTypes}
        />
    );
}

CartSummary.propTypes = {
    bottomSeparator: px.bool,
    checkoutLink: px.string,
};
