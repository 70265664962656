import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Relevant } from 'informed';
import { useCreditCard } from 'Common/hooks/payment';
import { Name, AddressUtil } from 'Common/utils';
import { PAYMENT, ADDRESS } from 'Common/constants/fields';
import { Field, PhoneField } from 'Common/components/forms';
import { CreditCardIssuers } from '../../display';
import ExpDateForm from './ExpDateForm';
import { validateNumber } from './helpers';

export default function CardForm({ options, vertical, scope, usePlaceholders = false, hideExtendedFields = false }) {
    const { isProcessor, labelProps, numberMask, prefix, currentIssuer, validateCvv } = useCreditCard({
        scope,
        options,
        usePlaceholders,
    });

    const nameClass = React.useMemo(() => (vertical ? 'col-12' : 'col-12 col-lg-6'), [vertical]);
    const dateClass = React.useMemo(() => (vertical ? 'col-8' : 'col-6 col-lg-3'), [vertical]);
    const cvvClass = React.useMemo(() => (vertical ? 'col-4' : 'col-6 col-lg-3'), [vertical]);
    const zipClass = React.useMemo(() => (vertical ? 'col-6' : 'col-12 col-lg-3'), [vertical]);

    return (
        <>
            <Relevant when={isProcessor('Paymetric')}>
                <Field
                    required
                    hidden
                    name={`${prefix}${PAYMENT.merchantGuid}`}
                    value={options.XiInterceptMerchantGuid}
                />
            </Relevant>

            <Field required hidden name={`${prefix}${PAYMENT.cardType}`} value={currentIssuer} validateOnValueChange />

            {hideExtendedFields ? null : (
                <>
                    <Field
                        className={nameClass}
                        name={`${prefix}${PAYMENT.address}.${ADDRESS.firstName}`}
                        {...labelProps('Commerce.Order.Checkout.Payments.Card.FirstName.Label')}
                        transform={Name.cleanString}
                        autocomplete="cc-given-name"
                        required
                    />
                    <Field
                        className={nameClass}
                        name={`${prefix}${PAYMENT.address}.${ADDRESS.lastName}`}
                        {...labelProps('Commerce.Order.Checkout.Payments.Card.LastName.Label')}
                        transform={Name.cleanString}
                        autocomplete="cc-family-name"
                        required
                    />
                </>
            )}

            <CreditCardIssuers className={cx('FormImageContainer', nameClass)} options={options} />

            {vertical ? null : <div className="d-none d-lg-block col-6">&nbsp;</div>}

            <Field
                className={nameClass}
                name={`${prefix}${PAYMENT.cardNumber}`}
                validate={validateNumber}
                mask={numberMask}
                validateWhen={[`${prefix}${PAYMENT.cardType}`]}
                autocomplete="cc-number"
                inputMode="numeric"
                {...labelProps('Commerce.Order.Checkout.Payments.Card.Number.Label')}
                hookAutofill
                trimOnInput
                required
            />

            <ExpDateForm
                className={dateClass}
                scope={scope}
                {...labelProps('Commerce.Order.Checkout.Payments.Card.Date.Label')}
                inputMode="numeric"
                maintainCursor
                hookAutofill
                trimOnInput
                required
            />
            <Field
                className={cvvClass}
                name={`${prefix}${PAYMENT.cardCvv}`}
                validate={validateCvv}
                validateWhen={[`${prefix}${PAYMENT.cardType}`]}
                autocomplete="cc-csc"
                inputMode="numeric"
                {...labelProps('Commerce.Order.Checkout.Payments.Card.VerificationCode.Label')}
                hookAutofill
                trimOnInput
                required
            />

            {hideExtendedFields ? null : (
                <>
                    <Field
                        className={zipClass}
                        name={`${prefix}${PAYMENT.address}.${ADDRESS.zip}`}
                        {...labelProps('Commerce.Order.Checkout.Payments.Card.PostalCode.Label')}
                        transform={AddressUtil.cleanPostalString}
                        autocomplete="postal-code"
                        required
                    />
                    <Field
                        className={zipClass}
                        name={`${prefix}${PAYMENT.address}.${ADDRESS.phone}`}
                        {...labelProps('Commerce.Order.Checkout.Payments.Card.Phone.Label')}
                        Component={PhoneField}
                        validate={AddressUtil.getPhoneValidationError}
                        autoComplete="tel"
                        required
                    />
                </>
            )}
        </>
    );
}

CardForm.propTypes = {
    options: px.shape({
        EnabledCardTypes: px.string,
        EnvKey: px.string,
        XiInterceptMerchantGuid: px.string,
        XiInterceptUrl: px.string,
        XiInterceptCallbackUrl: px.string,
    }),
    vertical: px.bool,
    scope: px.string,
    hideExtendedFields: px.bool,
    usePlaceholders: px.bool,
};
