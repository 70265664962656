import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Facet } from 'Common/types';
import SearchCheckboxFacet from './SearchCheckboxFacet';
import SearchRadioFacet from './SearchRadioFacet';
import SearchDropdownFacet from './SearchDropdownFacet';

const facetViews = {
    checkbox: SearchCheckboxFacet,
    dropdown: SearchDropdownFacet,
    button: SearchDropdownFacet,
    radio: SearchRadioFacet,
    tabs: null,
};

export default function SearchFacet({ className, style, facet, ...props }) {
    const Component = React.useMemo(() => facetViews[facet.type] ?? SearchCheckboxFacet, [facet]);

    return <Component className={cx('SearchFacet', className)} style={style} facet={facet} {...props} />;
}

SearchFacet.propTypes = {
    style: px.object,
    className: px.string,
    facet: Facet,
};
