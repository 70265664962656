import * as React from 'react';
import omit from 'lodash.omit';
import { verifyAddress } from 'Common/features/order/api';
import { SHIPMENT } from 'Common/constants/fields';
import { AddressVerification } from 'Common/models';

export default function useAddressVerification({ onUpdate }) {
    const complete = React.useRef(false);
    const success = React.useRef(false);
    const errors = React.useRef([]);
    const [verification, setVerification] = React.useState({
        shipment: null,
        verifiedAddresses: [],
        unverifiedAddress: null,
    });

    const resolved = React.useCallback(
        () =>
            new Promise((res) => {
                const handler = setInterval(() => {
                    if (complete.current) {
                        clearInterval(handler);
                        res({ success: success.current, errors: errors.current });
                    }
                }, 500);
            }),
        []
    );

    const onConfirmShipment = React.useCallback(
        async (form) => {
            try {
                const res = await onUpdate(
                    !verification.shipment
                        ? form
                        : verification.shipment[SHIPMENT.address] && !form[SHIPMENT.address]
                        ? {
                              ...verification.shipment,
                              [SHIPMENT.address]: { ...verification.shipment[SHIPMENT.address], ...form },
                          }
                        : form[SHIPMENT.address] && !verification.shipment[SHIPMENT.address]
                        ? { ...verification.shipment, ...form[SHIPMENT.address] }
                        : form[SHIPMENT.address]
                        ? {
                              ...verification.shipment,
                              ...form,
                              [SHIPMENT.address]: {
                                  ...verification.shipment[SHIPMENT.address],
                                  ...form[SHIPMENT.address],
                              },
                          }
                        : { ...verification.shipment, ...form }
                );

                success.current = typeof res === 'boolean' ? res : res?.success ?? true;
                if (res?.error) errors.current.push(res.error);
                if (res?.errors?.length) errors.current.push(...res.errors);
                setVerification({ shipment: null, verifiedAddresses: [], unverifiedAddress: null });
            } catch (e) {
                errors.current.push(e?.message || e);
                success.current = false;
            }
            complete.current = true;
            return success.current;
        },
        [onUpdate, verification, setVerification]
    );

    const onCancelVerification = React.useCallback(() => {
        setVerification({ shipment: null, verifiedAddresses: [], unverifiedAddress: null });
        success.current = false;
        complete.current = true;
    }, []);

    const onValidateShipment = React.useCallback(
        async (form = {}, skipValidation = false) => {
            complete.current = false;
            success.current = false;
            errors.current = [];

            if (skipValidation || form[SHIPMENT.isDigital] || form._static?.isDigital) {
                onConfirmShipment(form);
                return resolved();
            }

            const response = await verifyAddress(omit(form[SHIPMENT.address] ?? form, '_static'));

            if (response?.success) {
                const verifiedUserInput = AddressVerification.verifyUserInput(response.addressVerification);

                if (verifiedUserInput != null) {
                    if (!verifiedUserInput.IsStreetAddress) {
                        errors.current.push('Commerce.Order.Checkout.ShippingAddressVerification.StreetAddressWarning');
                        complete.current = true;
                    } else {
                        onConfirmShipment(form);
                    }
                } else {
                    setVerification({
                        shipment: form,
                        verifiedAddresses: response.addressVerification?.VerifiedAddresses ?? [],
                        unverifiedAddress: response.addressVerification?.Address,
                    });
                }
            } else {
                errors.current.push(response?.message || 'Commerce.Order.Cart.AddressVerification.FailureMessage');
                complete.current = true;
            }
            return resolved();
        },
        [onConfirmShipment, resolved]
    );

    return { verification, onValidateShipment, onConfirmShipment, onCancelVerification };
}
