import { Api } from 'Common/utils';

export function searchProducts(searchQuery) {
    return Api.POST('/catalog/searchfilter', searchQuery);
}

/**
 * @param {string|string[]} codes
 */
export function getProduct(codes) {
    const codeArr = typeof codes === 'string' ? [codes] : codes;
    const params = new URLSearchParams();

    for (const code of codeArr) params.append('code', code);

    return Api.GET('/product', params);
}
