import * as React from 'react';
import { useSelector } from 'react-redux';
import { Translation } from 'Common/components/localization';
import { UserMessage } from 'Common/components/ui';
import { customer } from '~features';

export default function CustomerError() {
    const error = useSelector(customer.selectors.getCustomerError);

    return (
        <UserMessage type={error.type} timestamp={error.ts}>
            <Translation id={error.message} params={error.translationParams} />
        </UserMessage>
    );
}
