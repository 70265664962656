import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useViewport, useTranslation } from 'Common/hooks';
import $ from 'jquery';

function Swatch({ option, colorValues }) {
    const colorValue = React.useMemo(() => colorValues.find((v) => v.value === option.value), [option, colorValues]);

    if (colorValue?.uri) {
        return (
            <div
                style={{
                    backgroundImage: `url('${colorValue.uri}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
        );
    }

    if (colorValue?.hex) {
        return <div style={{ backgroundColor: colorValue.hex }} />;
    }

    return <i className="fa fa-question" />;
}

Swatch.propTypes = {
    option: px.shape({ value: px.any }),
    colorValues: px.arrayOf(px.shape({ value: px.any, hex: px.string, uri: px.string })),
};

export default function ColorSelector({
    className,
    options = [],
    colorValues = [],
    selectedValues = [],
    title = '',
    onSelect,
    outOfStockToolTip,
    notAvailableToolTip,
    disabledMap,
    definition = null,
}) {
    const viewport = useViewport();
    const swatchesRef = React.useRef(null);
    const lblExpand = useTranslation('Commerce.Product.Selector.Expand.Label');
    const lblHide = useTranslation('Commerce.Product.Selector.Hide.Label');

    const optionIsDisabled = React.useCallback(
        ({ value } = {}) =>
            disabledMap && (disabledMap === true || disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid),
        [disabledMap]
    );

    const onClick = React.useCallback((value) => (onSelect ? () => onSelect(value) : null), [onSelect]);

    const [expanded, setExpanded] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
    const [height, setHeight] = React.useState('auto');

    React.useEffect(() => {
        if (expanded) setHeight(swatchesRef.current.children[0].offsetHeight);
        else setHeight(46);
    }, [expanded, viewport]);

    React.useEffect(() => {
        const swatchesContainer = $(swatchesRef.current).children().first();
        const children = swatchesContainer.children();

        for (let i = 0; i < children.length; i++) {
            if (i > 0 && children[i - 1].offsetTop < children[i].offsetTop) {
                setHidden(true);
                return;
            }
        }
        setHidden(false);
    }, [viewport, options, setHidden]);

    const buttonTitle = React.useCallback(
        (value) => {
            if (disabledMap && (disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid))
                return `${value} (${notAvailableToolTip})`;
            else if (disabledMap && !disabledMap[value]?.hasStock) return `${value} (${outOfStockToolTip})`;

            return value;
        },
        [outOfStockToolTip, notAvailableToolTip, disabledMap]
    );

    return (
        <div className={cx('ColorSelector', className)}>
            <p className="mb-0">
                {title}
                <span className="ml-1">{selectedValues.length === 1 && selectedValues[0] ? selectedValues : null}</span>
            </p>
            <div className="ColorSelector__Swatches position-relative">
                <div
                    ref={swatchesRef}
                    className="btn-toolbar justify-content-center justify-content-md-start"
                    role="toolbar"
                    style={{ height: `${height}px` }}
                >
                    <div className="btn-group d-block flex-wrap" role="group">
                        {options.map((option) => (
                            <button
                                key={option.value}
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={buttonTitle(option.label)}
                                aria-label={option.label}
                                className={cx('btn', {
                                    disabled: !disabledMap[option.value]?.hasStock || optionIsDisabled(option),
                                    oos: !disabledMap[option.value]?.hasStock,
                                    selected: selectedValues.includes(option.value),
                                })}
                                onClick={onClick(option.value)}
                            >
                                <Swatch option={option} colorValues={colorValues} />
                            </button>
                        ))}
                    </div>
                    {hidden ? (
                        <button
                            className="btn dropdown"
                            type="button"
                            onClick={() => setExpanded(!expanded)}
                            aria-label={expanded ? lblHide : lblExpand}
                            title={expanded ? lblHide : lblExpand}
                        >
                            <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
                        </button>
                    ) : null}
                </div>
            </div>
            {definition ? (
                <div className="definition mt-2">
                    <p className="light-sm">{definition}</p>
                </div>
            ) : null}
        </div>
    );
}

ColorSelector.propTypes = {
    className: px.string,
    options: px.arrayOf(px.shape({ label: px.string, value: px.string })),
    colorValues: px.arrayOf(px.object),
    selectedValues: px.arrayOf(px.oneOfType([px.string, px.number])),
    title: px.string,
    onSelect: px.func,
    outOfStockToolTip: px.string,
    notAvailableToolTip: px.string,
    disabledMap: px.objectOf(px.object),
    definition: px.node,
};
