import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import Default from './Default';

const Components = {
    Default,
};

export default function CatalogCTA({ type, className, ...props }) {
    const Component = Components[type] || Components.Default;

    return <Component className={cx('CatalogCTA', className)} {...props} />;
}

CatalogCTA.propTypes = {
    className: px.string,
    type: px.string,
};
