export default class OrderNumber {
    static isValid(str) {
        return !this.getValidationError(str);
    }

    static getValidationError(str) {
        if (!str) return 'Form.Error.Required';
        return null;
    }

    static cleanString(str) {
        return str.replace(/[^a-z0-9]/gi, '');
    }
}
