import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { GTM as gtmUtil } from 'Common/utils';
import * as GTM from 'Common/constants/gtm';
import { localization, order, product } from '~features';

const productIsForSale = (prod) => prod?.StockStatus !== 'DisplayOnly';

const useProductTabContent = (showDocs = false) => {
    const productData = useSelector(product.selectors.getProduct);
    const documents = useSelector(product.selectors.getProductDocuments);
    const facets = useSelector(product.selectors.getFacets);
    const children = useSelector(product.selectors.getChildren);
    const selectedChild = useSelector(product.selectors.getSelectedChild);
    const selectedAttributes = useSelector(product.selectors.getSelectedAttributes);
    const stringMap = useSelector(localization.selectors.getStringMap);
    const totalQuantity = useSelector(order.selectors.getTotalQuantity);
    
    const features = useMemo(() => {
        if (selectedChild?.Features) {
            if (productData.Features == null) {
                productData.Features = selectedChild.Features
                return productData.Features
            } else {
                return productData?.Features.concat(selectedChild?.Features);
            }
        }
        if (selectedAttributes.length && children?.length === 1 && children?.[0]?.Features) {
            return productData?.Features.concat(children?.[0].Features);
        }
        return productData?.Features || [];
    }, [selectedChild, selectedAttributes, children, productData]);

    const specificationsDesktop = useMemo(() => {
        let columns = [productData?.TypeId];

        columns = columns.concat(
            children?.reduce(
                (attributes, child) =>
                    attributes.concat(
                        child.Attributes.reduce(
                            (specifications, attribute) =>
                                attribute.IsSpecification && !attributes.includes(attribute.AttributeDisplayName)
                                    ? [...specifications, attribute.AttributeDisplayName]
                                    : specifications,
                            []
                        )
                    ),
                []
            )
        );

        const rows = children?.reduce(
            (rowData, child) =>
                rowData.concat([
                    {
                        label: child.Code,
                        badges:
                            stringMap['Commerce.MarketingBadge.New'] &&
                            (child.IsNew || child.MarketingBadges?.includes(stringMap['Commerce.MarketingBadge.New']))
                                ? [stringMap['Commerce.MarketingBadge.New']]
                                : [],
                        values: columns.slice(1).reduce((data, col) => {
                            const columnData = child.Attributes.find((att) => att.AttributeDisplayName === col);

                            return columnData
                                ? [
                                      ...data,
                                      { id: `${child.Code}_${columnData.AttributeName}`, values: columnData.Values },
                                  ]
                                : [...data, { id: `${child.Code}_${col}`, values: [' '] }];
                        }, []),
                        hasStock: child.StockStatus === 'InStock',
                        sortOrder: child.SortOrder,
                        gtaPayload: gtmUtil.mapEntityToAddCart(child, 1, child.Attributes, GTM.TAGS.PDP, totalQuantity),
                    },
                ]),
            []
        );

        return {
            columns,
            rows: rows?.sort((a, b) => a.sortOrder - b.sortOrder),
        };
    }, [productData, children, stringMap, totalQuantity]);

    const specificationsMobile = useMemo(() => {
        let columns = [{ name: productData?.TypeId, hasStock: true }];
        let rows = [];

        columns = columns.concat(
            children?.reduce(
                (colData, child) =>
                    colData.concat([
                        {
                            name: child.Code,
                            hasStock: child.StockStatus === 'InStock',
                            sortOrder: child.SortOrder,
                            badges:
                                stringMap['Commerce.MarketingBadge.New'] &&
                                (child.IsNew ||
                                    child.MarketingBadges?.includes(stringMap['Commerce.MarketingBadge.New']))
                                    ? [stringMap['Commerce.MarketingBadge.New']]
                                    : [],
                            gtaPayload: gtmUtil.mapEntityToAddCart(
                                child,
                                1,
                                child.Attributes,
                                GTM.TAGS.PDP,
                                totalQuantity
                            ),
                        },
                    ]),
                []
            )
        );

        for (let i = 0; i < children?.length; i++) {
            const currentChild = children?.[i];

            for (let j = 0; j < currentChild?.Attributes.length; j++) {
                const currentAttribute = currentChild.Attributes[j];

                if (!currentAttribute.IsSpecification) continue;

                const attributeRowIndex = rows.map((row) => row.label).indexOf(currentAttribute.AttributeDisplayName);

                if (attributeRowIndex < 0) {
                    const rowValues = [];

                    for (let k = 0; k < children?.length; k++) {
                        rowValues[k] = { id: `${children?.[k].Code}_${currentAttribute.AttributeName}`, values: [''] };
                    }
                    rowValues[i] = { id: currentAttribute.AttributeName, values: currentAttribute.Values };
                    rows = rows.concat([{ values: rowValues, label: currentAttribute.AttributeDisplayName }]);
                } else {
                    rows[attributeRowIndex].values[i].values = currentAttribute.Values;
                }
            }
        }

        return {
            columns: columns.sort((a, b) => a.sortOrder - b.sortOrder),
            rows,
        };
    }, [productData, children, stringMap, totalQuantity]);

    const childFacetData = useMemo(() => {
        return children?.map((child) => ({
            badges:
                stringMap['Commerce.MarketingBadge.New'] &&
                (child.IsNew || child.MarketingBadges?.includes(stringMap['Commerce.MarketingBadge.New']))
                    ? [stringMap['Commerce.MarketingBadge.New']]
                    : [],
            swatch: facets?.find((f) => f.FacetColors?.length)?.FacetColors?.length
                ? child.Attributes?.find(
                      (a) => a.AttributeName === facets?.find((f) => f.FacetColors?.length)?.Attribute
                  )?.Values[0]
                : null,
            id: child.Code,
            imageUrl: child.DefaultImageUrl,
            priceInfo: {
                price: child.Price,
                useQtySalePrice: child.Price.QtySalePrices != null && Object.keys(child.Price.QtySalePrices).length > 0,
            },
            hasStock: child.StockStatus === 'InStock',
            gtaPayload: gtmUtil.mapEntityToAddCart(child, 1, child.Attributes, GTM.TAGS.PDP, totalQuantity),
            productInfo: child,
        }));
    }, [children, facets, stringMap, totalQuantity]);

    const packageBundleData = useMemo(() => {
        return children?.map((child) => ({
            badges:
                stringMap['Commerce.MarketingBadge.New'] &&
                (child.IsNew || child.MarketingBadges?.includes(stringMap['Commerce.MarketingBadge.New']))
                    ? [stringMap['Commerce.MarketingBadge.New']]
                    : [],
            childRelationQuantity: child.ChildRelationQuantity,
            id: child.Code,
            imageUrl: child.DefaultImageUrl,
            childLink: child.ContentUrl,
            displayName: child.DisplayName,
            priceInfo: {
                price: child.Price,
                useQtySalePrice: child.Price.QtySalePrices != null && Object.keys(child.Price.QtySalePrices).length > 0,
            },
            hasStock: child.StockStatus === 'InStock',
            gtaPayload: gtmUtil.mapEntityToAddCart(child, 1, child.Attributes, GTM.TAGS.PDP, totalQuantity),
        }));
    }, [children, stringMap, totalQuantity]);

    return useMemo(() => {
        let content = [];

        if (productData?.Description && productData?.DisplayName) {
            content = content.concat([
                {
                    tab: 'Description',
                    content: {
                        description: productData?.Description,
                        displayName: productData?.DisplayName,
                        descriptionBackgroundUrl: productData?.Options.DescriptionBackground,
                        descriptionImageUrl: productData?.DescriptionImageUrl,
                    },
                },
            ]);
        }

        if (features.length) {
            content = content.concat([{ tab: 'Features', content: features }]);
        }

        switch (productData?.TypeId) {
            case 'Product':
                if (
                    specificationsDesktop.rows?.length &&
                    specificationsDesktop.columns?.length &&
                    specificationsMobile.rows?.length &&
                    specificationsMobile.columns?.length &&
                    productData?.Options.ShowSpecifications === 'True'
                ) {
                    content = content.concat([
                        {
                            tab: 'Specifications',
                            content: {
                                tableDataDesktop: specificationsDesktop,
                                tableDataMobile: specificationsMobile,
                                showAddToCartButton: productIsForSale(productData),
                            },
                        },
                    ]);
                }
                if (childFacetData.length && productData?.Options.ShowBulkBuy === 'True') {
                    content = content.concat([{ tab: 'BuyInBulk', content: childFacetData }]);
                }
                if (documents?.length && showDocs){
                    content = content.concat([{ tab: 'Information.Manuals', content: documents }])
                }
                break;
            case 'Package':
            case 'Bundle':
                if (packageBundleData.length) {
                    content = [
                        {
                            tab: 'Includes',
                            content: packageBundleData,
                            typeId: productData?.TypeId,
                            showAddToCart: productIsForSale(productData),
                        },
                    ].concat(content);
                }
                break;
        }

        return content;
    }, [productData, features, specificationsDesktop, specificationsMobile, childFacetData, documents, showDocs, packageBundleData]);
};

export default useProductTabContent;
