import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Facet } from 'Common/types';
import { Dropdown } from 'Common/components/ui';
import { toggleFacetValue } from './utils';

export default function SearchCheckboxFacet({ className, style, facet, onChange }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleOpen = React.useCallback(() => setIsOpen((val) => !val), []);

    return (
        <Dropdown
            className={cx('SearchCheckboxFacet', className)}
            style={style}
            icon={<i className={cx('fas  ml-2 mb-1', `fa-chevron-${isOpen ? 'up' : 'down'}`)} />}
            selectedValue={facet.title}
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            drawerType="relative"
        >
            {facet.options?.map((o, idx) => (
                <div key={o.value} className="SearchCheckboxFacet__option">
                    <label key={o.value} className="form-check-label">
                        <input
                            key={o.value}
                            type="checkbox"
                            name={`${o.title}_RadioSelector`}
                            checked={o.selected}
                            readOnly={true}
                            className="form-check-input"
                            onChange={() => onChange?.(toggleFacetValue(idx, facet))}
                        ></input>
                        {o.title}
                    </label>
                </div>
            ))}
        </Dropdown>
    );
}

SearchCheckboxFacet.propTypes = {
    className: px.string,
    style: px.object,
    facet: Facet,
    onChange: px.func,
};
