import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import * as SearchApi from 'Common/features/search/api';
import { Cookie } from 'Common/utils';
import { HeaderController } from 'Common/controllers';
import { useViewport } from 'Common/hooks';
import SearchDrawerLinks from './SearchDrawerLinks';
import { SearchContext } from './SearchProvider';
import SearchBar from './SearchBar';
import HeaderSearchResult from './HeaderSearchResult';
import { Translation } from 'Common/components/localization';
import { ResultsGrid, Section } from 'Common/components/ui';

const initialPageSize = 5;
const textSearchPageSize = 8;

function updateRecents(searches, relatedSearchCount, value) {
    let newSearches = searches?.map((s) => s.title);

    if (newSearches.includes(value)) return;
    newSearches.unshift(value);

    if (newSearches.length > relatedSearchCount) {
        newSearches = newSearches.slice(0, relatedSearchCount);
    }

    for (let i = 0; i < newSearches.length; i++) {
        Cookie.createCookie(`RecentSearch${i}`, newSearches[i]);
    }
}

/**
 *
 * @param {SearchContext} context
 * @param {boolean} appendResults
 * @returns {SearchContext}
 */
async function defaultSearch(context, query = null, pageSize = textSearchPageSize, sort) {
    return SearchContext.fromSearchApiResponse(
        context,
        await SearchApi.contentSearch(query, 1, pageSize, sort, context.facets)
    );
}

const defaultContext = (intialContext) => SearchContext.create(intialContext);

export default function HeaderSearch({
    className,
    id,
    style,
    onSearch = defaultSearch,
    linkSections,
    relatedSearchCount,
    theme = 'light',
    searchResultsPage,
}) {
    /**
     * @type {[SearchContext, (action: SearchContextAction) => void]}
     */
    const [context, dispatch] = React.useReducer(SearchContext.reducer, defaultContext({}));
    const viewport = useViewport();
    const drawerRef = React.useRef();
    const headerSearchRef = React.useRef($(`#${id}`)); // parent div
    const initialResults = React.useRef();
    const [isLoading, setIsLoading] = React.useState(false);

    const [showInitialResults, setShowInitialResults] = React.useState(true);

    /**
     * @type {(query: string) => void}
     */
    const setQuery = React.useCallback(
        (query) => {
            dispatch({
                type: 'setQuery',
                payload: query,
            });
        },
        [dispatch]
    );

    const onTextChange = React.useCallback(
        async (text) => {
            setQuery(text);
            if (text.length > 2) await debounceSearch(context, text);
            else debounceSearch.cancel();
        },
        [setQuery, debounceSearch, context]
    );

    const onClear = React.useCallback(() => {
        setQuery(null);
        HeaderController.closeSearch();
    }, [setQuery]);

    const search = React.useCallback(
        async (ctx, query, pageSize, sort = 'Relevance') => {
            setIsLoading(true);
            const newCtx = await onSearch(ctx, query, pageSize, sort);

            setIsLoading(false);
            if (!initialResults.current) initialResults.current = newCtx?.results;
            else setShowInitialResults(false);
            dispatch({
                type: 'setResults',
                payload: newCtx,
            });
        },
        [onSearch, dispatch]
    );

    const onSubmit = React.useCallback(
        (e) => {
            updateRecents(recentSearches, relatedSearchCount, context.query);
            e.preventDefault();
            window.location.href = `${searchResultsPage}?query=${context.query}`;
            const url = new URL(searchResultsPage, window.location.origin);

            url.searchParams.set('query', context.query);

            window.location.href = url.toString();
        },
        [recentSearches, relatedSearchCount, context.query, searchResultsPage]
    );

    const debounceSearch = React.useMemo(() => (search ? debounce(search, 900) : null), [search]);

    const recentSearches = React.useMemo(() => {
        let recents = [];

        for (let i in [...Array(relatedSearchCount)]) {
            const query = Cookie.getCookieByName(`RecentSearch${i}`);

            if (!query) continue;
            recents.push({
                title: query,
                link: `${searchResultsPage}?query=${query}`,
            });
        }
        return recents;
    }, [relatedSearchCount, searchResultsPage]);

    const searchDrawerLinks = React.useMemo(
        () =>
            !showInitialResults && (linkSections?.length || recentSearches?.length) ? (
                <SearchDrawerLinks
                    className={`theme-${theme}`}
                    recentSearches={recentSearches}
                    linkSections={linkSections}
                />
            ) : null,
        [showInitialResults, linkSections, recentSearches, theme]
    );

    const resultsLink = React.useMemo(
        () =>
            !showInitialResults ? (
                <a href={`${searchResultsPage}?query=${context.query}`}>
                    <Translation id="Search.Drawer.View.All.Link.Text" params={{ count: context.pageInfo.total }} />
                </a>
            ) : null,
        [showInitialResults, searchResultsPage, context.query, context.pageInfo.total]
    );

    const results = React.useMemo(() => (!showInitialResults ? context.results : initialResults.current), [
        context.results,
        showInitialResults,
    ]);

    const handleClickOutsideSearch = React.useCallback(
        (e) => {
            if (!document.getElementById(id).contains(e.target)) HeaderController.closeSearch();
        },
        [id]
    );

    React.useEffect(() => {
        async function initialSearch(e) {
            setShowInitialResults(true);
            setTimeout(() => document.addEventListener('click', handleClickOutsideSearch), 300); //delay to avoid intercepting searchopen click
            if (initialResults.current) return;
            await search(context, null, initialPageSize, 'BestSeller');
        }
        const el = headerSearchRef.current;

        el.on('open.search', initialSearch);
    }, []);

    React.useEffect(() => {
        const el = headerSearchRef.current;

        el.on('close.search', () => document.removeEventListener('click', handleClickOutsideSearch));
    }, []);

    React.useEffect(() => {
        if (results?.length || resultsLink || searchDrawerLinks)
            drawerRef.current.style.height = `${drawerRef.current.children[0]?.getBoundingClientRect()?.height}px`;
        else drawerRef.current.style.height = '0px';
    }, [results, viewport, resultsLink, searchDrawerLinks]);

    const isMobile = viewport.is.lt('md');

    return (
        <div style={style} className={cx(className, 'HeaderSearch', `theme-${theme}`)}>
            <SearchBar
                className={cx(`theme-${theme}`, 'container')}
                showLoader={isLoading}
                onSubmit={onSubmit}
                onTextChange={onTextChange}
                onClear={onClear}
                query={context.query}
            />
            <div
                ref={drawerRef}
                style={{ height: '0px' }}
                className={cx('HeaderSearch__drawer', `theme-${theme}`, 'row', 'justify-content-center')}
            >
                <Section
                    sidebarLeft={isMobile ? null : searchDrawerLinks}
                    sidebarRight={isMobile ? searchDrawerLinks : null}
                    className="container"
                >
                    {resultsLink ? <div className="HeaderSearch__drawer--link">{resultsLink}</div> : null}
                    {!results?.length && initialResults.current?.length ? (
                        <Translation id="Search.No.Results.Message" />
                    ) : (
                        <ResultsGrid gap={isMobile ? 1 : 4}>
                            {results?.map((result) => (
                                <HeaderSearchResult
                                    className={`theme-${theme}`}
                                    result={result}
                                    key={result.product?.url}
                                />
                            ))}
                        </ResultsGrid>
                    )}
                </Section>
            </div>
        </div>
    );
}

HeaderSearch.propTypes = {
    style: px.object,
    className: px.string,
    onSearch: px.func,
    linkSections: px.arrayOf(
        px.shape({
            Title: px.string,
            Links: px.arrayOf(
                px.shape({
                    Href: px.string,
                    Title: px.string,
                })
            ),
        })
    ),
    relatedSearchCount: px.number,
    /**
     * id of parent div
     */
    id: px.string,
    theme: px.string,
    searchResultsPage: px.string,
};
