import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { Translation } from 'Common/components/localization';
import { useTranslation } from 'Common/hooks';

export default function AccordionTable({ className, accordionData, accordionHeaders, listDisplay }) {
    const id = React.useRef(`AccordionTable_${v4()}`);
    const collapseLbl = useTranslation('AccordionTable.Collapse.Label');

    return (
        <div className={cx('AccordionTable d-flex justify-content-center', className)}>
            <table className="table table-sm">
                <thead>
                    <tr>
                        {accordionHeaders &&
                            accordionHeaders.map((header, index) => <th key={`${header}_${index}`}>{header}</th>)}
                    </tr>
                </thead>
                <tbody id={id.current} className="accordion light">
                    {accordionData &&
                        accordionData.map((data) => (
                            <>
                                <tr
                                    key={`${data.id}-a`}
                                    id={`${id.current}_${data.id}_heading`.replaceAll('.', '')}
                                    className="card-header py-1"
                                >
                                    {data.rowValues &&
                                        data.rowValues.map((node) => (
                                            <td className={cx(node.className)} key={node.id}>
                                                {node.value}
                                            </td>
                                        ))}
                                    {data.content?.length ? (
                                        <td>
                                            <button
                                                className="btn btn-small d-flex justify-content-between px-3 w-100"
                                                data-toggle="collapse"
                                                data-target={`#${id.current}_${data.id}`.replaceAll('.', '')}
                                                aria-expanded="false"
                                                aria-controls={`${id.current}_${data.id}`.replaceAll('.', '')}
                                                aria-label={collapseLbl}
                                            >
                                                <i className="fa fa-chevron-down"></i>
                                            </button>
                                        </td>
                                    ) : null}
                                </tr>
                                <tr className="bs-accordion" key={`${data.id}-b`}>
                                    <td className="p-0" colSpan="12">
                                        <div
                                            className="collapse"
                                            id={`${id.current}_${data.id}`.replaceAll('.', '')}
                                            aria-labelledby={`${id.current}_${data.id}_heading`.replaceAll('.', '')}
                                            data-parent={`#${id.current}`}
                                        >
                                            {data.content &&
                                                data.content.length &&
                                                data.content.map((facet) => (
                                                    <div key={facet?.label} className="container">
                                                        <div className="row my-2">
                                                            {facet.label ? (
                                                                <>
                                                                    <div className="col-6">
                                                                        <strong>
                                                                            <p className="paragraph-2 text-break mb-0">
                                                                                <Translation id={facet.label} />
                                                                                {facet.label ? ':' : null}
                                                                            </p>
                                                                        </strong>
                                                                    </div>
                                                                    <div className="col-6 p-0 px-md-3">
                                                                        {facet.values &&
                                                                            facet.values.map((value, index) => (
                                                                                <>
                                                                                    {typeof value.node === 'string' ? (
                                                                                        <p
                                                                                            className={cx(
                                                                                                'paragraph-2',
                                                                                                {
                                                                                                    'd-inline': !listDisplay,
                                                                                                }
                                                                                            )}
                                                                                            key={value.id}
                                                                                        >
                                                                                            {value.node}
                                                                                        </p>
                                                                                    ) : (
                                                                                        value.node
                                                                                    )}
                                                                                    {!listDisplay &&
                                                                                    index !== facet.values.length - 1
                                                                                        ? ', '
                                                                                        : null}
                                                                                </>
                                                                            ))}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                // Here we provide a way to pass in a node and have it display full width like for images or buttons.
                                                                <div className="col-12 px-3 d-flex justify-content-center">
                                                                    {facet.values[0].node}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </td>
                                </tr>
                            </>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

AccordionTable.propTypes = {
    className: px.string,
    accordionData: px.arrayOf(
        px.shape({
            content: px.arrayOf(
                px.shape({
                    label: px.string,
                    values: px.arrayOf(
                        px.shape({
                            id: px.any,
                            node: px.node,
                        })
                    ),
                })
            ),
            rowValues: px.arrayOf(
                px.shape({
                    value: px.node,
                    id: px.oneOfType([px.string, px.number]),
                    className: px.string,
                })
            ),
            id: px.node,
            priceInfo: px.object,
        })
    ),
    accordionHeaders: px.arrayOf(px.string),
    listDisplay: px.bool,
};
