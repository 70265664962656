import * as React from 'react';
import { useLocalFeature } from 'Common/hooks';
import * as types from 'Common/types';
import { SparePartsPartial as SpareParts } from 'Common/components/product/SpareParts';
import { product } from '~features';

export default function SparePartsPartial({ sparePartsData = {} }) {
    const [localSelector, localAction] = useLocalFeature(product, 'product');
    const lastDataRef = React.useRef(null);
    const updateSparePartsData = localAction(product.actions.setItemDetails);
    const sparePartTitle = localSelector(product.selectors.getChildTitle);
    const sparePartInfo = localSelector(product.selectors.getProduct);
    const media = localSelector(product.selectors.getMedia);
    const mainMedia = localSelector(product.selectors.getMainMedia);

    React.useEffect(() => {
        if (lastDataRef.current !== sparePartsData) {
            updateSparePartsData(sparePartsData);
            lastDataRef.current = sparePartsData;
        }
    }, [sparePartsData, updateSparePartsData]);

    return (
        <SpareParts
            desktopBreakpoint={'lg'}
            galleryProps={{
                allowImageZoom: false,
                media: media,
                mainMedia: mainMedia,
                useCarousel: false,
            }}
            sparePartTitle={sparePartTitle}
            sparePartInfo={sparePartInfo}
        />
    );
}

SparePartsPartial.propTypes = {
    sparePartsData: types.Product,
};
