import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Dropdown } from 'Common/components/ui';
import { Cell, Grid, Row } from 'Common/components/ui/Grid';

export default function SparePartsDropdown({
    className,
    style,
    options = [],
    placeholder,
    selected,
    setSelected,
    showId = false,
    ...props
}) {
    const sortedOptions = useMemo(() => {
        return [...options].sort((a, b) => a.Name.localeCompare(b.Name));
    }, [options]);

    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), []);
    const dropdownRef = useRef(null);

    const onChange = useCallback(
        (c) => {
            setSelected(c);
            setIsOpen(false);
        },
        [setSelected]
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            if (isOpen) {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        };
    }, [isOpen]);

    return (
        <div ref={dropdownRef}>
            <Dropdown
                className={className}
                isOpen={isOpen}
                toggleOpen={toggleOpen}
                icon={<i className={cx('fas ml-2 mb-1', `fa-chevron-${isOpen ? 'up' : 'down'}`)} />}
                selectedValue={selected ? selected.Name : placeholder}
                {...props}
            >
                <Grid>
                    {sortedOptions?.map((c) => (
                        <Row key={c.Id}>
                            <Cell>
                                <button
                                    onClick={() => onChange(c)}
                                    className={cx('SearchDropdownFacet__option btn btn-sm')}
                                >
                                    {c.Name}
                                    {showId && c.Id ? ` (${c.Id})` : null}
                                </button>
                            </Cell>
                        </Row>
                    ))}
                </Grid>
            </Dropdown>
        </div>
    );
}

SparePartsDropdown.propTypes = {
    className: px.string,
    style: px.object,
    options: px.arrayOf(
        px.shape({
            Name: px.string,
            Id: px.oneOfType([px.string, px.number]),
        })
    ),
    placeholder: px.string,
    selected: px.shape({
        Name: px.string,
        Id: px.oneOfType([px.string, px.number]),
    }),
    setSelected: px.func,
    showId: px.bool,
};
