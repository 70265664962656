import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { ProductRating as Rating } from 'Common/components/product';
import { product } from '~features';

export default function ProductRating({ writeReviewAnchor, ...props }) {
    const productInfo = useSelector(product.selectors.getProduct);

    return (
        <Rating
            {...props}
            rating={productInfo.Rating}
            reviewCount={productInfo.ReviewsCount}
            writeReviewAnchor={writeReviewAnchor}
            isDiscontinued={productInfo.ItemStatus === 'Discontinued'}
            reviewProviderEntityId={productInfo.ReviewProviderEntityId}
        />
    );
}

ProductRating.propTypes = {
    writeReviewAnchor: px.string,
};
