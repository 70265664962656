import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    top: .8em;
    height: 6.4em;
  }
  50%, 100% {
    top: 2.4em;
    height: 3.2em;
  }
`;

const Spin = styled.div`
    animation: ${loading} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
`;

export default function Spinner() {
    return (
        <div className="Spinner">
            <Spin className="bar">&nbsp;</Spin>
            <Spin className="bar">&nbsp;</Spin>
            <Spin className="bar">&nbsp;</Spin>
        </div>
    );
}
