import { useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function useAction(action, preAction, postAction) {
    const preActionRef = useRef(preAction);
    const postActionRef = useRef(postAction);
    const actionRef = useRef(action);
    const dispatch = useDispatch();
    const callback = useCallback(
        async (...args) => {
            let res;

            if (preActionRef.current) await preActionRef.current(...args);

            try {
                res = await dispatch(actionRef.current(...args));
            } catch (e) {
                // Skip
            } finally {
                if (postActionRef.current) await postActionRef.current(...args);
            }

            return res;
        },
        [dispatch]
    );

    useEffect(() => {
        actionRef.current = action;
    }, [action]);

    useEffect(() => {
        preActionRef.current = preAction;
    }, [preAction]);

    useEffect(() => {
        postActionRef.current = postAction;
    }, [postAction]);

    return callback;
}
