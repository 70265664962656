import * as React from 'react';
import $ from 'jquery';
import px from 'prop-types';
import cx from 'classnames';
import { logger } from 'Common/core';
import { getLocations } from 'Common/features/locator/api';
import { Loader } from 'Common/components/ui';
import { APP } from '$env';
import { Translation } from 'Common/components/localization';
import Locations from './Locations';
import { Url } from 'Common/utils';
import { useViewport } from 'Common/hooks';

//Avid is only using County faceting but we will always get country and category facets back as well.
const VALID_FACET = 'County';

export default function StoreLocator({
    className,
    brandName = APP,
    language,
    disclaimerText,
    locationContent,
    categories,
}) {
    const viewport = useViewport();
    const container = React.useRef();
    const mobileCountyList = React.useRef();
    const fetched = React.useRef(false);
    const [selectedCounty, setSelectedCounty] = React.useState(Url.current.getParam(VALID_FACET));
    const [locatorData, setLocatorData] = React.useState(null);
    const [showLoader, setShowLoader] = React.useState(false);
    const [pages, setPages] = React.useState([1]);
    const [query, setQuery] = React.useState({
        Site: brandName,
        Language: language,
        SortBy: [VALID_FACET],
        CategoriesFilter: categories,
        LocalityFilter: {
            County: selectedCounty,
        },
    });

    const updateQueryFacet = React.useCallback(
        (facet, value) => {
            setQuery({
                ...query,
                LocalityFilter: {
                    ...query.LocalityFilter,
                    [facet]: value,
                },
                Page: 1,
            });
        },
        [setQuery, query]
    );

    const paginate = React.useCallback(
        (page) => () => {
            setQuery({
                ...query,
                Page: page,
            });
            fetched.current = false;
        },
        [setQuery, query]
    );

    const onSelectFacet = React.useCallback(
        (facet, value) => () => {
            updateQueryFacet(facet, value);
            fetched.current = false;
            Url.current.addParam(facet, value).apply();
            setSelectedCounty(value);
            if (viewport.is.lt('md')) $(mobileCountyList.current).collapse('hide');
        },
        [updateQueryFacet, viewport]
    );

    const fetchLocations = React.useCallback(() => {
        setShowLoader(true);
        getLocations(query)
            .then((data) => {
                setLocatorData({ ...data.Response });
                setQuery({ ...data.Response.Query });
                const currentPg = data.Response.Query.Page;
                let pgArr = Array(data.Response.TotalPages)
                    .fill(0)
                    .map((_, i) => i + 1);

                if (currentPg > 4) pgArr = pgArr.slice(currentPg - 2, currentPg + 1);
                else if (data.Response.TotalPages > 5) pgArr = pgArr.slice(0, 5);

                setPages(pgArr);
                setShowLoader(false);
            })
            .catch((e) => {
                logger.error('error', e?.message || e);
                setShowLoader(false);
            });
    }, [setShowLoader, query]);

    React.useEffect(() => {
        if (!fetched.current) {
            fetched.current = true;
            fetchLocations();
        }
    }, [fetchLocations]);

    return (
        <div className={cx('StoreLocator Old PrestonStoreLocator row', className)} ref={container}>
            <div className="col-12 col-md-4 col-lg-3 col-xl-2 facet_container">
                <button data-toggle={viewport.is.lt('md') ? 'collapse' : null} data-target="#StoreLocator_Facets">
                    <h6>
                        <Translation id={`Dealer.Locator.Filter.FilterBy.County.Title`} />
                    </h6>
                    {viewport.is.lt('md') ? <i className="fas fa-chevron-down"></i> : null}
                </button>
                <ul
                    className={cx('accordion-collapse collapse', viewport.is.gt('sm') ? 'show' : null)}
                    id="StoreLocator_Facets"
                    ref={mobileCountyList}
                >
                    {locatorData?.Facets && locatorData.Facets[VALID_FACET]?.length
                        ? locatorData.Facets[VALID_FACET].map((f) => (
                              <li key={f.Value}>
                                  <a
                                      className={f.Value === selectedCounty ? 'selected' : null}
                                      onClick={onSelectFacet(VALID_FACET, f.Value)}
                                  >
                                      {f.Label}
                                  </a>
                              </li>
                          ))
                        : null}
                </ul>
            </div>
            <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                <Locations
                    locationContent={locationContent}
                    disclaimerText={disclaimerText}
                    locations={locatorData?.Locations}
                />
                {pages?.length > 1 ? (
                    <div className="pagination">
                        {pages?.length !== locatorData.TotalPages && !pages.includes(1) ? (
                            <>
                                <div key={query?.Page - 1} onClick={paginate(query?.Page - 1)} className="page arrow">
                                    <i className="fas fa-chevron-left" />
                                </div>
                                <div key={1} onClick={paginate(1)} className="page">
                                    1
                                </div>
                                <span className="ellipse">...</span>
                            </>
                        ) : null}
                        {pages.map((p) => (
                            <div key={p} onClick={paginate(p)} className={cx('page', { selected: query?.Page === p })}>
                                {p}
                            </div>
                        ))}
                        {pages?.length !== locatorData.TotalPages && !pages.includes(locatorData.TotalPages) ? (
                            <>
                                <span className="ellipse">...</span>
                                <div
                                    key={locatorData.TotalPages}
                                    onClick={paginate(locatorData.TotalPages)}
                                    className="page"
                                >
                                    {locatorData.TotalPages}
                                </div>
                                <div key={query?.Page + 1} onClick={paginate(query?.Page + 1)} className="page arrow">
                                    <i className="fas fa-chevron-right" />
                                </div>
                            </>
                        ) : null}
                    </div>
                ) : null}
            </div>
            {showLoader ? <Loader /> : null}
        </div>
    );
}

StoreLocator.propTypes = {
    brandName: px.string,
    className: px.string,
    language: px.string,
    disclaimerText: px.string,
    locationContent: px.string,
    categories: px.arrayOf(px.string),
};
