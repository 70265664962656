import React, { useState, useRef, useEffect } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useViewport } from 'Common/hooks';
import { Formatter } from 'Common/utils';
import { Translation } from 'Common/components/localization';
import { SparePartsPartial } from 'Common/containers/product';
import { Grid, Header, Row, Cell, Interrupter } from 'Common/components/ui/Grid';

export default function SparePartsTable({ spareParts, className }) {
    const viewport = useViewport();
    const [selectedPartCode, setSelectedPartCode] = useState(null);
    const quickViewRef = useRef(null);
    const isPriceExists = spareParts?.some((variation) => variation.Price.ListPrice.Amount > 0);
    const numberOfColumns = isPriceExists ? 4 : 3;

    useEffect(() => {
        if (selectedPartCode && quickViewRef.current) {
            quickViewRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [selectedPartCode]);

    const isMobile = viewport.is.lt('md');

    return spareParts?.length ? (
        <div className={cx('SparePartsTable', className)}>
            <Grid className="SparePartsTable__Grid">
                <Header>
                    <Cell className="text-left pl-4" style={{ borderTopLeftRadius: '10px' }}>
                        <Translation id="SpareParts.Table.PartCode.Label" />
                    </Cell>
                    {!isMobile ? (
                        <Cell className="text-center">
                            <Translation id="SpareParts.Table.Description.Label" />
                        </Cell>
                    ) : null}
                    {isPriceExists && !isMobile ? (
                        <Cell className="text-center">
                            <Translation id="SpareParts.Table.Price.Label" />
                        </Cell>
                    ) : null}
                    <Cell width="5rem" style={{ borderTopRightRadius: '10px' }} />
                </Header>
                {spareParts.map((part) => {
                    const isOpen = selectedPartCode === part.Code;

                    return [
                        <Row key={part.Code} onClick={() => setSelectedPartCode(isOpen ? null : part.Code)}>
                            <Cell className="text-left pl-4 border-left">{part.Code}</Cell>
                            {!isMobile ? <Cell className="text-center">{part.DisplayName}</Cell> : null}
                            {isPriceExists && !isMobile ? (
                                <Cell className="text-center">
                                    {part.Price.ListPrice.Amount > 0
                                        ? Formatter.currency(part.Price.ListPrice.Amount, part.Price.ListPrice.Currency)
                                        : null}
                                </Cell>
                            ) : null}
                            <Cell className="text-center border-right">
                                <i className={cx('fas', `fa-chevron-${isOpen ? 'up' : 'down'}`)} />
                            </Cell>
                        </Row>,
                        isOpen && (
                            <Interrupter
                                key={`${part.Code}-quickview`}
                                className="QuickviewInterrupter border-left border-right"
                            >
                                <Cell colSpan={numberOfColumns} ref={quickViewRef}>
                                    <SparePartsPartial sparePartsData={{ Product: part }} />
                                </Cell>
                            </Interrupter>
                        ),
                    ].filter(Boolean);
                })}
            </Grid>
        </div>
    ) : (
        <div>
            <Translation id="SpareParts.Table.SpareParts.Empty.Label" />
        </div>
    );
}

SparePartsTable.propTypes = {
    spareParts: px.arrayOf(
        px.shape({
            Code: px.string.isRequired,
            DisplayName: px.string.isRequired,
            Price: px.shape({
                ListPrice: px.shape({
                    Amount: px.number.isRequired,
                    Currency: px.string.isRequired,
                }).isRequired,
            }).isRequired,
        })
    ).isRequired,
    className: px.string,
};
