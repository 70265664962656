import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useViewport, useAction } from 'Common/hooks';
import { CatalogFilters as Filters, MobileCatalogFilters as MobileFilters } from 'Common/components/catalog';
import { catalog } from '~features';

export default function CatalogFilters({
    className,
    desktopBreakpoint = 'lg',
    startExpanded,
    useMobileFilters = true,
    ...props
}) {
    const viewport = useViewport();
    const hasChanged = useSelector(catalog.selectors.hasChanged);
    const facets = useSelector(catalog.selectors.getFilteredFacets);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const onUpdateSort = useAction(catalog.actions.updateSortOrder);
    const onUpdatePageSize = useAction(catalog.actions.updatePageSize);
    const onUpdateFacet = useAction(catalog.actions.selectFacetValue);
    const onUpdateFacets = useAction(catalog.actions.updateFacets);
    const onFetchItems = useAction(catalog.actions.fetchItems);

    React.useEffect(() => {
        if (hasChanged) {
            onFetchItems();
        }
    }, [hasChanged, onFetchItems]);

    return viewport.is.lt(desktopBreakpoint) && useMobileFilters ? (
        <MobileFilters
            {...props}
            className={className}
            pageInfo={pageInfo}
            facets={facets}
            onUpdateSort={onUpdateSort}
            onUpdatePageSize={onUpdatePageSize}
            onUpdateFacets={onUpdateFacets}
            dekstopBreakpoint={desktopBreakpoint}
        />
    ) : (
        <Filters
            {...props}
            className={className}
            pageInfo={pageInfo}
            facets={facets}
            onUpdateSort={onUpdateSort}
            onUpdatePageSize={onUpdatePageSize}
            onUpdateFacet={onUpdateFacet}
            dekstopBreakpoint={desktopBreakpoint}
            startExpanded={!viewport.is.mobile && startExpanded}
            viewport={viewport}
        />
    );
}

CatalogFilters.propTypes = {
    className: px.string,
    desktopBreakpoint: px.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    startExpanded: px.bool,
    useMobileFilters: px.bool,
};
