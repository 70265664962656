import * as types from './types';

const setItemsReducer = (state, items) => {
    return { ...state, items, isLoading: false, isError: false };
};

const setAlertReducer = (state, { translationKey, params, type }) => {
    return { ...state, alert: { ...state.alert, message: { translationKey, params, type }, timestamp: Date.now() } };
};

const setErrorReducer = (state) => ({ ...state, isLoading: false, isError: true });

const startLoadingReducer = (state) => ({ ...state, isLoading: true, isError: false });

export default {
    [types.SET_COMPARE_ITEMS]: setItemsReducer,
    [types.COMPARE_ERROR]: setErrorReducer,
    [types.COMPARE_START_LOAD]: startLoadingReducer,
    [types.COMPARE_SET_ALERT]: setAlertReducer,
};
