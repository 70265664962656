import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import get from 'lodash.get';
import * as types from 'Common/types';
import { PAYMENT, SHIPMENT, ADDRESS, EMPTY_PAYMENT } from 'Common/constants/fields';
import { Field } from 'Common/components/forms';
import { Translation } from 'Common/components/localization';
import { CreditCard } from 'Common/utils';
import { useExpDateFormat } from 'Common/hooks/payment';
import CheckoutForm from '../CheckoutForm';
import { GiftCard } from '../payments';
import ReadOnlyContents from './ReadOnlyContents';
import FormContents from './FormContents';

export default function CheckoutPayment({
    className,
    currency,
    accountEmail,
    orderPayments = [],
    paymentMethods = [],
    paymentAmount,
    addPromotion,
    removePromotion,
    promoError,
    isUserAuthenticated,
    removePayment,
    orderGiftCards,
    marketId,
    forceEdit,
    onUpdate,
    onCancelEdit,
    shipments,
    useFullBillingAddress = false,
    hideSaveButton = true,
    siteId,
    validateUser,
    useRecaptcha,
    disabled = false,
    ...props
}) {
    const formatter = useExpDateFormat();
    const lastForce = React.useRef(forceEdit);
    const [edit, setEdit] = React.useState(forceEdit);
    const [isFormRendered, setIsFormRendered] = React.useState(false);
    const shippingAddress = React.useMemo(() => {
        const ships = shipments.filter((s) => !s[SHIPMENT.isDigital] && !s[SHIPMENT.isGift]);

        if (ships.length === 1) return ships[0][SHIPMENT.address];
        return null;
    }, [shipments]);

    const defaultCountry = React.useMemo(
        () =>
            shippingAddress
                ? shippingAddress[ADDRESS.country]
                : get(shipments, `[0].${SHIPMENT.address}.${ADDRESS.country}`),
        [shippingAddress, shipments]
    );

    const giftCardPaymentMethod = React.useMemo(() => paymentMethods.find((pm) => pm.SystemName === 'GiftCard'), [
        paymentMethods,
    ]);

    const orderPayment = React.useMemo(() => orderPayments.find((p) => p.PaymentType !== 'GiftCard'), [orderPayments]);

    const paymentType = React.useMemo(
        () => orderPayment?.PaymentType || paymentMethods.find((p) => p.PaymentType !== 'GiftCard')?.PaymentType,
        [orderPayment, paymentMethods]
    );

    const [pType, setPType] = React.useState(paymentType);

    const paymentMethod = React.useMemo(() => paymentMethods?.find((p) => p[PAYMENT.paymentType] === pType), [
        pType,
        paymentMethods,
    ]);

    const initialState = React.useMemo(
        () =>
            CreditCard.paymentToForm({
                payment: orderPayment ?? EMPTY_PAYMENT,
                method: paymentMethod,
                formatter,
            }),
        [paymentMethod, formatter, orderPayment]
    );

    const onAddSavedPayment = React.useCallback(
        async (savedPayment, savePayment = true) => {
            const pmt = CreditCard.paymentToForm({
                payment: savedPayment,
                method: paymentMethod,
                formatter,
                savePayment,
            });

            if (!useRecaptcha) await onUpdate(pmt, null);
            else await validateUser().then((reCaptchaToken) => onUpdate(pmt, reCaptchaToken));
            setEdit(false);
        },
        [paymentMethod, formatter, onUpdate, validateUser, useRecaptcha]
    );

    const onChangeType = React.useCallback((t) => setPType(t), []);

    const wrappedUpdate = React.useCallback(
        async (form, ...args) => {
            if (form?.pmt) {
                setEdit(false);
                if (onCancelEdit) onCancelEdit();
                return null;
            } else {
                if (!useRecaptcha) return onUpdate(form, null, ...args);

                return await validateUser().then((reCaptchaToken) => onUpdate(form, reCaptchaToken, ...args));
            }
        },
        [onUpdate, onCancelEdit, validateUser, useRecaptcha]
    );

    React.useEffect(() => {
        if (forceEdit !== lastForce.current) {
            lastForce.current = forceEdit;
            setEdit(forceEdit);
        }
    }, [forceEdit]);

    React.useEffect(() => {
        setPType(paymentType);
    }, [paymentType]);

    return (
        <>
            {giftCardPaymentMethod ? (
                <CheckoutForm
                    className={cx('CheckoutPayment GiftCard', className)}
                    ReadOnlyContents={() => null}
                    title={<Translation id="Commerce.Order.Checkout.Payment.Title" />}
                    onUpdate={wrappedUpdate}
                    editState={[edit, setEdit]}
                    allowCancelEditing
                    allowUpdates
                    hideSaveActions
                    hideSaveButton={hideSaveButton}
                    onCancelEdit={onCancelEdit}
                    disabled={disabled}
                    {...props}
                >
                    <div className="col-12 col-md-6 col-lg-5 p-0">
                        <Field hidden required name={PAYMENT.systemName} value={giftCardPaymentMethod.SystemName} />
                        <Field hidden required name={PAYMENT.paymentType} value="GiftCard" />
                        <Field hidden required name={PAYMENT.displayName} value={giftCardPaymentMethod.DisplayName} />
                        <GiftCard
                            accountEmail={accountEmail}
                            currency={currency}
                            options={giftCardPaymentMethod.Options}
                            onUpdate={wrappedUpdate}
                            removePayment={removePayment}
                            giftCards={orderGiftCards}
                            savedGiftCards={giftCardPaymentMethod.SavedPaymentInfo}
                        />
                    </div>
                </CheckoutForm>
            ) : null}
            <CheckoutForm
                ReadOnlyContents={ReadOnlyContents}
                readOnlyProps={{
                    orderPayment,
                    paymentAmount,
                    currency,
                    orderGiftCards,
                    paymentType: pType,
                    paymentMethod,
                }}
                title={giftCardPaymentMethod ? null : <Translation id="Commerce.Order.Checkout.Payment.Title" />}
                className={cx('CheckoutPayment Payment', className)}
                isFormRendered={isFormRendered}
                initialState={initialState}
                onUpdate={wrappedUpdate}
                allowCancelEditing
                editState={[edit, setEdit]}
                onCancelEdit={onCancelEdit}
                allowUpdates
                disabled={disabled}
                {...props}
            >
                {Number(paymentAmount) > 0 ? (
                    <FormContents
                        onChangeType={onChangeType}
                        currency={currency}
                        paymentOptions={paymentMethods}
                        orderPayments={orderPayments}
                        orderPayment={orderPayment}
                        onAddSavedPayment={onAddSavedPayment}
                        marketId={marketId}
                        paymentAmount={paymentAmount}
                        isUserAuthenticated={isUserAuthenticated}
                        setIsFormRendered={setIsFormRendered}
                        isFormRendered={isFormRendered}
                        onUpdate={wrappedUpdate}
                        promoError={promoError}
                        addPromotion={addPromotion}
                        removePromotion={removePromotion}
                        useFullBillingAddress={useFullBillingAddress}
                        shippingAddress={shippingAddress}
                        defaultCountry={defaultCountry}
                        siteId={siteId}
                    />
                ) : null}
            </CheckoutForm>
        </>
    );
}

CheckoutPayment.propTypes = {
    className: px.string,
    payment: types.Payment,
    paymentMethods: px.arrayOf(px.object),
    currency: px.string,
    accountEmail: px.string,
    orderPayments: px.arrayOf(px.any),
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    removePayment: px.func,
    orderGiftCards: px.arrayOf(px.any),
    paymentAmount: px.number,
    addPromotion: px.func,
    removePromotion: px.func,
    promoError: px.shape({ message: px.string, timestamp: px.number, reference: px.string, type: px.string }),
    forceEdit: px.bool,
    onUpdate: px.func,
    marketId: px.string,
    onCancelEdit: px.func,
    hideSaveButton: px.bool,
    useFullBillingAddress: px.bool,
    shipments: px.arrayOf(types.Shipment),
    siteId: px.string,
    validateUser: px.func,
    useRecaptcha: px.bool,
    disabled: px.bool,
};
