import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Icon } from 'Common/components/ui';

export default function Panel({ children, className, style }) {
    return (
        <div style={style} className={cx(className, "Panel")}>
            {children}
        </div>
    );
}

Panel.propTypes = {
    children: px.arrayOf(px.node),
    style: px.object,
    className: px.string,
};
