import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import * as types from 'Common/types';
import { useScript } from 'Common/hooks';
import { Translation } from 'Common/components/localization';
import { Field } from 'Common/components/forms';
import { PAYMENT as FIELDS } from 'Common/constants/fields';
import PaymentBlock from '../PaymentBlock';

function createButtons(paypal, id, value, onApprove, onReady) {
    paypal
        .Buttons({
            style: { label: 'paypal' },
            onInit: onReady,
            createOrder: (_, actions) =>
                actions.order.create({
                    purchase_units: [{ amount: { value: String(value) } }],
                }),
            onApprove,
        })
        .render(`#${id}`);
}

export default function PayPal({ options, payment, className, paymentMethod, currency, paymentAmount = '0.01' }) {
    const id = React.useRef(`paypal-buttons-${v4()}`);
    const buttonsAdded = React.useRef(false);
    const hasToken = React.useMemo(() => payment?.PaymentType === 'PayPal' && payment.Token, [payment]);
    const [formValue, setFormValue] = React.useState(hasToken ? payment : { PaymentType: 'PayPal', Token: '' });
    const src = React.useMemo(() => {
        const params = new URLSearchParams();

        params.append('client-id', options.ClientId);
        params.append('currency', currency);
        params.append('intent', 'authorize');
        if (options.Sandbox) params.append('debug', 'true');
        if (options.MerchantId) params.append('merchant-id', options.MerchantId);

        return `https://www.paypal.com/sdk/js?${params.toString()}`;
    }, [options, currency]);

    const [buttonsLoaded, setButtonsLoaded] = React.useState(false);
    const paypal = useScript(src, { globalNamespace: 'paypal', destroyOnDismount: false, delay: 100 });
    const onReady = React.useCallback(() => setButtonsLoaded(true), [setButtonsLoaded]);
    const onComplete = React.useCallback((data) => setFormValue({ ...formValue, Token: data.orderID }), [formValue]);
    const onClear = React.useCallback(() => setFormValue(''), [setFormValue]);

    React.useEffect(() => {
        if (!formValue.Token && paypal && !buttonsAdded.current) {
            createButtons(paypal, id.current, paymentAmount, onComplete, onReady);
            buttonsAdded.current = true;
        }
    }, [paypal, formValue, paymentAmount, onComplete, onReady]);

    return (
        <div className={cx('PayPal row', className)}>
            <Field required hidden name={FIELDS.token} value={formValue.Token} validateOnValueChange />
            {formValue.Token ? null : (
                <p className="col-12 mt-2 ml-2">
                    <Translation id="Commerce.Order.Checkout.PayPal.Content" />
                </p>
            )}
            {buttonsLoaded || formValue.Token ? null : (
                <p className="col-12 ml-2 mt-2">
                    <Translation id="Commerce.Order.Checkout.PayPal.Waiting" />
                </p>
            )}
            {formValue.Token ? (
                <>
                    <p className="col-12 mt-2 ml-2">
                        {hasToken && hasToken === formValue.Token ? (
                            <Translation id="Commerce.Order.Checkout.Payments.ClearPayment.Label" />
                        ) : null}
                    </p>
                    <PaymentBlock
                        className="col-12 mt-2 ml-2"
                        payment={formValue}
                        paymentMethod={paymentMethod}
                        onClick={onClear}
                    />
                    {hasToken !== formValue.Token ? (
                        <p className="col-12 ml-2 mt-2">
                            <Translation id="Commerce.Order.Checkout.PayPal.Continue" />
                        </p>
                    ) : null}
                </>
            ) : (
                <div className="PayPal__Buttons col-12 mt-2 ml-2">
                    <div className={buttonsLoaded ? 'd-block' : 'd-none'} id={id.current}></div>
                </div>
            )}
        </div>
    );
}

PayPal.propTypes = {
    className: px.string,
    options: px.shape({
        Sandbox: px.string,
        EnvKey: px.string,
        MerchantId: px.oneOfType([px.string, px.number]),
        ClientId: px.string,
    }),
    payment: types.Payment,
    currency: px.string,
    paymentAmount: px.number,
    paymentMethod: types.PaymentMethod,
};
