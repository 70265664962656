import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

export default function ProductTitle({ className, productTitle = '', useLargeProductTitle }) {
    const targetRef = React.useRef();
    const [dimensions, setDimensions] = React.useState(null);

    React.useLayoutEffect(() => {
        if (targetRef.current) {
            const innerRef = targetRef.current;

            //set the dimensions when we first load
            setDimensions({
                height: innerRef.offsetHeight,
            });

            //use a resize observer (doesn't work in IE) to trigger a size change whenever the inner element resizes
            const resizeObserver = new ResizeObserver((observedEntries) => {
                setDimensions({
                    height: observedEntries[0].target.offsetHeight,
                });
            });

            resizeObserver.observe(innerRef);

            return () => {
                resizeObserver.unobserve(innerRef);
            };
        }
    }, [productTitle, targetRef]);

    return (
        <div
            className={cx('ProductTitle mt-4 mt-md-0', className)}
            style={{ height: dimensions?.height + 'px', transition: 'height 300ms', overflow: 'hidden' }}
        >
            {useLargeProductTitle ? <p ref={targetRef}>{productTitle}</p> : <h3 ref={targetRef}>{productTitle}</h3>}
        </div>
    );
}

ProductTitle.propTypes = {
    className: px.string,
    productTitle: px.string,
    useLargeProductTitle: px.bool,
};
