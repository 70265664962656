import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import $ from 'jquery';
import { useTranslation, useResize } from 'Common/hooks';
import { AlertTip, Icon } from '../ui';

export default function MiniCart({
    qty = 0,
    className,
    color = 'primary',
    iconSize,
    alertMessage,
    alertTimestamp,
    useSvg = false,
    onClick,
    cartPage,
    alertPlacement = 'bottom',
}) {
    const action = React.useMemo(() => onClick || (() => (window.location.href = cartPage)), [cartPage, onClick]);
    const ref = React.useRef();
    const lblCart = useTranslation('Commerce.Order.Cart.Link.Label');
    const [hideAlert, setHideAlert] = React.useState(false);
    const onResize = React.useCallback(() => (ref.current && $(ref.current).offset()) || {}, []);
    const position = useResize(onResize);

    //The MiniCart will be at position 0, 0 when it is hidden from the document
    React.useLayoutEffect(() => {
        setHideAlert(!position.left && !position.top);
    }, [position]);

    return (
        <AlertTip
            placement={alertPlacement}
            className="mini-cart-tip"
            message={alertMessage}
            timestamp={alertTimestamp}
            hidden={hideAlert}
        >
            <button
                role="button"
                onClick={action}
                aria-label={lblCart}
                className={cx('MiniCart', `btn header__minicart text__color-${color}`, className)}
                ref={ref}
            >
                <small>{qty}</small>
                {useSvg ? <Icon name="cart" size={iconSize} /> : <Icon icon="fas fa-shopping-cart" />}
            </button>
        </AlertTip>
    );
}

MiniCart.propTypes = {
    qty: px.number,
    className: px.string,
    onClick: px.func,
    color: px.string,
    cartPage: px.string,
    alertMessage: px.string,
    alertTimestamp: px.number,
    useSvg: px.bool,
    iconSize: px.oneOfType([px.string, px.number]),
    alertPlacement: px.oneOf(['bottom', 'left', 'top', 'right']),
};
