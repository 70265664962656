import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { ProductDescriptionTab } from 'Common/components/product';
import {
    ProductBuyInBulk,
    ProductFeatures,
    ProductSpecifications,
    ProductIncludes,
    ProductManuals
} from './ProductTabContent/TabContent';
import { Translation } from '../localization';

export default function StackContent({
    className,
    displayOnly,
    onAddItemToCart,
    orderLastUpdate,
    productDefinitions,
    specsTableBreakpoint,
    stackContent,
    totalQty = 0,
    ...props
}) {

    return (
        <div className={cx('StackContent', className)}>
            <div className="container">
                {stackContent.map((tab, index) => {
                    let Component = null;

                    switch (tab.tab) {
                        case 'Includes':
                            Component = (
                                <ProductIncludes
                                    typeId={tab.typeId}
                                    facetTableData={tab.content}
                                    onAddItemToCart={onAddItemToCart}
                                    orderLastUpdate={orderLastUpdate}
                                    showAddToCart={tab.showAddToCart}
                                />
                            );
                            break;
                        case 'Description':
                            Component = <ProductDescriptionTab {...props} data={tab.content} />;
                            break;
                        case 'Features':
                            Component = <ProductFeatures {...props} features={tab.content} />;
                            break;
                        case 'Specifications':
                            Component = (
                                <ProductSpecifications
                                    {...props}
                                    specsTableBreakpoint={specsTableBreakpoint}
                                    onAddItemToCart={displayOnly ? null : onAddItemToCart}
                                    orderLastUpdate={orderLastUpdate}
                                    specifications={tab.content}
                                    productDefinitions={productDefinitions}
                                    totalQty={totalQty}
                                />
                            );
                            break;
                        case 'BuyInBulk':
                            Component = (
                                <ProductBuyInBulk
                                    {...props}
                                    onAddItemToCart={onAddItemToCart}
                                    orderLastUpdate={orderLastUpdate}
                                    buyInBulkTableData={tab.content}
                                    totalQty={totalQty}
                                />
                            );
                            break;
                        case 'Information.Manuals':
                            Component = (
                                <ProductManuals {...props} data={tab.content} />
                            );
                            break;
                        default:
                            Component = null;
                    }

                    return (
                        <div key={index}>
                            <div className={`StackContent__header`}>
                                <Translation id={(`Commerce.Product.Tabs.${tab.tab}.TabName`)}/>
                            </div>
                            {Component}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

StackContent.propTypes = {
    className: px.string,
    displayOnly: px.bool,
    onAddItemToCart: px.func,
    orderLastUpdate: px.number,
    productDefinitions: px.arrayOf(px.string),
    specsTableBreakpoint: px.number,
    stackContent: px.arrayOf(
        px.shape({
            tab: px.string,
            content: px.any,
            typeId: px.string,
            showAddToCart: px.bool,
        })
    ),
    totalQty: px.number,
};
