import { useState, useEffect, useRef } from 'react';
import { Viewport } from 'Common/utils';
import $ from 'jquery';

const DEFAULT_GETTER = (window) => ({ width: $(window).width(), height: $(window).height() });

export default function useResize(getState = DEFAULT_GETTER) {
    const [state, setState] = useState(getState(window));
    const getter = useRef(getState);

    useEffect(() => {
        getter.current = getState;
        setState(getState(window));
    }, [getState]);

    useEffect(() => {
        function handler(window) {
            setState(getter.current(window));
        }

        Viewport.onResize(handler);
        return () => Viewport.offResize(handler);
    }, []);

    return state;
}
