import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import debounce from 'lodash.debounce';

const Popup = styled.div`
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: all;
    }
`;

export default function PortalPopup({ className, children, anchorRef, showPopup = false, style }) {
    const popupRef = React.useRef();
    const [y, setY] = React.useState(null);
    const [x, setX] = React.useState();

    const containerStyle = React.useMemo(
        () => ({
            ...style,
            left: x,
            top: y,
        }),
        [x, y, style]
    );

    const reposition = React.useMemo(
        () =>
            debounce(() => {
                const el = anchorRef.current;

                if (!el) return;
                const elRect = el.getBoundingClientRect();
                const bodyRect = document.body.getBoundingClientRect();
                const popupRect = popupRef.current?.getBoundingClientRect();

                elRect.top < screen.height / 2 || !popupRect
                    ? setY(`${elRect.bottom - bodyRect.top}px`)
                    : setY(`${elRect.bottom - bodyRect.top - popupRect.height - elRect.height}px`);
                const alignRight = screen.width - elRect.left < popupRect?.width / 2;
                const alignLeft = elRect.left < popupRect?.width / 2;

                setX(
                    alignRight
                        ? `calc(100% - ${popupRect?.width}px)`
                        : alignLeft
                        ? `${elRect.left}px`
                        : `${elRect.left - popupRect.width / 2}px`
                );
            }),
        [anchorRef]
    );

    const repositionRef = React.useRef(reposition);

    React.useEffect(() => {
        function handler() {
            repositionRef.current();
        }

        handler();
        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    React.useEffect(() => {
        repositionRef.current = reposition;
        if (showPopup) reposition();
    }, [showPopup, reposition]);

    return createPortal(
        <Popup ref={popupRef} style={containerStyle} className={cx('PortalPopup', showPopup && 'show', className)}>
            {children}
        </Popup>,
        document.body
    );
}

PortalPopup.propTypes = {
    className: px.string,
    children: px.node,
    anchorRef: px.objectOf(px.any),
    showPopup: px.bool,
    style: px.objectOf(px.any),
};
