import Url from './Url';
import * as PRODUCT from '~config/product';

export default class ProductImage {
    static getSecondaryImageUrl(
        media = [],
        primaryImageUrl = '',
        imageQuery = `format=png&width=430&height=430${
            PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
        }`
    ) {
        const medium = media.find(
            (m) => m.MediaType === 'image' && m.MediaGroup === 'Alternate' && m.Url !== primaryImageUrl
        );

        return medium ? Url.addToRelativeUrl(medium.Url, imageQuery) : null;
    }

    static getGlamOrDefaultImageUrl(media = [], defaultImage, imageQuery = null) {
        const mastImg = media.find((m) => m.MediaGroup === 'Glam')?.Url;
        const url = mastImg ? mastImg : defaultImage;

        return imageQuery ? Url.addToRelativeUrl(url, imageQuery) : url;
    }
}
