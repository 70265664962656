import * as React from 'react';
import VideoPlayer from './VideoPlayer';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import { Modal } from 'Common/components/ui';

export default function VideoPlayerModal({ className, videoProps = {}, show = false, onClose }) {
    const id = React.useRef(`VideoModal_${v4()}`);

    const clickOutside = React.useCallback(() => {
        const background = document.getElementById(id.current);

        if (background) onClose();
    }, [onClose])

    return (
        <div
            className={cx('VideoPlayerModal', className)}
            onClick={clickOutside}
        >
            <Modal
                modalSize="xl"
                showModal={show}
                id={id.current}
                onClose={onClose}
                body={<VideoPlayer {...videoProps} />}
            />
        </div>
    );
}

VideoPlayerModal.propTypes = {
    className: px.string,
    videoProps: px.shape({
        url: px.string,
        previewImage: px.string,
        playing: px.bool,
    }),
    onClose: px.func,
    show: px.bool,
};
