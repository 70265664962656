import { useEffect } from "react";

export default function useWindowResize(callback, deps) {
    useEffect(() => {
        function recompute() {
            callback();
        }

        window.addEventListener('resize', recompute);

        return () => {
            window.removeEventListener('resize', recompute);
        };
    }, deps);
}
