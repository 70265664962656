import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import ToolTip from './ToolTip';

const DEFAULT_TIMEOUT_MS = 5000;

export default function AlertTip({
    className,
    message,
    timeout = DEFAULT_TIMEOUT_MS,
    timestamp,
    selector,
    hidden = false,
    ...props
}) {
    const [showTip, setShowTip] = React.useState(false);
    const tipTimer = React.useRef(null);
    const lastTimestamp = React.useRef(0);
    const timeoutRef = React.useRef(timeout);

    React.useEffect(() => {
        timeoutRef.current = timeout;
    }, [timeout]);

    React.useEffect(() => {
        if (timestamp !== lastTimestamp.current) {
            setShowTip(true);
            clearTimeout(tipTimer.current);
            tipTimer.current = setTimeout(() => setShowTip(false), timeoutRef.current);
            lastTimestamp.current = timestamp;
        }
    }, [timestamp]);

    React.useEffect(() => {
        return () => {
            clearTimeout(tipTimer.current);
        };
    }, []);

    return (
        <ToolTip
            className={cx('AlertTip', className)}
            forceShowTip={!hidden && showTip}
            tip={message}
            selector={selector}
            {...props}
        ></ToolTip>
    );
}

AlertTip.propTypes = {
    className: px.string,
    message: px.string,
    timestamp: px.number,
    children: px.node,
    hidden: px.bool,
    selector: px.string,
    timeout: px.number,
};
