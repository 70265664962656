import React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import $ from 'jquery';
import { useTranslation, useRegionSelection } from 'Common/hooks';
import * as types from 'Common/types';
import { AddressUtil } from 'Common/utils';
import { ADDRESS } from 'Common/constants/fields';
import { Modal } from '../ui';
import { Translation } from '../localization';
import { AddressVerificationDrawer } from '../checkout';
import EditAddressForm from './EditAddressForm';

const ACTION = {
    edit: 'edit',
    delete: 'delete',
    none: null,
};

export default function CustomerAddressesGrid({
    className,
    siteId,
    addresses,
    onFetchAddresses,
    onRemoveAddress,
    setCustomerError,
    clearCustomerError,
    verification,
    onValidateShipment,
    onConfirmShipment,
    onCancelVerification,
    defaultCountry,
}) {
    const initialFetch = React.useRef(false);
    const formRef = React.useRef(null);
    const formApi = React.useRef(null);
    const closeLabel = useTranslation('Commerce.Account.CancelLabel');
    const confirmLabel = useTranslation('Commerce.Account.ConfirmLabel');
    const lblEdit = useTranslation('Commerce.Order.Shipments.EditItem.Label');
    const lblRemove = useTranslation('Commerce.Order.Shipments.RemoveItem.Label');
    const [working, setWorking] = React.useState(false);
    const [selected, setSelected] = React.useState({ action: null, address: null });
    const [stateCopy, setStateCopy] = React.useState({});
    const [localError, setLocalError] = React.useState(null);
    const [postalRegex, setPostalRegex] = React.useState(null);
    const getRegion = React.useCallback(() => stateCopy[ADDRESS.state] || null, [stateCopy]);
    const getCountry = React.useCallback(() => stateCopy[ADDRESS.country] || null, [stateCopy]);

    const setRegion = React.useCallback((v) => formApi.current?.setValue(ADDRESS.region, v), []);
    const setCountry = React.useCallback((v, { PostalValidationRegex } = {}) => {
        formApi.current?.setValue(ADDRESS.country, v);
        if (PostalValidationRegex) setPostalRegex(new RegExp(PostalValidationRegex, 'i'));
    }, []);

    const { countryList, regionOptions, fetchRegions } = useRegionSelection({
        getCountry,
        getRegion,
        setCountry,
        setRegion,
        siteId,
        enabled: true,
    });

    const onCountryChange = React.useCallback(
        (code) => {
            const { PostalValidationRegex } = countryList?.find((c) => c.CountryCode === code) || {};

            if (PostalValidationRegex) setPostalRegex(new RegExp(PostalValidationRegex, 'i'));
            fetchRegions(code);
        },
        [countryList, fetchRegions]
    );

    const sortedAddress = React.useMemo(() => (addresses ? addresses.slice(0).sort(AddressUtil.sortedAddress) : []), [
        addresses,
    ]);

    const addressExists = React.useCallback(
        (form) =>
            addresses?.filter((a) => a[ADDRESS.id] !== form[ADDRESS.id])?.find((a) => AddressUtil.isEqual(form, a)),
        [addresses]
    );

    const selectAddress = React.useCallback(
        (action = null, address = null) => () => {
            setSelected({ action, address });
            setStateCopy({ ...address });
        },
        []
    );

    const clearSelection = React.useCallback(() => selectAddress()(), [selectAddress]);

    const onCancel = React.useCallback(() => {
        setLocalError();
        clearCustomerError();
        clearSelection();
        formApi.current = null;
    }, [clearCustomerError, clearSelection]);

    const onSubmit = React.useCallback(
        async ({ values: form } = {}) => {
            clearCustomerError();
            setWorking(true);
            setLocalError(null);
            if (addressExists(form)) {
                setLocalError('Commerce.Account.Error.DuplicateAddress');
            } else {
                const res = await onValidateShipment(form);

                if (res?.success) {
                    clearSelection();
                    formApi.current = null;
                } else if (res?.errors?.length) {
                    setLocalError(res.errors[0]);
                }
            }
            setWorking(false);
        },
        [clearCustomerError, addressExists, onValidateShipment, clearSelection]
    );

    const onDelete = React.useCallback(async () => {
        let res;

        clearCustomerError();
        setWorking(true);

        try {
            res = onRemoveAddress(selected.address[ADDRESS.id]);
        } catch (e) {
            setCustomerError(e?.message || e);
        }

        clearSelection();
        setWorking(false);
        return res;
    }, [clearCustomerError, setCustomerError, clearSelection, onRemoveAddress, selected]);

    React.useEffect(() => {
        if (!initialFetch.current && !addresses?.length) {
            initialFetch.current = true;
            onFetchAddresses();
        }
    }, [addresses, onFetchAddresses]);

    // scroll to and open address form when adding or editing an address
    React.useLayoutEffect(() => {
        if (selected.action === ACTION.edit) {
            $(formRef.current).collapse('show');
            $('html, body').animate({ scrollTop: formRef.current?.offsetTop || 0 }, 'slow');
        } else {
            $(formRef.current).collapse('hide');
        }
    }, [selected.action]);

    return (
        <div className={cx('CustomerAddressesGrid', className)}>
            <button
                onClick={selectAddress(ACTION.edit, { [ADDRESS.country]: defaultCountry ?? null })}
                disabled={working || selected.action}
                className={cx('btn btn-primary', {
                    disabled: working || selected.action,
                })}
                type="button"
            >
                <i className="fa fa-plus fa-fw" aria-hidden="true" />
                <strong>
                    <Translation id="Commerce.Account.AddAddressLabel" />
                </strong>
            </button>
            <div ref={formRef} className="collapse">
                {selected.action === ACTION.edit ? (
                    <EditAddressForm
                        formProps={{
                            initialValues: selected.address,
                            onSubmit,
                            formApiRef: formApi,
                            onValueChange: ({ values }) => setStateCopy({ ...values }),
                        }}
                        onClose={onCancel}
                        className="p-2"
                        formError={localError}
                        countryList={countryList}
                        postalRegex={postalRegex}
                        regionOptions={regionOptions}
                        onCountryChange={onCountryChange}
                        disabled={working}
                    />
                ) : null}
            </div>
            <div className="card-group pt-4">
                {sortedAddress?.length ? (
                    sortedAddress.map((address) => (
                        <div className="col-12 col-lg-6 p-0" key={address.Id}>
                            <div className="address card d-inline-block border-0 pb-5">
                                <strong className="address__label">
                                    <Translation id="Commerce.Account.ShippingAddressLabel" />{' '}
                                    {address[ADDRESS.defaultAddress] ? (
                                        <Translation id="Commerce.Account.DefaultLabel" />
                                    ) : null}
                                </strong>
                                <div className="address__full-name mt-1">
                                    {address.FirstName} {address.LastName}
                                </div>
                                {address.Organization ? (
                                    <div className="address__company mt-1">{address.Organization}</div>
                                ) : null}
                                <div className="address__line-1 mt-1">{address.Line1}</div>
                                {address.Line2 ? <div className="address__line-2 mt-1">{address.Line2}</div> : null}
                                <div className="address__line-3 mt-1">
                                    {address.City}, {address.RegionCode} {address.PostalCode}
                                </div>
                                {countryList ? (
                                    <div className="address__country mt-1">
                                        {countryList.find((c) => c.CountryCode === address?.CountryCode)?.CountryName}
                                    </div>
                                ) : null}
                                <div className="mt-1 flex flex-center">
                                    <button
                                        disabled={working}
                                        onClick={selectAddress(ACTION.edit, address)}
                                        className="mr-1 btn btn-sm p-1"
                                        title={lblEdit}
                                        aria-label={lblEdit}
                                    >
                                        <i className="fa fa-pen" />
                                    </button>
                                    <button
                                        disabled={working}
                                        onClick={selectAddress(ACTION.delete, address)}
                                        className="btn btn-sm p-1"
                                        title={lblRemove}
                                        aria-label={lblRemove}
                                    >
                                        <i className="fa fa-trash" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : !selected.action ? (
                    <p>
                        <Translation id="Commerce.Account.NoAddressesSavedLabel" />
                    </p>
                ) : null}
            </div>
            {selected.action === ACTION.delete ? (
                <Modal
                    onConfirm={onDelete}
                    onClose={clearSelection}
                    closeLabel={closeLabel}
                    confirmLabel={confirmLabel}
                    header={<Translation id="Commerce.Account.DeleteModalLabel" />}
                    body={<Translation id="Commerce.Account.DeleteAddressModalBodyText" />}
                    id="deleteAddressModal"
                    showModal={true}
                />
            ) : null}
            <AddressVerificationDrawer
                open={!!verification.unverifiedAddress}
                onSelect={onConfirmShipment}
                onCancel={onCancelVerification}
                address={verification.unverifiedAddress}
                verifiedAddresses={verification.verifiedAddresses}
                className="Drawer__slider content"
            />
        </div>
    );
}

CustomerAddressesGrid.propTypes = {
    className: px.string,
    siteId: px.string,
    addresses: px.arrayOf(px.object),
    onFetchAddresses: px.func,
    onRemoveAddress: px.func,
    // onUpdateAddress: px.func,
    setCustomerError: px.func,
    clearCustomerError: px.func,
    defaultCountry: px.string,
    verification: px.shape({
        shipment: types.Address,
        unverifiedAddress: types.Address,
        verifiedAddresses: px.arrayOf(types.Address),
    }),
    onValidateShipment: px.func,
    onConfirmShipment: px.func,
    onCancelVerification: px.func,
};
