import * as React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import Grid from 'Common/components/customer/CustomerGiftCardsGrid';
import { customer } from '~features';
import CustomerError from './CustomerError';

export default function CustomerGiftCardsGrid(props) {
    const giftCards = useSelector(customer.selectors.getCustomerGiftCards);
    const onFetchGiftCards = useAction(customer.actions.fetchGiftCards);
    const onSaveGiftCard = useAction(customer.actions.saveCustomerGiftCard);
    const onRemoveGiftCard = useAction(customer.actions.removeCustomerGiftCard);

    return (
        <>
            <Grid
                {...props}
                giftCards={giftCards}
                onFetchGiftCards={onFetchGiftCards}
                onSaveGiftCard={onSaveGiftCard}
                onRemoveGiftCard={onRemoveGiftCard}
            />
            <CustomerError />
        </>
    );
}
