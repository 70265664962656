import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useBEM, useViewport } from 'Common/hooks';
import { InfiniteScroll } from 'Common/components/ui';
import CatalogExploreCard from './CatalogExploreCard';

export default function CatalogExplore({ pageInfo = {}, className, products = [], onLoadMore, cardProps = {} }) {
    const viewPort = useViewport();
    const isDoneLoading = React.useMemo(() => pageInfo.page * pageInfo.pageSize >= pageInfo.total, [pageInfo]);
    const [b, em] = useBEM('CatalogExplore', className);

    return (
        <div className={cx(b, 'container')}>
            <InfiniteScroll onLoad={onLoadMore} useLoadIndicator={true} isDoneLoading={isDoneLoading}>
                <div>
                    {products?.length
                        ? products.map((x, i) => (
                              <CatalogExploreCard
                                  {...cardProps}
                                  className={cx(em('Card'), cardProps.className)}
                                  key={x.Code}
                                  product={x}
                                  displayImgFirst={i % 2 == 0 || viewPort.is.lt('md')}
                              />
                          ))
                        : null}
                </div>
            </InfiniteScroll>
        </div>
    );
}

CatalogExplore.propTypes = {
    className: px.string,
    pageInfo: types.PageInfo,
    products: px.arrayOf(types.Product),
    onLoadMore: px.func,
    desktopBreakpoint: px.string,
    loadOnArgsChange: px.bool,
    gtmListValue: px.string,
    cardProps: px.shape(CatalogExploreCard.propTypes),
};
