import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { PageInfo } from 'Common/types';
import { Translation } from 'Common/components/localization';

export default function SearchPagingSummary({ className, style, pageInfo, hide = false }) {
    return (
        <div style={style} className={cx(className, 'SearchPagingSummary', hide ? 'invisible' : null)}>
            <p>
                {!pageInfo?.total ? (
                    <Translation id="Search.No.Results.Message" />
                ) : (
                    <Translation id="Search.Results.Page.Data" params={{ count: pageInfo?.count }} />
                )}
            </p>
        </div>
    );
}

SearchPagingSummary.propTypes = {
    style: px.object,
    className: px.string,
    pageInfo: PageInfo,
    hide: px.bool,
};
