import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { Facet } from 'Common/types';
import { Dropdown } from 'Common/components/ui';
import { toggleFacetValue } from './utils';

export default function SearchRadioFacet({ className, style, facet, onChange }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleOpen = React.useCallback(() => setIsOpen((val) => !val), []);
    const id = React.useRef(`radio_${v4()}`);

    return (
        <Dropdown
            className={cx('SearchRadioFacet', className)}
            style={style}
            icon={<i className={cx('fas  ml-2 mb-1', `fa-chevron-${isOpen ? 'up' : 'down'}`)} />}
            selectedValue={facet.title}
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            drawerType="relative"
        >
            {facet.options?.map((o, idx) => (
                <div key={o.value} className="SearchRadioFacet__option">
                    <input
                        type="radio"
                        name={`${o.title}_RadioSelector`}
                        id={`${id.current}${o.value.replace(/\s/g, '')}`}
                        checked={o.selected}
                        readOnly={true}
                        className="form-check-input"
                        onClick={() => onChange(toggleFacetValue(idx, facet))}
                    ></input>
                    <label className="form-check-label" htmlFor={`${id.current}${o.value.replace(/\s/g, '')}`}>
                        {o.title}
                    </label>
                </div>
            ))}
        </Dropdown>
    );
}

SearchRadioFacet.propTypes = {
    className: px.string,
    style: px.object,
    facet: Facet,
    onChange: px.func,
};
