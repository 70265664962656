import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useViewport, useTranslation, useOrderRequestState } from 'Common/hooks';
import { Url } from 'Common/utils';
import { AccordionTable, PureTip } from 'Common/components/ui';
import * as PRODUCT from '~config/product';
import ProductPrice from '../../ProductPrice';
import ProductBadge from '../../ProductBadge';
import ProductInput from '../../ProductInput';
import { Product } from 'Common/types';
import { GTM } from 'Common/constants';
import { Translation } from 'Common/components/localization';

function isQuantityValid(value = 0) {
    const nonZeroPositiveIntegerRegex = /^[1-9][0-9]*$/;

    return nonZeroPositiveIntegerRegex.test(value);
}

export default function ProductBuyInBulk({
    className,
    buyInBulkTableData,
    id,
    onAddItemToCart,
    orderLastUpdate,
    forceMobile = false,
    Price = ProductPrice,
    labelledBy = id ? id.replace(/content/g, 'tab') : undefined,
    isUserAuthenticated,
    statusTooltip,
    totalQty
}) {
    const viewport = useViewport();
    const [isLoading, setIsLoading] = React.useState(false);
    const [rows, setRows] = React.useState({});
    const updateRow = React.useCallback(
        (rowId, state) => {
            setRows({ ...rows, [rowId]: state });
        },
        [rows]
    );

    const addToCartLabel = useTranslation('Form.Button.AddToCart.Label');
    const quantityLabel = useTranslation('Commerce.Product.Tabs.BuyInBulk.Quantity.Label');
    const priceLabel = useTranslation('Commerce.Product.Tabs.Price.Label');
    const typeIdLabel = useTranslation('Commerce.Product.Tabs.TypeId.Label');
    const skuLabel = useTranslation('Commerce.Product.Tabs.Sku.Label');
    const colorLabel = useTranslation('Commerce.Product.Tabs.Color.Label');
    const cartIsUpdating = useOrderRequestState();
    const [addToCartTip, setAddToCartTip] = React.useState({
        timestamp: 0,
        message: null,
        hidden: true,
    });

    const accordionHeaders = React.useMemo(
        () =>
            forceMobile || viewport.is.mobile
                ? [typeIdLabel, skuLabel]
                : [typeIdLabel, colorLabel, skuLabel, priceLabel, quantityLabel],
        [colorLabel, viewport, priceLabel, quantityLabel, skuLabel, typeIdLabel, forceMobile]
    );
    
    const showTip = React.useCallback(
        (tip) => (viewport.is.touch && tip ? () => alert(tip) : null),
        [viewport]
    );

    const statusCallback = React.useCallback(
        (code) => 
            statusTooltip
            ? (successMsg, validationIssues = [], params) => {
                const setter = setAddToCartTip;
                const timestamp = Date.now();
                const placement = 'top';

                if (successMsg) {
                    setter({ message: successMsg, params, timestamp, placement, level: 'success' });
                } else {
                    const lineItem = validationIssues?.find(
                        (issue) => issue.RefType === 'LineItem' && code.includes(issue.Ref)
                    )?.Issue;

                    setter({ message: lineItem, timestamp, placement, level: 'danger' });
                }
                showTip(addToCartTip);
              } : null, 
              [addToCartTip, showTip, statusTooltip]
    )

    const onAddCart = React.useCallback(
        (code, quantity, { gtmPayload }) => {
            if (gtmPayload.ecommerce.items.length) {
                gtmPayload.ecommerce.items[0].quantity = quantity;
            }
            setIsLoading(true);
            onAddItemToCart(code, quantity, { gtmPayload, statusCallback: statusCallback(code) });
        },
        [onAddItemToCart, statusCallback]
    );

    React.useEffect(() => {
        setIsLoading(false);
    }, [orderLastUpdate]);
    
    const accordionData = React.useMemo(() => {
        return buyInBulkTableData.map((data) => {
            if (forceMobile || viewport.is.mobile) {
                const dropdownData = [
                    {
                        label: colorLabel,
                        values: [
                            {
                                node: data.swatch,
                                id: data.swatch,
                            },
                        ],
                    },
                    {
                        label: priceLabel,
                        values: [
                            {
                                node: <Price priceInfo={data.priceInfo} quantity={rows[data.id]?.quantity} />,
                                id: priceLabel,
                            },
                        ],
                    },
                    {
                        label: quantityLabel,
                        values: [
                            {
                                //mobile
                                node: (
                                    <ProductInput
                                        className={"BuyInBulk"}
                                        isUserAuthenticated={isUserAuthenticated}
                                        onQuantityChange={(inputValue) =>
                                            updateRow(data.id, {
                                                quantity: inputValue,
                                                disabled: !isQuantityValid(inputValue),
                                            })}
                                        quantity={rows[data.id]?.quantity ?? ''}
                                        currentChildren={data}
                                        onAddToCart={onAddItemToCart}
                                        currentItemStatus={
                                            {
                                                code: data.id,
                                                hasStock: data.hasStock
                                            }
                                        }
                                        productInfo={data.productInfo}
                                        gtmListValue={GTM.TAGS.PDP}
                                        statusTooltip={statusTooltip ? 'top' : null}
                                        productRecommendationId={data.productInfo?.ProductRecommendationId}
                                        itemCodes={data.id}
                                        totalQty={totalQty}
                                        bibProdInput={true}
                                    />
                                ),
                                id: quantityLabel,
                            },
                        ],
                    },
                    {
                        label: null,
                        values: [
                            {
                                node: (
                                    <div 
                                        className='w-100'
                                    >
                                        <PureTip
                                            timestamp={addToCartTip.timestamp}
                                            placement={addToCartTip.placement}
                                            level={addToCartTip.level}
                                            clickDismiss
                                        >
                                            <Translation id={addToCartTip.message} params={addToCartTip.params} />
                                        </PureTip>
                                        <button
                                            disabled={
                                                isLoading ||
                                                !data.hasStock ||
                                                cartIsUpdating ||
                                                (rows[data.id] ? rows[data.id].disabled : true)
                                            }
                                            onClick={() =>
                                                onAddCart(data.id, rows[data.id]?.quantity, { gtmPayload: data.gtaPayload })
                                            }
                                            className="w-100 btn-primary"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                        >
                                            <Translation id={addToCartLabel} />
                                        </button>
                                    </div>
                                ),
                                id: 'addToCart',
                            },
                        ],
                    },
                ];

                return {
                    content: dropdownData,
                    id: data.id,
                    rowValues: [
                        {
                            value: (
                                <div className="d-flex align-items-center">
                                    <ProductBadge badges={data.badges} className="mr-2" style={{ fontSize: '.8em' }} />
                                    <img
                                        className="thumbnail mr-3 mr-md-0"
                                        src={Url.addToRelativeUrl(
                                            data.imageUrl,
                                            `format=png&height=49&width=82${
                                                PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                                            }&fuzziness=40`
                                        )}
                                        aria-hidden
                                        alt=""
                                    />
                                </div>
                            ),
                            id: data.imageUrl,
                        },
                        {
                            value: data.id,
                            id: data.id,
                        },
                    ],
                };
            }

            return {
                id: data.id,
                rowValues: [
                    {
                        value: (
                            <div className="d-flex align-items-center">
                                <ProductBadge badges={data.badges} className="mr-2" style={{ fontSize: '.8em' }} />
                                <img
                                    className="thumbnail mr-3 mr-md-0"
                                    src={Url.addToRelativeUrl(
                                        data.imageUrl,
                                        `format=png&height=49&width=82${
                                            PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                                        }&fuzziness=40`
                                    )}
                                    aria-hidden
                                    alt=""
                                />
                            </div>
                        ),
                        id: data.imageUrl,
                    },
                    {
                        value: data.swatch,
                        id: data.swatch,
                    },
                    {
                        value: data.id,
                        id: data.id,
                    },
                    {
                        value: <Price priceInfo={data.priceInfo} quantity={rows[data.id]?.quantity} />,
                        id: 'Commerce.Product.Tabs.Price.Label',
                    },
                    {
                        //Desktop
                        value: (
                            <ProductInput
                                isUserAuthenticated={isUserAuthenticated}
                                onQuantityChange={(inputValue) =>
                                    updateRow(data.id, {
                                        quantity: inputValue,
                                        disabled: !isQuantityValid(inputValue),
                                    })}
                                quantity={rows[data.id]?.quantity ?? ''}
                                currentChildren={data}
                                onAddToCart={onAddItemToCart}
                                currentItemStatus={
                                    {
                                        code: data.id,
                                        hasStock: data.hasStock
                                    }
                                }
                                productInfo={data.productInfo}
                                gtmListValue={GTM.TAGS.PDP}
                                statusTooltip={statusTooltip ? 'right' : null}
                                productRecommendationId={data.productInfo?.ProductRecommendationId}
                                itemCodes={data.id}
                                totalQty={totalQty}
                                bibProdInput={true}
                            />
                        ),
                        id: 'Commerce.Product.Tabs.BuyInBulk.Quantity.Label',
                    },
                ],
            };
        });
    }, [buyInBulkTableData, forceMobile, viewport.is.mobile, rows, isUserAuthenticated, onAddItemToCart, statusTooltip, totalQty, colorLabel, priceLabel, quantityLabel, addToCartTip.timestamp, addToCartTip.placement, addToCartTip.level, addToCartTip.message, addToCartTip.params, isLoading, cartIsUpdating, addToCartLabel, updateRow, onAddCart]);

    return (
        <div
            id={id}
            role="tabpanel"
            aria-labelledby={labelledBy}
            className={cx('ProductBuyInBulk tab-pane fade show table-responsive', className)}
        >
            <AccordionTable accordionData={accordionData} accordionHeaders={accordionHeaders} />
        </div>
    );
}

ProductBuyInBulk.propTypes = {
    className: px.string,
    buyInBulkTableData: px.arrayOf(
        px.shape({
            content: px.arrayOf(
                px.shape({ values: px.arrayOf(px.shape({ node: px.node, id: px.any })), label: px.string })
            ),
            id: px.string,
            imageUrl: px.string,
            priceInfo: px.object,
            hasStock: px.bool,
            gtaPayload: px.object,
            productInfo: Product,
        })
    ),
    onAddItemToCart: px.func,
    id: px.string,
    labelledBy: px.string,
    forceMobile: px.bool,
    orderLastUpdate: px.number,
    Price: px.elementType,
    isUserAuthenticated: px.bool,
    statusTooltip: px.oneOf([false, true, undefined, null, 'top', 'bottom', 'right']),
    totalQty: px.number,
};
