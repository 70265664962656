import * as types from './types';
import * as api from './api';
import state from './state';
import { logger } from 'Common/core';
import { Url } from 'Common/utils';
import { getSearchQuery, getFilteredItems, getFilteredFacets } from './selectors';

export function setFilteredItems(filteredItems) {
    return {
        type: types.SET_FILTERED_ITEMS,
        payload: filteredItems,
    };
}

export function fetchItems() {
    return async (dispatch, getState) => {
        const response = await api.searchProducts(getSearchQuery(getState()));

        if (response?.success) {
            dispatch(setFilteredItems(response.filteredItems));
        } else {
            logger.warn(`Search request failed: ${response.errors[0]}`);
        }
    };
}

export function loadMore(skipVariantEnrichment) {
    return async (dispatch, getState) => {
        const s = getState();
        const filteredItems = getFilteredItems(s);
        const query = getSearchQuery(s);
        const nextPage =
            filteredItems.Page + 1 > filteredItems.TotalPages ? filteredItems.TotalPages : filteredItems.Page + 1;

        const response = await api.searchProducts({ ...query, Page: nextPage, ShouldSkipChildEnrichment: skipVariantEnrichment });

        if (response.success) {
            const Items = filteredItems.Items.concat(response.filteredItems.Items);

            Url.current
                .addParam('s', response.filteredItems.SearchQuery.PageSize)
                .addParam('p', response.filteredItems.SearchQuery.Page)
                .apply();

            dispatch(
                setFilteredItems({
                    ...filteredItems,
                    Page: response.filteredItems.SearchQuery.Page,
                    PageItems: Items.length,
                    Items,
                    SearchQuery: response.filteredItems.SearchQuery,
                })
            );
        } else {
            logger.warn(`Search request failed: ${response.errors[0]}`);
        }
    };
}

export function goToPage(pageNumber, skipChildEnrichment = false) {
    return async (dispatch, getState) => {
        const s = getState();
        const filteredItems = getFilteredItems(s);
        const query = getSearchQuery(s);
        const response = await api.searchProducts({ ...query, Page: pageNumber, ShouldSkipChildEnrichment: skipChildEnrichment });

        if (response.success) {
            const Items = response.filteredItems.Items;

            Url.current
                .addParam('s', response.filteredItems.SearchQuery.PageSize)
                .addParam('p', response.filteredItems.SearchQuery.Page)
                .apply();

            dispatch(
                setFilteredItems({
                    ...filteredItems,
                    Page: response.filteredItems.SearchQuery.Page,
                    PageItems: Items.length,
                    Items,
                    SearchQuery: response.filteredItems.SearchQuery,
                })
            );
        } else {
            logger.warn(`Search request failed: ${response.errors[0]}`);
        }
    };
}

export function clearFilteredItems() {
    return {
        type: types.SET_FILTERED_ITEMS,
        payload: state.filteredItems,
    };
}

export function selectFacetValue(facet, value) {
    Url.current
        .delParam('p')
        .toggleParam(facet.PropertyName, value.Value, facet.SelectorType.includes('multi'))
        .apply();

    return {
        type: facet.SelectorType.includes('multi') ? types.SELECT_MULTI_FACET : types.SELECT_FACET,
        payload: { facet, value },
    };
}

export function updateFacets(Facets) {
    const url = Url.current;

    Facets?.forEach((f) => {
        const multi = f.SelectorType.includes('multi');

        f.Values?.forEach((v) => {
            if (v.Selected) url.addParam(f.PropertyName, v.Value, multi);
            else url.delParam(f.PropertyName, v.Value);
        });
    });
    url.delParam('p').apply();

    return {
        type: types.UPDATE_FACETS,
        payload: Facets,
    };
}

export function updatePageSize(pageSize) {
    Url.current.addParam('s', pageSize).delParam('p').apply();

    return {
        type: types.SET_PAGE_SIZE,
        payload: pageSize,
    };
}

export function updateSortOrder(sortOrder) {
    Url.current.delParam('s').addParam('o', sortOrder).apply();

    return {
        type: types.SET_PAGE_SORT,
        payload: sortOrder,
    };
}

export function clearAllFacetFilters() {
    return (dispatch, getState) => {
        const url = Url.current.delParam('o').delParam('q');
        const filters = getFilteredFacets(getState());

        filters.forEach((f) => url.delParam(f.PropertyName));
        url.apply();
        dispatch({ type: types.CLEAR_FACET_VALUE });
    };
}

export function clearFacetValue(facet, value) {
    Url.current.delParam('p').delParam(facet.PropertyName, value?.Value).apply();

    return {
        type: types.CLEAR_FACET_VALUE,
        payload: { facet, value },
    };
}

export function clearFacetFilters(facet) {
    return clearFacetValue(facet);
}
