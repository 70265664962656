import * as React from 'react';
import { useSelector } from 'react-redux';
import px from 'prop-types';
import { useAction } from 'Common/hooks';
import { customer } from '~features';

export default function UserEmailLoader({ email }) {
    const currentEmail = useSelector(customer.selectors.getUserEmail);
    const setEmail = useAction(customer.actions.setUserEmail);

    React.useEffect(() => {
        if (email && currentEmail !== email) {
            setEmail(email);
        }
    }, [currentEmail, email, setEmail]);

    return null;
}

UserEmailLoader.propTypes = {
    email: px.string,
};
