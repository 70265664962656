import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import * as types from 'Common/types';
import { useTranslation } from 'Common/hooks';
import { Translation } from 'Common/components/localization';
import { Field } from 'Common/components/forms';
import { SHIPMENT, ADDRESS } from 'Common/constants/fields';
import CheckoutForm from '../CheckoutForm';
import ReadOnlyContents from './ReadOnlyContents';
import FormContents from './FormContents';

export default function CheckoutShipment({
    accountEmail,
    className,
    currency,
    index,
    shipment = {},
    savedShippingAddresses = [],
    showSummary,
    siteId,
    onUpdate,
    isUserAuthenticated,
    fetchShippingMethods,
    cartPage,
    ...props
}) {
    const lblSubtotal = useTranslation('Commerce.Order.Checkout.CheckoutSummary.Subtotal.Label');
    const lblShipping = useTranslation('Commerce.Order.Checkout.CheckoutSummary.Shipping.Label');
    const lblHandling = useTranslation('Commerce.Order.Checkout.CheckoutSummary.Handling.Label');
    const summaryData = React.useMemo(
        () => ({
            total: (shipment?.SubTotal ?? 0) + (shipment?.ShippingTax ?? 0) + (shipment?.ShippingCost ?? 0),
            lineItems: shipment?.lineItems ?? [],
            costs: [
                {
                    label: lblSubtotal,
                    isPositive: true,
                    value: shipment?.SubTotal ?? 0,
                },
                {
                    label: lblHandling,
                    isPositive: true,
                    value: shipment?.HandlingTotal ?? 0,
                },
                {
                    label: lblShipping,
                    isPositive: true,
                    value: shipment?.ShippingCost ?? 0,
                },
            ],
        }),
        [shipment, lblSubtotal, lblShipping, lblHandling]
    );

    return (
        <CheckoutForm
            className={cx('CheckoutShipment', className)}
            title={
                showSummary ? (
                    <Translation id="Commerce.Order.Checkout.Shipment.Split.Title" params={{ index: index + 1 }} />
                ) : (
                    <Translation id="Commerce.Order.Checkout.Shipment.Title" />
                )
            }
            ReadOnlyContents={ReadOnlyContents}
            readOnlyProps={{ shipment, email: accountEmail, cartPage }}
            initialState={{
                _static: {
                    summaryData,
                    showSummary,
                    currency: currency,
                    isDigital: shipment[SHIPMENT.isDigital],
                },
                [SHIPMENT.address]: {
                    ...shipment[SHIPMENT.address],
                    [ADDRESS.defaultAddress]:
                        (shipment[SHIPMENT.address] && shipment[SHIPMENT.address][ADDRESS.defaultAddress]) ??
                        shipment[ADDRESS.defaultAddress] ??
                        false,
                },
                [SHIPMENT.shippingMethod]:
                    shipment[SHIPMENT.shippingMethod] ||
                    (shipment.AvailableShippingMethods?.length &&
                        shipment.AvailableShippingMethods[0].ShippingMethodName),
                [SHIPMENT.isGift]: !!shipment[SHIPMENT.isGift],
                [SHIPMENT.giftMessage]: shipment[SHIPMENT.giftMessage],
            }}
            onUpdate={onUpdate}
            allowUpdates
            {...props}
        >
            <Field hidden name={SHIPMENT.isDigital} value={shipment[SHIPMENT.isDigital]} />
            <Field hidden required name={SHIPMENT.shipmentId} value={shipment[SHIPMENT.shipmentId]} />
            <FormContents
                currency={currency}
                onUpdate={onUpdate}
                savedShippingAddresses={savedShippingAddresses}
                isUserAuthenticated={isUserAuthenticated}
                isDigitalDelivery={shipment.IsDigitalShipment}
                shippingOptions={shipment.AvailableShippingMethods}
                shipmentId={shipment.ShipmentId}
                siteId={siteId}
                fetchShippingMethods={fetchShippingMethods}
            />
        </CheckoutForm>
    );
}

CheckoutShipment.propTypes = {
    className: px.string,
    currency: px.string,
    shipment: types.Shipment,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    savedShippingAddresses: px.arrayOf(types.Address),
    showSummary: px.bool,
    index: px.number,
    siteId: px.oneOfType([px.number, px.string]),
    fetchShippingMethods: px.func,
    onUpdate: px.func,
    accountEmail: px.string,
    cartPage: px.string,
};
