import * as React from 'react';
import px from 'prop-types';
import { Relevant } from 'informed';
import { Name } from 'Common/utils';
import { useCreditCard } from 'Common/hooks/payment';
import { ADDRESS, PAYMENT } from 'Common/constants/fields';
import Field from 'Common/components/forms/Field';
import ExpDateForm from './ExpDateForm';
import { validateNumber } from './helpers';

export default function CardForm({ options, scope, usePlaceholders = false }) {
    const { labelProps, numberMask, isProcessor, prefix, currentIssuer, validateCvv } = useCreditCard({
        scope,
        options,
        usePlaceholders,
    });

    return (
        <>
            <Field
                className="col-6"
                name={`${prefix}${PAYMENT.address}.${ADDRESS.firstName}`}
                {...labelProps('Commerce.Order.Checkout.Address.FirstName.Label')}
                transform={Name.cleanString}
                autocomplete="cc-given-name"
                required
                hookAutofill
            />
            <Field
                className="col-6"
                name={`${prefix}${PAYMENT.address}.${ADDRESS.lastName}`}
                {...labelProps('Commerce.Order.Checkout.Address.LastName.Label')}
                transform={Name.cleanString}
                autocomplete="cc-family-name"
                required
                hookAutofill
            />

            <Field
                className="col-12"
                name={`${prefix}${PAYMENT.cardNumber}`}
                validate={validateNumber}
                validateWhen={[`${prefix}${PAYMENT.cardType}`]}
                mask={numberMask}
                inputMode="numeric"
                autocomplete="cc-number"
                {...labelProps('Commerce.Order.Checkout.Payments.Card.Number.Label')}
                hookAutofill
                trimOnInput
                required
                initializeValueIfPristine
            />

            <ExpDateForm
                className="col-6"
                scope={scope}
                inputMode="numeric"
                maintainCursor
                hookAutofill
                trimOnInput
                required
                initializeValueIfPristine
            />

            <Field
                className="col-6"
                name={`${prefix}${PAYMENT.cardCvv}`}
                validate={validateCvv}
                validateWhen={[`${prefix}${PAYMENT.cardType}`]}
                inputMode="numeric"
                autocomplete="cc-csc"
                {...labelProps('Commerce.Order.Checkout.Payments.Card.VerificationCode.Label')}
                hookAutofill
                trimOnInput
                required
                initializeValueIfPristine
            />

            <Relevant when={isProcessor('Paymetric')}>
                <Field
                    required
                    hidden
                    name={`${prefix}.${PAYMENT.merchantGuid}`}
                    value={options.XiInterceptMerchantGuid}
                />
            </Relevant>

            <Field required hidden name={`${prefix}${PAYMENT.cardType}`} value={currentIssuer} />
        </>
    );
}

CardForm.propTypes = {
    options: px.shape({
        EnabledCardTypes: px.string,
        EnvKey: px.string,
        XiInterceptMerchantGuid: px.string,
        XiInterceptUrl: px.string,
        XiInterceptCallbackUrl: px.string,
    }),
    vertical: px.bool,
    scope: px.string,
    usePlaceholders: px.bool,
};
