import * as React from 'react';
import px from 'prop-types';
import { RadioGroup, Radio, useFormState } from 'informed';
import { CreditCard } from 'Common/utils';
import * as types from 'Common/types';
import { useUrlParam } from 'Common/hooks';
import { Field } from 'Common/components/forms';
import { Icon } from 'Common/components/ui';
import { PAYMENT } from 'Common/constants/fields';
import VALIDATION_STRINGS from 'Common/constants/validation';
import * as payments from '../payments';
import CheckoutFormContext from '../CheckoutFormContext';

export default function FormContents({
    paymentOptions = [],
    orderPayment,
    isUserAuthenticated,
    currency,
    paymentAmount,
    onAddSavedPayment,
    addPromotion,
    removePromotion,
    promoError,
    setIsFormRendered,
    isFormRendered,
    onChangeType,
    marketId,
    shippingAddress,
    defaultCountry,
    useFullBillingAddress = false,
    siteId,
}) {
    const formCtx = React.useContext(CheckoutFormContext);
    const formState = useFormState();
    const type = React.useMemo(() => formState.values[PAYMENT.paymentType], [formState]);
    const selectedType = useUrlParam('paymentType', true);

    const selectedPayment = React.useMemo(() => type && paymentOptions.find((p) => p.PaymentType === type), [
        paymentOptions,
        type,
    ]);

    const PaymentForm = React.useMemo(() => (selectedPayment && payments[selectedPayment.PaymentType]) || null, [
        selectedPayment,
    ]);

    const tokenType = React.useRef(
        orderPayment && orderPayment[PAYMENT.token] ? orderPayment[PAYMENT.paymentType] : null
    );

    const onPaymentChange = React.useCallback(
        (nextValue) => {
            const handler = setTimeout(() => {
                if (formState.values[PAYMENT.token] && nextValue !== tokenType.current) {
                    formCtx.setValues({ [PAYMENT.token]: undefined });
                }
                tokenType.current = nextValue;
                formCtx.validate();
            });

            if (onChangeType) onChangeType(nextValue);

            return () => {
                clearTimeout(handler);
            };
        },
        [formState, formCtx, onChangeType]
    );

    React.useEffect(() => {
        if (PaymentForm) setIsFormRendered(true);
        else if (isFormRendered) setIsFormRendered(false);
    }, [PaymentForm, isFormRendered, setIsFormRendered]);

    React.useEffect(() => {
        if (selectedType) formCtx.setValues({ [PAYMENT.paymentType]: selectedType });
    }, [selectedType, formCtx]);

    return (
        <>
            <Field hidden required name={PAYMENT.systemName} value={selectedPayment?.SystemName} />
            <Field hidden required name={PAYMENT.displayName} value={selectedPayment?.DisplayName} />
            {paymentOptions.length ? (
                <RadioGroup
                    field={PAYMENT.paymentType}
                    validation={(value) => (value ? VALIDATION_STRINGS.required : undefined)}
                    onValueChange={onPaymentChange}
                    validateOn="change"
                >
                    <div className="container mt-1">
                        <div className="row d-flex flex-wrap">
                            {paymentOptions.map((p) =>
                                p.PaymentType !== 'GiftCard' ? (
                                    <p
                                        key={p.PaymentType}
                                        className="pr-3 mt-3 paragraph-2 d-flex align-items-center"
                                        title={p.PaymentType}
                                    >
                                        <Radio value={p.PaymentType} />
                                        <span>
                                            <Icon
                                                className="mr-1"
                                                icon={CreditCard.getIcon(p.CardType || p.PaymentType)}
                                                label={p.PaymentType}
                                                size="2.5rem"
                                            />
                                        </span>
                                    </p>
                                ) : null
                            )}
                        </div>
                    </div>
                </RadioGroup>
            ) : null}
            {PaymentForm ? (
                <PaymentForm
                    className="PaymentForm"
                    systemName={selectedPayment?.SystemName}
                    displayName={selectedPayment?.DisplayName}
                    isUserAuthenticated={isUserAuthenticated}
                    payment={orderPayment}
                    paymentType={type}
                    onAddSavedPayment={onAddSavedPayment}
                    savedPayments={selectedPayment?.SavedPaymentInfo}
                    options={selectedPayment?.Options}
                    paymentAmount={paymentAmount}
                    currency={currency}
                    marketId={marketId}
                    promoError={promoError}
                    addPromotion={addPromotion}
                    removePromotion={removePromotion}
                    useFullBillingAddress={useFullBillingAddress}
                    shippingAddress={shippingAddress}
                    defaultCountry={defaultCountry}
                    siteId={siteId}
                    paymentMethod={selectedPayment}
                />
            ) : null}
        </>
    );
}

FormContents.propTypes = {
    paymentOptions: px.arrayOf(px.object),
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    orderPayment: px.object,
    onAddSavedPayment: px.func,
    paymentAmount: px.number,
    currency: px.string,
    setIsFormRendered: px.func,
    isFormRendered: px.bool,
    onChangeType: px.func,
    marketId: px.string,
    addPromotion: px.func,
    removePromotion: px.func,
    promoError: px.shape({ message: px.string, timestamp: px.number, reference: px.string, type: px.string }),
    shippingAddress: types.Shipment,
    useFullBillingAddress: px.bool,
    defaultCountry: px.string,
    siteId: px.string,
};
