import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useBEM, useProductDetails } from 'Common/hooks';
import * as PRODUCT from '~config/product';
import { Translation } from '../../localization';
import { ProductPrice, ProductBadge } from 'Common/components/product';
import { ProductImage } from 'Common/utils';
import { ProductInfo } from 'Common/models';

export default function GridCard({
    className,
    product,
    active = false,
    onOpenQuickview,
    onCloseQuickview,
    stacked = false,
    imgHeight = 0,
    imgWidth = 430,
}) {
    const [b, em] = useBEM('GridCard', className);
    const { badges, priceInfo } = useProductDetails(product);
    const isDisplayOnly = ProductInfo.isDisplayOnly(product);

    const primary = React.useMemo(
        () =>
            ProductImage.getGlamOrDefaultImageUrl(
                product.CatalogMedia,
                product.DefaultImageUrl,
                `format=png${imgWidth ? `&width=${imgWidth}` : ''}${imgHeight ? `&height=${imgHeight}` : ''}${
                    PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                }`
            ),
        [product, imgWidth, imgHeight]
    );

    return (
        <a
            className={cx(b, { [em(null, 'selected')]: active, [em(null, 'stacked')]: stacked })}
            aria-label={product.ProductName}
            href={product.ContentUrl}
        >
            <ProductBadge className={em('badges')} badges={badges} />
            <div className={em('image')} style={{ backgroundImage: `url('${primary}')` }} aria-hidden="true"></div>
            <div className={em('details')}>
                <div className={em('details__title')}>
                    <h4>{product.DisplayName}</h4>
                </div>
                {product.DescriptionShort ? (
                    <div className={em('details__description')}>
                        <p>
                            <span>{product.DescriptionShort}</span>
                            <em>
                                <Translation id="Commerce.Product.Description.ReadMore.Label" />
                            </em>
                        </p>
                    </div>
                ) : null}
                <div className={em('details__bottom-row')}>
                    <ProductPrice className={cx(em('details__bottom-row__price'), 'a b c')} priceInfo={priceInfo} />

                    <button
                        className="btn btn-md btn-primary btn-half-pad btn-offset-top btn-angled"
                        onClick={active ? onCloseQuickview : onOpenQuickview}
                        role="button"
                        href="#"
                    >
                        <span>
                            <Translation id={isDisplayOnly
                                ? "Search.Results.Product.OpenDisplayOnlyQuickView.Label"
                                : "Search.Results.Product.OpenQuickView.Label"} />
                        </span>
                    </button>
                </div>
            </div>
        </a>
    );
}

GridCard.propTypes = {
    className: px.string,
    product: types.Product,
    active: px.bool,
    onOpenQuickview: px.func,
    onCloseQuickview: px.func,
    stacked: px.bool,
    imgHeight: px.number,
    imgWidth: px.number,
};
