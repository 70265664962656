import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Spinner } from 'Common/components/ui';
import { Field } from 'Common/components/forms';
import { Translation } from 'Common/components/localization';
import { PAYMENT as FIELDS } from 'Common/constants/fields';
import { validateApplePay } from 'Common/features/order/api';

//the states the display could be in
const STATE_STARTUP = 'STATE_STARTUP';
const STATE_UNSUPPORTED_BROWSER = 'STATE_UNSUPPORTED_BROWSER';
const STATE_NO_PAYMENT = 'STATE_NO_PAYMENT';
const STATE_SHOW_BUTTON = 'STATE_SHOW_BUTTON';
const STATE_PAYMENT_IN_PROGRESS = 'STATE_PAYMENT_IN_PROGRESS';
const STATE_PAYMENT_COMPLETE = 'STATE_PAYMENT_COMPLETE';

export default function SpreedlyApplePayGateway({ className, options, paymentAmount, systemName, marketId }) {
    const [token, setToken] = React.useState(undefined);
    const [state, setState] = React.useState(STATE_STARTUP);
    const [error, setError] = React.useState(false);

    //properties for Apple Pay
    const merchantIdentifier = options.ApplePayMerchantIdentifier;
    const merchantName = options.ApplePayDisplayName;
    const amount = paymentAmount.toString();

    //this is all the logic to set up the Apple Pay session and associated callbacks
    const onInitSession = React.useCallback(() => {
        const request = {
            countryCode: 'US',
            currencyCode: 'USD',
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            merchantCapabilities: ['supports3DS'],
            total: { label: merchantName, amount: amount },
        };

        const newSession = new window.ApplePaySession(3, request);

        //when it's time to validate the merchant, we need to make a callback to the external URL to verify the domain
        //and generate one-time transaction credentials
        newSession.onvalidatemerchant = async (event) => {
            try {
                const apiResp = await validateApplePay(event.validationURL, systemName, marketId);

                if (apiResp && apiResp.Status == 'Success') {
                    const appleData = JSON.parse(apiResp.MerchantSession);

                    newSession.completeMerchantValidation(appleData);
                } else {
                    setError(true);
                    setState(STATE_SHOW_BUTTON);
                    newSession.abort();
                }
            } catch (err) {
                setError(true);
                setState(STATE_SHOW_BUTTON);
                newSession.abort();
            }
        };

        //when we select a payment, don't change the total at all but mark payment selection as complete
        newSession.onpaymentmethodselected = (/* event */) => {
            const newTotal = { type: 'final', label: merchantName, amount: amount };

            newSession.completePaymentMethodSelection({ newTotal });
        };

        //when the payment is authorized, signal to Apple Pay that we're done and update the UI to say we're done
        newSession.onpaymentauthorized = (event) => {
            setToken(JSON.stringify(event.payment.token));

            newSession.completePayment(window.ApplePaySession.STATUS_SUCCESS);

            setState(STATE_PAYMENT_COMPLETE);
        };

        //if we cancel the payment, go back to showing the button
        newSession.oncancel = () => {
            setState(STATE_SHOW_BUTTON);
        };

        //begin the payment session and change the button to a display that we're currently in progress
        newSession.begin();
        setState(STATE_PAYMENT_IN_PROGRESS);
    }, [amount, merchantName, marketId, systemName]);

    //initialize the Apple Pay session
    React.useEffect(() => {
        async function initApplePay() {
            if (window.ApplePaySession) {
                //first check if the browser even supports Apple Pay
                const canMakePayments = await window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);

                //we could enter one of two states here if the browser supports Apple Pay...
                if (canMakePayments) {
                    setState(STATE_SHOW_BUTTON); //either the user has a payment method ready and they can make payments
                } else {
                    setState(STATE_NO_PAYMENT); //or they don't, so they can't make payments until they set up Apple Pay
                }
            } else {
                setState(STATE_UNSUPPORTED_BROWSER);
            }
        }

        initApplePay();
    }, [merchantIdentifier]);

    return (
        <div className={cx('ApplePay p-3', className)}>
            <Field required hidden name={FIELDS.token} value={token} />
            {state === STATE_STARTUP && (
                <div>
                    <Spinner />
                </div>
            )}
            {state === STATE_UNSUPPORTED_BROWSER && (
                <div className="alert alert-danger">
                    <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.UnsupportedBrowser" />
                </div>
            )}
            {state === STATE_NO_PAYMENT && (
                <div className="alert alert-danger">
                    <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.CannotMakePayments" />
                </div>
            )}
            {state === STATE_SHOW_BUTTON && (
                <>
                    {error && (
                        <div className="alert alert-danger">
                            <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.SomethingWentWrong" />
                        </div>
                    )}
                    <div className="apple-pay-button apple-pay-button-black" onClick={onInitSession}></div>
                </>
            )}
            {state === STATE_PAYMENT_IN_PROGRESS && (
                <div>
                    <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.PaymentInProgress" />
                </div>
            )}
            {state === STATE_PAYMENT_COMPLETE && (
                <div className="alert alert-success">
                    <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.PaymentComplete" />
                </div>
            )}
        </div>
    );
}

SpreedlyApplePayGateway.propTypes = {
    className: px.string,
    options: px.shape({
        ApplePayMerchantIdentifier: px.string.isRequired,
        ApplePayDisplayName: px.string.isRequired,
    }),
    systemName: px.string.isRequired,
    marketId: px.string.isRequired,
    paymentAmount: px.number.isRequired,
};
