import supercluster from 'supercluster';
import { logger } from 'Common/core';

const Location = {
    Coord(latitude = 0, longitude = 0, convertRange = null) {
        let lat = latitude;
        let lng = longitude;

        if (latitude && typeof latitude === 'object' && !longitude) {
            lat = latitude.lat ?? latitude.latitude ?? 0;
            lng = latitude.lng ?? latitude.long ?? latitude.longitude ?? 0;
        }
        if (typeof lat !== 'number') {
            throw new Error(
                `Invalid Location.Coord arguments supplied. Expected () or (number, [, number[, string]]) or (object: { lat/latitude: number, lng/long/longitude: number }[, undefined, string]) but received (${JSON.stringify(
                    latitude
                )}, ${JSON.stringify(longitude)}, ${JSON.stringify(convertRange)}).`
            );
        }
        if (convertRange === 'offset') {
            lat = lat > 90 ? lat - 180 : lat;
            lng = lng > 180 ? lng - 360 : lng;
        } else if (convertRange === 'whole') {
            lat = lat < 0 ? lat + 180 : lat;
            lng = lng < 0 ? lng + 360 : lng;
        }
        return { lat, lng };
    },

    truncateCoord(coord, decimalPlaces = 0) {
        return {
            lat: parseFloat(coord.lat).toFixed(decimalPlaces),
            lng: parseFloat(coord.lng).toFixed(decimalPlaces),
        };
    },

    convertBounds({ ne, sw }, convertRange = null) {
        return { ne: Location.Coord(ne, 0, convertRange), sw: Location.Coord(sw, 0, convertRange) };
    },

    addressFromCoords(coords, google, throws = false) {
        if (google && coords) {
            const latLng = new google.maps.LatLng(coords.lat, coords.lng);

            return new Promise((res, rej) =>
                new google.maps.Geocoder().geocode({ location: latLng }, (results, status) => {
                    if (status == google.maps.GeocoderStatus.OK) {
                        const result =
                            results.find((r) => r.types.includes('postal_code')) ??
                            results.find((r) => r.types.includes('locality')) ??
                            results.find((r) => r.types.includes('political'));

                        res(result?.formatted_address);
                    } else {
                        logger.warn('geocoder request failed', status);
                        if (throws) rej(status);
                        else res(null);
                    }
                })
            );
        }
        return null;
    },

    addressFromPlace(place = {}) {
        const updatedAddress = Location.Coord(place.geometry?.location?.lat(), place.geometry?.location?.lng());

        if (place.address_components) {
            place.address_components.forEach((comp) => {
                if (comp.types.includes('postal_code')) updatedAddress.zip = comp.short_name;
                if (comp.types.includes('street_number')) updatedAddress.address = comp.short_name;
                if (comp.types.includes('locality')) updatedAddress.city = comp.short_name;
                if (comp.types.includes('administrative_area_level_1')) updatedAddress.state = comp.short_name;
                if (comp.types.includes('country')) updatedAddress.country = comp.short_name;
                if (comp.types.includes('route')) {
                    updatedAddress.address
                        ? (updatedAddress.address += ` ${comp.short_name}`)
                        : (updatedAddress.address = comp.short_name);
                }
            });
        }
        if (place.formatted_address) updatedAddress.formatted_address = place.formatted_address;
        if (place.name) updatedAddress.searchInput = place.name;
        updatedAddress.fullAddress =
            updatedAddress.formatted_address ||
            `${updatedAddress.address} ${updatedAddress.city}, ${updatedAddress.state} ${updatedAddress.country} ${updatedAddress.zip}`;
        updatedAddress.place = place;

        return updatedAddress;
    },

    createClusters(bounds, zoom, markers, radius, extent, nodeSize, minZoom, maxZoom) {
        const ne = Location.Coord(bounds.ne, 0, 'whole');
        const sw = Location.Coord(bounds.sw, 0, 'whole');
        const index = new supercluster({
            radius: radius || 40,
            extent: extent || 512,
            nodeSize: nodeSize || 64,
            minZoom: minZoom || 0,
            maxZoom: maxZoom || 16,
        });

        const points = markers.map((marker) => ({
            ...marker,
            geometry: { coordinates: [marker.lng, marker.lat] },
        }));

        const clusters = index
            .load(points)
            .getClusters([sw.lng, sw.lat, ne.lng, ne.lat], zoom)
            .map((cluster) =>
                cluster.type === 'Feature'
                    ? {
                          id: cluster.id,
                          lat: cluster.geometry.coordinates[1],
                          lng: cluster.geometry.coordinates[0],
                          point_count: cluster.properties.point_count,
                          cluster_id: cluster.properties.cluster_id,
                          // onclick to get correct zoom
                          getZoom: () => index.getClusterExpansionZoom(cluster.properties.cluster_id),
                      }
                    : cluster
            );

        return clusters.length ? clusters : markers;
    },

    getDistanceBetweenCoords(a, b) {
        return Location.getDistance(a.lat, a.lng, b.lat, b.lng);
    },

    getDistance(lat1, lon1, lat2, lon2) {
        if (lat1 === lat2 && lon1 === lon2) return 0;

        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const theta = lon1 - lon2;
        const radtheta = (Math.PI * theta) / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

        if (dist > 1) dist = 1;
        dist = Math.acos(dist);
        dist *= 180 / Math.PI;
        dist *= 60 * 1.1515;

        return dist;
    },

    convertResponseLocation({ Lat, Lng, Id, Name, Extended } = {}) {
        return {
            lat: Lat,
            lng: Lng,
            id: Id,
            name: Name,
            address1: Extended.Address.Line1,
            address2: `${Extended.Address.Locality}, ${Extended.Address.RegionCode} ${Extended.Address.PostalCode}`,
            phone: Extended.Phone,
            website: Extended.Website,
            directions: `https://www.google.com/maps/dir/?${new URLSearchParams({
                api: 1,
                destination: `${Extended.Address.Line1}+${Extended.Address.Locality},+${Extended.Address.StateCode}+${Extended.Address.PostalCode}`,
            }).toString()}`,
            categories: Extended.Categories,
        };
    },

    ui: {
        enableEnterKey(input, autocomplete) {
            const originalAddEventListener = input.addEventListener;

            const addEventListenerWrapper = (type, callback) => {
                let listener = callback;

                if (type === 'keydown') {
                    const originalListener = callback;

                    listener = (event) => {
                        const pacContainer = Location.ui.getAutoCompleteContainer(autocomplete);

                        if (pacContainer) {
                            const suggestionsVisible = pacContainer.style.display !== 'none';
                            const suggestionSelected =
                                pacContainer.getElementsByClassName('pac-item-selected').length > 0;

                            const enterPress = event.key === 'Enter' || event.which === 13 || event.keyCode === 13;

                            if (suggestionsVisible && enterPress) {
                                if (!suggestionSelected) {
                                    const arrowDownEvent = JSON.parse(
                                        JSON.stringify(
                                            event,
                                            (k, v) => {
                                                if (v instanceof Node) return 'Node';
                                                if (v instanceof Window) return 'Window';
                                                return v;
                                            },
                                            ' '
                                        )
                                    );

                                    arrowDownEvent.which = 40;
                                    arrowDownEvent.keyCode = 40;
                                    originalListener.call(input, arrowDownEvent);
                                }
                                event.preventDefault();
                            }
                        } else {
                            logger.warn('could not find google autocomplete container');
                        }

                        originalListener.apply(input, [event]);
                    };
                }
                originalAddEventListener.apply(input, [type, listener]);
            };

            input.addEventListener = addEventListenerWrapper;
        },

        getAutoCompleteContainer(autocomplete) {
            if (autocomplete && autocomplete.gm_accessors_) {
                const place = autocomplete.gm_accessors_.place;
                const placeKey = Object.keys(place).find(
                    (value) =>
                        typeof place[value] === 'object' &&
                        Object.prototype.hasOwnProperty.call(place[value], 'gm_accessors_')
                );

                const input = place[placeKey].gm_accessors_.input[placeKey];
                const inputKey = Object.keys(input).find(
                    (value) => input[value].classList && input[value].classList.contains('pac-container')
                );

                return input[inputKey];
            } else {
                logger.warn('could not find google autocomplete container : incomplete autocomplete object');
            }
        },
    },
};

export default Location;
