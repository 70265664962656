import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import * as types from 'Common/types';
import { logger } from 'Common/core';
import { Translation } from '../localization';
import CheckoutForm from './CheckoutForm';
import CheckoutSummary from './CheckoutSummary';
import { PromoCode } from './payments';

export default function CheckoutComplete({
    className,
    onPlaceOrder,
    disabled,
    disableSubmit,
    orderSummaryData,
    addPromotion,
    removePromotion,
    promoError,
    currency,
    promotions,
    cartPage,
    validateUser,
    useRecaptcha,
    ...props
}) {
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [disable, setDisable] = React.useState(false);

    const canCompleteOrder = React.useMemo(() => Boolean(!disable && !disabled && onPlaceOrder && !disableSubmit), [
        disable,
        disabled,
        onPlaceOrder,
        disableSubmit,
    ]);

    const costs = React.useMemo(() => {
        return [
            {
                label: 'Commerce.Order.Checkout.CheckoutSummary.Subtotal.Label',
                key: 'subtotal',
                value: orderSummaryData?.subtotal,
            },
            orderSummaryData?.handling > 0
                ? {
                      label: 'Commerce.Order.Checkout.CheckoutSummary.Handling.Label',
                      key: 'handling',
                      value: orderSummaryData?.handling,
                  }
                : null,
            {
                label: 'Commerce.Order.Checkout.CheckoutSummary.Shipping.Label',
                key: 'shippingSubTotal',
                value: orderSummaryData?.shippingSubTotal,
            },
            orderSummaryData?.shippingDiscount > 0
                ? {
                      label: 'Commerce.Order.Checkout.CheckoutSummary.ShippingDiscount.Label',
                      key: 'shippingDiscount',
                      value: orderSummaryData?.shippingDiscount * -1,
                  }
                : null,
            orderSummaryData?.orderDiscount > 0
                ? {
                      label: 'Commerce.Order.Checkout.CheckoutSummary.OrderDiscount.Label',
                      key: 'orderDiscount',
                      value: orderSummaryData?.orderDiscount * -1,
                  }
                : null,
            {
                label: 'Commerce.Order.Checkout.CheckoutSummary.Tax.Label',
                key: 'tax',
                value: orderSummaryData?.tax,
            },
        ];
    }, [orderSummaryData]);

    const orderSubmit = React.useCallback(
        async (e) => {
            e.preventDefault();
            setDisable(true);
            if (!useRecaptcha) {
                const response = await onPlaceOrder();

                setDisable(false);
                if (response?.length) {
                    setErrorMessages(response.map((errorRes) => errorRes.Issue));
                    setDisable(false);
                }
                return;
            }
            validateUser()
                .then(async (reCaptchaToken) => {
                    const response = await onPlaceOrder(reCaptchaToken);

                    setDisable(false);
                    if (response?.length) {
                        setErrorMessages(response.map((errorRes) => errorRes.Issue));
                        setDisable(false);
                    }
                })
                .catch((error) => {
                    logger.warn([error?.message || error]);
                    setDisable(false);
                });
        },
        [useRecaptcha, validateUser, onPlaceOrder]
    );

    return (
        <CheckoutForm
            className={cx('CheckoutPayment', className)}
            title={<Translation id="Commerce.Order.Checkout.PlaceOrder.Title" />}
            ReadOnlyContents={() => null}
            disabled={disabled}
            {...props}
        >
            {orderSummaryData && (
                <CheckoutSummary
                    className="PaymentSummary mt-3 w-100"
                    costs={costs}
                    currency={currency}
                    orderSummary={orderSummaryData}
                    cartPage={cartPage}
                    summaryTitle={<Translation id="Commerce.Order.Checkout.PaymentSummary.Title" />}
                    totalLabel={<Translation id="Commerce.Order.Checkout.PaymentSummary.Total.Label" />}
                />
            )}
            <div className="col-12 col-md-6 col-lg-5 mt-2 mb-4 p-0">
                <PromoCode
                    promotions={promotions}
                    addPromotion={addPromotion}
                    removePromotion={removePromotion}
                    promoError={promoError}
                />
            </div>
            {errorMessages && errorMessages.length ? (
                <div className="col-12 p-0 w-100">
                    {errorMessages.map((err, index) => (
                        <p className="mx-0 paragraph-2 text-danger" key={err}>
                            <Translation id={err} />
                            {errorMessages.length === index + 1 ? null : ','}
                        </p>
                    ))}
                </div>
            ) : null}
            <div className="mt-3 w-100">
                <button
                    type="button"
                    role="button"
                    className="btn btn-primary w-auto"
                    onClick={orderSubmit}
                    disabled={!canCompleteOrder}
                >
                    <span>
                        <Translation id="Commerce.Order.Checkout.CompleteOrderButton.Label" />
                    </span>
                </button>
            </div>
        </CheckoutForm>
    );
}

CheckoutComplete.propTypes = {
    className: px.string,
    disabled: px.bool,
    disableSubmit: px.bool,
    onPlaceOrder: px.func,
    cartPage: px.string,
    addPromotion: px.func,
    removePromotion: px.func,
    promoError: px.shape({ message: px.string, timestamp: px.number, reference: px.string, type: px.string }),
    currency: px.string,
    promotions: px.oneOfType([px.array, px.object]),
    orderSummaryData: types.OrderSummary,
    validateUser: px.func,
    useRecaptcha: px.bool,
};
