import { CreditCard } from 'Common/utils';
import VALIDATION_STRINGS from 'Common/constants/validation';

export function ccMask({ value = '' } = {}) {
    return CreditCard.getCardNumberFormat(value);
}

export function validateNumber(value = '') {
    if (!value) return VALIDATION_STRINGS.required;
    return CreditCard.isValidNumber(value) ? undefined : VALIDATION_STRINGS.invalidCC;
}
