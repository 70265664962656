import px from 'prop-types';
import CartLineItem from './CartLineItem';

export const OrderSummaryShape = {
    subtotal: px.number,
    handling: px.number,
    shipping: px.number,
    shippingSubTotal: px.number,
    shippingDiscount: px.number,
    orderDiscount: px.number,
    tax: px.number,
    total: px.number,
    giftCardPayments: px.arrayOf(px.shape({ CardNumber: px.string, Amount: px.number, Balance: px.number })),
    giftCardDiscount: px.number,
    totalSansGiftCards: px.number,
    discounts: px.arrayOf(
        px.shape({ label: px.string, value: px.number, id: px.string, code: px.string, discountType: px.string })
    ),
    lineItems: px.arrayOf(CartLineItem),
};

export default px.shape(OrderSummaryShape);
