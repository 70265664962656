import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { Translation } from 'Common/components/localization';
import { useViewport, useTranslation } from 'Common/hooks';
import Portal from './Portal';

export default function ShopByDropdown({ className, facet, pageLink, attributeName, textColor, onClose }) {
    const viewport = useViewport();
    const shopByRef = React.useRef(null);
    const lblClose = useTranslation('Block.ShopByCard.Dropdown.Close.Label');
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [sliceIdx, setSliceIndx] = React.useState(facet.Values?.length);
    const onSelectFacet = React.useCallback(
        (value) => () => {
            if (selectedValues.includes(value))
                setSelectedValues(selectedValues.filter((selectedValue) => selectedValue !== value));
            else {
                setSelectedValues([...selectedValues, value]);
            }
        },
        [setSelectedValues, selectedValues]
    );

    const handleSubmit = React.useCallback(
        (facets) => {
            if (facets?.length) {
                //we need to update our state before navigating in the case of MobileFiltersMenu
                const selected = facets[0].Values.filter((value) => value.Selected);

                window.location.href = `${pageLink}?${attributeName}=${selected
                    ?.map((value) => value.Value)
                    .join(',')}`;
            } else {
                window.location.href = `${pageLink}?${attributeName}=${selectedValues
                    ?.map((value) => value)
                    .join(',')}`;
            }
        },
        [selectedValues, pageLink, attributeName]
    );

    React.useEffect(() => {
        setTimeout(() => {
            shopByRef?.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        }, 300);
    }, [shopByRef]);

    React.useEffect(() => {
        if (facet.Values?.length > 1) setSliceIndx(Math.ceil(facet.Values?.length / 2));
        else setSliceIndx(facet.Values?.length);
    }, [facet]);

    return viewport.is.gt('md') ? (
        <div ref={shopByRef} className={cx('ShopByDropdown container', className)}>
            <button
                className={cx('btn btn-close btn-lg btn-link', textColor)}
                onClick={onClose}
                aria-label={lblClose}
                title={lblClose}
            >
                <i className="fas fa-times-circle" />
            </button>
            <h5 className={textColor}>
                <Translation
                    id="Block.ShopByCard.Dropdown.Label"
                    params={{ label: facet?.DisplayName ?? attributeName }}
                />
            </h5>
            {facet?.Values?.length ? (
                <div className="container">
                    <div className="ShopByDropdown__Values row">
                        <div className="col-2 p-0 mr-3">
                            {facet.Values.slice(0, sliceIdx).map((option) => (
                                <label
                                    className={cx('my-2', textColor)}
                                    key={option?.Label}
                                    onClick={onSelectFacet(option?.Value)}
                                    data-toggle="tooltip"
                                    title={option?.Label}
                                >
                                    <input
                                        key={Date.now()}
                                        type="checkbox"
                                        className={textColor}
                                        checked={selectedValues.includes(option?.Value)}
                                        onChange={() => {}}
                                    />
                                    <div>{option?.Label}</div>
                                </label>
                            ))}
                        </div>
                        {sliceIdx !== facet.Values.length ? (
                            <div className="col-2 p-0 mr-3">
                                {facet.Values.slice(sliceIdx).map((option) => (
                                    <label
                                        className={cx('my-2', textColor)}
                                        key={option?.Label}
                                        onClick={onSelectFacet(option?.Value)}
                                        data-toggle="tooltip"
                                        title={option?.Label}
                                    >
                                        <input
                                            key={Date.now()}
                                            type="checkbox"
                                            className={textColor}
                                            checked={selectedValues.includes(option?.Value)}
                                            onChange={() => {}}
                                        />
                                        <div>{option?.Label}</div>
                                    </label>
                                ))}
                            </div>
                        ) : null}
                    </div>
                    <div className="row justify-content-center">
                        <button
                            onClick={handleSubmit}
                            disabled={selectedValues.length === 0}
                            className="btn btn-primary"
                        >
                            <Translation
                                id="Block.ShopByCard.Dropdown.Label"
                                params={{ label: facet?.DisplayName ?? attributeName }}
                            />
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    ) : (
        <Portal>
            <div className="MobileShopBy MobileFiltersMenu">
                <button className="MobileFiltersMenu__btnClose btn" onClick={onClose} aria-label={lblClose}>
                    <i className="fas fa-times" />
                </button>
                <div className="MobileFiltersMenu__list">
                    <h2 className="MobileFiltersMenu__list__head">{facet.DisplayName ?? attributeName}</h2>
                    <ul className="list-group">
                        {facet.Values.map((value) => (
                            <li key={`${value.Label}`} role="button" className="list-group-item">
                                <label
                                    className={cx('Selector checkbox', className)}
                                    onClick={onSelectFacet(value.Value)}
                                >
                                    <div
                                        className={cx(
                                            `input-checkbox`,
                                            selectedValues.includes(value.Value) ? 'checked' : null
                                        )}
                                    />
                                    <span>{value.Label}</span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
                <button
                    role="button"
                    className="MobileFiltersMenu__btnApply btn btn-primary"
                    onClick={handleSubmit}
                    disabled={selectedValues.length === 0}
                >
                    <span>
                        <Translation id="Commerce.CatalogNode.Filters.ApplyFilters" />
                    </span>
                </button>
            </div>
        </Portal>
    );
}

ShopByDropdown.propTypes = {
    className: px.string,
    facet: types.Facet,
    pageLink: px.string,
    attributeName: px.string,
    textColor: px.string,
    onClose: px.func,
};
