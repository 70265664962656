// Consider the below model to be used in a later immplementation of facets which should reflect the frontend implementation of facets.
// The implementation currently used references backend properties which are not mapped but consumed directly from the api.
// import px from 'prop-types';

// const FacetOption = {
//     title: px.string,
//     value: px.string,
//     selected: px.boolean,
//     count: px.number,
// };

// const Facet = {
//     id: px.string,
//     title: px.string,
//     type: px.string.isRequired,
//     options: px.arrayOf(px.shape(FacetOption)),
//     canSelectMultiple: px.bool,
// };

// export default px.shape(Facet);

import px from 'prop-types';

export const FacetValueShape = {
    Value: px.string,
    Selected: px.bool,
    Count: px.number,
};

export const FacetValue = px.shape(FacetValueShape);

export const FacetShape = {
    FacetValues: px.arrayOf(FacetValue),
    DisplayName: px.string,
    Attribute: px.string,
    SelectorControlType: px.string,
};

export default px.shape(FacetShape);
