import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import debounce from 'lodash.debounce';
import { Spinner } from 'Common/components/ui';

export default function InfiniteScroll({
    className,
    children,
    isDoneLoading,
    isLoading = false,
    onLoad,
    useLoadIndicator,
}) {
    const scrollViewRef = React.useRef();
    const isFetching = React.useRef(false);
    const handleScrollRef = React.useRef(null);
    const [showLoader, setShowLoader] = React.useState(false);
    const debounceFetch = React.useMemo(
        () =>
            debounce(async () => {
                await onLoad();
                isFetching.current = false;
                setShowLoader(false);
            }, 500),
        [onLoad]
    );

    const handleScroll = React.useCallback(() => {
        const bounding = scrollViewRef.current.getBoundingClientRect();

        if (!isFetching.current && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            isFetching.current = true;
            setShowLoader(true);
            debounceFetch();
        }
    }, [debounceFetch]);

    React.useEffect(() => {
        if (handleScrollRef.current) {
            window.removeEventListener('scroll', handleScrollRef.current);
        }
        handleScrollRef.current = handleScroll;
        if (isLoading || isDoneLoading) window.removeEventListener('scroll', handleScrollRef.current);
        else window.addEventListener('scroll', handleScrollRef.current);

        return () => window.removeEventListener('scroll', handleScrollRef.current);
    }, [handleScroll, isLoading, isDoneLoading]);

    return (
        <div ref={scrollViewRef} className={cx('InfiniteScroll', className)}>
            {children}
            {showLoader && useLoadIndicator ? (
                <div className="row justify-content-center">
                    <Spinner />
                </div>
            ) : null}
        </div>
    );
}

InfiniteScroll.propTypes = {
    className: px.string,
    children: px.node,
    isDoneLoading: px.bool,
    onLoad: px.func,
    useLoadIndicator: px.bool,
    isLoading: px.bool,
};
