import * as React from 'react';
import { useSelector } from 'react-redux';
import { ProductBadge } from 'Common/components/product';
import { product } from '~features';

export default function ProductBadges(props) {
    const currentMarketingBadges = useSelector(product.selectors.getCurrentMarketingBadges);

    return <ProductBadge {...props} badges={currentMarketingBadges} />;
}

ProductBadges.propTypes = {};
