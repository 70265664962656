import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'Common/hooks';

export default function CheckoutSection({
    className,
    disabled = false,
    collapsed = false,
    children,
    title = null,
    onToggleCollapse,
    onEdit,
    onSave,
    canSave = false,
    isEditing = false,
    hideSaveButton = false,
}) {
    const sectionRef = React.useRef(null);
    const onToggle = React.useCallback(() => onToggleCollapse(!collapsed), [onToggleCollapse, collapsed]);
    const lblEdit = useTranslation('Commerce.Order.Checkout.EditSection.Label');
    const lblSave = useTranslation('Commerce.Order.Checkout.SaveSection.Label');

    React.useLayoutEffect(() => {
        const el = sectionRef.current;

        if (el && isEditing) {
            el.scrollIntoView();
        }
    }, [isEditing]);

    return (
        <div
            className={cx('CheckoutSection', className, disabled && 'disabled', collapsed && 'collapsed', 'row')}
            disabled={disabled}
        >
            {title ? (
                <div ref={sectionRef} className="CheckoutSection__header container" onClick={onToggle}>
                    <div className="row d-flex justify-content-between align-items-center">
                        <h3>{title}</h3>
                        <div className="CheckoutSection__header__actions" onClick={(e) => e.stopPropagation()}>
                            {onEdit ? (
                                <button
                                    className="btn btn-small"
                                    onClick={onEdit}
                                    disabled={disabled}
                                    aria-label={lblEdit}
                                    title={lblEdit}
                                >
                                    <i className="fas fa-edit" />
                                </button>
                            ) : null}
                            {onSave && !hideSaveButton ? (
                                <button
                                    className="btn btn-small"
                                    onClick={onSave}
                                    disabled={disabled || !canSave}
                                    aria-label={lblSave}
                                    title={lblSave}
                                >
                                    <i className="fas fa-save" />
                                </button>
                            ) : null}
                        </div>
                    </div>
                    <div className="divider row mb-3 mt-2" />
                </div>
            ) : null}
            <div className="CheckoutSection__body container p-0">{children}</div>
            <div className="CheckoutSection__footer container p-0"></div>
        </div>
    );
}

CheckoutSection.propTypes = {
    className: px.string,
    disabled: px.bool,
    collapsed: px.bool,
    children: px.node,
    title: px.node,
    onToggleCollapse: px.func,
    onEdit: px.func,
    onSave: px.func,
    canSave: px.bool,
    isEditing: px.bool,
    hideSaveButton: px.bool,
};
