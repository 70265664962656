import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { order, product } from '~features';
import { LinkItem } from 'Common/types';
import { ProductView as Product } from 'Common/components/product';
import { ProductDetails, ProductGallery, RelatedProducts } from '~components/product';
import * as GTM from 'Common/constants/gtm';

export default function ProductView({
    checkoutPageLink,
    detailsProps,
    includeDescription = false,
    isUserAuthenticated,
    pageId,
    ProductDetailsComponent = ProductDetails,
    ProductGalleryComponent = ProductGallery,
    RelatedProductsComponent = RelatedProducts,
    reviewProviderEntityId,
    showRelatedEntries = 'None',
    siteId,
    storeLocatorLink,
    useCompare = false,
    useSocialShare = false,
    writeReviewAnchor,
    ...props
}) {
    const totalQty = useSelector(order.selectors.getTotalQuantity);
    const productTitle = useSelector(product.selectors.getChildTitle);
    const currentMarketingBadges = useSelector(product.selectors.getCurrentMarketingBadges);

    return (
        <Product
            {...props}
            detailsProps={{
                checkoutPageLink,
                gtmListValue: GTM.TAGS.PDP,
                includeDescription,
                pageId,
                reviewProviderEntityId,
                siteId,
                storeLocatorLink,
                useCompare,
                useSocialShare,
                writeReviewAnchor,
                totalQty,
                ...detailsProps,
            }}
            productTitle={productTitle}
            currentMarketingBadges={currentMarketingBadges}
            isUserAuthenticated={isUserAuthenticated === 'True'}
            showRelatedEntries={showRelatedEntries === 'Sidebar'}
            Details={ProductDetailsComponent}
            Gallery={ProductGalleryComponent}
            Related={RelatedProductsComponent}
            prodcutId={reviewProviderEntityId}
        />
    );
}

ProductView.propTypes = {
    checkoutPageLink: px.string,
    detailsProps: px.objectOf(px.any),
    gtmListValue: px.string,
    hideRelatedProducts: px.bool,
    includeDescription: px.bool,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    pageId: px.oneOfType([px.string, px.number]),
    ProductDetailsComponent: px.elementType,
    ProductGalleryComponent: px.elementType,
    RelatedProductsComponent: px.elementType,
    reviewProviderEntityId: px.string,
    showRelatedEntries: px.oneOf(['Sidebar', 'Carousel', 'None', '']),
    siteId: px.string,
    storeLocatorLink: LinkItem,
    useCompare: px.bool,
    useSocialShare: px.bool,
    writeReviewAnchor: px.string,
};
