import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as PRODUCT from '~config/product';
import { HTMLRender } from 'Common/components/ui';

function Content({ className, data, hideImage = false }) {
    return (
        <div className={className}>

            <div className={cx("px-0 col-12", hideImage ? null : 'col-md-6')}>
                <HTMLRender content={data.description} className="ProductDescriptionTab__Description" />
            </div>
            {hideImage ? null : (
                <div className="col-6 d-none d-md-block">
                    <img
                        src={`${data.descriptionImageUrl}?format=png&height=450&width=550${
                            PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                        }`}
                        alt={data.displayName}
                    />
                </div>
            )}
        </div>
    );
}

Content.propTypes = {
    className: px.string,
    data: px.shape({
        description: px.string,
        displayName: px.string,
        descriptionBackgroundUrl: px.string,
        descriptionImageUrl: px.string,
    }),
    hideImage: px.bool,
};

export default function ProductDescriptionTab({
    className,
    id,
    labelledBy = id ? id.replace(/content/g, 'tab') : undefined,
    unwrap = false,
    ...props
}) {
    return unwrap ? (
        <Content className={className} {...props} />
    ) : (
        <div
            id={id}
            role="tabpanel"
            aria-labelledby={labelledBy}
            className={cx('ProductDescriptionTab tab-pane fade show container text-center text-md-left', className)}
        >
            <Content className="background row" {...props} />
        </div>
    );
}

ProductDescriptionTab.propTypes = {
    className: px.string,
    unwrap: px.bool,
    data: px.shape({
        description: px.string,
        displayName: px.string,
        descriptionBackgroundUrl: px.string,
        descriptionImageUrl: px.string,
    }),
    id: px.string,
    labelledBy: px.string,
    hideImage: px.bool,
    hideTitle: px.bool,
};
