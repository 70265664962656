import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Formatter } from 'Common/utils';
import { localization } from 'Common/features';

function createSelector(id) {
    return id ? (state) => localization.selectors.getTranslationString(state, id) : () => id;
}

export default function useTranslator(id, { notFoundValue = id } = {}) {
    const selector = useMemo(() => createSelector(id), [id]);
    const property = useSelector(selector);

    const defaultProp = useMemo(() => (property === id ? notFoundValue : property || notFoundValue), [
        property,
        id,
        notFoundValue,
    ]);

    const translate = useCallback(
        (params = null) =>
            params ? Formatter.property(defaultProp, typeof params === 'object' ? params : {}) : defaultProp,
        [defaultProp]
    );

    return translate;
}
