import { Api } from 'Common/utils';

export function addToCompare(code) {
    return Api.POST('/Comparelist/AddToComparelist', {
        code,
    });
}

export function getCompareList() {
    return Api.GET('/Comparelist/GetComparelist');
}

export function removeFromCompareList(code) {
    return Api.DELETE('/Comparelist/RemoveFromComparelist', { code });
}

export function clearCompareList() {
    return Api.DELETE('/Comparelist/ClearComparelist');
}
