import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Url } from 'Common/utils';
import { TRANSFORM_PRODUCT_IMAGE_BACKGROUND } from 'Common/config/product';
import { ProductPrice } from 'Common/components/product';

export default function HeaderSearchResult({ className, style, result, showFake }) {
    return showFake ? (
        <FakeResult className={className} />
    ) : (
        <div style={style} className={cx('HeaderSearchResult', className)}>
            <a href={result.product.url}>
                <img
                    className="HeaderSearchResult__image"
                    src={Url.addToRelativeUrl(
                        result.imageUrl,
                        `format=png&height=160&width=160${TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''}`
                    )}
                />
            </a>

            <div className="HeaderSearchResult__title">
                <a href={result.product.url}>{result.product.title}</a>
            </div>
            <ProductPrice
                className="HeaderSearchResult__price"
                priceInfo={{
                    price: result.product.price,
                    reducedPrice: result.product.reducedPrice,
                }}
                noRanges
                skipPriceTransform
            />
        </div>
    );
}

HeaderSearchResult.propTypes = {
    style: px.object,
    className: px.string,
    result: px.object,
    showFake: px.bool,
};

function FakeResult({ className }) {
    return (
        <div className={cx('HeaderSearchResult__fake', className)}>
            <div className="HeaderSearchResult__fake--image" />
            <div className="HeaderSearchResult__fake--title" />
        </div>
    );
}

FakeResult.propTypes = {
    className: px.string,
};
