import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { ChildDetail } from 'Common/components/product';
import { LinkItem } from 'Common/types';
import { product } from '~features';

export default function ProductStock(props) {
    const productInfo = useSelector(product.selectors.getProduct);
    const currentItemStatus = useSelector(product.selectors.getCurrentItemStatus);
    const displayOnly = React.useMemo(() => productInfo?.StockStatus === 'DisplayOnly', [productInfo]);

    return displayOnly ? null : (
        <ChildDetail
            {...props}
            currentItemStatus={currentItemStatus}
            productCode={productInfo.Code}
            productTypeId={productInfo.TypeId}
        />
    );
}

ProductStock.propTypes = {
    Links: px.node,
    storeLocatorLink: LinkItem,
    reviewProviderEntityId: px.string,
};
