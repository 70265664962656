export default {
    map: {
        center: { lat: 34.0, lng: -81.0 },
        bounds: null,
        zoom: 12,
        openLocation: null,
    },
    search: {
        input: '',
        location: null,
        response: {
            Page: 1,
            PageSize: 25,
            PageItems: 0,
            TotalItems: 0,
            TotalPages: 0,
            Locations: [],
        },
        query: {
            Language: 'en',
            Page: 1,
            PageSize: 25,
            Site: 'Default',
            CategoriesFilter: [],
            LocationFilter: {
                Latitude: '34.0',
                Longitude: '-81.0',
                Distance: 50,
                DistanceUnits: 'Miles',
            },
        },
    },
};
