import React from 'react';
import px from 'prop-types';
import { Form } from 'informed';
import { AddressForm } from '../forms';
import { Translation } from '../localization';

export default function EditAddressForm({ onClose, disabled, formError, formProps = {}, ...props }) {
    return (
        <Form {...formProps} disabled={disabled}>
            <div className="row mt-2">
                <AddressForm useDefaultCheck disabled={disabled} {...props} />
            </div>
            {formError ? (
                <div className="alert alert-danger col-12 mt-4">
                    <Translation id={formError} />
                </div>
            ) : null}
            <div className="mt-4">
                <button type="submit" className="btn btn-primary ml-1 mr-1" disabled={disabled}>
                    <strong>
                        <Translation id="Commerce.Account.SaveLabel" />
                    </strong>
                </button>
                <button type="button" onClick={onClose} className="btn btn-secondary ml-1 mr-1" disabled={disabled}>
                    <strong>
                        <Translation id="Commerce.Account.CancelLabel" />
                    </strong>
                </button>
            </div>
        </Form>
    );
}

EditAddressForm.propTypes = {
    onClose: px.func,
    disabled: px.bool,
    formError: px.string,
    formProps: px.object,
};
