import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Formatter } from 'Common/utils';
import { useViewport } from 'Common/hooks';
import { Translation } from '../localization';

const MOBILE_BREAKPOINT = 'md';

export default function OrderDetailTable({ className, LineItems = [], currency, shipmentId }) {
    const viewport = useViewport();
    const itemCount = React.useMemo(() => LineItems.length, [LineItems]);

    return (
        <div className={cx('OrderDetailTable CartTable', className)}>
            <div className="table container">
                <div className={cx('row tablehead d-none', `d-${MOBILE_BREAKPOINT}-flex`)}>
                    <div className="OrderDetailTableLabel col-6">
                        <span>
                            <Translation id="Commerce.Order.Cart.Item.Label" />
                        </span>
                    </div>
                    <div className="OrderDetailTableLabel col-2">
                        <span>
                            <Translation id="Commerce.Order.Cart.Price.Label" />
                        </span>
                    </div>
                    <div className="OrderDetailTableLabel col-2 text-center">
                        <span>
                            <Translation id="Commerce.Order.Cart.Quantity.Label" />
                        </span>
                    </div>
                    <div className="OrderDetailTableLabel col-2 text-center">
                        <span>
                            <Translation id="Commerce.Order.Cart.Subtotal.Label" />
                        </span>
                    </div>
                </div>
                {itemCount
                    ? LineItems.map((item) => (
                          <div key={`cart-0-${shipmentId}-${item.LineItemId}`} className={cx()}>
                              {viewport.is.lt(MOBILE_BREAKPOINT) ? (
                                  <div className="row tablerow">
                                      <div className="col-4 tablecell image p-0">
                                          <div className="position-relative h-auto">
                                              <img
                                                  className="img-responsive"
                                                  src={`${item.CatalogEntity?.DefaultImageUrl}?height=200&width=200&bgcolor=white`}
                                                  alt={item.CatalogEntity?.DisplayName}
                                              />
                                          </div>
                                      </div>
                                      <div className="col-8">
                                          <div className="col-12 tablecell name">
                                              <span className="subheader">{item.CatalogEntity?.DisplayName}</span>
                                          </div>
                                          <div className="col-12 tablecell price text-left">
                                              <p className="paragraph-2">
                                                  {Formatter.currency(item.SalePrice, currency)}
                                              </p>
                                          </div>
                                          <div className="col-12 tablecell quantity text-left px-3">
                                              <span className="paragraph-2">
                                                  <Translation id="Commerce.Order.Mobile.Quantity.Label" />
                                              </span>
                                              <p className="paragraph-2 d-inline font-weight-bold">{item.Quantity}</p>
                                          </div>
                                      </div>
                                  </div>
                              ) : (
                                  <div className="row tablerow">
                                      <div className="col-6 tablecell item">
                                          <div className="row">
                                              <div className="col-6 image p-0">
                                                  <img
                                                      className="img-responsive"
                                                      src={`${item.CatalogEntity?.DefaultImageUrl}?height=200&width=200&bgcolor=white`}
                                                      alt={item.CatalogEntity?.DisplayName}
                                                  />
                                              </div>
                                              <div className="col-6 name">
                                                  <span className="subheader">{item.CatalogEntity?.DisplayName}</span>
                                              </div>
                                          </div>
                                      </div>
                                      {item.ListPrice > item.SalePrice ? (
                                          <div className="col-2 tablecell price">
                                              <p className="strike paragraph-2">
                                                  {Formatter.currency(item.ListPrice, currency)}
                                              </p>
                                              <p className="paragraph-2">
                                                  {Formatter.currency(item.SalePrice, currency)}
                                              </p>
                                          </div>
                                      ) : (
                                          <div className="col-2 tablecell price">
                                              <p className="paragraph-2">
                                                  {Formatter.currency(item.SalePrice, currency)}
                                              </p>
                                          </div>
                                      )}
                                      <div className="col-2 tablecell quantity">
                                          <p className="paragraph-2 d-inline font-weight-bold">{item.Quantity}</p>
                                      </div>
                                      {item.ListPrice > item.SalePrice ? (
                                          <div className="col-2 tablecell subtotal price text-center">
                                              <p className="strike paragraph-2">
                                                  {Formatter.currency(item.ListPrice * item.Quantity, currency)}
                                              </p>
                                              <p className="paragraph-2">
                                                  {Formatter.currency(item.SalePrice * item.Quantity, currency)}
                                              </p>
                                          </div>
                                      ) : (
                                          <div className="col-2 tablecell subtotal price text-center">
                                              <p className="paragraph-2">
                                                  {Formatter.currency(item.SalePrice * item.Quantity, currency)}
                                              </p>
                                          </div>
                                      )}
                                  </div>
                              )}
                          </div>
                      ))
                    : null}
            </div>
        </div>
    );
}

OrderDetailTable.propTypes = {
    className: px.string,
    LineItems: px.arrayOf(
        px.shape({
            LineItemId: px.number,
            DisplayName: px.string,
            Quantity: px.number,
            SalePrice: px.number,
            MinQuantity: px.number,
            MaxQuantity: px.number,
        })
    ),
    shipmentId: px.number,
    currency: px.string,
    returnRequest: px.shape({
        OrderNumber: px.string,
        Notes: px.string,
        LineItems: px.arrayOf(
            px.shape({
                Code: px.string,
                ReasonCode: px.string,
                Notes: px.string,
                Quantity: px.number,
            })
        ),
    }),
};
