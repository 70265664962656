import * as React from 'react';
import { useSelector } from 'react-redux';
import { ProductDescriptionTab } from 'Common/components/product';
import { product } from '~features';

export default function ProductDescriptionContent(props) {
    const productInfo = useSelector(product.selectors.getProduct);
    const data = React.useMemo(
        () =>
            productInfo
                ? {
                      description: productInfo.Description,
                      displayName: productInfo.DisplayName,
                      descriptionBackgroundUrl: productInfo.Options?.DescriptionBackground,
                      descriptionImageUrl: productInfo.DescriptionImageUrl,
                  }
                : null,
        [productInfo]
    );

    return data ? <ProductDescriptionTab unwrap data={data} {...props} /> : null;
}
