import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Facet } from 'Common/types';
import { TabbedContent } from 'Common/components/ui';

export default function SearchTabbedFacet({ className, style, facet, onChange, order = [] }) {
    const unsortedTabs = React.useMemo(
        () =>
            facet?.options
                ?.filter((o) => o.count > 0)
                ?.map((o) => ({ contentId: o.value, tabContent: `${o.title} (${o.count})`, selected: o.selected })) ?? [],
        [facet]
    );

    const tabs = React.useMemo(
        () => [
            ...order.map((value) => unsortedTabs?.filter((x) => x.contentId === value)[0]).filter(Boolean),
            ...unsortedTabs.filter((tab) => !order.includes(tab.contentId)),
        ],
        [unsortedTabs, order]
    );

    const onSelectTab = React.useCallback(
        ({ contentId }) => {
            onChange({
                ...facet,
                options: facet.options.map((o) =>
                    o.value === contentId ? { ...o, selected: true } : { ...o, selected: false }
                ),
            });
        },
        [facet, onChange]
    );

    return (
        <div style={style} className={cx(className, 'SearchTabbedFacet')}>
            <TabbedContent onSelectTab={onSelectTab} tabGap={5} tabScroll tabs={tabs} />
        </div>
    );
}

SearchTabbedFacet.propTypes = {
    order: px.arrayOf(px.string),
    style: px.object,
    className: px.string,
    facet: Facet,
    onChange: px.func,
};
