export default class Script {
    static scripts = {};

    static listen(src, globalNamespace, opts, onChange) {
        if (src) {
            if (!this.scripts[src]) {
                this.scripts[src] = new this(src, globalNamespace, opts);
            } else if (opts.forceReload) {
                this.scripts[src].load(opts);
            }

            this.scripts[src].onChange(onChange);
        }
    }

    static release(src, onChange) {
        if (this.scripts[src]) {
            this.scripts[src].offChange(onChange);
        }
    }

    static destroy(src, andRemove = false) {
        if (this.scripts[src]) {
            this.scripts[src].destroy();
            if (andRemove) delete this.scripts[src];
        }
    }

    listeners = [];
    script = null;
    src = null;
    namespace = null;
    _value = null;

    get value() {
        return this._value;
    }

    constructor(src, globalNamespace, opts) {
        this.ns = globalNamespace;
        this.src = src;
        if (globalNamespace && window[globalNamespace] && !opts.forceReload) {
            this.update(window[globalNamespace]);
        } else {
            this.load(opts);
        }
    }

    load({ async = true, onBefore } = {}) {
        if (this.script) this.destroy();

        this.script = document.createElement('script');
        if (onBefore) onBefore();
        if (this.ns && window[this.ns]) delete window[this.ns];
        this.script.async = async;
        this.script.src = this.src;
        this.script.onload = this.script.onreadystatechange = () => {
            this.update(this.ns ? window[this.ns] : true);
        };
        document.body.appendChild(this.script);
    }

    destroy() {
        if (this.script) {
            this.script.onload = this.script.onreadystatechange = () => undefined;
            document.body.removeChild(this.script);
            if (this.ns) delete window[this.ns];
            this.script = null;
            this.update(null);
        }
    }

    update(value) {
        this._value = value;
        for (const fn of this.listeners) fn(value);
    }

    onChange(listener) {
        this.listeners.push(listener);
        listener(this.value);
    }

    offChange(listener) {
        const i = this.listeners.indexOf(listener);

        if (i !== -1) this.listeners.splice(i, 1);
    }
}
