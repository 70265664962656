import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { useViewport, useTranslation } from 'Common/hooks';

export default function CheckboxSelector({
    className,
    options = [],
    selectedValues = [],
    title = '',
    onSelect,
    disabledMap,
    outOfStockToolTip,
    notAvailableToolTip,
    definition = null,
}) {
    const viewport = useViewport();
    const optionsRef = React.useRef(null);
    const id = React.useRef(`checkbox_${v4()}`);
    const lblExpand = useTranslation('Commerce.Product.Selector.Expand.Label');
    const lblHide = useTranslation('Commerce.Product.Selector.Hide.Label');
    const [expanded, setExpanded] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);

    const buttonTitle = React.useCallback(
        (value) => {
            if (disabledMap && (disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid))
                return notAvailableToolTip;
            else if (disabledMap && !disabledMap[value]?.hasStock) return outOfStockToolTip;

            return value;
        },
        [outOfStockToolTip, notAvailableToolTip, disabledMap]
    );

    const optionIsDisabled = React.useCallback(
        ({ value } = {}) =>
            disabledMap && (disabledMap === true || disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid),
        [disabledMap]
    );

    const onClick = React.useCallback((value) => (onSelect ? () => onSelect(value) : null), [onSelect]);

    React.useEffect(() => {
        optionsRef.current.style.height = expanded
            ? `${optionsRef.current.children[0]?.getBoundingClientRect()?.height}px`
            : '21px';
    }, [expanded]);

    React.useEffect(() => {
        const optionsContainer = optionsRef.current.children[0];
        const children = optionsContainer?.children || [];

        for (let i = 0, l = children.length; i < l; i++) {
            if (i > 0 && children[i - 1].offsetTop < children[i].offsetTop) {
                setHidden(true);
                return;
            }
        }
        setHidden(false);
    }, [viewport, options]);

    return (
        <div className={cx('CheckboxSelector', className)}>
            <p className="mb-0 strong">{title}</p>
            <div ref={optionsRef} style={{ transition: 'height .2s ease' }} className="checkbox-group mt-2">
                <div className="row justify-content-center justify-content-md-start w-100">
                    {options.map((option) => (
                        <div
                            key={`${id.current}_${option.value}`}
                            className="form-check d-flex align-items-center"
                            data-toggle="tooltip"
                            title={buttonTitle(option.value)}
                        >
                            <input
                                type="checkbox"
                                name={`${title}_RadioSelector`}
                                id={`${id.current}${option.value.replace(/\s/g, '')}`}
                                checked={selectedValues.includes(option.value)}
                                readOnly={true}
                                className={cx('form-check-input', {
                                    disabled: !disabledMap[option.value]?.hasStock || optionIsDisabled(option),
                                    oos: !disabledMap[option.value]?.hasStock,
                                    selected: selectedValues.includes(option.value),
                                })}
                                onClick={onClick(option.value)}
                            ></input>
                            <label
                                className={cx('paragraph-2 form-check-label', { disabled: optionIsDisabled(option) })}
                                htmlFor={`${id.current}${option.value.replace(/\s/g, '')}`}
                            >
                                {option.label}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
            {hidden ? (
                <button
                    className="btn dropdown"
                    type="button"
                    onClick={() => setExpanded(!expanded)}
                    aria-label={expanded ? lblHide : lblExpand}
                    title={expanded ? lblHide : lblExpand}
                >
                    <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
                </button>
            ) : null}
            {definition ? (
                <div className="definition mt-2">
                    <p className="light-sm">{definition}</p>
                </div>
            ) : null}
        </div>
    );
}

CheckboxSelector.propTypes = {
    definition: px.node,
    className: px.string,
    options: px.arrayOf(px.shape({ label: px.string, value: px.string })),
    selectedValues: px.arrayOf(px.oneOfType([px.string, px.number])),
    title: px.string,
    onSelect: px.func,
    outOfStockToolTip: px.string,
    notAvailableToolTip: px.string,
    disabledMap: px.objectOf(px.object),
};
