import * as React from 'react';
import { useSelector } from 'react-redux';
import { ProductPrice as Price } from 'Common/components/product';
import { product } from '~features';

export default function ProductPrice(props) {
    const priceInfo = useSelector(product.selectors.getCurrentPriceInfo);
    const quantity = useSelector(product.selectors.getQuantity);

    return <Price {...props} priceInfo={priceInfo} quantity={quantity} />;
}

ProductPrice.propTypes = {};
