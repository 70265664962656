import px from 'prop-types';
import SavedPayment from './SavedPayment';

export const PaymentMethodShape = {
    DisplayName: px.string,
    PaymentProvider: px.string,
    PaymentType: px.string,
    SystemName: px.string,
    SortOrder: px.number,
    SavedPaymentInfo: px.arrayOf(SavedPayment),
};

export default px.shape(PaymentMethodShape);
