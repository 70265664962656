import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useBEM, useProductDetails } from 'Common/hooks';
import * as PRODUCT from '~config/product';
import { Translation } from '../../localization';
import { Icon } from 'Common/components/ui';
import { ProductPrice, ProductBadge } from 'Common/components/product';
import { ProductImage } from 'Common/utils';
import { ProductInfo } from 'Common/models';

export default function GridCard({ className, product, active = false, onOpenQuickview, onCloseQuickview }) {
    const [b, em] = useBEM('GridCard', className);
    const { badges, priceInfo } = useProductDetails(product);
    const [primary, secondary] = React.useMemo(
        () => [
            ProductImage.getGlamOrDefaultImageUrl(
                product.CatalogMedia,
                product.DefaultImageUrl,
                `format=png&width=430&height=430${PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''}`
            ),
            ProductImage.getSecondaryImageUrl(
                product.GalleryMedia,
                product.DefaultImageUrl,
                `format=png&width=430&height=430${PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''}`
            ),
        ],
        [product]
    );

    return (
        <a
            className={cx(b, { [em(null, 'selected')]: active })}
            aria-label={product.ProductName}
            href={product.ContentUrl}
        >
            <ProductBadge className={em('badges')} badges={badges} />
            <div
                className={cx(em('image'), em('image', 'primary'))}
                style={{ backgroundImage: `url('${primary}')` }}
                aria-hidden="true"
            ></div>
            <div
                className={cx(em('image'), em('image', 'secondary'))}
                style={{ backgroundImage: `url('${secondary}')` }}
                aria-hidden="true"
            ></div>
            <div className={em('title')}>
                <h4>{product.DisplayName}</h4>
            </div>
            <div className={em('description')}>
                <p>{product.DescriptionShort}</p>
            </div>
            <div className={em('bottom-row')}>
                <button
                    className="btn btn-link"
                    onClick={active ? onCloseQuickview : onOpenQuickview}
                    role="button"
                    href="#"
                >
                    <Icon name="caret-down" />
                    <span>
                        <Translation id={ProductInfo.isDisplayOnly(product)
                            ? "Commerce.CatalogNode.Card.DisplayOnlyQuickView"
                            : "Commerce.CatalogNode.Card.QuickView"}
                        />
                    </span>
                </button>
                <ProductPrice className={cx(em('bottom-row__price'), 'a b c')} priceInfo={priceInfo} />
            </div>
        </a>
    );
}

GridCard.propTypes = {
    className: px.string,
    product: types.Product,
    active: px.bool,
    onOpenQuickview: px.func,
    onCloseQuickview: px.func,
};
