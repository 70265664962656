import * as React from 'react';
import px from 'prop-types';
import { v4 } from 'uuid';
import isEqual from 'fast-deep-equal';
import omit from 'lodash.omit';
import get from 'lodash.get';
import { Checkbox, RadioGroup, Radio, useFormState } from 'informed';
import { Formatter } from 'Common/utils';
import * as types from 'Common/types';
import { AddressForm, Field } from 'Common/components/forms';
import { Translation } from 'Common/components/localization';
import { SHIPMENT, ADDRESS, EMPTY_ADDRESS } from 'Common/constants/fields';
import VALIDATION_STRINGS from 'Common/constants/validation';
import CheckoutFormContext from '../CheckoutFormContext';

const GIFT_MESSAGE_MAX_LENGTH = 50;

function onValidateGiftMessage(value) {
    if (value?.length > GIFT_MESSAGE_MAX_LENGTH) {
        return 'Commerce.Order.Checkout.GiftMessageMaxLengthLabel';
    }
    return undefined;
}

export default function FormContents({
    currency,
    id: idStr = `CheckoutShipment_${v4()}`,
    isUserAuthenticated,
    isDigitalDelivery,
    shippingOptions,
    savedShippingAddresses,
    fetchShippingMethods,
    siteId,
    shipmentId,
}) {
    const id = React.useRef(idStr);
    const formCtx = React.useContext(CheckoutFormContext);
    const resetShippingMethod = React.useRef(() => {});

    const {
        values: {
            [SHIPMENT.isGift]: isGift,
            [SHIPMENT.address]: {
                [ADDRESS.id]: addressId,
                [ADDRESS.line1]: addressLine1,
                [ADDRESS.city]: addressCity,
                [ADDRESS.state]: addressState,
                [ADDRESS.firstName]: addressFirstName,
                [ADDRESS.lastName]: addressLastName,
            } = {},
        } = {},
    } = useFormState();

    const clearGiftMessage = React.useCallback(
        (value) => {
            if (!value) formCtx?.setValue(SHIPMENT.giftMessage, '');
        },
        [formCtx]
    );

    const leanSavedShippingAddress = React.useMemo(
        () =>
            savedShippingAddresses?.reduce(
                (acc, address) =>
                    acc.find(
                        (a) =>
                            a[ADDRESS.id] === address[ADDRESS.id] ||
                            isEqual(omit(a, [ADDRESS.id]), omit(address, [ADDRESS.id]))
                    )
                        ? acc
                        : acc.concat(address),
                []
            ),
        [savedShippingAddresses]
    );

    const shouldShowAddressSelector = React.useMemo(
        () => !isDigitalDelivery && (isUserAuthenticated || leanSavedShippingAddress?.length),
        [isDigitalDelivery, isUserAuthenticated, leanSavedShippingAddress]
    );

    const addressName = React.useMemo(() => [addressFirstName, addressLastName].filter(Boolean).join(' '), [
        addressLastName,
        addressFirstName,
    ]);

    const addressCityStatePostal = React.useMemo(
        () => [addressLine1, addressCity, addressState].filter(Boolean).join(', '),
        [addressLine1, addressCity, addressState]
    );

    const onSelect = React.useCallback(
        async (address) => {
            await formCtx.setValues({ [SHIPMENT.address]: address });
            formCtx.validate();
            document.getElementById(`${id.current}_shipment_drawer`).classList.remove('show');
        },
        [formCtx]
    );

    const onNewAddress = React.useCallback(async () => {
        await formCtx.setValues({ [SHIPMENT.address]: omit(EMPTY_ADDRESS, ADDRESS.country) });
        formCtx.validate();
        document.getElementById(`${id.current}_shipment_drawer`).classList.remove('show');
    }, [formCtx]);

    const onLocationChange = React.useCallback(
        (country, region) => {
            if (fetchShippingMethods) fetchShippingMethods(shipmentId, country, region);
        },
        [shipmentId, fetchShippingMethods]
    );

    React.useEffect(() => {
        resetShippingMethod.current = (options) => {
            const shippingMethod = get(formCtx.getValues(), SHIPMENT.shippingMethod);

            if (formCtx.setValues && !options.find((m) => m.ShippingMethodName === shippingMethod)) {
                formCtx.setValues({
                    [SHIPMENT.shippingMethod]: options.length ? options[0].ShippingMethodName : null,
                });
            }
        };
    }, [formCtx]);

    React.useEffect(() => {
        const handler = setTimeout(() => resetShippingMethod.current(shippingOptions));

        return () => clearTimeout(handler);
    }, [shippingOptions]);

    return (
        <>
            <div className="col-12 p-0 mt-4">
                <h6 className="mb-0">
                    <Translation id="Commerce.Order.Checkout.ShippingAddress.Display" />
                </h6>
                {shouldShowAddressSelector ? (
                    <div className="col-12 col-lg-6 mt-4">
                        <div className="dropdown mt-2 row">
                            <div className="accordion w-100" id={`${id.current}_accordion`}>
                                <div className="card-header w-100" id={`${id.current}_creditcard_header`}>
                                    <button
                                        className="btn d-flex justify-content-between align-items-center w-100"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target={`#${id.current}_shipment_drawer`}
                                        aria-expanded="false"
                                        aria-controls={`${id.current}_shipment_drawer`}
                                    >
                                        <p className="paragraph-2">
                                            <Translation id="Commerce.Order.Checkout.SavedShipments.Dropdown.Label" />
                                        </p>
                                        <i className="fa fa-chevron-down" aria-hidden="true" />
                                    </button>
                                </div>
                                <div
                                    id={`${id.current}_shipment_drawer`}
                                    className="collapse show pb-1"
                                    aria-labelledby={`${id.current}_shipment_header`}
                                    data-parent={`#${id.current}_accordion`}
                                >
                                    <div className="drawer container">
                                        <button onClick={onNewAddress} className="btn p-1 w-100 text-left">
                                            <p className="paragraph-2">
                                                <Translation id="Commerce.Order.Checkout.SavedShipments.NewAddress.Label" />
                                            </p>
                                        </button>
                                        {addressId ? (
                                            <button disabled className="btn p-1 w-100 text-left">
                                                {addressName ? (
                                                    <p className="paragraph-2 p-0">{addressName}</p>
                                                ) : undefined}
                                                {addressCityStatePostal ? (
                                                    <p className="paragraph-2 p-0">{addressCityStatePostal}</p>
                                                ) : undefined}
                                            </button>
                                        ) : null}
                                        {leanSavedShippingAddress.map((a) =>
                                            a[ADDRESS.id] === addressId ? null : (
                                                <button
                                                    key={a[ADDRESS.id]}
                                                    onClick={() => onSelect(a)}
                                                    className="btn p-1 w-100 text-left"
                                                >
                                                    <p className="paragraph-2 p-0">
                                                        {a[ADDRESS.firstName]} {a[ADDRESS.lastName]}
                                                    </p>
                                                    <p className="paragraph-2 p-0">
                                                        {a[ADDRESS.line1]}, {a[ADDRESS.city]}, {a[ADDRESS.state]}
                                                    </p>
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="row col-12">
                    {isDigitalDelivery ? (
                        <AddressForm scope={SHIPMENT.address} digital />
                    ) : (
                        <AddressForm
                            useDefaultCheck={isUserAuthenticated}
                            scope={SHIPMENT.address}
                            onLocationChange={onLocationChange}
                            siteId={siteId}
                            connected
                        />
                    )}
                </div>
            </div>
            <div className="col-12 p-0 mt-4">
                <h6>
                    <Translation id="Commerce.Order.Checkout.ShippingMethod.Title" />
                </h6>
                <RadioGroup
                    field={SHIPMENT.shippingMethod}
                    validate={(value) => (value ? undefined : VALIDATION_STRINGS.required)}
                    validateOn="change"
                >
                    <div className="container mt-2 p-0">
                        {shippingOptions.map((m) => (
                            <div className="row my-1" key={m.ShippingMethodName}>
                                <div className="col-2 col-md-1 ">
                                    <Radio value={m.ShippingMethodName} />
                                </div>
                                <ShippingLabels m={m} currency={currency} />
                            </div>
                        ))}
                    </div>
                </RadioGroup>
            </div>
            <div className="col-12 p-0 mb-3">
                <Field
                    Component={Checkbox}
                    name={SHIPMENT.isGift}
                    label={<Translation id="Commerce.Order.Checkout.GiftOption.Label" />}
                    labelInline
                    labelLast
                    hideError
                    onChange={clearGiftMessage}
                />
                <Field
                    name={SHIPMENT.giftMessage}
                    label={<Translation id="Commerce.Order.Checkout.GiftMessage.Label" />}
                    disabled={!isGift}
                    validate={onValidateGiftMessage}
                />
            </div>
        </>
    );
}

FormContents.propTypes = {
    currency: px.string,
    shippingOptions: px.arrayOf(px.object),
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    id: px.string,
    siteId: px.oneOfType([px.number, px.string]),
    isDigitalDelivery: px.bool,
    shipmentId: px.oneOfType([px.number, px.string]),
    savedShippingAddresses: px.arrayOf(types.Address),
    fetchShippingMethods: px.func,
};

function ShippingLabels({ m, currency }) {
    return (
        <div className="col-10 col-md-11">
            <div className="row">
                <div className="col-12 col-md-6 p-0">
                    <div className="row">
                        <p className="paragraph-2 col-6 p-0">{m.DisplayName || ''}</p>
                        <p className="paragraph-2 col-6 p-0">{m.DeliveryInfo || ''}</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 p-0">
                    <div className="row">
                        <p className="paragraph-2 col-6 p-0">{Formatter.currency(m.EstimatedCost || 0, currency)}</p>
                        <p className="paragraph-2 col-6 p-0">{m.Description || ''}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

ShippingLabels.propTypes = {
    m: px.shape({ EstimatedCost: px.number, DisplayName: px.string, Description: px.string, DeliveryInfo: px.string }),
    currency: px.string,
};
