import px from 'prop-types';
import { PAYMENT } from 'Common/constants/fields';
import Address from './Address';

export const PaymentShape = {
    [PAYMENT.paymentId]: px.oneOfType([px.number, px.string]),
    [PAYMENT.paymentMethodId]: px.oneOfType([px.number, px.string]),
    [PAYMENT.merchantGuid]: px.string,
    [PAYMENT.displayName]: px.string,
    [PAYMENT.paymentType]: px.string,
    [PAYMENT.systemName]: px.string,
    [PAYMENT.cardType]: px.string,
    [PAYMENT.token]: px.string,
    [PAYMENT.cardNumber]: px.string,
    [PAYMENT.cardCvv]: px.string,
    [PAYMENT.cardDate]: px.string,
    [PAYMENT.savePayment]: px.bool,
    [PAYMENT.expMonth]: px.number,
    [PAYMENT.expYear]: px.number,
    [PAYMENT.address._name]: Address,
    CreditCardTypeFriendlyName: px.string,
    Amount: px.number,
    Balance: px.number,
    PaymentMethodName: px.string,
};

export default px.shape(PaymentShape);
