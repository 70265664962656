import * as React from 'react';
import px from 'prop-types';
import { default as CardForm } from './CardForm';
import { default as AlternateCardForm } from './AlternateCardForm';
import { default as ConnectedCardForm } from './ConnectedCardForm';

export { default as ExpDateForm } from './ExpDateForm';

export default function CreditCard({ alternate = false, connected = false, ...props }) {
    if (connected) {
        return <ConnectedCardForm {...props} Form={alternate ? AlternateCardForm : CardForm} />;
    }

    if (alternate) {
        return <AlternateCardForm {...props} />;
    }

    return <CardForm {...props} />;
}

CreditCard.propTypes = {
    alternate: px.bool,
    connected: px.bool,
};
