import * as React from 'react';
import { createPortal } from 'react-dom';
import px from 'prop-types';

const STYLE = {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    transform: 'rotate(0deg)',
    zIndex: 5,
};

function Portal({ style, ...props }) {
    const elementRef = React.useRef(typeof window !== 'undefined' ? window.document.createElement('div') : null);

    React.useEffect(() => {
        const bodyHeight = document.body.style.height;
        const overflowY = document.body.style.overflowY;
        const bodyScrollBehavior = document.body.style.scrollBehavior;
        const htmlScrollBehavior = document.documentElement.style.scrollBehavior;
        const scrollPosition = window.scrollY;
        const el = elementRef.current;

        if (el) {
            document.body.style.height = '100vh';
            document.body.style.overflowY = 'hidden';
            document.body.style.scrollBehavior = 'auto';
            document.documentElement.style.scrollBehavior = 'auto';
            el.id = 'portalDiv';
            document.body.appendChild(el);
        }

        return () => {
            document.body.style.height = bodyHeight;
            document.body.style.overflowY = overflowY;
            el?.remove();
            window.scroll(window.scrollX, scrollPosition);
            document.body.style.scrollBehavior = bodyScrollBehavior;
            document.documentElement.style.scrollBehavior = htmlScrollBehavior;
        };
    }, []);

    return elementRef.current
        ? createPortal(<div {...props} style={{ ...STYLE, ...style }} tabIndex={0} />, elementRef.current)
        : null;
}

Portal.propTypes = {
    style: px.objectOf(px.any),
};

export default React.memo(Portal);
