import React from 'react';
import px from 'prop-types';
import ContentSearchResult from './ContentSearchResult';
import ProductSearchResult from './ProductSearchResult';
import VideoSearchResult from './VideoSearchResult';

const ResultComponents = { product: ProductSearchResult, content: ContentSearchResult, video: VideoSearchResult };

export default function SearchResult({ result, ...props }) {
    const Component = ResultComponents[result?.type] ?? ContentSearchResult;

    return <Component result={result} {...props} />;
}

SearchResult.propTypes = {
    style: px.object,
    className: px.string,
    result: px.object,
};
