import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Url } from 'Common/utils';
import { useTranslation } from 'Common/hooks';
import Icon from '../Icon';

export const SERVICE = {
    facebook: 'Facebook',
    twitter: 'Twitter',
    email: 'Email',
};

export function getUrl(service, shareLink, title = '', hashtags = '') {
    if (shareLink) {
        const { href } = Url.create(shareLink);

        switch (service) {
            case SERVICE.facebook:
                return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(href)}`;
            case SERVICE.twitter:
                return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    title
                )}&url=${href}&hashtags=${encodeURIComponent(hashtags)}`;
            case SERVICE.email:
                return `mailto:?body=${encodeURIComponent(href)}`;
        }
    }
    return null;
}

export default function SocialShare({
    className,
    service = SERVICE.facebook,
    link = '',
    name = '',
    hashtags = '',
    propagate = false,
}) {
    const lblId = React.useMemo(() => `Social.${service}.Share.Label`, [service]);
    const lbl = useTranslation(lblId);
    const url = React.useMemo(() => getUrl(service, link, name, hashtags), [service, link, name, hashtags]);
    const onClick = React.useCallback(
        (e) => {
            if (!propagate) e.stopPropagation();
        },
        [propagate]
    );

    return url ? (
        <a
            className={cx(`SocialShare SocialShare--${service}`, className)}
            href={url}
            onClick={onClick}
            target="_blank"
            rel="noreferrer noopener"
            title={lbl}
        >
            <Icon name={service.toLowerCase()} sheet="social" />
        </a>
    ) : null;
}

SocialShare.propTypes = {
    className: px.string,
    service: px.oneOf(['Facebook', 'Twitter', 'Email']),
    link: px.oneOfType([px.string, px.object]),
    name: px.string,
    hashtags: px.string,
    propagate: px.bool,
};

SocialShare.Facebook = function FacebookShare(props) {
    return <SocialShare {...props} service={SERVICE.facebook} />;
};
SocialShare.Twitter = function TwitterShare(props) {
    return <SocialShare {...props} service={SERVICE.twitter} />;
};
SocialShare.Email = function EmailShare(props) {
    return <SocialShare {...props} service={SERVICE.email} />;
};
