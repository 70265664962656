import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import px from 'prop-types';
import { Product } from 'Common/types';
import { ProductSlider as Slider } from 'Common/components/prostaff';
import { CatalogQuickView } from '~components/catalog';
import { customer, product } from '~features';

export default function ProductSlider({ className, catalogEntities }) {
    const dispatch = useDispatch();
    const isUserAuthenticated = useSelector(customer.selectors.isLoggedIn);

    const onSetProduct = React.useCallback((p) => dispatch(product.actions.setItemDetails({ Product: p })), [dispatch]);

    return catalogEntities?.length ? (
        <Slider
            className={className}
            catalogEntities={catalogEntities}
            QuickView={CatalogQuickView}
            isUserAuthenticated={isUserAuthenticated}
            openQuick={onSetProduct}
        />
    ) : null;
}

ProductSlider.propTypes = {
    className: px.string,
    catalogEntities: px.arrayOf(Product),
};
