import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Checkbox, Select, Option } from 'informed';
import { Name, AddressUtil } from 'Common/utils';
import { ADDRESS as FIELDS } from 'Common/constants/fields';
import { Translation } from 'Common/components/localization';
import Field from '../Field';
import PhoneField from '../PhoneField';
import { useAddressForm } from './helpers';

export default function AlternateAddressForm({
    useDefaultCheck = false,
    scope = '',
    children,
    countryList = [],
    postalRegex = null,
    regionOptions = {},
    halfInputsBreakpoint = 'lg',
    onCountryChange,
    onRegionChange,
    onLocationChange,
    usePlaceholders = false,
}) {
    const { prefix, labelProps, defaultCountry, lblRegion, validateZip, lblCountry } = useAddressForm({
        scope,
        postalRegex,
        onCountryChange,
        onRegionChange,
        onLocationChange,
        usePlaceholders,
        countryList,
    });

    const halfInput = React.useMemo(() => (halfInputsBreakpoint ? `col-${halfInputsBreakpoint}-6` : ''), [
        halfInputsBreakpoint,
    ]);

    const thirdInput = React.useMemo(() => (halfInputsBreakpoint ? `col-${halfInputsBreakpoint}-4` : ''), [
        halfInputsBreakpoint,
    ]);

    return (
        <>
            <Field hidden name={`${prefix}${FIELDS.id}`} />
            <Field
                className={cx('col-12', halfInput)}
                name={`${prefix}${FIELDS.firstName}`}
                {...labelProps('Commerce.Order.Checkout.Address.FirstName.Label')}
                transform={Name.cleanString}
                autoComplete="given-name"
                required
                hookAutofill
            />
            <Field
                className={cx('col-12', halfInput)}
                name={`${prefix}${FIELDS.lastName}`}
                {...labelProps('Commerce.Order.Checkout.Address.LastName.Label')}
                transform={Name.cleanString}
                autoComplete="family-name"
                required
                hookAutofill
            />
            <Field
                className="col-12"
                name={`${prefix}${FIELDS.company}`}
                {...labelProps('Commerce.Order.Checkout.Address.Company.Label')}
                autoComplete="organization"
                hookAutofill
            />
            <Field
                className="col-12"
                name={`${prefix}${FIELDS.line1}`}
                {...labelProps('Commerce.Order.Checkout.Address.StreetLine1.Label')}
                transform={AddressUtil.cleanAddressString}
                autoComplete="address-line1"
                required
                hookAutofill
            />
            <Field
                className="col-12"
                name={`${prefix}${FIELDS.line2}`}
                {...labelProps('Commerce.Order.Checkout.Address.StreetLine2.Label')}
                transform={AddressUtil.cleanAddressString}
                autoComplete="address-line2"
                hookAutofill
            />

            <Field
                className="CheckoutSection__select col-12"
                name={`${prefix}${FIELDS.country}`}
                {...labelProps('Commerce.Order.Checkout.Address.Country.Label')}
                Component={Select}
                autoComplete="country-name"
                notify={[`${prefix}${FIELDS.state}`]}
                required
            >
                <Option value="" disabled>
                    {lblCountry}
                </Option>
                {countryList?.length
                    ? countryList
                          .filter((x) => x.CountryCode)
                          .map((x) => (
                              <Option value={x.CountryCode} key={x.CountryCode}>
                                  {x.CountryName}
                              </Option>
                          ))
                    : null}
            </Field>

            <Field
                className={cx('col-12', thirdInput)}
                name={`${prefix}${FIELDS.city}`}
                {...labelProps('Commerce.Order.Checkout.Address.City.Label')}
                transform={AddressUtil.cleanCityString}
                autoComplete="address-level2"
                hookAutofill
                required
            />
            <Field
                className={cx('CheckoutSection__select col-12', thirdInput)}
                name={`${prefix}${FIELDS.state}`}
                {...labelProps('Commerce.Order.Checkout.Address.State.Label')}
                Component={Select}
                notify={[`${prefix}${FIELDS.zip}`]}
                autoComplete="address-level1"
                required
            >
                <Option value="" disabled>
                    {lblRegion}
                </Option>
                {regionOptions
                    ? Object.entries(regionOptions)
                          .filter((entry) => entry[1])
                          .map((entry) => (
                              <Option value={entry[0]} key={entry[0]}>
                                  {entry[1]}
                              </Option>
                          ))
                    : null}
            </Field>

            <Field
                className={cx('col-12', thirdInput)}
                name={`${prefix}${FIELDS.zip}`}
                {...labelProps('Commerce.Order.Checkout.Address.ZipCode.Label')}
                transform={AddressUtil.cleanPostalString}
                validate={validateZip}
                autoComplete="postal-code"
                hookAutofill
                required
            />
            <Field
                className="col-12"
                name={`${prefix}${FIELDS.phone}`}
                {...labelProps('Commerce.Order.Checkout.Address.Phone.Label')}
                Component={PhoneField}
                defaultCountry={defaultCountry}
                validate={AddressUtil.getPhoneValidationError}
                autoComplete="tel"
                hookAutofill
                required
            />

            {useDefaultCheck ? (
                <Field
                    className="col-12"
                    name={`${prefix}${FIELDS.defaultAddress}`}
                    label={<Translation id="Commerce.Order.Checkout.Address.DefaultAddress.Label" />}
                    Component={Checkbox}
                    hideError
                    labelInline
                    labelLast
                />
            ) : null}
            {children}
        </>
    );
}

AlternateAddressForm.propTypes = {
    children: px.node,
    useDefaultCheck: px.bool,
    countryList: px.arrayOf(
        px.shape({ CountryCode: px.string, CountryName: px.string, PostalValidationRegex: px.string })
    ),
    postalRegex: px.object,
    regionOptions: px.object,
    onCountryChange: px.func,
    onRegionChange: px.func,
    onLocationChange: px.func,
    scope: px.string,
    usePlaceholders: px.bool,
    halfInputsBreakpoint: px.oneOf(['sm', 'md', 'lg', 'xl', false]),
};
