import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import styled from 'styled-components';
import get from 'lodash.get';
import * as types from 'Common/types';
import { PAYMENT, ADDRESS } from 'Common/constants/fields';
import { CreditCard, Formatter } from 'Common/utils';
import { Translation, Currency, ExpDate } from '../localization';
import { Icon } from '../ui';
import { useExpDateFormat } from 'Common/hooks/payment';

const Container = styled.div`
    position: relative;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    user-select: none;

    > .details {
        flex: 1 0 0%;
        padding-left: 0.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    > .default {
        position: absolute;
        top: 0.5em;
        left: 0.5em;
    }
`;

export default function PaymentBlock({
    savedPayment,
    payment: pmt = {},
    amount,
    currency,
    className,
    id,
    style,
    onClick,
    children,
    showDefault,
    paymentMethod,
    paymentType = paymentMethod && paymentMethod[PAYMENT.paymentType],
}) {
    const formatter = useExpDateFormat();

    const payment = React.useMemo(
        () =>
            savedPayment
                ? CreditCard.paymentToForm({
                      payment: savedPayment,
                      method: paymentMethod,
                      formatter,
                      savePayment: true,
                  })
                : pmt,
        [savedPayment, pmt, formatter, paymentMethod]
    );

    const type = React.useMemo(() => paymentType || (payment && payment[PAYMENT.paymentType]), [paymentType, payment]);
    const isCC = React.useMemo(() => type === 'CreditCard', [type]);
    const isGiftCard = React.useMemo(() => !!payment?.length, [payment]);

    const paymentMethodName = React.useMemo(
        () => payment?.PaymentMethodName?.substring(payment.PaymentMethodName.lastIndexOf('_') + 1),
        [payment]
    );

    const iconCls = React.useMemo(
        () =>
            isGiftCard
                ? null
                : CreditCard.getIcon((type === 'CreditCard' && payment[PAYMENT.cardType]) || type || paymentMethodName),
        [payment, isGiftCard, paymentMethodName, type]
    );

    const customerName = React.useMemo(
        () =>
            get(payment, PAYMENT.customerName) ||
            [
                get(payment, `${PAYMENT.address}.${ADDRESS.firstName}`, payment[ADDRESS.firstName]),
                get(payment, `${PAYMENT.address}.${ADDRESS.lastName}`, payment[ADDRESS.lastName]),
            ].join(' '),
        [payment]
    );

    const paymentNumber = React.useMemo(
        () =>
            isCC
                ? CreditCard.formatNumber(payment[PAYMENT.cardNumber], {
                      separator: '-',
                      issuer: payment[PAYMENT.cardType],
                  })
                : payment[PAYMENT.cardNumber],
        [payment, isCC]
    );

    return payment &&
        (!currency || (typeof amount === 'number' && amount > 0) || payment.Amount > 0 || payment[0]?.Amount > 0) ? (
        <Container
            className={cx('PaymentBlock', className)}
            id={id}
            style={style}
            onClick={onClick}
            isGiftCard={isGiftCard}
        >
            <div className="type">
                {!isGiftCard && iconCls ? (
                    <Icon key={payment[PAYMENT.cardType]} icon={iconCls} size="3rem" />
                ) : (
                    <p className="text-center p-1 font-weight-bold">
                        <Icon className="fas fa-credit-card" size="3rem" />
                        <br />
                        <Translation id="Commerce.Order.Checkout.PaymentMethod.GiftCard.Label" />
                    </p>
                )}
                {showDefault && payment[PAYMENT.isDefault] ? (
                    <p className="paragraph-3 mb-0 font-weight-bold text-center" style={{ marginTop: '-.5em' }}>
                        <Translation id="Commerce.Account.DefaultLabel" />
                    </p>
                ) : null}
            </div>
            <div className="details">
                {isGiftCard ? (
                    <>
                        {payment.map((p) => [
                            <div key={`${p[PAYMENT.cardNumber]}-card`} className="row col-12">
                                <p className="paragraph-3 col-4 p-0">{p[PAYMENT.cardNumber]}</p>
                                <p className="paragraph-3 col-8 text-right p-0">
                                    <Currency code={currency} amount={p.Amount * -1} />
                                </p>
                            </div>,
                            <div key={`${p[PAYMENT.cardNumber]}-balance`} className="row col-12">
                                <p className="paragraph-3 col-12 text-right p-0">
                                    <Translation
                                        id="Commerce.Order.Checkout.PaymentMethod.GiftCard.Remaining"
                                        params={{
                                            remaining: Formatter.currency(
                                                p.Balance > 0 ? p.Balance - p.Amount : p.Balance,
                                                currency
                                            ),
                                        }}
                                    />
                                </p>
                            </div>,
                        ])}
                    </>
                ) : (
                    <>
                        {isCC && customerName ? <p className="paragraph-3 mb-0">{customerName}</p> : null}
                        {isCC && payment.CardNumber ? <p className="paragraph-3 mb-0">{paymentNumber}</p> : null}
                        {isCC && payment.CardNumber ? (
                            <p className="paragraph-3">
                                <ExpDate
                                    month={payment[PAYMENT.expMonth] || 0}
                                    year={payment[PAYMENT.expYear] || 0}
                                    mask="X"
                                    forceMask={isGiftCard}
                                />
                            </p>
                        ) : null}
                        {currency && payment.Amount ? (
                            <p className="paragraph-3 mb-0">
                                <Currency code={currency} amount={payment.Amount} />
                            </p>
                        ) : null}
                    </>
                )}
            </div>
            {children}
        </Container>
    ) : null;
}

PaymentBlock.propTypes = {
    onClick: px.func,
    currency: px.string,
    payment: px.oneOfType([px.arrayOf(types.Payment), types.Payment]),
    savedPayment: types.Payment,
    className: px.string,
    amount: px.number,
    id: px.string,
    paymentType: px.string,
    children: px.node,
    style: px.oneOfType([px.string, px.objectOf(px.string)]),
    showDefault: px.bool,
    paymentMethod: types.PaymentMethod,
};
