import * as React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { EditProductView as ProductView } from 'Common/components/cart';
import { product } from '~features';

export default function EditProductView(props) {
    const productTitle = useSelector(product.selectors.getChildTitle);
    const priceInfo = useSelector(product.selectors.getCurrentPriceInfo);
    const productInfo = useSelector(product.selectors.getProduct);
    const productChildren = useSelector(product.selectors.getChildren);
    const currentItemStatus = useSelector(product.selectors.getCurrentItemStatus);
    const currentChildren = useSelector(product.selectors.getCurrentChildren);
    const selectedChild = useSelector(product.selectors.getSelectedChild);
    const facets = useSelector(product.selectors.getFacets);
    const disabledFacets = useSelector(product.selectors.getDisabledFacetValues);
    const onFacetSelect = useAction(product.actions.selectFacet);
    const onSelectChild = useAction(product.actions.selectChild);
    const clearFacets = useAction(product.actions.clearFacets);

    return (
        <ProductView
            {...props}
            productTitle={productTitle}
            priceInfo={priceInfo}
            productInfo={productInfo}
            productChildren={productChildren}
            selectedChild={selectedChild}
            currentItemStatus={currentItemStatus}
            currentChildren={currentChildren}
            facets={facets}
            clearFacets={clearFacets}
            disabledFacets={disabledFacets}
            onFacetSelect={onFacetSelect}
            onSelectChild={onSelectChild}
        />
    );
}
