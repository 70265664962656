import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

function Block({ c, h, w, mt }) {
    return <div className={cx('FakeBlock', c)} style={{ height: h, width: w, marginTop: mt }}></div>;
}

Block.propTypes = {
    c: px.string,
    h: px.oneOfType([px.string, px.number]),
    w: px.oneOfType([px.string, px.number]),
    mt: px.string,
};

export default function FakeQuickviewItem() {
    return (
        <div className="FakeProductView row px-0 px-md-3">
            <Block c="FakeImage col-12 col-lg-5" />
            <div className="FakeProductDetails px-0 px-md-3 col-12 col-lg-7">
                <div className="divider"></div>
                <Block c="mt-4 mt-md-0" h="4rem" />
                <Block h="3rem" mt="1rem" />
                <div className="row my-3 justify-content-center justify-content-md-start">
                    <div className="col-4 pr-0">
                        <Block h="2rem" />
                    </div>
                </div>
                <Block c="mt-3 px-0" h="3rem" mt="1rem" />
                <div className="row my-3 justify-content-center justify-content-md-start">
                    <div className="col-4 pr-0">
                        <Block h="2rem" />
                    </div>
                </div>
            </div>
        </div>
    );
}
