import GooglePayButton from '@google-pay/button-react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { logger } from 'Common/core';
import { Field } from 'Common/components/forms';
import { Translation } from 'Common/components/localization';
import { PAYMENT as FIELDS } from 'Common/constants/fields';

export default function SpreedlyGooglePayGateway({ payment, paymentAmount, currency, options }) {
    const [error, setError] = React.useState(undefined);

    const [paymentObject, setPaymentObject] = React.useState(
        payment?.PaymentType === 'GooglePay' ? payment : undefined
    );

    const setToken = React.useCallback(
        (token) => setPaymentObject({ ...paymentObject, PaymentType: 'GooglePay', Token: token }),
        [paymentObject]
    );

    return (
        <div className="paragraph-2 p-3">
            <Field required hidden name={FIELDS.token} value={paymentObject?.Token} validateOnValueChange />
            {error && (
                <div className="w-100 alert alert-danger mb-2">
                    <Translation id="Commerce.Order.Checkout.PaymentMethod.GooglePay.Error" />
                </div>
            )}
            {paymentObject?.Token ? ( //show the success message if we have the token (i.e. paid successfully via Google) and show the payment box if we don't
                <div>
                    <div className="w-100 alert alert-success mb-2">
                        <Translation id="Commerce.Order.Checkout.PaymentMethod.GooglePay.ThankYou" />
                    </div>
                    <button className="btn btn-primary" onClick={() => setToken(undefined)}>
                        <Translation id="Commerce.Order.Checkout.PaymentMethod.GooglePay.Reset" />
                    </button>
                </div>
            ) : (
                <div className="w-100">
                    <GooglePayButton
                        environment={options.TestMode == 'True' ? 'TEST' : 'PRODUCTION'}
                        paymentRequest={{
                            apiVersion: 2,
                            apiVersionMinor: 0,
                            allowedPaymentMethods: [
                                {
                                    type: 'CARD',
                                    parameters: {
                                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                        allowedCardNetworks: options.EnabledCardNetworks.split(','),
                                    },
                                    tokenizationSpecification: {
                                        type: 'PAYMENT_GATEWAY',
                                        parameters: {
                                            gateway: 'spreedly',
                                            gatewayMerchantId: options.GatewayMerchantId,
                                        },
                                    },
                                },
                            ],
                            merchantInfo: {
                                merchantId: options.MerchantId,
                                merchantName: options.MerchantName,
                            },
                            transactionInfo: {
                                totalPriceStatus: 'FINAL',
                                totalPriceLabel: 'Total',
                                totalPrice: paymentAmount.toString(),
                                currencyCode: currency,
                                countryCode: 'US',
                            },
                            //callbackIntents: ['PAYMENT_AUTHORIZATION'],
                        }}
                        //onPaymentAuthorized={(auth) => {
                        //    setToken(auth.paymentMethodData.tokenizationData.token);
                        //}}
                        onLoadPaymentData={(data) => {
                            setToken(data.paymentMethodData.tokenizationData.token);
                        }}
                        onError={(e) => {
                            logger.error(e);
                            setError(e);
                        }}
                    />
                </div>
            )}
        </div>
    );
}

SpreedlyGooglePayGateway.propTypes = {
    paymentAmount: PropTypes.number,
    payment: PropTypes.object,
    currency: PropTypes.string,
    options: PropTypes.shape({
        TestMode: PropTypes.oneOf(['True', 'False']),
        EnabledCardNetworks: PropTypes.string.isRequired,
        MerchantId: PropTypes.string.isRequired,
        MerchantName: PropTypes.string.isRequired,
        GatewayMerchantId: PropTypes.string.isRequired,
    }),
};
