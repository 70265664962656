import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

export default function ArrowButton({
    className,
    style,
    onClick,
    direction = 'left',
    children,
    iconClass = 'chevron',
    ariaLabel,
    ariaHidden,
}) {
    return (
        <button
            type="button"
            role="button"
            className={cx('ArrowButton', `${direction}-arrow`, className)}
            style={style}
            onClick={onClick}
            aria-label={ariaLabel}
            aria-hidden={ariaHidden}
        >
            {direction === 'right' ? children : null}
            <i className={`fas fa-${iconClass}-${direction}`} />
            {direction === 'right' ? children : null}
        </button>
    );
}

ArrowButton.propTypes = {
    children: px.node,
    className: px.string,
    style: px.objectOf(px.any),
    ariaLabel: px.string,
    onClick: px.func,
    ariaHidden: px.bool,
    iconClass: px.oneOf(['chevron', 'arrow']),
    direction: px.oneOf(['left', 'right']),
};
