import * as React from 'react';
import px from 'prop-types';
import { Product } from 'Common/types';
import { Url } from 'Common/utils';
import * as PRODUCT from '~config/product';
import ProductPrice from 'Common/components/product/ProductPrice';
import ProductRating from 'Common/components/product/ProductRating';
import { Translation } from 'Common/components/localization';
import { ProductInfo } from 'Common/models';

export default function RecommendedProductCard({ product, onOpenQuick, onSelectProduct, index, hasBorder=false }) {
    const productLink = React.useMemo(
        () => (product ? `${product.ContentUrl}?prid=${product.ProductRecommendationId.toString()}` : null),
        [product]
    );

    const productReviewProviderEntityId = React.useMemo(
        () => (product ? product.ReviewProviderEntityId : null),
        [product]
    );

    const productUrlIsForSameSite = React.useMemo(
        () =>
            productLink ? (Url.isAbsolute(productLink) ? new URL(productLink).host === location.host : true) : false,
        [productLink]
    );

    const anchorTarget = React.useMemo(() => (productUrlIsForSameSite ? '_self' : '_blank'), [productUrlIsForSameSite]);
    const anchorRel = React.useMemo(
        () => (productUrlIsForSameSite ? undefined : 'noopener noreferrer'),
        [productUrlIsForSameSite]
    );

    const isDisplayOnly = ProductInfo.isDisplayOnly(product)

    return (
        <div className={hasBorder ? "ProductRecommendations__Product__related" : "ProductRecommendations__Product px-2"}>
            <div className="d-flex flex-column flex-flow-wrap justify-content-center h-100">
                <a
                    href={productLink}
                    target={anchorTarget}
                    rel={anchorRel}
                    onClick={onSelectProduct(product, index)}
                    className="subheader px-2 py-3"
                    aria-label={product.DisplayName}
                >
                    <img
                        className="ProductRecommendations__Product__image fit-image-main"
                        src={`${product.DefaultImageUrl}?format=png&height=250&width=381${
                            PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                        }`}
                        alt={product.DisplayName}
                    />
                </a>
                <div className="ProductRecommendations__Product__rating">
                    <div className='d-flex justify-content-center'>
                        <ProductRating
                            rating={product.Rating}
                            reviewProviderEntityId={productReviewProviderEntityId}
                            center
                            slimView
                        />
                    </div>
                </div>
                <div className="ProductRecommendations__Product__content">
                    <div className='d-flex'>
                        <a
                            href={productLink}
                            target={anchorTarget}
                            rel={anchorRel}
                            onClick={onSelectProduct(product, index)}
                            className={hasBorder ? "item-name" : "subheader px-2 py-3"}
                        >
                            {product.DisplayName}
                        </a>
                    </div>
                    {isDisplayOnly ? null : (
                        <div className='d-flex'>
                            <ProductPrice
                                className="pt-2 pb-3 font-weight-bolder"
                                priceInfo={{ price: product.Price, useQtySalePrice: false }}
                                quantity={1}
                            />
                        </div>
                        ) 
                    }
                    <div className="ProductRecommendations__Product__quickview p-0">
                        <p onClick={onOpenQuick(product)} className={hasBorder ? "quick-view-link" : "paragraph-3 p-0 font-weight-bold"}>
                            <i className="fas fa-eye mr-1" aria-hidden="true" />
                            <Translation
                                id={
                                    ProductInfo.isDisplayOnly(product)
                                        ? 'Commerce.CatalogNode.Card.DisplayOnlyQuickView'
                                        : 'Commerce.CatalogNode.Card.QuickView'
                                }
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

RecommendedProductCard.propTypes = {
    product: Product,
    onOpenQuick: px.func,
    onSelectProduct: px.func,
    index: px.number,
    hasBorder: px.bool
};
