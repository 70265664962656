import { useEffect, useRef } from 'react';
import px from 'prop-types';
import { useDispatch } from 'react-redux';

export default function createStateLoader({ setState, clearState } = {}) {
    let hasLoaded = false;

    function StateLoader({ initialState }) {
        const dispatchRef = useRef(useDispatch());

        useEffect(() => {
            const dispatch = dispatchRef.current;

            if (!hasLoaded) {
                hasLoaded = true;
                dispatch(setState(initialState));
                return () => {
                    if (clearState) dispatch(clearState());
                };
            }
            return () => {};
        }, [initialState]);

        return null;
    }

    StateLoader.propTypes = {
        initialState: px.oneOfType([px.any, px.arrayOf(px.objectOf(px.any))]),
    };

    return StateLoader;
}
