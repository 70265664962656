import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Stack, Panel, PanelSection } from 'Common/components/ui';
import { Translation } from 'Common/components/localization';

/**
 * Displays link sections configured in cms using the Models specified in the backend.
 * Also displays recentSearches as stored in cookies.
 */
export default function SearchDrawerLinks({ className, style, linkSections, recentSearches }) {
    return (
        <Panel style={style} className={cx('SearchDrawerLinks', className)}>
            {recentSearches?.length ? (
                <Stack gap={2} dividers>
                    <div className="SearchDrawerLinks__recents--title">
                        <Translation id="Header.RecentSearches" />
                    </div>
                    <PanelSection>
                        {recentSearches.map((search) => (
                            <a className="SearchDrawerLinks__recents--link" key={search.title} href={search.link}>
                                {search.title}
                            </a>
                        ))}
                    </PanelSection>
                </Stack>
            ) : null}
            {linkSections?.map((link) => (
                <Stack key={link.Title} dividers gap={2}>
                    <div className="SearchDrawerLinks__links--title">{link.Title}</div>
                    <PanelSection className="SearchDrawerLinks__links">
                        {link.Links?.map((l) => (
                            <a key={l.Href} href={l.Href} className="SearchDrawerLinks__links--href">
                                {l.Title}
                            </a>
                        ))}
                    </PanelSection>
                </Stack>
            ))}
        </Panel>
    );
}

SearchDrawerLinks.propTypes = {
    className: px.string,
    style: px.string,
    linkSections: px.arrayOf(
        px.shape({
            Title: px.string,
            Links: px.arrayOf(
                px.shape({
                    Href: px.string,
                    Title: px.string,
                })
            ),
        })
    ),
    recentSearches: px.arrayOf(px.shape({ title: px.string, link: px.string })),
};
