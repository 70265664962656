import * as React from 'react';
import $ from 'jquery';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import * as types from 'Common/types';
import { useTranslation } from 'Common/hooks';
import Catalog from 'Common/features/catalog';
import { ReduxReducer } from 'Common/utils';
import * as CatalogTypes from 'Common/features/catalog/types';
import { Selector } from '../ui';
import { Translation } from '../localization';

const catalogReducer = ReduxReducer.create(Catalog.reducers);

export default function MobileFiltersMenu({
    id: idProp = `mfm_${v4()}`,
    className,
    style,
    facets = [],
    onApplyFilters,
    open = true,
    onCancel,
    closeOnApply = false,
    fitToParent = false,
    categoryIcon = <i className="fas fa-caret-right" />,
}) {
    const id = React.useRef(idProp);
    const [selectedFacet, setSelectedFacet] = React.useState(null);
    const [tempState, setTempState] = React.useState({ filteredItems: { Facets: facets } });
    const lblClose = useTranslation('Commerce.CatalogNode.Filters.CloseMenu.Label');
    const onSelectFacet = React.useCallback((facet) => setSelectedFacet(facet), []);
    const onSelectFacetValue = React.useCallback(
        (facet, value) => {
            setTempState(
                catalogReducer(tempState, {
                    type: facet.SelectorType.includes('multi')
                        ? CatalogTypes.SELECT_MULTI_FACET
                        : CatalogTypes.SELECT_FACET,
                    payload: { facet, value },
                })
            );
        },
        [tempState]
    );

    const styles = React.useMemo(() => {
        let s = {};

        if (fitToParent) {
            s = {
                position: 'relative',
                height: '100%',
                width: '100%',
            };
        }

        return { ...s, ...style };
    }, [fitToParent, style]);

    const onApply = React.useCallback(() => {
        if (onApplyFilters && tempState.dirty) {
            onApplyFilters(tempState.filteredItems.Facets);
            if (closeOnApply) onCancel();
        }
    }, [tempState, onApplyFilters, closeOnApply, onCancel]);

    const listItems = React.useMemo(() => {
        const selected = selectedFacet
            ? tempState.filteredItems.Facets.find((f) => f.DisplayName === selectedFacet)
            : null;

        return selected
            ? selected.Values.map((v) => ({
                  type: selected.SelectorType,
                  label: v.Label ?? v.Value,
                  selected: v.Selected,
                  onSelect: () => onSelectFacetValue(selected, v),
              }))
            : tempState.filteredItems.Facets.map((F) => ({
                  type: 'button',
                  rightIcon: categoryIcon,
                  label: F.DisplayName,
                  onSelect: () => onSelectFacet(F.DisplayName),
              }));
    }, [tempState, selectedFacet, onSelectFacetValue, categoryIcon, onSelectFacet]);

    React.useEffect(() => {
        return () => {
            setSelectedFacet(null);
            $(document.body).height('auto');
        };
    }, []);

    React.useEffect(() => {
        if (!open) {
            setSelectedFacet(null);
            $(document.body).height('auto');
        } else {
            $(document.body).height(open);
        }
    }, [open]);

    React.useEffect(() => {
        setTempState({ filteredItems: { Facets: facets } });
    }, [facets]);

    return (
        <div id={id.current} className={cx('MobileFiltersMenu', open ? 'shown' : 'hidden', className)} style={styles}>
            <div className="MobileFiltersMenu__toolbar">
                {selectedFacet ? (
                    <button className="MobileFiltersMenu__btnBack btn btn-link" onClick={() => onSelectFacet(null)}>
                        <i className="fas fa-caret-left" aria-hidden="true" />
                        <span>
                            <Translation id="Commerce.CatalogNode.Filters.MobileFilterBy" />
                        </span>
                    </button>
                ) : (
                    <div />
                )}
                <button
                    className="MobileFiltersMenu__btnClose btn"
                    aria-label={lblClose}
                    title={lblClose}
                    onClick={onCancel}
                >
                    <i className="fas fa-times" />
                </button>
            </div>
            <div className="MobileFiltersMenu__list">
                <h2 className="MobileFiltersMenu__list__head">
                    {selectedFacet || <Translation id="Commerce.CatalogNode.Filters.MobileFilterBy" />}
                </h2>
                <ul className="list-group">
                    {listItems.map((item) => (
                        <li key={`${id.current}-${item.label}`} role="button" className="list-group-item">
                            <Selector {...item} />
                        </li>
                    ))}
                </ul>
                {selectedFacet || tempState.dirty ? (
                    <button
                        className="MobileFiltersMenu__btnApply btn btn-primary"
                        onClick={onApply}
                        disabled={!tempState.dirty}
                    >
                        <span>
                            <Translation id="Commerce.CatalogNode.Filters.ApplyFilters" />
                        </span>
                    </button>
                ) : null}
            </div>
        </div>
    );
}

MobileFiltersMenu.propTypes = {
    className: px.string,
    closeOnApply: px.bool,
    open: px.bool,
    facets: px.arrayOf(types.Facet),
    onApplyFilters: px.func,
    onCancel: px.func,
    categoryIcon: px.node,
    properties: px.objectOf(px.string),
    id: px.string,
    style: px.object,
    fitToParent: px.bool,
};
