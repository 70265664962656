export default function toggleFacetValue(index, facet) {
    const option = facet.options[index];

    if (option == null) return facet;

    const options = facet.options.map((x, i) =>
        i === index
            ? { ...x, selected: !x.selected }
            : !facet.canSelectMultiple && x.selected
            ? { ...x, selected: false }
            : x
    );

    return { ...facet, options };
}
