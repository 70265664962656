import * as React from 'react';
import px from 'prop-types';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { RMARequest as Request } from 'Common/components/customer';
import { customer } from '~features';

export default function RMARequest({ className, rmaReasonCodes, successContentId }) {
    const dispatch = useDispatch();
    const orderDetail = useSelector(customer.selectors.getOrderDetail);
    const onSubmitReturn = (returnRequest) => dispatch(customer.actions.submitReturnRequest(returnRequest));
    const rmaRequestSuccess = useSelector(customer.selectors.getCustomerRmaRequestSuccess);
    const rmaRequestMessage = useSelector(customer.selectors.getCustomerRmaRequestMessage);
    const customerOrderLastUpdate = useSelector(customer.selectors.getCustomerOrderLastUpdate);

    React.useEffect(() => {
        if (rmaRequestSuccess && successContentId) {
            $(`#${successContentId}`).removeClass('d-none');
        }
    }, [rmaRequestSuccess, successContentId]);

    return !rmaRequestSuccess ? (
        <Request
            className={className}
            customerOrderLastUpdate={customerOrderLastUpdate}
            orderDetail={orderDetail}
            onSubmitReturn={onSubmitReturn}
            rmaReasonCodes={rmaReasonCodes}
            rmaRequestSuccess={rmaRequestSuccess}
        />
    ) : (
        <p className="rma-success-message">{rmaRequestMessage}</p>
    );
}

RMARequest.propTypes = {
    className: px.string,
    rmaReasonCodes: px.arrayOf(
        px.shape({
            Disabled: px.bool,
            Group: px.object,
            Selected: px.bool,
            Text: px.string,
            Value: px.string,
        })
    ),
    successContentId: px.string,
};
