import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import styled from 'styled-components';
import { useViewport } from 'Common/hooks';
import { LinkItem } from 'Common/components/ui';
import { LinkItem as LinkItemType } from 'Common/types';

const Container = styled.div`
    align-items: ${({ HorizontalAlign }) => {
        switch (HorizontalAlign) {
            case 'right':
                return 'flex-end';
            case 'middle':
            case 'center':
                return 'center';
            default:
                return 'flex-start';
        }
    }};
    text-align: ${({ HorizontalAlign }) => HorizontalAlign};
    justify-content: ${({ VerticalAlign }) => {
        switch (VerticalAlign) {
            case 'bottom':
                return 'flex-end';
            case 'middle':
            case 'center':
                return 'center';
            case 'span':
            case 'stretch':
                return 'space-between';
            default:
                return 'flex-start';
        }
    }};
    background-image: ${({ ImageUrl }) => `url(${ImageUrl})`};
    background-position: center center;
    background-repeat: no-repeat;
    background-size: ${({ ImageResize }) => {
        switch (ImageResize) {
            case 'fit':
            case 'contain':
                return 'contain';
            case 'cover':
                return 'cover';
            case 'stretch':
                return '100% 100%';
            default:
                return 'auto auto';
        }
    }};
`;

function goto(url) {
    return url ? () => (window.location.href = url) : undefined;
}

export default function Default({
    className,
    model: {
        SubTitle,
        Title = 'Call To Action',
        Details,
        HorizontalAlign = 'center',
        MobileHorizontalAlign,
        VerticalAlign = 'center',
        MobileVerticalAlign,
        ImageUrl,
        ImageResize = 'cover',
        ButtonText,
        ButtonIcon,
        ButtonIconPosition = 'last',
        TextColor = 'light',
        LinkUrl,
        LinkItem: Link,
    } = {},
}) {
    const viewport = useViewport();
    const vAlign = React.useMemo(() => (viewport.is.mobile && MobileVerticalAlign) || VerticalAlign, [
        viewport,
        MobileVerticalAlign,
        VerticalAlign,
    ]);

    const hAlign = React.useMemo(() => (viewport.is.mobile && MobileHorizontalAlign) || HorizontalAlign, [
        viewport,
        MobileHorizontalAlign,
        HorizontalAlign,
    ]);

    const link = React.useMemo(() => Link || { href: LinkUrl || '#' }, [Link, LinkUrl]);

    return (
        <Container
            className={cx('DefaultCatalogCTA', className, `text-color-${TextColor}`)}
            role={LinkUrl && !ButtonText ? 'button' : 'card'}
            HorizontalAlign={hAlign}
            VerticalAlign={vAlign}
            ImageResize={ImageResize}
            ImageUrl={ImageUrl}
            onClick={goto(!ButtonText && LinkUrl)}
        >
            {SubTitle ? <h6>{SubTitle}</h6> : null}
            {Title ? <h3>{Title}</h3> : null}
            {Details ? <p className="paragraph-2">{Details}</p> : null}
            {ButtonText || ButtonIcon ? (
                <LinkItem role="button" className="btn btn-md btn-primary" link={link}>
                    {ButtonIcon && ButtonIconPosition?.toLowerCase() === 'first' ? (
                        <i className={ButtonIcon} aria-hidden="true" />
                    ) : null}
                    <span>{ButtonText}</span>
                    {ButtonIcon && ButtonIconPosition?.toLowerCase() === 'last' ? (
                        <i className={ButtonIcon} aria-hidden="true" />
                    ) : null}
                </LinkItem>
            ) : null}
        </Container>
    );
}

Default.propTypes = {
    className: px.string,
    model: px.shape({
        SubTitle: px.string,
        Title: px.string,
        Details: px.string,
        HorizontalAlign: px.string,
        VerticalAlign: px.string,
        MobileHorizontalAlign: px.string,
        MobileVerticalAlign: px.string,
        ImageUrl: px.string,
        ImageFormat: px.string,
        ButtonText: px.string,
        ButtonIcon: px.string,
        ButtonIconOrder: px.string,
        TextColor: px.string,
        LinkUrl: px.string,
        LinkItem: LinkItemType,
    }),
};
