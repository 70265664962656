import px from 'prop-types';

export const SavedPaymentShape = {
    CardExpirationMonth: px.string,
    CardExpirationYear: px.string,
    CardLastFourDigits: px.string,
    CardNumber: px.string,
    CardSecurityCode: px.string,
    CardType: px.string,
    City: px.string,
    CountryCode: px.string,
    CountryName: px.string,
    CustomerName: px.string,
    DaytimePhoneNumber: px.string,
    Email: px.string,
    FirstName: px.string,
    IsDefault: px.string,
    LastName: px.string,
    Line1: px.string,
    Line2: px.string,
    PostalCode: px.string,
    RegionCode: px.string,
    RegionName: px.string,
    Token: px.string,
};

export default px.shape(SavedPaymentShape);
