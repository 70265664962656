import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Icon } from 'Common/components/ui';
import { useTranslation, useWindowResize } from 'Common/hooks';
import $ from 'jquery';

const TAB_POS = {
    vert: 'vertical',
    horiz: 'horizontal',
};

/**
 * ID of each tab must corrilate with the ID of it's indended content (in children).
 */
export default function TabbedContent({
    className,
    style,
    tabs,
    children,
    onSelectTab,
    tabLayout = TAB_POS.horiz,
    tabGap = 1,
    tabScroll = false,
}) {
    const [canScroll, setCanScroll] = React.useState({ left: false, right: false });
    const leftLbl = useTranslation('TabbedContent.Navigation.Button.Left.Label');
    const rightLbl = useTranslation('TabbedContent.Navigation.Button.Right.Label');
    const tabsRef = React.useRef();
    const showScrollBtn = React.useMemo(() => tabScroll && tabLayout === TAB_POS.horiz, [tabLayout, tabScroll]);
    const selectedContentId = React.useMemo(() => tabs.find((t) => t.selected)?.contentId, [tabs]);
    const onClick = React.useCallback(
        (tab) => () => {
            onSelectTab(tab);
        },
        [onSelectTab]
    );

    /**
     * determines the arrow direction clicked based on parameter to then add 1 or -1 to the currently selected tab index.
     */
    const onArrowClick = React.useCallback(
        (dir) => () => {
            const indxChange = dir === 'left' ? -1 : 1;
            const selectedIdx = tabs.findIndex((t) => t.selected);
            const newIdx = selectedIdx + indxChange;

            if (newIdx > -1 && newIdx < tabs.length) {
                scrollToIndex(newIdx);
                onSelectTab(tabs.find((_, i) => i === newIdx));
            } else {
                scrollToIndex(selectedIdx);
            }
        },
        [onSelectTab, scrollToIndex, tabs]
    );

    const scrollToIndex = React.useCallback((idx) => {
        const el = tabsRef.current.children[idx];

        $(tabsRef.current).animate({ scrollLeft: el.offsetLeft + el.offsetWidth }, 350);
    }, []);

    /**
     * determines to show our left and or right scroll buttons based on current scroll state of tabs (scrollWidth and scrollLeft).
     */
    const computeCanScroll = React.useCallback(() => {
        if (tabsRef.current.scrollWidth > tabsRef.current.offsetWidth) {
            const firstItem = tabsRef.current.children.item(1); // 1 to skip the arrow button

            setCanScroll({
                left: tabsRef.current.scrollLeft > firstItem.offsetWidth / 2,
                right: tabsRef.current.scrollWidth - (tabsRef.current.offsetWidth + tabsRef.current.scrollLeft) > 0,
            });
        } else {
            setCanScroll({ left: false, right: false });
        }
    }, []);

    useWindowResize(computeCanScroll, [computeCanScroll]);
    React.useEffect(() => {
        computeCanScroll();

        tabsRef.current.addEventListener('scroll', computeCanScroll);
        return () => {
            window.removeEventListener('scroll', computeCanScroll);
        };
    }, [computeCanScroll]);

    return (
        <div style={style} className={cx(className, 'TabbedContent', `TabbedContent--layout-${tabLayout}`)}>
            <div className="TabbedContent__tabs--container">
                <div
                    ref={tabsRef}
                    draggable={tabScroll}
                    className={cx(
                        'TabbedContent__tabs',
                        `tabs--layout-${tabLayout}`,
                        tabScroll && 'tabs--scrollable',
                        `tabs--gap-${tabGap}`
                    )}
                >
                    <button
                        className={cx(
                            'TabbedContent__nav-left btn',
                            showScrollBtn && canScroll.left ? 'd-block' : 'd-none'
                        )}
                        onClick={onArrowClick('left')}
                        value="left"
                        aria-label={leftLbl}
                    >
                        <Icon icon="fas fa-chevron-left" />
                    </button>
                    {tabs?.map((tab) => (
                        <button
                            onClick={onClick(tab)}
                            className={cx(
                                'TabbedContent__tab btn',
                                selectedContentId === tab.contentId ? 'selected' : null
                            )}
                            key={tab.contentId}
                            id={`${tab.contentId}_Tab`}
                        >
                            {tab.tabContent}
                        </button>
                    ))}
                    <button
                        className={cx(
                            'TabbedContent__nav-right btn',
                            showScrollBtn && canScroll.right ? 'd-block' : 'd-none'
                        )}
                        onClick={onArrowClick('right')}
                        value="right"
                        aria-label={rightLbl}
                    >
                        <Icon icon="fas fa-chevron-right" />
                    </button>
                </div>
            </div>
            <div className={cx('TabbedContent__contents')}>
                {React.Children.map(children, (child) => (child.props.id === selectedContentId ? child : null))}
            </div>
        </div>
    );
}

TabbedContent.propTypes = {
    style: px.object,
    className: px.string,
    tabs: px.arrayOf(
        px.shape({ contentId: px.string.isRequired, tabContent: px.node, selected: px.bool, args: px.object })
    ),
    children: px.node,
    onSelectTab: px.func.isRequired,
    tabLayout: px.oneOf([TAB_POS.vert, TAB_POS.horiz]),
    tabGap: px.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
    tabScroll: px.bool,
};
