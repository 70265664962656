import React from 'react';
import px from 'prop-types';
import cx from 'classnames';

export default function PanelSection({ className, style, title, icon, iconPos, children }) {
    return (
        <div style={style} className={cx(className, 'PanelSection', `PanelSection--iconPos-${iconPos}`)}>
            {icon && <div className="PanelSection__icon">{icon}</div>}
            {title && <div className="PanelSection__title">{title}</div>}
            <div className="PanelSection__body">{children}</div>
        </div>
    );
}

PanelSection.propTypes = {
    style: px.object,
    className: px.string,
    title: px.node,
    icon: px.node,
    iconPos: px.oneOf(['left', 'right']),
    children: px.node.isRequired,
};
