import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Product } from 'Common/types';
import { ProductBuyInBulk, ProductFeatures, ProductSpecifications, ProductIncludes } from './TabContent';
import { Translation } from 'Common/components/localization';
import ContentSwitch from './ContentSwitch';

const TAB_NAME_LOCALIZATION = {
    Includes: 'Commerce.Product.Tabs.Includes.TabName',
    Description: 'Commerce.Product.Tabs.Description.TabName',
    Features: 'Commerce.Product.Tabs.Features.TabName',
    Specifications: 'Commerce.Product.Tabs.Specifications.TabName',
    BuyInBulk: 'Commerce.Product.Tabs.BuyInBulk.TabName',
};

export default function ProductTabContent({
    className,
    DescriptionTab,
    displayOnly,
    gtmListValue,
    isUserAuthenticated,
    onAddItemToCart,
    orderLastUpdate,
    pageId,
    productDefinitions,
    productInfo,
    specsTableBreakpoint,
    statusTooltip,
    tabContent,
    BuyInBulkTab = ProductBuyInBulk,
    FeaturesTab = ProductFeatures,
    SpecificationsTab = ProductSpecifications,
    IncludesTab = ProductIncludes,
    totalQty = 0,
}) {
    return (
        <div className={cx('ProductTabContent', className)}>
            <div className="container">
                <ul className="nav nav-tabs justify-content-center" id={`${pageId}_tabs`} role="tablist">
                    {tabContent.map((tab, index) => (
                        <li key={`${pageId}_tab_${tab.tab}`} className="nav-item">
                            <a
                                className={cx('nav-link', tab.tab, { active: index === 0 })}
                                id={`${pageId}_tab_${tab.tab}`}
                                data-toggle="tab"
                                href={`#${pageId}_content_${tab.tab}`}
                                role="tab"
                                aria-controls={`${pageId}_content_${tab.tab}`}
                                aria-selected="true"
                            >
                                <Translation id={TAB_NAME_LOCALIZATION[tab.tab]} />
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="tab-content" id={`${pageId}_content`}>
                    {tabContent.map((tab, index) => (
                        <ContentSwitch
                            BuyInBulkTab={BuyInBulkTab}
                            className={cx({ active: index === 0 })}
                            DescriptionTab={DescriptionTab}
                            displayOnly={displayOnly}
                            FeaturesTab={FeaturesTab}
                            gtmListValue={gtmListValue}
                            id={`${pageId}_content_${tab.tab}`}
                            IncludesTab={IncludesTab}
                            isUserAuthenticated={isUserAuthenticated}
                            statusTooltip={statusTooltip}
                            key={`${pageId}_content_${tab.tab}`}
                            onAddItemToCart={onAddItemToCart}
                            orderLastUpdate={orderLastUpdate}
                            productDefinitions={productDefinitions}
                            productInfo={productInfo}
                            SpecificationsTab={SpecificationsTab}
                            specsTableBreakpoint={specsTableBreakpoint}
                            tab={tab}
                            totalQty={totalQty}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

ProductTabContent.propTypes = {
    BuyInBulkTab: px.elementType,
    className: px.string,
    DescriptionTab: px.elementType,
    displayOnly: px.bool,
    FeaturesTab: px.elementType,
    gtmListValue: px.string,
    IncludesTab: px.elementType,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    onAddItemToCart: px.func,
    orderLastUpdate: px.number,
    pageId: px.string,
    productDefinitions: px.arrayOf(px.string),
    productInfo: Product,
    SpecificationsTab: px.elementType,
    specsTableBreakpoint: px.number,
    statusTooltip: px.oneOf([false, true, undefined, null, 'top', 'bottom', 'left']),
    tabContent: px.arrayOf(
        px.shape({
            tab: px.string,
            content: px.any,
            typeId: px.string,
            showAddToCart: px.bool,
        })
    ),
    totalQty: px.number,
};
