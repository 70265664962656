import React, { useCallback } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Icon } from 'Common/components/ui';

/**
 * Creates a form input, input clear button, and a loading indicator for searching on text change.
 */
export default function SearchBar({
    className,
    style,
    onClear,
    showLoader,
    onTextChange,
    query,
    onSubmit,
    placeholder,
}) {
    const handleChange = useCallback((e) => {
        onTextChange?.(e.currentTarget.value);
    }, [onTextChange]);

    return (
        <div style={style} className={cx(className, 'SearchBar')}>
            <form className="w-100" onSubmit={onSubmit}>
                <input
                    onChange={handleChange}
                    className="SearchBar__input col"
                    type="search"
                    name="SearchBar"
                    value={query ?? ''} //proptype warning otherwise
                    placeholder={placeholder}
                />
            </form>
            <div onClick={onClear} className="SearchBar__icon d-flex align-items-center">
                {showLoader ? <i aria-hidden="true" className="fas fa-spinner" /> : null}
                <Icon name="times" />
            </div>
        </div>
    );
}

SearchBar.propTypes = {
    style: px.object,
    className: px.string,
    onClear: px.func,
    onTextChange: px.func,
    onSubmit: px.func,
    query: px.string,
    showLoader: px.bool,
    placeholder: px.string,
};
