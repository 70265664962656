import { Location } from 'Common/utils';
import * as types from './types';

export default {
    [types.SET_MAP]: (state, payload) => ({ ...state, map: payload }),
    [types.SET_SEARCH]: (state, payload) => ({ ...state, search: payload }),
    [types.UPDATE_MAP]: (state, payload) => ({ ...state, map: { ...state.map, ...payload } }),
    [types.UPDATE_SEARCH]: (state, payload) => ({ ...state, search: { ...state.search, ...payload } }),
    [types.SET_QUERY]: (state, payload) => ({
        ...state,
        search: {
            ...state.search,
            query: payload,
        },
    }),
    [types.SET_RESPONSE]: (state, payload) => ({
        ...state,
        search: {
            ...state.search,
            response: {
                ...payload,
                Locations:
                    payload.Page === 1
                        ? payload.Locations.map(Location.convertResponseLocation)
                        : state.search.response.Locations.concat(
                              payload.Locations.map(Location.convertResponseLocation)
                          ),
            },
        },
    }),
};
