import { useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { product } from '~features';
import useAction from './useAction';
import { entityCache } from './useCatalogEntity';

export default function useCatalogEntity(lineItems = []) {
    const lastCodeStr = useRef('');
    const fetchProducts = useAction(product.actions.fetchProductDetails);
    const productMap = useSelector(product.selectors.getProductStore);
    const productMapRef = useRef(productMap ?? {});

    const catalogEntities = useMemo(() => lineItems.map(({ ParentCode }) => productMap[ParentCode]).filter(Boolean), [
        productMap,
        lineItems,
    ]);

    useEffect(() => {
        productMapRef.current = productMap ?? {};
    }, [productMap]);

    useEffect(() => {
        if (lineItems?.length) {
            const codes = [];
            let codeStr = '';

            for (const { ParentCode } of lineItems) {
                if (!productMapRef.current[ParentCode] && !entityCache[ParentCode]) codes.push(ParentCode);
            }

            codeStr = codes.join(',');
            if (codeStr && codeStr !== lastCodeStr.current) {
                lastCodeStr.current = codeStr;
                fetchProducts(codeStr);
            }
        }
    }, [lineItems, fetchProducts]);

    return catalogEntities;
}
