import * as React from 'react';
import { useSelector } from 'react-redux';
import { useAction, useAddressVerification } from 'Common/hooks';
import { CustomerAddressesGrid as Grid } from 'Common/components/customer';
import { customer } from '~features';
import CustomerError from './CustomerError';

export default function CustomerAddressesGrid(props) {
    const addresses = useSelector(customer.selectors.getCustomerAddresses);
    const onFetchAddresses = useAction(customer.actions.fetchAddresses);
    const onUpdateAddress = useAction(customer.actions.saveCustomerAddress);
    const onRemoveAddress = useAction(customer.actions.removeCustomerAddress);
    const setCustomerError = useAction(customer.actions.setCustomerError);
    const clearCustomerError = useAction(customer.actions.clearCustomerError);
    const { verification, onValidateShipment, onConfirmShipment, onCancelVerification } = useAddressVerification({
        onUpdate: onUpdateAddress,
    });

    return (
        <>
            <Grid
                {...props}
                addresses={addresses}
                onFetchAddresses={onFetchAddresses}
                onUpdateAddress={onUpdateAddress}
                onRemoveAddress={onRemoveAddress}
                setCustomerError={setCustomerError}
                clearCustomerError={clearCustomerError}
                verification={verification}
                onValidateShipment={onValidateShipment}
                onConfirmShipment={onConfirmShipment}
                onCancelVerification={onCancelVerification}
            />
            <CustomerError />
        </>
    );
}
