import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Translation } from 'Common/components/localization';

export default function RecaptchaDetails({ className }) {
    return (
        <p className={cx('RecaptchaDetails', className)}>
            <Translation id="Google.Recaptcha.Details" />{' '}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                <Translation id="Google.Recaptcha.PrivacyPolicy" />
            </a>
            {'/ '}
            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                <Translation id="Google.Recaptcha.TermsOfService" />
            </a>
        </p>
    );
}

RecaptchaDetails.propTypes = {
    className: px.string,
};
