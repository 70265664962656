import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import { useFormApi, useFormState } from 'informed';
import { Formatter } from 'Common/utils';
import { useTranslation } from 'Common/hooks';
import { Field } from 'Common/components/forms';
import { Translation, Currency } from 'Common/components/localization';
import { Loader } from 'Common/components/ui';
import { validation } from 'Common/constants';
import { PAYMENT, ADDRESS } from 'Common/constants/fields';

function filterCards(saved, cards) {
    return saved ? saved.filter((c) => !cards.find((p) => p.CardNumber === c.CardNumber)).map((p) => p.CardNumber) : [];
}

export function DefaultCards({ cards, onRemoveCard, currency }) {
    const lblRemove = useTranslation('Commerce.Order.RemoveGiftCard.Label');

    return (
        cards?.map((card) => (
            <div key={card.CardNumber} className="applied container mb-3">
                <div className="row d-flex align-items-center">
                    <div className="col-8 px-4">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <span>
                                    <button
                                        onClick={onRemoveCard(card.PaymentMethodId, card.CardNumber)}
                                        className="btn p-0 mr-1"
                                        title={lblRemove}
                                        aria-label={lblRemove}
                                    >
                                        <i className="fa fa-times-circle"></i>
                                    </button>
                                </span>
                                <span className="value paragraph-2">{card.CardNumber}</span>
                            </div>
                            <p className="paragraph-2 mb-0">
                                <Currency amount={card.Balance} code={currency} />
                            </p>
                        </div>
                    </div>
                    <div className="applied__label btn btn-sm col-4 px-0">
                        <span>
                            <i className="fa fa-check mr-1" aria-hidden="true"></i>
                        </span>
                        <span>
                            <Translation id="Commerce.Order.AddGiftCard.Applied.Label" />
                        </span>
                    </div>
                </div>
            </div>
        )) ?? null
    );
}

DefaultCards.propTypes = {
    onRemoveCard: px.func,
    cards: px.arrayOf(px.shape({ CardNumber: px.string, PaymentMethodId: px.string, Balance: px.number })),
    currency: px.string,
};

export default function GiftCard({
    className,
    currency,
    accountEmail,
    giftCards,
    onUpdate,
    GiftCards = DefaultCards,
    removePayment,
    savedGiftCards,
    noLoadingIndicator = false,
    errorMessage = '',
    id: idStr = `CheckoutGiftCard_${v4()}`,
}) {
    const id = React.useRef(idStr);
    const [availableCards, setAvailableCards] = React.useState(filterCards(savedGiftCards || [], giftCards || []));
    const placeholder = useTranslation('Commerce.Order.AddGiftCard.Input.PlaceHolder');
    const formApi = useFormApi();
    const state = useFormState();
    const [showSpinner, setShowSpinner] = React.useState(false);

    const [showError, setShowError] = React.useState(false);
    const spinWrapper = React.useCallback(
        (fn) => async (...args) => {
            setShowSpinner(true);

            const succeeded = await fn(...args);

            setShowSpinner(false);

            return succeeded;
        },
        []
    );

    React.useEffect(() => {
        setAvailableCards(filterCards(savedGiftCards, giftCards));
    }, [giftCards, savedGiftCards]);

    const clearNumber = React.useCallback(() => {
        formApi.setValue(PAYMENT.cardNumber, '');
    }, [formApi]);

    const onAddCard = React.useCallback(
        (cardNumber = formApi.getValue(PAYMENT.cardNumber)) =>
            cardNumber
                ? spinWrapper(async () => {
                      formApi.setError(PAYMENT.cardNumber, null);
                      await onUpdate({
                          ...formApi.getFormState().values,
                          [PAYMENT.cardNumber]: cardNumber,
                          [PAYMENT.paymentType]: 'GiftCard',
                          [PAYMENT.systemName]: 'GiftCard',
                          [PAYMENT.cardType]: 'GiftCard',
                          [PAYMENT.address]: {
                              [ADDRESS.email]: decodeURIComponent(accountEmail),
                          },
                      });
                      clearNumber();
                  })
                : () => null,
        [formApi, clearNumber, onUpdate, spinWrapper, accountEmail]
    );

    const handleKeyDown = React.useCallback(
        async (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                formApi.setError(PAYMENT.cardNumber, null);
                await onUpdate({
                    ...formApi.getFormState().values,
                    [PAYMENT.cardNumber]: formApi.getValue(PAYMENT.cardNumber),
                    [PAYMENT.paymentType]: 'GiftCard',
                    [PAYMENT.systemName]: 'GiftCard',
                    [PAYMENT.cardType]: 'GiftCard',
                    [PAYMENT.address]: {
                        [ADDRESS.email]: decodeURIComponent(accountEmail),
                    },
                });
                clearNumber();
            }
        },
        [formApi, clearNumber, accountEmail, onUpdate]
    );

    const onRemovePayment = React.useCallback(
        (method, number) => () =>
            spinWrapper((paymentMethodId, cardNumber) => removePayment(paymentMethodId, cardNumber))(method, number),
        [removePayment, spinWrapper]
    );

    React.useEffect(() => {
        if (errorMessage !== '') {
            setShowError(true);
            const handle = setTimeout(() => {
                setShowError(false);
            }, 5000);
            return () => {
                if (handle != null) clearTimeout(handle);
            };
        } else {
            setShowError(false); 
        }
    }, [errorMessage]);

    return (
        <div className={cx('GiftCardGateway pt-4', className)}>
            {showSpinner && !noLoadingIndicator ? <Loader /> : null}
            <Field required hidden name={PAYMENT.cardType} value="GiftCard" />
            <div className="subheader mb-1">
                <Translation id="Commerce.Order.AddGiftCard.Label" />
            </div>
            <GiftCards cards={giftCards} onRemoveCard={onRemovePayment} currency={currency} />
            <div className="apply container">
                <div className="row d-flex flex-row align-items-stretch">
                    <Field
                        hideError
                        placeholder={placeholder}
                        className="w-75"
                        name={PAYMENT.cardNumber}
                        onKeyDown={handleKeyDown}
                    />
                    <button
                        onClick={onAddCard()}
                        className="btn btn-sm btn-secondary w-25 px-0"
                        disabled={state.invalid || !state.values[PAYMENT.cardNumber]}
                    >
                        <Translation id="Commerce.Order.AddGiftCard.Button.Label" />
                    </button>
                </div>
                {showError ||
                (state?.errors &&
                    state.errors[PAYMENT.cardNumber] &&
                    state.errors[PAYMENT.cardNumber] !== validation.required) ? (
                    <div className="row">
                        <div className="px-3 py-2 mt-3 alert alert-danger">
                                <p className="paragraph-2 mb-0">
                                <Translation id={errorMessage || state.errors[PAYMENT.cardNumber]} />
                            </p>
                        </div>
                    </div>
                ) : null}
                {savedGiftCards?.length ? (
                    <div className="dropdown mt-2 row">
                        <div className="accordion w-100" id={`${id.current}_accordion`}>
                            <div className="card-header w-100" id={`${id.current}_giftcard_header`}>
                                <button
                                    className="btn d-flex justify-content-between align-items-center w-100"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#${id.current}_giftcard_drawer`}
                                    aria-expanded="false"
                                    aria-controls={`${id.current}_giftcard_drawer`}
                                >
                                    <p className="paragraph-2 m-0">
                                        <Translation id="Commerce.Order.AddGiftCard.Dropdown.Label" />
                                    </p>
                                    <i className="fa fa-chevron-down" aria-hidden="true" />
                                </button>
                            </div>
                            <div
                                id={`${id.current}_giftcard_drawer`}
                                className="collapse"
                                aria-labelledby={`${id.current}_giftcard_header`}
                                data-parent={`#${id.current}_accordion`}
                            >
                                <div className="drawer container">
                                    {savedGiftCards.map((giftCard) => (
                                        <div key={giftCard.CardNumber} className="col py-1">
                                            <button
                                                onClick={onAddCard(giftCard.CardNumber)}
                                                className="btn p-0 row w-100 d-flex justify-content-between"
                                                disabled={!availableCards.includes(giftCard.CardNumber)}
                                            >
                                                <p className="paragraph-2 m-0">{giftCard.CardNumber}</p>
                                                <p className="paragraph-2 text-right m-0">
                                                    <Translation
                                                        id="Commerce.Order.Checkout.PaymentMethod.GiftCard.Balance"
                                                        params={{
                                                            balance: Formatter.currency(giftCard.Amount, currency),
                                                        }}
                                                    />
                                                </p>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

GiftCard.propTypes = {
    className: px.string,
    currency: px.string,
    accountEmail: px.string,
    giftCards: px.arrayOf(px.any),
    onUpdate: px.func,
    removePayment: px.func,
    savedGiftCards: px.arrayOf(px.any),
    noLoadingIndicator: px.bool,
    id: px.string,
    GiftCards: px.elementType,
    errorMessage: px.string,
};
