import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import Icon from './Icon';
import { useTranslation } from 'Common/hooks';

export default function NumberInput({
    className,
    step = noDecimal ? 1 : undefined,
    forceStep,
    noDecimal,
    value,
    pattern = noDecimal ? /^\d*$/ : undefined,
    onChange,
    onBlur,
    min,
    max,
    useStepBtns = false,
    disabled = false,
    ...props
}) {
    const [inputValue, setInputValue] = React.useState(value);
    const subLbl = useTranslation('NumberInput.Button.Minus.Label');
    const addLbl = useTranslation('NumberInput.Button.Plus.Label');
    const inRange = React.useCallback(
        (v) => (typeof min === 'undefined' || v >= min) && (typeof max === 'undefined' || v <= max),
        [min, max]
    );

    const inStep = React.useCallback((v) => typeof step === 'undefined' || !forceStep || v % step === 0, [
        step,
        forceStep,
    ]);

    const clampRange = React.useCallback(
        (v) => {
            if (typeof min !== 'undefined' && v < min) return min;
            if (typeof max !== 'undefined' && v > max) return max;
            return v;
        },
        [min, max]
    );

    const clampStep = React.useCallback(
        (v) => {
            if (typeof step === 'undefined' || !forceStep) return v;
            return Math.round(v / step) * step;
        },
        [step, forceStep]
    );

    const onInputChange = React.useCallback(
        (e) => {
            if (!pattern || pattern.test(e.target.value)) {
                const num = Number(e.target.value);

                setInputValue(e.target.value);
                if (onChange && e.target.value && num.toString() === e.target.value && inRange(num) && inStep(num)) {
                    onChange(num);
                }
            }
        },
        [onChange, inRange, inStep, pattern]
    );

    const onStep = React.useCallback((mod) => () => onInputChange({ target: { value: `${value + mod}` } }), [
        value,
        onInputChange,
    ]);

    const onInputBlur = React.useCallback(
        (e) => {
            if (inputValue) {
                const num = clampRange(clampStep(Number(inputValue)));

                setInputValue(`${num}`);
                onChange(num);
            } else {
                setInputValue(`${value}`);
            }
            if (onBlur) onBlur(e);
        },
        [onBlur, value, inputValue, clampRange, clampStep, onChange]
    );

    React.useEffect(() => {
        setInputValue(`${value}`);
    }, [value]);

    return (
        <>
            {useStepBtns ? (
                <button
                    className="NumberInput-btn btn btn-sub"
                    onClick={onStep(-step)}
                    disabled={disabled || (typeof min === 'number' && value <= min)}
                    aria-label={subLbl}
                >
                    <Icon name="minus" />
                </button>
            ) : null}
            <input
                {...props}
                type="text"
                min={min}
                max={max}
                step={step}
                pattern={`${pattern}`}
                onChange={onInputChange}
                onBlur={onInputBlur}
                className={cx('NumberInput input', { buttons: useStepBtns }, className)}
                value={inputValue}
                inputMode="numeric"
                disabled={disabled}
            />
            {useStepBtns ? (
                <button
                    className="NumberInput-btn btn btn-add"
                    onClick={onStep(step)}
                    disabled={disabled || (typeof max === 'number' && value >= max)}
                    aria-label={addLbl}
                >
                    <Icon name="plus" />
                </button>
            ) : null}
        </>
    );
}

NumberInput.propTypes = {
    className: px.string,
    onChange: px.func,
    onBlur: px.func,
    min: px.number,
    max: px.number,
    value: px.oneOfType([px.string, px.number]),
    step: px.number,
    forceStep: px.bool,
    noDecimal: px.bool,
    pattern: px.object,
    useStepBtns: px.bool,
    disabled: px.bool,
};
