import { useMemo } from 'react';
import px from 'prop-types';
import { useExpDateFormat } from 'Common/hooks/payment';

export default function ExpDate({ year, month, defaultFormat = 'MM / YY', mask = 'X', forceMask = false }) {
    const formatter = useExpDateFormat(defaultFormat);
    const value = useMemo(
        () =>
            !year || !month || forceMask
                ? formatter.formatStr.replace(/[MY]/g, mask)
                : formatter.format(Number(month), Number(year)),
        [month, year, formatter, mask, forceMask]
    );

    return value;
}

ExpDate.propTypes = {
    year: px.oneOfType([px.number, px.string]),
    month: px.oneOfType([px.number, px.string]),
    defaultFormat: px.string,
    mask: px.string,
    forceMask: px.bool,
};
