import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Product } from 'Common/types';
import { Translation } from 'Common/components/localization';
import ProductGridCell from './ProductGridCell';

const VAR_CLS = 'col-12 col-md-6 col-xl-4';

export default function ProductGridView({
    variants = [],
    onAddToCart,
    title,
    className,
    gtmListValue,
    checkoutPageLink,
    isUserAuthenticated,
    inputReverseButtonOrder,
    inputNumInputButtons,
    resetQtyAfterAdd,
    inputStatusTooltip,
    NotifyMe,
    googleRecaptchaV3Sitekey,
    useRecaptcha,
    totalQty = 0,
}) {
    const props = React.useMemo(
        () => ({
            checkoutPageLink,
            isUserAuthenticated,
            gtmListValue,
            onAddToCart,
            inputReverseButtonOrder,
            inputNumInputButtons,
            resetQtyAfterAdd,
            inputStatusTooltip,
        }),
        [
            checkoutPageLink,
            isUserAuthenticated,
            gtmListValue,
            onAddToCart,
            inputReverseButtonOrder,
            inputNumInputButtons,
            resetQtyAfterAdd,
            inputStatusTooltip,
        ]
    );

    return (
        <div className={cx('ProductGridView row', className)}>
            {title ? (
                <div className="ProductGridView__title col-12">
                    <span>
                        <Translation id={title} />
                    </span>
                </div>
            ) : null}
            {variants.map((variant) => (
                <ProductGridCell
                    NotifyMe={NotifyMe}
                    notifyProps={{ googleRecaptchaV3Sitekey, useRecaptcha }}
                    className={VAR_CLS}
                    key={`grid__${variant.Code}`}
                    variant={variant}
                    totalQty={totalQty}
                    {...props}
                />
            ))}
        </div>
    );
}

ProductGridView.propTypes = {
    className: px.string,
    variants: px.arrayOf(Product),
    title: px.string,
    onAddToCart: px.func,
    gtmListValue: px.string,
    googleRecaptchaV3Sitekey: px.string,
    useRecaptcha: px.bool,
    checkoutPageLink: px.string,
    isUserAuthenticated: px.bool,
    inputReverseButtonOrder: px.bool,
    inputNumInputButtons: px.bool,
    resetQtyAfterAdd: px.bool,
    NotifyMe: px.elementType,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    totalQty: px.number,
};
