import { useCallback, useMemo, useState } from 'react';

export default function useLoading() {
    const [isLoading, setIsLoading] = useState(false);

    const load = useCallback(async (callback) => {
        setIsLoading(true);
        try {
            const result = await callback();

            return result;
        } finally {
            setIsLoading(false);
        }
    }, []);

    return useMemo(() => [isLoading, load], [isLoading, load]);
}
