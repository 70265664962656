import * as React from 'react';
import px from 'prop-types';
import { Translation } from 'Common/components/localization';
import { Social } from 'Common/components/ui';

export default function ProductShare({ siteId }) {
    return (
        <div className="col d-flex">
            <Translation id="Social.Share" />: 
            <div className='w-100 d-flex ml-1'>
                <span><Social.Share.Facebook className="" link={window.location} /></span>
                <span><Social.Share.Twitter className="" link={window.location} hashtags={siteId} /></span>
                <span><Social.Share.Email link={window.location} /></span>
            </div>
        </div>
    );
}

ProductShare.propTypes = {
    siteId: px.string,
};
