import px from 'prop-types';
import CartLineItem from './CartLineItem';
import Address from './Address';

export const CartShipmentShape = {
    ShipmentId: px.number,
    IsDigitalShipment: px.bool,
    IsGift: px.bool,
    OrderShipmentStatus: px.string,

    GiftMessage: px.string,
    ShippingMethodDeliveryInfo: px.string,
    ShippingMethodDescription: px.string,
    ShippingMethodDisplayName: px.string,
    ShippingMethodId: px.string,
    ShippingMethodName: px.string,

    HandlingTotal: px.number,
    ShippingCost: px.number,
    SubTotal: px.number,
    Total: px.number,
    ShippingTax: px.number,

    LineItems: px.arrayOf(CartLineItem),
    FauxLineItems: px.arrayOf(CartLineItem),
    ShipmentTrackingNumbers: px.arrayOf(px.shape({ TrackingNumber: px.string, TrackingUrl: px.string })),
    ShippingAddress: Address,
};

export default px.shape(CartShipmentShape);
