import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { MiniCart as Mini } from 'Common/components/cart';
import { order, customer } from '~features';
import { CART_LOCATION_HASH } from '~config/order';

const DEFAULT_PAGE = `#${CART_LOCATION_HASH}`;

export default function MiniCart({ opensCart = false, noFetch = false, cartPage, ...props }) {
    const loadedRef = React.useRef(false);
    const loadCart = useAction(order.actions.loadCart);
    const hasCart = useSelector(order.selectors.hasCart);
    const isUpdating = useSelector(order.selectors.isCartUpdating);
    const qty = useSelector(order.selectors.getTotalQuantity);
    const alertMessage = useSelector(order.selectors.getTranslatedHeaderUserMessage);
    const alertTimestamp = useSelector(order.selectors.getUserMessageTimestamp);
    const openCart = useAction(customer.actions.openCart);
    const onClick = React.useMemo(() => (opensCart ? openCart : undefined), [opensCart, openCart]);

    React.useEffect(() => {
        if (!isUpdating && !hasCart && !noFetch && !loadedRef.current) {
            loadedRef.current = true;
            loadCart();
        }
    }, [noFetch, hasCart, isUpdating, loadCart]);

    return (
        <Mini
            {...props}
            cartPage={cartPage || DEFAULT_PAGE}
            qty={qty}
            onClick={onClick}
            alertMessage={alertMessage}
            alertTimestamp={alertTimestamp}
        />
    );
}

MiniCart.propTypes = {
    cartPage: px.string,
    color: px.string,
    opensCart: px.bool,
    noFetch: px.bool,
};
