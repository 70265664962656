import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { createPortal } from 'react-dom';
import { getProduct } from 'Common/features/catalog/api';
import { useTranslation, useViewport } from 'Common/hooks';
import ProductPartialView from './ProductPartialView';

export default function ProductLinkQuickview({ code, linkText }) {
    const viewport = useViewport();
    const [showQuick, setShowQuick] = React.useState(false);
    const [productData, setProductData] = React.useState(null);
    const [y, setY] = React.useState(null);
    const [x, setX] = React.useState();
    const linkRef = React.useRef(null);
    const popupRef = React.useRef(null);
    const lblClose = useTranslation('Commerce.CatalogNode.QuickView.Close');
    const fetchProduct = React.useCallback(
        async (c) => {
            let response = await getProduct(c);

            if (response?.success) setProductData({ Product: response.products[0] });
        },
        [setProductData]
    );

    const containerStyle = React.useMemo(
        () => ({
            left: x,
            top: y,
        }),
        [x, y]
    );

    const reposition = React.useMemo(
        () =>
            debounce(() => {
                const el = linkRef.current;

                if (!el) return;
                const elRect = el.getBoundingClientRect();
                const bodyRect = document.body.getBoundingClientRect();
                const popupRect = popupRef.current?.getBoundingClientRect();

                elRect.top < screen.height / 2 || !popupRect
                    ? setY(`${elRect.bottom - bodyRect.top}px`)
                    : setY(`${elRect.bottom - bodyRect.top - popupRect.height - elRect.height}px`);
                const isFullScreen = viewport.is.lt('lg');
                const alignRight = screen.width - elRect.left < popupRect?.width / 2;
                const alignLeft = elRect.left < popupRect?.width / 2;

                setX(
                    isFullScreen
                        ? '0px'
                        : alignRight
                        ? `calc(100% - ${popupRect?.width}px)`
                        : alignLeft
                        ? `${elRect.left}px`
                        : `${elRect.left - popupRect.width / 2}px`
                );
            }),
        [viewport]
    );

    const onShowPopup = React.useCallback(() => {
        reposition();
        setShowQuick(true);
    }, [reposition]);

    React.useEffect(() => {
        if (popupRef.current) reposition();
    }, [reposition]);

    React.useEffect(() => {
        fetchProduct(code);
    }, [code, fetchProduct]);

    return (
        <span className="ProductLinkQuickview">
            <a ref={linkRef} className="wysiwyg-link" onClick={onShowPopup}>
                {linkText}
            </a>
            {productData
                ? createPortal(
                      <div
                          className={cx('ProductViewPopup popup', showQuick ? 'show' : 'hide')}
                          ref={popupRef}
                          style={containerStyle}
                      >
                          <button className="close-btn btn" onClick={() => setShowQuick(false)} aria-label={lblClose}>
                              <i className="fas fa-times-circle"></i>
                          </button>
                          <ProductPartialView isUserAuthenticated={true} productData={productData} />
                      </div>,
                      document.body
                  )
                : null}
        </span>
    );
}

ProductLinkQuickview.propTypes = {
    code: px.string,
    linkText: px.string,
};
