import * as React from 'react';
import px from 'prop-types';
import { order } from '~features';
import { useAction } from 'Common/hooks';

export default function OrderConfigLoader({ inPlaceAddToCart = false }) {
    const loadInPlaceAddToCartConfig = useAction(order.actions.loadInPlaceAddToCartConfig);

    React.useEffect(() => {
        if (inPlaceAddToCart) {
            loadInPlaceAddToCartConfig();
        }
    }, [inPlaceAddToCart, loadInPlaceAddToCartConfig]);

    return null;
}

OrderConfigLoader.propTypes = {
    inPlaceAddToCart: px.bool,
};
