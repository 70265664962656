import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Translation } from 'Common/components/localization';
import OrderDetail from './OrderDetail';
import { OrderNumber } from '../../utils';

export default function OrderLookup({
    orderEmailPlaceholder,
    orderEmailLabelText,
    orderNumberPlaceholder,
    orderNumber,
    orderEmail,
    buttonText,
    orderDetail,
    rmaPage,
    onSubmit,
    lastUpdate,
}) {
    const [dirty, setDirty] = React.useState(false);
    const [emailInput, setEmailInput] = React.useState(orderEmail);
    const [orderNumInput, setOrderNumInput] = React.useState(orderNumber);
    const [isLoading, setIsLoading] = React.useState(false);

    const onOrderNumChange = React.useCallback(
        (e) => {
            const orderNum = OrderNumber.cleanString(e.target.value.trim());

            setOrderNumInput(orderNum);
        },
        [setOrderNumInput]
    );

    const onFormSubmit = React.useCallback(
        (e) => {
            e.preventDefault();
            setIsLoading(true);
            setDirty(true);
            onSubmit(orderNumInput, emailInput);
        },
        [onSubmit, orderNumInput, emailInput]
    );

    React.useEffect(() => {
        setIsLoading(false);
    }, [lastUpdate]);

    return (
        <div className={cx('OrderLookup container p-0 d-flex flex-column align-items-center')}>
            <form
                className="row align-items-end justify-content-start justify-content-md-center"
                onSubmit={onFormSubmit}
            >
                <div className="col-12 col-md-4 p-0 pl-md-3">
                    <label className="w-50 pb-1 pb-lg-0 subheader">{orderEmailLabelText}</label>
                    <input
                        className="email w-100"
                        required
                        type="email"
                        value={emailInput}
                        onChange={(e) => setEmailInput(e.target.value)}
                        placeholder={orderEmailPlaceholder}
                    />
                </div>
                <div className="col-12 col-md-4 p-0 pl-md-3">
                    <input
                        className="w-100 orderNumber"
                        required
                        type="text"
                        value={orderNumInput}
                        onChange={onOrderNumChange}
                        placeholder={orderNumberPlaceholder}
                    />
                </div>
                <div className="col-12 col-md-4 col-xl-3 p-0 pl-md-3">
                    <button disabled={isLoading} type="submit" className="btn btn-primary btn-md">
                        {buttonText}
                    </button>
                </div>
            </form>
            {orderDetail ? (
                <OrderDetail
                    className="pt-5 w-100"
                    rmaPage={`${rmaPage}?orderNumber=${orderDetail.OMSOrderReference}&orderEmail=${orderDetail.OrderEmail}`}
                    orderDetail={orderDetail}
                />
            ) : null}
            {dirty && !isLoading && !orderDetail ? (
                <h5 className="not-found">
                    <Translation id="Commerce.Order.Lookup.NotFound.Message" />
                </h5>
            ) : null}
        </div>
    );
}

OrderLookup.propTypes = {
    orderDetail: px.object,
    orderEmailPlaceholder: px.string,
    orderEmailLabelText: px.string,
    orderNumberPlaceholder: px.string,
    orderNumber: px.string,
    orderEmail: px.string,
    buttonText: px.string,
    rmaPage: px.string,
    onSubmit: px.func,
    lastUpdate: px.number,
};
