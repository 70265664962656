import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import $ from 'jquery';
import { useTranslation } from 'Common/hooks';

export default function ToolTip({
    className,
    selector,
    tip,
    translationParams = null,
    children,
    disabled = false,
    forceShowTip = null,
    placement = 'top',
    trigger = 'click',
    cursor = 'default',
    ...props
}) {
    const id = React.useRef(`tip-${v4()}`);
    const translatedTip = useTranslation(tip, translationParams);
    const onClick = React.useMemo(
        () =>
            disabled || trigger !== 'click'
                ? null
                : (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                  },
        [disabled, trigger]
    );

    React.useEffect(() => {
        const el = $(selector || `#${id.current}`);

        el.tooltip({
            placement,
            trigger,
        });
        el.on('shown.bs.tooltip', () => {
            $('.tooltip').addClass(className);
        });
        el.on('hidden.bs.tooltip', () => {
            $('.tooltip').removeClass(className);
        });
        return () => el.tooltip('dispose');
    }, [className, selector, placement, trigger]);

    React.useEffect(() => {
        const el = $(selector || `#${id.current}`);

        if (selector) {
            const title = disabled && !forceShowTip ? null : translatedTip;

            el.attr('title', title);
            el.attr('data-original-title', title);
        }

        el.tooltip(disabled && !forceShowTip ? 'disable' : 'enable');
        if (typeof forceShowTip === 'boolean') {
            el.tooltip(forceShowTip ? 'show' : 'hide');
        }
    }, [disabled, forceShowTip, selector, translatedTip]);

    return (
        <div
            id={id.current}
            className={cx('ToolTip', className)}
            style={{ cursor }}
            role="button"
            data-toggle="tooltip"
            data-placement={placement}
            onClick={onClick}
            title={disabled && !forceShowTip ? null : translatedTip}
            data-original-title={disabled && !forceShowTip ? null : translatedTip}
            {...props}
        >
            {children}
        </div>
    );
}

ToolTip.propTypes = {
    className: px.string,
    selector: px.string,
    tip: px.node,
    children: px.node,
    disabled: px.bool,
    cursor: px.string,
    forceShowTip: px.bool,
    translationParams: px.objectOf(px.any),
    placement: px.string,
    trigger: px.string,
};
