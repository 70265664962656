import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { CatalogQuery as Query } from 'Common/components/catalog';
import { catalog } from '~features';

export default function CatalogQuery({ showPageInfo = true, ...props }) {
    const facets = useSelector(catalog.selectors.getFilteredFacets);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const onClearAll = useAction(catalog.actions.clearAllFacetFilters);
    const onClearFacetValue = useAction(catalog.actions.clearFacetValue);

    return (
        <Query
            {...props}
            pageInfo={pageInfo}
            facets={facets}
            onClearAll={onClearAll}
            onClearFacetValue={onClearFacetValue}
            showPageInfo={showPageInfo}
        />
    );
}
CatalogQuery.propTypes = {
    showPageInfo: px.bool,
};
