import { useEffect, useState } from 'react';

function nextUrl(urlParams) {
    const params = urlParams.toString();

    return params ? `${window.location.pathname}?${params}` : window.location.pathname;
}

export default function useUrlParam(paramName, consumeParam = false) {
    const [value, setValue] = useState(undefined);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const v = urlParams.get(paramName);

        if (v) {
            if (consumeParam) {
                urlParams.delete(paramName);
                window.history.replaceState({}, document.title, nextUrl(urlParams));
            }
            setValue(v);
        }
    }, [paramName, consumeParam]);

    return value;
}
