import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useViewport, useTranslation } from 'Common/hooks';
import { GTM } from 'Common/utils';
import { Translation } from '../localization';
import { CatalogQuickView } from '../catalog';
import CartLineItem from './CartLineItem';

export default function CartTable({
    className,
    Forms = [],
    qtyChanges = [],
    QuickView = CatalogQuickView,
    onChangeQty,
    onDelete,
    currency,
    onAddItemToCart,
    emptyLabel = 'Commerce.Order.Cart.EmptyCart.Label',
    isEditable = true,
    gtmListValue,
    statusTooltip,
    totalQty,
}) {
    const viewport = useViewport();
    const lblAdd = useTranslation('Commerce.Order.Cart.AddItem.Label');
    const lblEdit = useTranslation('Commerce.Order.Cart.EditItem.Label');
    const lblRemove = useTranslation('Commerce.Order.Cart.RemoveItem.Label');
    const [editingItem, setEditingItem] = React.useState(null);

    const itemCount = React.useMemo(
        () =>
            Forms.reduce(
                (acc, form) => acc + form.Shipments.reduce((sum, shipment) => sum + shipment.LineItems.length, 0),
                0
            ),
        [Forms]
    );

    const fauxItemCount = React.useMemo(
        () =>
            Forms.reduce(
                (acc, form) =>
                    acc + form.Shipments.reduce((sum, shipment) => sum + (shipment.FauxLineItems?.length || 0), 0),
                0
            ),
        [Forms]
    );

    const getQty = React.useCallback(
        (shipment, item) => {
            const change = qtyChanges.find((c) => c.shipmentId === shipment.ShipmentId && c.itemCode === item.Code);

            return change ? change.qty : item.Quantity;
        },
        [qtyChanges]
    );

    const hasChange = React.useCallback(
        (shipment, item) => {
            const change = qtyChanges.find((c) => c.shipmentId === shipment.ShipmentId && c.itemCode === item.Code);

            return change && change.qty !== item.Quantity;
        },
        [qtyChanges]
    );

    const addItem = React.useMemo(
        () =>
            onAddItemToCart
                ? (lineItem, productInfo, quantity, listValue) => async () => {
                      const gtmPayload = GTM.mapEntityToAddCart(
                          productInfo,
                          quantity,
                          productInfo.Attributes,
                          listValue,
                          totalQty + quantity
                      );

                      await onAddItemToCart(lineItem.Code, quantity, { gtmPayload });
                  }
                : null,
        [onAddItemToCart, totalQty]
    );

    return (
        <div className={cx('CartTable', className)}>
            {fauxItemCount ? (
                <>
                    <div className="row tablerow">
                        <a className="w-100 text-center" href="#fauxHeader">
                            <p className="my-2 alert popup-alert alert-danger">
                                <Translation id="Commerce.Order.Cart.OutOfStock.Header.Label" />
                            </p>
                        </a>
                    </div>
                </>
            ) : null}
            <div className="table container">
                <div className="row tablehead d-none d-sm-flex">
                    <div className="col-6">
                        <span>
                            <Translation id="Commerce.Order.Cart.Item.Label" />
                        </span>
                    </div>
                    <div className="col-2">
                        <span>
                            <Translation id="Commerce.Order.Cart.Price.Label" />
                        </span>
                    </div>
                    <div className="col-2 text-center">
                        <span>
                            <Translation id="Commerce.Order.Cart.Quantity.Label" />
                        </span>
                    </div>
                    <div className="col-2 text-right">
                        <span>
                            <Translation id="Commerce.Order.Cart.Subtotal.Label" />
                        </span>
                    </div>
                </div>
                {itemCount ? (
                    Forms.map((form) =>
                        form.Shipments.map((shipment) =>
                            shipment.LineItems.map((item) => (
                                <CartLineItem
                                    item={item}
                                    key={`cart-0-${shipment.ShipmentId}-${item.LineItemId}`}
                                    viewport={viewport}
                                    form={form}
                                    shipment={shipment}
                                    hasChange={hasChange}
                                    isEditable={isEditable}
                                    lblAdd={lblAdd}
                                    lblEdit={lblEdit}
                                    editingItem={editingItem}
                                    lblRemove={lblRemove}
                                    currency={currency}
                                    getQty={getQty}
                                    onChangeQty={onChangeQty}
                                    setEditingItem={setEditingItem}
                                    gtmListValue={gtmListValue}
                                    addItem={addItem}
                                    onDelete={onDelete}
                                    QuickView={QuickView}
                                    statusTooltip={statusTooltip}
                                />
                            ))
                        )
                    )
                ) : (
                    <div className="m-2">
                        <p>
                            <Translation id={emptyLabel} />
                        </p>
                    </div>
                )}
                {fauxItemCount ? (
                    <>
                        <div className="row tablerow faux-head">
                            <p className="w-100 text-center alert popup-alert alert-danger" id="fauxHeader">
                                <Translation id="Commerce.Order.Cart.OutOfStock.Label" />
                            </p>
                        </div>
                        {Forms.map((form) =>
                            form.Shipments.map((shipment) =>
                                shipment.FauxLineItems.map((item) => (
                                    <CartLineItem
                                        item={item}
                                        key={`cart-0-${shipment.ShipmentId}-${item.LineItemId}`}
                                        viewport={viewport}
                                        form={form}
                                        shipment={shipment}
                                        hasChange={hasChange}
                                        isEditable={isEditable}
                                        onAddItemToCart={onAddItemToCart}
                                        lblAdd={lblAdd}
                                        lblEdit={lblEdit}
                                        editingItem={editingItem}
                                        lblRemove={lblRemove}
                                        currency={currency}
                                        getQty={getQty}
                                        onChangeQty={onChangeQty}
                                        setEditingItem={setEditingItem}
                                        gtmListValue={gtmListValue}
                                        addItem={addItem}
                                        onDelete={onDelete}
                                        QuickView={QuickView}
                                        fauxQuantity={item.Quantity}
                                        OOS
                                        statusTooltip={statusTooltip}
                                    />
                                ))
                            )
                        )}
                    </>
                ) : null}
            </div>
        </div>
    );
}

CartTable.propTypes = {
    className: px.string,
    Forms: px.arrayOf(types.CartForm),
    onChangeQty: px.func,
    onDelete: px.func,
    currency: px.string,
    qtyChanges: px.arrayOf(px.any),
    onAddItemToCart: px.func,
    isEditable: px.bool,
    QuickView: px.elementType,
    emptyLabel: px.string,
    gtmListValue: px.string,
    statusTooltip: px.string,
    totalQty: px.number,
};
