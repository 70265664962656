import * as React from 'react';
import { useAction } from 'Common/hooks';
import { LanguageSelector as Selector } from 'Common/components/localization';
import { Loader } from 'Common/components/ui';
import { localization } from '~features';

export default function LanguageSelector(props) {
    const [loading, setLoading] = React.useState(false);
    const selectLanguage = useAction(localization.actions.selectLanguage);
    const onChange = React.useCallback(
        async (lang, setDefaultLanguage) => {
            setLoading(true);
            await selectLanguage(lang, setDefaultLanguage);
            setLoading(false);
        },
        [selectLanguage]
    );

    return (
        <>
            {loading ? <Loader /> : null}
            <Selector {...props} onChange={onChange} disabled={loading} />
        </>
    );
}

LanguageSelector.propTypes = Selector.propTypes;
