import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { LinkItem as LinkItemType } from 'Common/types';

export default function LinkItem({ link, className, children, ...props }) {
    const rel = React.useMemo(() => (link?.target === '_blank' ? 'noopener noreferrer' : ''), [link]);

    if (!link) return null;

    return (
        <a className={cx('LinkItem', className)} href={link.href} target={link.target} rel={rel} {...props}>
            {children}
        </a>
    );
}

LinkItem.propTypes = {
    className: px.string,
    link: LinkItemType,
    children: px.node,
};
