import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useTranslation } from 'Common/hooks';
import { MediaConverter } from 'Common/components/ui';

export default function CarouselItem({
    item,
    onClickGalleryImg,
    showGalleryMobile,
    isMobile,
    selected,
    imageResizeSearchQuery,
}) {
    const thumb = useTranslation('Commerce.Product.Gallery.SelectThumb.Label', item);

    return (
        <div className={cx('CarouselItem', { selected })}>
            <MediaConverter
                aria-label={thumb}
                media={item}
                onSelectPhoto={onClickGalleryImg(item)}
                onSelectSpin={onClickGalleryImg(item)}
                className="fit-image-nav img-fluid"
                imageResizeSearchQuery={imageResizeSearchQuery}
                renderVideoAsThumb={!isMobile || showGalleryMobile}
                renderSpinAsThumb
                autoPlayVideo
            />
        </div>
    );
}

CarouselItem.propTypes = {
    item: px.shape({
        MediaType: px.string,
        MediaGroup: px.string,
        Url: px.string,
        Thumbnail: px.string,
        FileType: px.string,
        Order: px.number,
        IsDefault: px.bool,
    }),
    selected: px.bool,
    onClickGalleryImg: px.func,
    showGalleryMobile: px.bool,
    isMobile: px.bool,
    imageResizeSearchQuery: px.string,
};
