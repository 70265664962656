import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import get from 'lodash.get';
import { CreditCard } from 'Common/utils';
import { Translation } from 'Common/components/localization';
import { PAYMENT } from 'Common/constants/fields';
import useCurrentIssuer from './useCurrentIssuer';

export const processor = CreditCard.currentProcessor?.name ?? CreditCard.currentProcessor?.processorName;

export default function useCreditCard({ scope, options, usePlaceholders }) {
    const prefix = useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const currentIssuer = useCurrentIssuer(scope);
    const numberMask = useMemo(() => CreditCard.getCardNumberFormat({ issuer: currentIssuer }), [currentIssuer]);
    const issuerRef = useRef(currentIssuer);

    const labelProps = useCallback(
        (id, params) =>
            usePlaceholders
                ? { placeholder: id, placeholderParams: params }
                : { label: <Translation id={id} params={params} /> },
        [usePlaceholders]
    );

    const validateCvv = useCallback(
        (value = '', values) => CreditCard.validateCVV(value, get(values, `${prefix}${PAYMENT.cardNumber}`, '')),
        [prefix]
    );

    const isProcessor = useCallback((name) => () => processor === name, []);

    useEffect(() => {
        CreditCard.setupPaymentProcessor(options);
        return () => CreditCard.tearDownPaymentProcessor(options);
    }, [options]);

    useEffect(() => {
        issuerRef.current = currentIssuer;
    }, [currentIssuer]);

    return { currentIssuer, prefix, validateCvv, isProcessor, labelProps, numberMask };
}
