import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useTranslation } from 'Common/hooks';
import { Translation } from 'Common/components/localization';
import VideoPlayer from './VideoPlayer';

export default function ProductVideo({ className, backgroundImage, videoUrl, title, isIOS = false }) {
    const [playVideo, setPlayVideo] = React.useState(false);
    const lblStop = useTranslation('Commerce.Product.Video.Stop.Label');
    const lblPlay = useTranslation('Commerce.Product.Video.Play.Label');

    return (
        <div
            className={cx('ProductVideo d-flex align-items-center justify-content-center', className)}
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                overflow: 'hidden',
            }}
        >
            {playVideo ? (
                <div className="container position-relative">
                    <button
                        onClick={() => setPlayVideo(false)}
                        className="btn close p-3"
                        aria-label={lblStop}
                        title={lblStop}
                    >
                        <i className="fa fa-times" />
                    </button>
                    {isIOS ? (
                        <VideoPlayer url={videoUrl} playing playsInline muted />
                    ) : (
                        <VideoPlayer url={videoUrl} playing />
                    )}
                </div>
            ) : (
                <div className="py-5 d-flex align-items-center justify-content-center">
                    <div className="text-center">
                        <h3>{title}</h3>
                        <h6 className="subheader">
                            <Translation id="Commerce.Product.Video.Subtitle" />
                        </h6>
                        <button
                            onClick={() => setPlayVideo(!playVideo)}
                            type="button"
                            className="btn"
                            data-toggle="modal"
                            data-target="#@(uid)_Modal"
                            aria-label={lblPlay}
                            title={lblPlay}
                        >
                            <i className="far fa-play-circle" />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

ProductVideo.propTypes = {
    className: px.string,
    videoUrl: px.string,
    backgroundImage: px.string,
    title: px.string,
    isIOS: px.bool,
};
