import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import { useAmazonPay } from 'Common/hooks/payment';
import { Translation } from 'Common/components/localization';
import { Spinner } from 'Common/components/ui';
import { Field } from 'Common/components/forms';
import { PAYMENT as FIELDS } from 'Common/constants/fields';

export default function AmazonPay({ id: idProp = `AmazonPay-${v4()}`, options, className }) {
    const id = React.useRef(idProp);
    const { logout, isLoading, isLoggedIn, orderId, btnId, widgetId } = useAmazonPay(id.current, options);

    return (
        <div className={cx('AmazonPayment paragraph-2 p-3', className)} id={id.current}>
            <Field required hidden name={FIELDS.token} value={orderId} validateOnValueChange />
            {isLoading ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <div id={btnId} className={!isLoggedIn ? 'd-block' : 'd-none'} />
            )}
            {isLoggedIn ? (
                <div>
                    <div style={{ height: '300px' }}>
                        <div id={widgetId} />
                    </div>
                    <div>
                        <button className="btn btn-secondary" onClick={logout}>
                            <Translation id="Commerce.Order.Checkout.PaymentMethod.AmazonPay.UseADifferentAccount" />
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

AmazonPay.propTypes = {
    id: px.string,
    className: px.string,
    options: px.shape({
        ClientId: px.string.isRequired,
        Env: px.string.isRequired,
        SellerId: px.string.isRequired,
    }).isRequired,
};
