import { ADDRESS } from 'Common/constants/fields';
import * as types from './types';

export default {
    [types.SET_CUSTOMER_MESSAGE]: (state, { message, success, type, ts, position, translationParams } = {}) => ({
        ...state,
        customerMessage: { message, success, type, ts, position, translationParams },
    }),
    [types.SET_CUSTOMER_ERROR]: (state, { message, type, ts, translationParams } = {}) => ({
        ...state,
        customerError: { message, type, ts, translationParams },
    }),
    [types.SET_USER_EMAIL]: (state, payload) => ({
        ...state,
        userEmail: payload || null,
    }),
    [types.SET_CUSTOMER_ADDRESSES]: (state, payload) => ({
        ...state,
        addresses: [...payload],
    }),

    [types.DELETE_CUSTOMER_ADDRESS]: (state, payload) => {
        const { [ADDRESS.defaultAddress]: isDefault } = state.addresses.find((a) => a.Id === payload);
        const addresses = state.addresses.filter((a) => a.Id !== payload);

        if (isDefault && addresses.length) addresses[0] = { ...addresses[0], [ADDRESS.defaultAddress]: true };

        return { ...state, addresses };
    },

    // gift cards

    [types.SET_CUSTOMER_GIFT_CARDS]: (state, payload) => ({
        ...state,
        giftCards: [...payload],
    }),

    [types.ADD_CUSTOMER_GIFT_CARD]: (state, { card } = {}) => {
        return {
            ...state,
            giftCards: [...state.giftCards, card],
        };
    },

    [types.DELETE_CUSTOMER_GIFT_CARD]: (state, payload) => ({
        ...state,
        giftCards: state.giftCards.filter((card) => card.CardNumber !== payload.id),
    }),

    // payment methods

    [types.SET_CUSTOMER_PAYMENT_METHODS]: (state, payload) => ({
        ...state,
        paymentMethods: [...payload],
    }),

    [types.DELETE_CUSTOMER_PAYMENT_METHOD]: (state, payload) => ({
        ...state,
        paymentMethods: state.paymentMethods.filter((card) => card.Id !== payload.id),
    }),

    [types.MARK_DEFAULT_CUSTOMER_PAYMENT_METHOD]: (state, payload) => {
        // get payment methods but without any defaults set

        const updatedPaymentMethod = state.paymentMethods.find((card) => card.Id === payload.id);
        const filteredPaymentMethods = state.paymentMethods.filter((card) => card.Id !== payload.id);

        return {
            ...state,
            paymentMethods: [
                ...filteredPaymentMethods,
                {
                    ...updatedPaymentMethod,
                    IsDefault: true,
                },
            ],
        };
    },

    // order
    [types.SET_CUSTOMER_ORDER_RMARETURNDATA]: (state, rmaReturnData) => ({
        ...state,
        customerOrders: { ...state.customerOrders, ...rmaReturnData, lastUpdate: Date.now() },
    }),
    [types.SET_CUSTOMER_ORDERS]: (state, orders) => ({
        ...state,
        customerOrders: { ...state.customerOrders, ...orders, lastUpdate: Date.now() },
    }),

    // notification subscription
    [types.SET_CUSTOMER_USER_NOTIFICATION_SUBSCRIPTIONS]: (state, payload) => ({
        ...state,
        userNotificationSubscriptions: [...payload],
    }),

    [types.REMOVE_CUSTOMER_USER_NOTIFICATION_SUBSCRIPTION]: (state, payload) => ({
        ...state,
        userNotificationSubscriptions: state.userNotificationSubscriptions.filter(
            (n) => n.NotificationUniqueId !== payload
        ),
    }),

    // Notifications
    [types.SET_CUSTOMER_NOTIFICATION]: (state, payload) => ({
        ...state,
        notifications: payload,
    }),

    // Mailchimp
    [types.SET_CUSTOMER_MAILCHIMP]: (state, payload) => ({
        ...state,
        mailchimp: payload,
    }),
};
