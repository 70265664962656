import { useState, useRef, useEffect } from 'react';
import { logger } from 'Common/core';
import { getCountrySelectionData } from 'Common/features/order/api';

const cache = { fetching: false, list: null };

export default function useCountryList({ siteId, enabledRef = null, enabled = !enabledRef }) {
    const ref = useRef(enabledRef);
    const [countryList, setCountryList] = useState(cache.list);

    const fetchCountries = useRef();

    useEffect(() => {
        fetchCountries.current = () => {
            if (!ref.current || (ref.current?.current && !cache.fetching)) {
                cache.fetching = true;
                getCountrySelectionData(siteId)
                    .then((list) => {
                        if (list || !cache.list) {
                            cache.list = list ?? [];
                            setCountryList(cache.list);
                        }
                    })
                    .catch((e) => {
                        logger.warn(`FetchCountries failed: ${e?.message || e}`);
                        if (!cache.list) {
                            cache.list = [];
                            setCountryList(cache.list);
                        }
                    })
                    .finally(() => {
                        cache.fetching = false;
                    });
            }
        };
    }, [siteId]);

    useEffect(() => {
        if (enabled && !countryList) {
            fetchCountries.current();
        }
    }, [enabled, countryList]);

    return countryList;
}
