import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import $ from 'jquery';
import { AlertTip } from '../ui';

export default function HeaderCustomerMessage({ className, message, timestamp, selector, placement = 'auto' }) {
    const ref = React.createRef();
    const [hideAlert, setHideAlert] = React.useState(false);

    const handleResize = React.useRef(() => {
        const position = $(ref.current).position();

        setHideAlert(!position?.left && !position?.top);
    });

    React.useLayoutEffect(() => {
        function handler() {
            handleResize.current();
        }

        window.addEventListener('resize', handler);
        handler();
        return () => window.removeEventListener('resize', handler);
    }, []);

    return (
        <AlertTip
            placement={placement}
            className={cx('HeaderCustomerMessage user-menu-tip', className)}
            message={message}
            timestamp={timestamp}
            hidden={hideAlert}
            selector={selector}
        >
            <div ref={ref} className="HeaderCustomerMessage" />
        </AlertTip>
    );
}

HeaderCustomerMessage.propTypes = {
    className: px.string,
    message: px.string,
    timestamp: px.number,
    selector: px.string,
    placement: px.string,
};
