import { useCallback, useReducer, useRef } from 'react';
import { ReduxFeature } from 'Common/utils';

export default function useLocalFeature(feature, featureName) {
    const nameRef = useRef(featureName);
    const [state, dispatch] = useReducer(ReduxFeature.getReducer(feature), feature.initialState);
    const localSelector = useCallback((fn) => fn({ [nameRef.current]: state }), [state]);
    const localAction = useCallback(
        (fn) => (...args) => {
            const actionResult = fn(...args);

            if (typeof actionResult === 'function') actionResult(dispatch, () => ({ [nameRef.current]: state }));
            else dispatch(actionResult);
        },
        [dispatch, state]
    );

    return [localSelector, localAction];
}
