import * as React from 'react';
import { useField, useFormState } from 'informed';
import PhoneNumber from 'react-phone-number-input';

export default function PhoneField(props) {
    const { values } = useFormState();
    const {
        fieldState: { value, touched },
        fieldApi: { setValue, setTouched, setError },
        ref,
        render,
    } = useField(props);

    const {
        onBlur,
        placeholder,
        validate,
        validateOn,
        onChange,
        initialValue,
        defaultCountry,
        id,
        formatterDependencies,
        ...rest
    } = props;

    const onValidate = React.useCallback(
        (v, vs) => {
            setError(validate(v, vs));
        },
        [setError, validate]
    );

    const onNumberChange = React.useCallback(
        (nextValue) => {
            if (!touched) setTouched(true);
            if (nextValue !== value) {
                if (onChange) onChange(nextValue);
                if (validateOn === 'change') onValidate(nextValue, values);
                setValue(nextValue);
            }
        },
        [touched, onValidate, onChange, validateOn, setTouched, value, setValue, values]
    );

    const onNumberBlur = React.useCallback(() => {
        if (!touched) setTouched(true);
        if (validateOn === 'blur') onValidate(value, values);
        if (onBlur) onBlur();
    }, [value, touched, setTouched, onBlur, validateOn, onValidate, values]);

    return render(
        <>
            <input {...rest} type="hidden" ref={ref} value={value || initialValue || ''} />
            <PhoneNumber
                value={value}
                onChange={onNumberChange}
                defaultCountry={defaultCountry ?? undefined}
                onBlur={onNumberBlur}
                placeholder={placeholder}
            />
        </>
    );
}
