import { useMemo, useCallback } from 'react';
import cx from 'classnames';

export default function useBEM(...classNames) {
    const b = useMemo(() => cx(...classNames), [classNames]);
    const blocks = useMemo(
        () =>
            classNames
                .join(' ')
                .split(' ')
                .filter((x) => x && /^[A-Z]/.test(x) && !/--\w+$/.test(x)),
        [classNames]
    );

    const em = useCallback(
        (name, modifier = '') =>
            cx(
                blocks.map((n) =>
                    modifier && name
                        ? `${n}__${name}--${modifier}`
                        : name
                        ? `${n}__${name}`
                        : modifier
                        ? `${n}--${modifier}`
                        : ''
                )
            ),
        [blocks]
    );

    return [b, em];
}
