import { useMemo } from 'react';
import { useFieldState } from 'informed';
import { CreditCard } from 'Common/utils';
import { PAYMENT } from 'Common/constants/fields';

export default function useCurrentIssuer(scope) {
    const { value: cardNumber } = useFieldState(scope ? `${scope}.${PAYMENT.cardNumber}` : PAYMENT.cardNumber);

    return useMemo(() => (cardNumber ? CreditCard.getIssuerByNumber(cardNumber) : null), [cardNumber]);
}
