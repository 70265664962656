import * as React from 'react';
import px from 'prop-types';
import { SHIPMENT, ADDRESS } from 'Common/constants/fields';
import { Translation } from 'Common/components/localization';
import CheckoutSummary from '../CheckoutSummary';

export default function ReadOnlyContents({ state, shipment, email, cartPage }) {
    const {
        _static: { showSummary, summaryData, currency },
    } = React.useMemo(() => state || {}, [state]);

    const {
        [SHIPMENT.isDigital]: isDigital,
        [SHIPMENT.isGift]: isGift,
        [SHIPMENT.giftMessage]: giftMessage,
        [SHIPMENT.shippingMethodDisplay]: methodDisplay,
        [SHIPMENT.shippingMethodDesc]: methodDesc,
        [SHIPMENT.shippingMethodInfo]: methodInfo,
        [SHIPMENT.address]: {
            [ADDRESS.firstName]: fName,
            [ADDRESS.lastName]: lName,
            [ADDRESS.line1]: line1,
            [ADDRESS.line2]: line2,
            [ADDRESS.email]: shipmentEmail,
            [ADDRESS.company]: org,
            [ADDRESS.zip]: zip,
            [ADDRESS.city]: city,
            [ADDRESS.state]: region,
            [ADDRESS.country]: country,
            [ADDRESS.phone]: phone,
        } = {},
    } = React.useMemo(() => shipment || {}, [shipment]);

    const isReadOnlyValid = React.useMemo(
        () => (isDigital ? (shipmentEmail || email) != null : lName && country && zip),
        [isDigital, email, shipmentEmail, lName, country, zip]
    );

    return isReadOnlyValid ? (
        <div className="ReadOnlyShipment">
            {showSummary ? (
                <CheckoutSummary
                    costs={summaryData.costs}
                    className="ShippingSummary mt-3"
                    orderSummary={summaryData}
                    totalLabel={<Translation id="Commerce.Order.Checkout.ShippingSummary.Total.Label" />}
                    summaryTitle={<Translation id="Commerce.Order.Checkout.ShippingSummary.Title" />}
                    currency={currency}
                    cartPage={cartPage}
                />
            ) : null}
            <div className="row">
                <div className="ReadOnlyShipment__address col-10 col-sm-8 col-md-6 col-lg-4">
                    <h6>
                        <Translation id="Commerce.Order.Checkout.ShippingAddress.Display" />
                    </h6>
                    <p>
                        {fName} {lName}
                    </p>
                    {isDigital ? (
                        <p>{shipmentEmail || email}</p>
                    ) : (
                        <>
                            {org ? <p>{org}</p> : null}
                            <p>{line1}</p>
                            {line2 ? <p>{line2}</p> : null}
                            <p>
                                {city}, {region || ''} {zip}
                            </p>
                            <p>{country}</p>
                            {phone ? <p>{phone}</p> : null}
                        </>
                    )}
                </div>
                <div className="ReadOnlyShipment__method col-10 col-sm-8 col-md-6 col-lg-4">
                    <h6>
                        <Translation id="Commerce.Order.Checkout.ShippingMethod.Display" />
                    </h6>
                    <p className="paragraph-2">
                        {methodDisplay}, {methodDesc}
                    </p>
                    <p>
                        <Translation id="Commerce.Order.Checkout.ShippingMethod.ExpectedDelivery.Label" />
                        <span className="ml-1">{methodInfo}</span>
                    </p>

                    {isGift ? (
                        <p className="ReadOnlyShipment__gift">
                            <span>
                                <Translation id="Commerce.Order.Checkout.GiftMessage.Display" />{' '}
                            </span>
                            <span>{giftMessage || ''}</span>
                        </p>
                    ) : null}
                </div>
            </div>
        </div>
    ) : null;
}

ReadOnlyContents.propTypes = {
    state: px.objectOf(px.any),
    shipment: px.objectOf(px.any),
    email: px.string,
    cartPage: px.string,
};
