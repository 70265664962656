import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import PagingButtons from './PagingButtons';
import { withResize } from 'Common/HOCs';

/**
 * Multiplier for gap property.
 */
const GAP_PX = 10;

// eslint-disable-next-line react/prop-types
function PagedCarouselItemRender({ el, style }, ref) {
    return (
        // eslint-disable-next-line react/prop-types
        <div key={el.key} ref={ref} className="PagedCarousel__item" style={style}>
            {el}
        </div>
    );
}

const PagedCarouselItem = forwardRef(PagedCarouselItemRender);

PagedCarouselItem.propTypes = {
    el: px.node,
    style: px.object,
};

const ResizeableItem = withResize(PagedCarouselItem);

export default function PagedCarousel({ gap = 1, className, maxVisibleItems, children }) {
    const containerRef = useRef(null);
    const itemWidths = useRef([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [clickPos, setClickPos] = useState();
    const [movedBy, setMovedBy] = useState(0);
    const [containerWidth, setContainerWidth] = useState(null);
    const [isClicked, setDraggableClick] = useState(false);
    const [counter, setCounter] = useState(0);

    /**
     * Compute the valid page indexes based on the dimensions of all items and of the
     * carousel container. Returns an array of pages, each of which contains the array
     * of visible item indices (ex: [[0, 1], [2, 3, 4], [3, 4, 5], [6], [7, 8]]).
     *
     * Note:
     * The same index can show up multiple times, depending on the location of
     * full width items.
     */
    const pageRanges = useMemo(() => {
        const widths = itemWidths.current;

        // If we don't have the item widths yet, we can't compute any pages.
        if (widths.some((width) => width == null)) return [];

        const ranges = [];
        const count = widths.length;

        // Iterate forward from the first item...
        for (let i = 0; i < count; ) {
            let pageWidth = widths[i];
            const range = [i];

            // Add items to the current range. Stop if the new item would
            // exceed the container width.
            for (let j = i + 1; j < count; j++) {
                const width = widths[j] + gap * GAP_PX;

                if (width + pageWidth > containerWidth || (maxVisibleItems && range.length >= maxVisibleItems)) {
                    break;
                }

                pageWidth += width;

                range.push(j);
            }

            // Compute how many items to shift forward before we start computing
            // the next page. We don't want to include prepended items, which are
            // computed in the next loop, since we have already processed them.
            const shift = range.length;

            // In the case where the next item is larger than can be displayed,
            // we look backwards, prepending smaller items to fill up
            // the space as much as possible.
            for (let k = i - 1; k >= 0; k--) {
                const width = widths[k] + gap * GAP_PX;

                if (width + pageWidth > containerWidth || (maxVisibleItems && range.length >= maxVisibleItems)) {
                    break;
                }

                pageWidth += width;

                range.unshift(k);
            }

            // Shift forward.
            i += shift;

            // Store the new range.
            ranges.push(range);
        }

        return ranges;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerWidth, counter, gap]);

    /**
     * Compute the space between the fully visible elements and the right side of the container.
     *
     * This is used to center the visible elements within the slider container.
     *
     * +---------------------------------+
     * |   1   |   2   |   3   |--space--|
     * +---------------------------------+
     */
    const rangesTrailingSpace = useMemo(() => {
        return pageRanges.map(
            (range) =>
                containerWidth -
                range.reduce((total, i) => itemWidths.current[i] + total, 0) -
                (range.length - 1) * gap * GAP_PX
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageRanges, gap, containerWidth, counter]);

    /**
     * Updates the width (in pixels) of the carousel item at index `index`.
     *
     * Forces a rerender by incrementing a counter.
     */
    const setWidth = useCallback(
        (index) => (el) => {
            if (el === null) return;

            const rectWidth = el.getBoundingClientRect().width;

            if (!rectWidth) return;

            const newWidth = rectWidth;

            if (itemWidths.current[index] === newWidth) return;

            itemWidths.current[index] = newWidth;
            setCounter((x) => x + 1);
        },
        []
    );

    /**
     * The x-offset of the carousel slider. This slides all images to the left.
     */
    const offset = useMemo(() => {
        let imageLeft = 0;
        const startIndex = pageRanges[selectedIndex]?.[0];

        for (let i = 0; i < itemWidths.current.length; i++) {
            if (i === startIndex) break;

            imageLeft += itemWidths.current[i] + gap * GAP_PX;
        }

        return imageLeft;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex, pageRanges, gap, children, counter]);

    const currentPage = pageRanges[selectedIndex] ?? [];

    /**
     * Stored item widths get updated when they are rendered. Changing the `children` rerenders
     * the items, so we want to clear the item width array before we start inserting new
     * values. If we don't do this and the new `children` array is shorter, then we will
     * have too many widths stored in the array.
     */
    useEffect(() => {
        itemWidths.current = [];
    }, [children]);

    /**
     * Make sure the active page is always within the ever-changing page count.
     *
     * If we don't do this, then making the browser wider while selecting the last
     * page could potentially show a blank row, since the new page count may be less than
     * the selected page index.
     */
    useEffect(() => {
        if (pageRanges.length > 0 && selectedIndex >= pageRanges.length) {
            setSelectedIndex(pageRanges.length - 1);
        }
    }, [selectedIndex, pageRanges]);

    /**
     * Store the new carousel container width every time carousel container width
     * changes.
     */
    useEffect(() => {
        const el = containerRef.current;

        if (el == null) return;

        function computeContainerWidth() {
            const bounds = el.getBoundingClientRect();

            if (bounds == null) return;

            setContainerWidth(bounds.width);
        }

        computeContainerWidth();

        const observer = new ResizeObserver((entries) => {
            if (entries.length) {
                computeContainerWidth();
            }
        });

        observer.observe(el);

        return () => {
            observer.unobserve(el);
        };
    }, []);

    /**
     * The amount of pixels to shift the slider in order to render the visible items.
     * This centers the visible items using the precomputed space between the last
     * visible item and the end of the container.
     *
     * (This shift uses the left margin, so all values are shifting to the right.
     * Most of the time, this number will be negative, since it is shifting the slider
     * to the left. The very first page may shift to the right, since it needs to
     * center the first item.)
     */
    const sliderOffset = rangesTrailingSpace[selectedIndex] / 2 - offset;


    const onMove = useCallback((isTouch = false) => (e) => {
        if (!isClicked) return;

        setMovedBy((isTouch ? e.touches[0].clientX : e.clientX ) - containerRef.current.offsetLeft - clickPos);
    }, [isClicked, clickPos]);

    const onMouseUp = useCallback(() =>  {
        setDraggableClick(false);
        if (Math.abs(movedBy) > 10) {
            if (movedBy < 0) {
                setSelectedIndex(pageRanges.length >= selectedIndex + 1 ? selectedIndex + 1 : selectedIndex);
            } else {
                setSelectedIndex(0 <= selectedIndex - 1 ? selectedIndex - 1 : selectedIndex);
            }
            setMovedBy(0);
        }
    }, [movedBy, pageRanges.length, selectedIndex]);

    const onMouseDown = useCallback((isTouch = false) => (e) => {
        e.preventDefault();
        setDraggableClick(true);
        const downPos = isTouch ? e.touches[0].clientX : e.clientX;

        setClickPos(downPos - containerRef.current.offsetLeft);
    }, []);
    
    return (
        <div ref={containerRef} 
            onTouchStart={onMouseDown(true)} 
            onMouseDown={onMouseDown()}  
            onTouchMove={onMove(true)} 
            onMouseMove={onMove()} 
            onMouseUp={onMouseUp} 
            onTouchEnd={onMouseUp} 
            className={cx(className, 'PagedCarousel')}> 
            <div
                style={{ marginLeft: `${sliderOffset}px` }}
                className={cx('PagedCarousel__slider', `PagedCarousel__slider--gap-${gap}`)}
            >
                <PagingButtons
                    value={selectedIndex}
                    pageCount={pageRanges.length}
                    onSelect={setSelectedIndex}
                    className="PagedCarousel__paging"
                >
                    {React.Children.map(children, (el, index) => (
                        <ResizeableItem
                            onResize={setWidth(index)}
                            el={el}
                            style={{
                                maxWidth: `${containerWidth}px`,
                                opacity: currentPage.includes(index) ? 1 : 0,
                            }}
                        />
                    ))}
                </PagingButtons>
                
            </div>
        </div>
    );
}

PagedCarousel.propTypes = {
    gap: px.number,
    children: px.node,
    className: px.string,
    maxVisibleItems: px.number,
};
