import { QUERY_UNIT } from 'Common/constants/locator';
import * as types from './types';
import defaultState from './state';
import { getSearchState } from './selectors';
import { getLocations } from './api';

function arrayEq(a, b) {
    return JSON.stringify(a.slice().sort()) === JSON.stringify(b.slice().sort());
}

export function setMap(mapState = { ...defaultState.map }) {
    return { type: types.SET_MAP, payload: mapState };
}

export function setSearch(searchState = { ...defaultState.search }) {
    return { type: types.SET_SEARCH, payload: searchState };
}

export function updateMap(partialState = {}) {
    return { type: types.UPDATE_MAP, payload: partialState };
}

export function updateSearch(partialState = {}) {
    return { type: types.UPDATE_SEARCH, payload: partialState };
}

export function setQuery(query) {
    return { type: types.SET_QUERY, payload: query };
}

export function setResponse(response) {
    return { type: types.SET_RESPONSE, payload: response };
}

export function queryLocations(queryUpdate = {}, skipFetch = false) {
    return async (dispatch, getState) => {
        const { query = {} } = getSearchState(getState()) || {};
        const nextQuery = {
            ...query,
            ...queryUpdate,
            LocationFilter: {
                ...query.LocationFilter,
                ...queryUpdate.LocationFilter,
            },
        };

        dispatch(setQuery(nextQuery));

        if (skipFetch) return null;

        const res = await getLocations(nextQuery);

        if (res?.Status?.match(/^(|success|ok)$/i)) {
            nextQuery.Page = res.Response.Page;
            nextQuery.PageSize = res.Response.PageSize;
            dispatch(setQuery(nextQuery));
            dispatch(setResponse(res.Response));
            return res.Response;
        } else {
            throw new Error(res?.StatusMessage || null);
        }
    };
}

export function startLocationSearch(location) {
    return async (dispatch) => {
        const res = await dispatch(
            queryLocations({
                LocationFilter: {
                    Latitude: `${location.lat}`,
                    Longitude: `${location.lng}`,
                },
            })
        );

        await dispatch(updateSearch({ location }));
        return res;
    };
}

export function nextSearchPage() {
    return async (dispatch, getState) => {
        const { response = {} } = getSearchState(getState()) || {};

        if (response.Page < response.TotalPages) {
            return dispatch(
                queryLocations({
                    Page: response.Page + 1,
                })
            );
        } else {
            return null;
        }
    };
}

export function previousSearchPage() {
    return async (dispatch, getState) => {
        const { response = {} } = getSearchState(getState()) || {};

        if (response.Page > 1) {
            return dispatch(
                queryLocations({
                    Page: response.Page - 1,
                })
            );
        } else {
            return null;
        }
    };
}

export function updateQuery({ brandName, distance, unit, categories, language, pageSize } = {}, skipFetch = false) {
    return (dispatch, getState) => {
        const { query = {} } = getSearchState(getState()) || {};
        const update = { LocationFilter: {} };
        let flag = false;

        if (brandName && query.Site !== brandName) {
            flag = true;
            update.Site = brandName;
        }
        if (distance && query.LocationFilter.Distance !== distance) {
            flag = true;
            update.LocationFilter.Distance = distance;
        }
        if (QUERY_UNIT[unit] && query.LocationFilter.DistanceUnits !== QUERY_UNIT[unit]) {
            flag = true;
            update.LocationFilter.DistanceUnits = QUERY_UNIT[unit];
        }
        if (categories && !arrayEq(categories, query.CategoriesFilter)) {
            flag = true;
            update.CategoriesFilter = categories;
        }
        if (language && language !== query.Language) {
            flag = true;
            update.Language = language;
        }
        if (pageSize && pageSize !== query.PageSize) {
            flag = true;
            update.PageSize = pageSize;
        }

        if (flag) {
            return dispatch(queryLocations({ ...update, Page: 1 }, skipFetch));
        } else {
            return null;
        }
    };
}
