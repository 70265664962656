import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Checkbox } from 'informed';
import VALIDATION_STRINGS from 'Common/constants/validation';
import { Email } from 'Common/utils';
import { EMAIL as FIELDS } from 'Common/constants/fields';
import { Field } from '../forms';
import { Translation } from '../localization';
import CheckoutForm from './CheckoutForm';

function validateConfirmation(value, values) {
    return value?.toLowerCase() !== values[FIELDS.email]?.toLowerCase()
        ? VALIDATION_STRINGS.confirmationMismatch
        : undefined;
}

const EmailFormType = px.shape({
    [FIELDS.email]: px.string,
    [FIELDS.confirmEmail]: px.string,
    [FIELDS.signup]: px.bool,
});

function ReadOnlyContents({ state }) {
    return (
        <div className="col-8 col-md-6 col-lg-4 pl-0">
            <h6>
                <Translation id="Commerce.Order.Checkout.Email.Display.Label" />
            </h6>
            <p className="paragraph-2">{state[FIELDS.email]}</p>
        </div>
    );
}

ReadOnlyContents.propTypes = {
    state: EmailFormType,
};

export default function CheckoutEmail({
    className,
    accountEmail = '',
    loggedIn = false,
    signup = false,
    signinLink,
    ...props
}) {
    return (
        <CheckoutForm
            className={cx('CheckoutEmail', className)}
            title={<Translation id="Commerce.Order.Checkout.Email.Title" />}
            ReadOnlyContents={ReadOnlyContents}
            allowCancelEditing={false}
            initialState={{
                [FIELDS.email]: accountEmail,
                [FIELDS.confirmEmail]: accountEmail,
                [FIELDS.signup]: signup,
            }}
            allowUpdates={!loggedIn}
            {...props}
        >
            <div className="row px-3">
                <div className="col-12 p-0">
                    <h6 className="mb-0">
                        <Translation id="Commerce.Order.Checkout.Email.ReturningUserInstructions.Label" />
                    </h6>
                    <p className="paragraph-2 mb-3">
                        <Translation id="Commerce.Order.Checkout.Email.ReturningUserMessage.Label" />
                    </p>
                    <a role="button" className="btn btn-primary w-auto" href={signinLink}>
                        <Translation id="Commerce.Order.Checkout.Email.LoginButton.Label" />
                    </a>
                </div>
                <div className="col-12 p-0 mt-4">
                    <h6 className="mt-3 mb-0">
                        <Translation id="Commerce.Order.Checkout.Email.GuestUserInstructions.Label" />
                    </h6>
                    <p className="paragraph-2">
                        <Translation id="Commerce.Order.Checkout.Email.CreateAccountMessage.Label" />
                    </p>
                    <Field
                        className="col-12 col-md-6 p-0"
                        label={<Translation id="Commerce.Order.Checkout.Email.Input.Label" />}
                        name={FIELDS.email}
                        validate={Email.getValidationError}
                        notify={[FIELDS.confirmEmail]}
                        validateOnValueChange
                        hookAutofill
                        required
                    />
                    <Field
                        className="col-12 col-md-6 p-0"
                        label={<Translation id="Commerce.Order.Checkout.Email.ConfirmInput.Label" />}
                        name={FIELDS.confirmEmail}
                        validate={validateConfirmation}
                        notify={[FIELDS.email]}
                        validateOnValueChange
                        required
                    />
                </div>
                <div className="col-12 p-0 mt-4 mb-1">
                    <h6 className="mt-3 mb-0">
                        <Translation id="Commerce.Order.Checkout.Email.Signup.Title" />
                    </h6>
                    <p className="paragraph-2">
                        <Translation id="Commerce.Order.Checkout.Email.Signup.Subtitle" />
                    </p>
                    <Field
                        Component={Checkbox}
                        label={
                            <p className="paragraph-3">
                                <Translation id="Commerce.Order.Checkout.Email.Signup.Label" />
                            </p>
                        }
                        name={FIELDS.signup}
                        labelInline
                        labelLast
                        hideError
                    />
                </div>
            </div>
        </CheckoutForm>
    );
}

CheckoutEmail.propTypes = {
    signinLink: px.string,
    className: px.string,
    accountEmail: px.string,
    loggedIn: px.bool,
    signup: px.bool,
};
