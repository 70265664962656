import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Formatter, OrderNumber } from 'Common/utils';
import { useTranslation, useViewport } from 'Common/hooks';
import { Translation, Currency } from '../localization';
import { AccordionTable } from '../ui';

export default function OrderHistory({
    className,
    onSearchOrder,
    onUpdatePage,
    orderDetailPage,
    orderPagination,
    orderTableData,
    trackingUrlTarget,
}) {
    const viewport = useViewport();
    const searchRef = React.useRef();
    const [isLoading, setIsLoading] = React.useState(false);
    const dateColumnName = useTranslation('Commerce.Order.History.Table.Date.Column');
    const orderNumColumnName = useTranslation('Commerce.Order.History.Table.OrderNum.Column');
    const totalColumnName = useTranslation('Commerce.Order.History.Table.Total.Column');
    const trackNumColumnName = useTranslation('Commerce.Order.History.Table.TrackingNum.Singular.Column');
    const actionColumnName = useTranslation('Commerce.Order.History.Table.Action.Column');
    const orderDetailButtonText = useTranslation('Commerce.Order.History.Table.Action.Button.Label');

    const pageOptions = React.useMemo(() => {
        const pages = Math.ceil(orderPagination.orderTotal / orderPagination.orderCount);

        return pages > 0
            ? Array(pages)
                  .fill(0)
                  .map((_, i) => i + 1)
            : null;
    }, [orderPagination]);

    const onSubmit = React.useCallback(
        (e) => {
            e.preventDefault();
            if (searchRef.current?.value) return onSearchOrder(searchRef.current.value);
        },
        [onSearchOrder]
    );

    const onPageClick = React.useCallback(
        (pageNum) => {
            setIsLoading(true);
            return onUpdatePage(pageNum);
        },
        [onUpdatePage]
    );

    const onChange = React.useCallback(() => {
        searchRef.current.value = OrderNumber.cleanString(searchRef.current.value);
        if (!searchRef.current.value) {
            return onUpdatePage(orderPagination.orderPage);
        }
    }, [onUpdatePage, orderPagination]);

    React.useEffect(() => {
        setIsLoading(false);
    }, [orderPagination]);

    return (
        <div className={cx('OrderHistory', className)}>
            <form
                className="mb-3 d-flex align-items-center justify-content-center justify-content-md-start"
                onSubmit={onSubmit}
            >
                <input
                    placeholder={useTranslation('Commerce.Order.History.Search.Placeholder')}
                    onChange={onChange}
                    ref={searchRef}
                    className="OrderHistory__input d-inline h-100"
                    type="text"
                    name=""
                />
                <button type="submit" className="btn btn-sm btn-primary h-100">
                    <Translation id="Commerce.Order.History.Search.Button.Text" />
                </button>
            </form>
            {orderTableData && orderTableData.length ? (
                viewport.is.gt('md') ? (
                    <div className={cx('Table', className)}>
                        <table className={cx('table table-sm', isLoading ? 'disabled' : null)}>
                            <thead>
                                <tr>
                                    <th scope="col">{dateColumnName}</th>
                                    <th scope="col">{orderNumColumnName}</th>
                                    <th scope="col">{totalColumnName}</th>
                                    <th scope="col">{trackNumColumnName}</th>
                                    <th scope="col">{actionColumnName}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderTableData.map((data) => (
                                    <tr key={data.omsOrderReference} className="TableRow">
                                        <td>
                                            <p className="paragraph-2">{Formatter.dateFormat(data.date)}</p>
                                        </td>
                                        <td>
                                            <p className="paragraph-2">{data.orderNumber}</p>
                                        </td>
                                        <td>
                                            <p className="paragraph-2">
                                                <Currency code={data.currency} amount={data.total} />
                                            </p>
                                        </td>
                                        <td>
                                            {data.trackingNumbers?.length
                                                ? data.trackingNumbers.map((tracking) => (
                                                      <p key={tracking.number}>
                                                          <a
                                                              className="paragraph-2 tracking"
                                                              href={tracking.url}
                                                              target={trackingUrlTarget}
                                                          >
                                                              {tracking.number}
                                                          </a>
                                                      </p>
                                                  ))
                                                : null}
                                        </td>
                                        <td>
                                            <a
                                                href={
                                                    data.omsOrderReference
                                                        ? `${orderDetailPage}?orderNumber=${data.omsOrderReference}`
                                                        : `${orderDetailPage}?orderNumber=${data.orderNumber}`
                                                }
                                                className="btn btn-sm btn-primary"
                                            >
                                                {orderDetailButtonText}
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <AccordionTable
                        listDisplay
                        className={isLoading ? 'disabled' : null}
                        accordionHeaders={[orderNumColumnName, totalColumnName, actionColumnName]}
                        accordionData={orderTableData.map((row) => ({
                            id: row.omsOrderReference,
                            rowValues: [
                                { value: row.orderNumber, id: row.orderNumber },
                                { value: Formatter.currency(row.total, row.currency), id: row.total },
                                {
                                    value: (
                                        <a
                                            href={
                                                row.omsOrderReference
                                                    ? `${orderDetailPage}?orderNumber=${row.omsOrderReference}`
                                                    : `${orderDetailPage}?orderNumber=${row.orderNumber}`
                                            }
                                            className="btn btn-sm btn-primary"
                                        >
                                            {orderDetailButtonText}
                                        </a>
                                    ),
                                    id: `${row.orderNumber}_${row.omsOrderReference}`,
                                    className: 'p-0',
                                },
                            ],
                            content: [
                                {
                                    values: [{ node: new Date(row.date)?.toLocaleDateString(), id: row.date }],
                                    label: dateColumnName,
                                },
                                {
                                    values: row.trackingNumbers?.length
                                        ? row.trackingNumbers.map((tracking) => ({
                                              node: (
                                                  <p className="paragraph-2 text-break mb-0">
                                                      <a href={tracking.url}>{tracking.number}</a>
                                                  </p>
                                              ),
                                              id: tracking.number,
                                          }))
                                        : null,
                                    label: trackNumColumnName,
                                },
                            ],
                        }))}
                    />
                )
            ) : (
                <h6 className="p-3">
                    <Translation id="Commerce.Order.History.EmptyOrders.Text" />
                </h6>
            )}
            <div className="OrderHistory__pagination">
                {orderTableData?.length > 1 && pageOptions?.length > 1
                    ? pageOptions.map((pageNum) => (
                          <button
                              key={`btn-page-${pageNum}`}
                              onClick={() => onPageClick(pageNum)}
                              className={cx(
                                  'btn mr-1 p-2',
                                  pageNum === orderPagination.orderPage ? 'btn-primary' : null
                              )}
                          >
                              {pageNum}
                          </button>
                      ))
                    : null}
            </div>
        </div>
    );
}

OrderHistory.propTypes = {
    className: px.string,
    onSearchOrder: px.func,
    onUpdatePage: px.func,
    orderDetailPage: px.string,
    orderPagination: px.shape({
        orderPage: px.number,
        orderCount: px.number,
        orderTotal: px.number,
    }),
    orderTableData: px.arrayOf(
        px.shape({
            date: px.string,
            orderNumber: px.string,
            total: px.number,
            currency: px.string,
            trackingNumbers: px.arrayOf(
                px.shape({
                    number: px.oneOfType([px.number, px.string]),
                    url: px.string,
                })
            ),
            omsOrderReference: px.string,
        })
    ),
    trackingUrlTarget: px.string,
};
