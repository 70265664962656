import * as React from 'react';
import $ from 'jquery';
import cx from 'classnames';
import px from 'prop-types';
import * as PRODUCT from '~config/product';
import { Product } from 'Common/types';
import Slider from 'react-slick';
import { ArrowButton } from 'Common/components/ui';
import { CatalogCard } from '../catalog';
import { Formatter, Url } from 'Common/utils';
import { Translation } from 'Common/components/localization';
import { ProductInfo } from 'Common/models';

const slickSliderSettings = {
    arrows: true,
    slidesToShow: 3.5,
    infinite: false,
    prevArrow: <ArrowButton direction="left" ariaHidden />,
    nextArrow: <ArrowButton direction="right" ariaHidden />,
    responsive: [
        {
            breakpoint: 1200,
            settings: { slidesToShow: 3 },
        },
        {
            breakpoint: 767,
            settings: { slidesToShow: 2 },
        },
        {
            breakpoint: 400,
            settings: { slidesToShow: 1, centerMode: true },
        },
    ],
};

function priceProps(price) {
    const retail = [price.MinListPrice.Amount, price.MaxListPrice.Amount];
    const sale = [price.MinSalePrice.Amount, price.MaxSalePrice.Amount];
    const retailStr = retail ? Formatter.currency(retail, price.ListPrice.Currency) : '';
    const saleStr = sale ? Formatter.currency(sale, price.SalePrice.Currency) : '';

    if (!retail[1] && !sale[1]) return null;
    if ((retail[1] && !sale[1]) || retailStr === saleStr) return { price: retailStr };
    if (sale[1] && !retail[1]) return { price: saleStr };
    return { price: retailStr, reducedPrice: saleStr };
}

export default function ProductSlider({
    className,
    catalogEntities,
    checkoutPageLink = checkoutPageLink,
    isUserAuthenticated = isUserAuthenticated,
    openQuick,
}) {
    React.useEffect(() => {
        $('div.slick-slide[aria-hidden="true"]').attr('aria-hidden', false);
    }, []);

    return (
        <div className={cx('ProductSlider', className)}>
            <Slider {...slickSliderSettings}>
                {catalogEntities.map((product) => (
                    <CatalogCard
                        code={product.Code}
                        key={product.Code}
                        title={product.DisplayName}
                        rating={product.Rating}
                        reviewProviderEntityId={product.ReviewProviderEntityId}
                        onSelectProduct={() => openQuick(product)}
                        defaultImage={Url.addToRelativeUrl(
                            product.DefaultImageUrl,
                            `format=png&height=172&width=172${
                                PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                            }`
                        )}
                        productLinks={
                            ProductInfo.isDisplayOnly(product)
                                ? []
                                : [
                                      {
                                          label: <Translation id="Commerce.CatalogNode.Card.QuickView" />,
                                          key: 'quickViewLink',
                                          icon: 'fas fa-eye',
                                          action: () => {
                                              openQuick(product);
                                          },
                                      },
                                  ]
                        }
                        marketingBadges={product.MarketingBadges}
                        {...priceProps(product.Price)}
                    />
                ))}
            </Slider>
        </div>
    );
}

ProductSlider.propTypes = {
    className: px.string,
    catalogEntities: px.arrayOf(Product),
    checkoutPageLink: px.string,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    openQuick: px.func,
};
