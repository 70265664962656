import { useEffect, useState, useRef } from 'react';
import { Script } from 'Common/utils';
import { ensureLib } from 'Common/core';

export default function useScript(
    src,
    {
        onBefore,
        onCleanup,
        onLoad,
        async = true,
        globalNamespace,
        forceReload = false,
        destroyOnDismount = true,
        delay,
    } = {}
) {
    const [loaded, setLoaded] = useState(false);
    const opts = useRef({ onBefore, onLoad, async, forceReload, destroyOnDismount, delay });

    useEffect(() => {
        const destroy = opts.current.destroyOnDismount;

        if (src && opts.current.delay && globalNamespace) {
            ensureLib(globalNamespace, opts.current.delay)
                .then((lib) => {
                    if (opts.current.onLoad) {
                        const transformedLib = opts.current.onLoad(lib);

                        return transformedLib;
                    }
                    return lib;
                })
                .then((lib) => {
                    if (lib) setLoaded(lib);
                    else Script.listen(src, globalNamespace, opts.current, setLoaded);
                })
                .catch(() => {
                    Script.listen(src, globalNamespace, opts.current, setLoaded);
                });
        } else {
            Script.listen(src, globalNamespace, opts.current, setLoaded);
        }

        return () => {
            if (src) Script.release(src, setLoaded);
            if (destroy && src) Script.destroy(src);
            if (onCleanup) onCleanup();
        };
    }, [src, globalNamespace, onCleanup]);

    return loaded;
}
