import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { HeaderCustomerMessage as Message } from 'Common/components/customer';
import { customer } from '~features';

export default function HeaderCustomerMessage({ className, selector, placement }) {
    const timestamp = useSelector(customer.selectors.getCustomerMessageTimestamp);
    const message = useSelector(customer.selectors.getTranslatedHeaderCustomerMessage);

    return (
        <Message
            className={className}
            timestamp={timestamp}
            message={message}
            selector={selector}
            placement={placement}
        />
    );
}

HeaderCustomerMessage.propTypes = {
    className: px.string,
    selector: px.string,
    placement: px.string,
};
