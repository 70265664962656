import { useRef, useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { v4 } from 'uuid';
import { logger } from 'Common/core';
import useScript from '../useScript';

const PAGE_LOADING = 'PAGE_LOADING';
const PAGE_LOGIN = 'PAGE_LOGIN';
const PAGE_WALLET = 'PAGE_WALLET';
const SCOPE = 'payments:widget';
const POPUP = 'POPUP-PARAMETER';

window.onAmazonLoginReady = () => {};

export default function useAmazonPay(idProp = `AmazonPay-${v4()}`, { Env, SellerId, ClientId } = {}) {
    const id = useRef({ main: idProp, btn: `${idProp}-btn`, widget: `${idProp}-widget` });
    const [orderReference, setOrderReference] = useState(null);
    const [readyToOrder, setReadyToOrder] = useState(false);
    const [page, setPage] = useState(PAGE_LOADING);

    const orderId = useMemo(() => (readyToOrder ? orderReference.getAmazonOrderReferenceId() : null), [
        readyToOrder,
        orderReference,
    ]);

    const src = useMemo(
        () =>
            `https://static-na.payments-amazon.com/OffAmazonPayments/us/${
                Env === 'TEST' ? 'sandbox/' : ''
            }js/Widgets.js`,
        [Env]
    );

    const onBefore = useCallback(() => {
        window.onAmazonLoginReady = () => {
            window.amazon.Login.setClientId(ClientId);
            setPage(PAGE_LOGIN);
        };
    }, [ClientId]);

    const onCleanup = useCallback(() => {
        window.onAmazonLoginReady = () => {};
        setReadyToOrder(false);
        setOrderReference(null);
        setPage(PAGE_LOADING);
    }, []);

    const amazon = useScript(src, {
        onBefore,
        onCleanup,
        globalNamespace: 'amazon',
        forceReload: true,
        destroyOnDismount: true,
    });

    const logout = useCallback(() => {
        if (amazon) amazon.Login.logout();
        setReadyToOrder(false);
        setOrderReference(null);
        setPage(PAGE_LOGIN);
    }, [amazon]);

    const state = useMemo(
        () => ({
            isLoading: page === PAGE_LOADING,
            isLoggedIn: page === PAGE_WALLET,
            logout,
            orderId,
            btnId: id.current.btn,
            widgetId: id.current.widget,
        }),
        [logout, page, orderId]
    );

    useEffect(() => {
        let handler;

        if (page === PAGE_LOADING && amazon) {
            clearTimeout(handler);
            handler = setTimeout(() => window.onAmazonLoginReady(), 100);
        }
        return () => clearTimeout(handler);
    }, [page, amazon]);

    useLayoutEffect(() => {
        if (page === PAGE_WALLET) {
            // bind the Amazon Wallet Widget to the appropriate div
            document.querySelector(`#${id.current.widget}`).innerHTML = '';
            new window.OffAmazonPayments.Widgets.Wallet({
                sellerId: SellerId,
                onPaymentSelect: (/* paymentInfo */) => {
                    setReadyToOrder(true);
                },
                design: { designMode: 'responsive' },
                onOrderReferenceCreate: (newOrderReference) => {
                    setOrderReference(newOrderReference);
                },
                onError: (error) => {
                    logger.error(`${error.getErrorCode()}: ${error.getErrorMessage()}`);
                },
            }).bind(id.current.widget);
        } else if (page === PAGE_LOGIN) {
            // bind the Amazon Pay Login button to the appropriate div
            document.querySelector(`#${id.current.btn}`).innerHTML = '';
            window.OffAmazonPayments.Button(id.current.btn, SellerId, {
                type: 'PwA',
                color: 'LightGray',
                size: 'large',
                popup: true,

                authorization: () => {
                    window.amazon.Login.authorize({ scope: SCOPE, popup: POPUP }, () => {
                        setPage(PAGE_WALLET);
                    });
                },
                onError: (error) => {
                    logger.error(`${error.getErrorCode()}: ${error.getErrorMessage()}`);
                },
            });
        }
    }, [page, SellerId]);

    return state;
}
