import px from 'prop-types';
import { SHIPMENT } from 'Common/constants/fields';
import Address from './Address';

export const ShippingMethodShape = {
    DeliveryInfo: px.string,
    Description: px.string,
    DisplayName: px.string,
    EstimatedCost: px.number,
    ShippingMethodName: px.string,
    SortOrder: px.number,
};

export const ShippingMethod = px.shape(ShippingMethodShape);

export const ShipmentShape = {
    [SHIPMENT.isGift]: px.bool,
    [SHIPMENT.giftMessage]: px.string,
    [SHIPMENT.shippingMethod]: px.string,
    [SHIPMENT.shippingMethodDesc]: px.string,
    [SHIPMENT.shippingMethodInfo]: px.string,
    [SHIPMENT.shippingMethodDisplay]: px.string,
    [SHIPMENT.shipmentId]: px.oneOfType([px.string, px.number]),
    [SHIPMENT.isDigital]: px.bool,
    [SHIPMENT.address]: Address,

    AvailableShippingMethods: px.arrayOf(ShippingMethod),
};

export default px.shape(ShipmentShape);
