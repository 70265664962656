import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import { logger } from 'Common/core';
import { Translation } from 'Common/components/localization';
import { useTranslation } from 'Common/hooks';
import { Email, Api, GTM as gtmUtil } from 'Common/utils';
import * as GTM from 'Common/constants/gtm';

function decodeHtml(str = '') {
    const div = document.createElement('div');

    div.innerHTML = str;
    return div.innerText || '';
}

const REQ = {
    MailChimp: async function mailChimpRequest(email, options) {
        const id = `frame_${v4()}`;
        const [, urlStr = ''] = options?.embeddedForm?.match(/action=['"]([^'"]+)['"]/i) || [];
        let url;

        try {
            url = new URL(decodeHtml(urlStr));
        } catch (e) {
            url = null;
        }

        if (url) {
            const form = document.createElement('form');
            const frame = document.createElement('iframe');

            frame.name = id;
            frame.id = id;
            frame.src = 'about:blank';
            frame.style.display = 'none';
            document.body.appendChild(frame);
            form.action = url.href;
            form.method = 'post';
            form.target = id;
            form.noValidate = true;
            form.innerHTML = `
                <input type="hidden" value="${email}" name="EMAIL" />
                <input type="hidden" value="" name="b_${url.searchParams.get('u')}_${url.searchParams.get('id')}" />
                <input type="hidden" value="Submit" name="submit" />
            `;
            form.style.display = 'none';
            document.body.appendChild(form);

            await new Promise((res) => setTimeout(res, 100));

            const error = await new Promise((res) => {
                frame.onload = () => res(null);
                frame.onerror = () => res('Form.Subscription.Failure');
                document.createElement('form').submit.call(form);
            }).catch((e) => {
                logger.warn(`Failed to submit mailchimp form: `, e?.message || e);
                return 'Form.Subscription.Failure';
            });

            document.body.removeChild(form);
            document.body.removeChild(frame);
            return { success: !error, error };
        } else {
            logger.warn('No url found in options.embeddedForm for mailchimp subscribe', options);
        }
        return { success: false };
    },

    Default: async function defaultRequest(email, options = {}) {
        if (options.url) {
            const form = new FormData();

            form.append('__RequestVerificationToken', Api.antiForgeryToken);
            form.append('ProviderKey', options.providerKey);
            form.append('ListId', options.listId);
            form.append('MetaTags', options.metaTags);
            form.append('SubscriptionEmail', email);

            return Api.POST(options.url, form, { antiForgery: false });
        } else {
            logger.warn('No url found in options.url for default subscribe', options);
        }
        return { success: false };
    },
};

export default function SubscribeForm({
    host = 'Default',
    id = v4(),
    className,
    options,
    allowMultiple,
    style,
    useIcon = false,
}) {
    const idRef = React.useRef(id);
    const [complete, setComplete] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [email, setEmail] = React.useState('');
    const isValid = React.useMemo(() => Email.isValid(email || ''), [email]);
    const lblPlaceholder = useTranslation('Form.Subscription.Email.Placeholder');
    const submitLbl = useTranslation('Form.Subscription.Submit');
    const onChange = React.useCallback((e) => setEmail(e.target.value), []);
    const onSubmit = React.useCallback(
        async (e) => {
            e.preventDefault();
            if (isValid) {
                const req = REQ[host] || REQ.Default;

                setComplete(false);
                setError(null);

                const r = await req(email, options);

                if (!r?.success) setError(r?.error || 'Form.Subscription.Failure');
                if (r?.success) {
                    gtmUtil.updateDataLayer(gtmUtil.getEmailSignup(GTM.TAGS.FOOTER), GTM.LAYERS.EMAILSIGNUP); // assumes using this component in footer
                }
                setComplete(true);
            } else {
                setError('Form.Subscription.Failure');
            }
        },
        [email, isValid, options, host]
    );

    return (
        <>
            <div className={cx('MailingListSignup', host, 'row', className)} style={style} id={idRef.current}>
                {allowMultiple || !complete || error ? (
                    <div className="col-12 p-1 d-flex justify-content-center align-items-center">
                        <form onSubmit={onSubmit} className="col-12 subscription__form form-inline needs-validation">
                            <label className="col-lg-5 pb-1 pb-lg-0 d-flex justify-content-center justify-content-lg-end subheader">
                                <Translation id="Form.Subscription.Email" />
                            </label>
                            <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={onChange}
                                    className="form-control"
                                    placeholder={lblPlaceholder}
                                    autoComplete="email"
                                    required
                                />
                                {useIcon ? (
                                    <button type="submit" disabled={!isValid} aria-label={submitLbl}>
                                        <i className="fa fa-arrow-right text__color-secondary"></i>
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-primary" disabled={!isValid}>
                                        <Translation id="Form.Subscription.Submit" />
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                ) : null}
            </div>
            {complete ? (
                <div className="MailingListSignup__message d-flex justify-content-center align-items-center px-3">
                    {error ? (
                        <div className="col form-messagebox alert alert-danger text-center">
                            <Translation id={error} />
                        </div>
                    ) : (
                        <div className="col form-messagebox alert alert-custom text-center">
                            <Translation id="Form.Subscription.Success" />
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
}

SubscribeForm.propTypes = {
    host: px.oneOf(['MailChimp', 'Default']),
    options: px.shape({ embeddedForm: px.string }),
    className: px.string,
    id: px.string,
    style: px.any,
    allowMultiple: px.bool,
    useIcon: px.bool,
};
