import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import mergewith from 'lodash.mergewith';
import { Form } from 'informed';
import { Translation } from '../localization';
import CheckoutSection from './CheckoutSection';
import CheckoutFormContext from './CheckoutFormContext';
import omit from 'lodash.omit';

function setFormValues(formApi, values, prefix) {
    Object.keys(values).forEach((k) => {
        if (values[k] && typeof values[k] === 'object' && !Array.isArray(values[k])) {
            setFormValues(formApi, values[k], [prefix, k].filter(Boolean).join('.'));
        } else {
            formApi.setValue(prefix ? `${prefix}.${k}` : k, values[k]);
        }
    });
}

function mergeState(state, next) {
    return mergewith(state, next, (x, y) => (Array.isArray(y) ? y : undefined));
}

export default function CheckoutForm({
    onUpdate,
    className,
    initialStateIsValid = false,
    initialState,
    isFormRendered = true,
    allowCancelEditing = true,
    forceEdit = false,
    ReadOnlyContents = null,
    allowUpdates = false,
    title = null,
    children,
    hideSaveActions = false,
    onBeginEdit,
    onEndEdit,
    onCancelEdit,
    readOnlyProps = {},
    editState,
    showNext,
    hideSaveButton = false,
    disabled: disabledProp = false,
}) {
    const lastEditRef = React.useRef(false);
    const formApiRef = React.useRef(null);
    const forceEditRef = React.useRef(forceEdit);
    const initialStateRef = React.useRef(JSON.stringify(omit(initialState, '_static')));
    const pristineRef = React.useRef(true);
    const [disabled, setDisabled] = React.useState(false);
    const [canSave, setCanSave] = React.useState(initialStateIsValid);
    const localEditState = React.useState(forceEdit);
    const [isEditing, setIsEditing] = editState || localEditState;
    const [collapsed, setCollapsed] = React.useState(isEditing);
    const [formErrors, setFormErrors] = React.useState([]);
    const [formState, setFormState] = React.useState(initialState);
    const onChange = React.useCallback(({ invalid } = {}) => setCanSave(!invalid), []);
    const onValueChange = React.useCallback(({ values }) => setFormState({ _static: formState?._static, ...values }), [
        formState,
    ]);

    const updateState = React.useCallback((values) => setFormState(mergeState(formState, values)), [formState]);

    const setValues = React.useCallback(
        async (values) => {
            await updateState(values);
            if (formApiRef.current) {
                setFormValues(formApiRef.current, values);
            }
        },
        [updateState]
    );

    const getValues = React.useCallback(
        () => (formApiRef.current ? formApiRef.current.getFormState().values : formState),
        [formState]
    );

    const onSave = React.useMemo(
        () =>
            isEditing && onUpdate && isFormRendered
                ? async () => {
                      setDisabled(true);
                      formApiRef.current?.touchAllFields();
                      formApiRef.current?.validate();
                      if (formApiRef.current && !formApiRef.current.getFormState().invalid) {
                          const response = await onUpdate(formState);

                          setDisabled(false);
                          if (response) {
                              if (!response?.success && response?.errors) {
                                  setFormErrors(response?.errors || []);
                              } else {
                                  setFormErrors([]);
                                  setIsEditing(false);
                              }
                          }
                      } else {
                          setDisabled(false);
                      }
                  }
                : null,
        [isEditing, formState, setIsEditing, onUpdate, isFormRendered]
    );

    const onEdit = React.useMemo(() => (allowUpdates && !isEditing && onUpdate ? () => setIsEditing(true) : null), [
        isEditing,
        allowUpdates,
        onUpdate,
        setIsEditing,
    ]);

    const onToggleCollapse = React.useCallback((next) => (isEditing ? null : setCollapsed(next)), [isEditing]);

    const onCancel = React.useCallback(() => {
        setIsEditing(false);
        if (onCancelEdit) onCancelEdit();
    }, [onCancelEdit, setIsEditing]);

    const ctx = React.useMemo(
        () => ({ validate: () => {}, ...formApiRef.current, setValues, getValues, updateState }),
        [setValues, getValues, updateState]
    );

    React.useEffect(() => {
        pristineRef.current = true;
    }, []);

    React.useEffect(() => {
        const nextInitial = JSON.stringify(omit(initialState, '_static'));

        if (initialStateRef.current !== nextInitial) {
            initialStateRef.current = nextInitial;
            pristineRef.current = true;
            setFormState(initialState);
            if (formApiRef.current) {
                formApiRef.current.setValues(initialState);
                formApiRef.current.validate();
                setCanSave(!formApiRef.current.getFormState().invalid);
            }
        }
    }, [initialState]);

    React.useEffect(() => {
        if (forceEdit !== forceEditRef.current) {
            forceEditRef.current = forceEdit;
            if (forceEdit && !isEditing) {
                setIsEditing(true);
                setCollapsed(false);
            } else if (!forceEdit && isEditing) {
                setIsEditing(false);
                setCollapsed(true);
            }
        }
    }, [forceEdit, isEditing, setIsEditing]);

    React.useEffect(() => {
        if (isEditing && collapsed) {
            setCollapsed(false);
        }
    }, [isEditing, collapsed]);

    React.useEffect(() => {
        if (!lastEditRef.current && isEditing && onBeginEdit) {
            lastEditRef.current = isEditing;
            onBeginEdit();
        } else if (lastEditRef.current && !isEditing && onEndEdit) {
            lastEditRef.current = isEditing;
            onEndEdit();
        } else {
            lastEditRef.current = isEditing;
        }
    }, [isEditing, onBeginEdit, onEndEdit]);

    React.useEffect(() => {
        setDisabled(disabledProp);
    }, [disabledProp]);

    const isSaveDisabled = React.useMemo(() => disabled || !canSave, [disabled, canSave]);

    return (
        <CheckoutSection
            className={cx('CheckoutForm', className)}
            title={title}
            canSave={canSave}
            onSave={onSave}
            onEdit={onEdit}
            isEditing={isEditing}
            disabled={disabled}
            onToggleCollapse={onToggleCollapse}
            collapsed={collapsed}
            showNext={forceEdit || showNext}
            setForceEdit={setIsEditing}
            hideSaveButton={hideSaveButton}
        >
            {isEditing || !ReadOnlyContents ? (
                <Form
                    initialValues={initialState}
                    onValueChange={onValueChange}
                    onChange={onChange}
                    style={{ width: '100%' }}
                    formApiRef={formApiRef}
                >
                    <CheckoutFormContext.Provider value={ctx}>
                        <fieldset disabled={disabled || !isEditing}>
                            {children}
                            {formErrors?.length ? (
                                <div className="container row mb-0">
                                    {formErrors.map((error) => (
                                        <div
                                            className="alert alert-danger mt-1 mb-0 paragraph-2 text-danger col-md-12"
                                            key={error.attribute}
                                        >
                                            <Translation
                                                id={error.message ?? error}
                                                params={error.params ?? undefined}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </fieldset>
                        {onSave && !hideSaveActions ? (
                            <div className="container">
                                <div className="row">
                                    <button
                                        type="button"
                                        role="button"
                                        className="btn btn-primary w-auto"
                                        onClick={onSave}
                                        disabled={isSaveDisabled}
                                    >
                                        <span>
                                            <Translation
                                                id={
                                                    forceEdit
                                                        ? 'Commerce.Order.Checkout.NextSectionButton.Label'
                                                        : 'Commerce.Order.Checkout.SaveSectionButton.Label'
                                                }
                                            />
                                        </span>
                                    </button>
                                    {allowCancelEditing ? (
                                        <button type="button" role="button" className="btn w-auto" onClick={onCancel}>
                                            <span>
                                                <Translation id={'Commerce.Order.Checkout.CancelSectionButton.Label'} />
                                            </span>
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </CheckoutFormContext.Provider>
                </Form>
            ) : (
                <ReadOnlyContents state={formState} {...readOnlyProps} />
            )}
        </CheckoutSection>
    );
}

CheckoutForm.propTypes = {
    onChange: px.func,
    className: px.string,
    state: px.objectOf(px.any),
    readOnlyProps: px.objectOf(px.any),
    forceEdit: px.bool,
    onSave: px.func,
    title: px.node,
    children: px.node,
    ReadOnlyContents: px.elementType,
    initialStateIsValid: px.bool,
    initialState: px.objectOf(px.any),
    isFormRendered: px.bool,
    onUpdate: px.func,
    allowUpdates: px.bool,
    onCancelEdit: px.func,
    showNext: px.bool,
    allowCancelEditing: px.bool,
    hideSaveActions: px.bool,
    editState: px.arrayOf(px.oneOfType([px.bool, px.func])),
    onEditChange: px.func,
    onBeginEdit: px.func,
    onEndEdit: px.func,
    hideSaveButton: px.bool,
    disabled: px.bool,
};
