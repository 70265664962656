import px from 'prop-types';
import { useTranslation } from 'Common/hooks';

export default function Translation({ id, params = null, notFoundValue = id }) {
    const translation = useTranslation(id, params, { notFoundValue });

    return translation || null;
}

Translation.propTypes = {
    id: px.string,
    params: px.objectOf(px.any),
    notFoundValue: px.any,
};
