import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import * as types from 'Common/types';
import { Translation, Currency } from '../localization';

export default function CheckoutSummary({
    className,
    costs,
    summaryTitle,
    totalLabel,
    orderSummary = {},
    id = `CheckoutSummary_${v4()}`,
    currency,
}) {
    return (
        <div className={cx('CheckoutSummary', className)}>
            <h5 className="mt-0">{summaryTitle}</h5>
            <div className="container CheckoutSummary__Costs">
                {costs
                    ? costs.map((cost) =>
                          cost ? (
                              <div key={cost.key} className="row d-flex justify-content-between align-items-center">
                                  <p className="paragraph-2">
                                      <Translation id={cost.label} />
                                  </p>
                                  <p className="paragraph-2">
                                      <Currency amount={cost.value} code={currency} useParens="negative" />
                                  </p>
                              </div>
                          ) : null
                      )
                    : null}
                {orderSummary.giftCardDiscount ? (
                    <div className="row d-flex justify-content-between align-items-center">
                        <p className="paragraph-2">
                            <Translation id="Commerce.Order.Checkout.CheckoutSummary.GiftCardTotal.Label" />
                        </p>
                        <p className="paragraph-2">
                            <Currency
                                amount={orderSummary.giftCardDiscount * -1}
                                code={currency}
                                max={0}
                                useParens="negative"
                            />
                        </p>
                    </div>
                ) : null}
                <div className="row CheckoutSummary__Divider" />
                <div className="CheckoutSummary__total row d-flex justify-content-between align-items-center">
                    <p className="paragraph-2">
                        <strong>{totalLabel}</strong>
                    </p>
                    <p className="paragraph-2">
                        <strong>
                            <Currency
                                amount={orderSummary.totalSansGiftCards ?? orderSummary.total}
                                code={currency}
                                min={0}
                            />
                        </strong>
                    </p>
                </div>
            </div>
            <div className="CheckoutSummary__LineItems">
                <div className="accordion" id={`${id}_accordion`}>
                    {orderSummary.discounts?.length ? (
                        <>
                            <div className="card-header" id={`${id}_promotions_header`}>
                                <button
                                    className="btn d-flex justify-content-between"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#${id}_promotions_drawer`}
                                    aria-expanded="false"
                                    aria-controls={`${id}_promotions_drawer`}
                                >
                                    <p className="CheckoutSummary__promotions-list paragraph-2">
                                        <span>
                                            <Translation id="Commerce.Order.Checkout.CheckoutSummary.Discount.Label" />
                                        </span>
                                        <span className="ml-2">
                                            <i className="fa fa-chevron-down" aria-hidden="true" />
                                        </span>
                                    </p>
                                </button>
                            </div>
                            <div
                                id={`${id}_promotions_drawer`}
                                className="collapse"
                                aria-labelledby={`${id}_promotions_header`}
                                data-parent={`#${id}_accordion`}
                            >
                                <div className="CheckoutSummary__LineItems__Items">
                                    {orderSummary.discounts.map((promo) => (
                                        <div
                                            key={promo.code}
                                            className="CheckoutSummary__LineItems__Items__Item container"
                                        >
                                            <div className="row d-flex justify-content-between align-items-center pl-3">
                                                <p>{promo.label}</p>
                                                <p>
                                                    <Currency
                                                        amount={promo.value}
                                                        code={currency}
                                                        useParens="negative"
                                                        truncate
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    ) : null}
                    {orderSummary.lineItems?.length ? (
                        <div>
                            <div className="card-header" id={`${id}_lineitems_header`}>
                                <button
                                    className="btn d-flex justify-content-between"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#${id}_lineitems_drawer`}
                                    aria-expanded="false"
                                    aria-controls={`${id}_lineitems_drawer`}
                                >
                                    <p className="paragraph-2">
                                        <span>
                                            <Translation
                                                id={
                                                    orderSummary.lineItems?.length === 1
                                                        ? 'Commerce.Order.Checkout.CheckoutSummary.LineItems.Singular.Label'
                                                        : 'Commerce.Order.Checkout.CheckoutSummary.LineItems.Plural.Label'
                                                }
                                                params={{ count: orderSummary.lineItems?.length }}
                                            />
                                        </span>
                                        <span>
                                            <i className="fa fa-chevron-down ml-2" aria-hidden="true" />
                                        </span>
                                    </p>
                                </button>
                            </div>
                            <div
                                id={`${id}_lineitems_drawer`}
                                className="collapse"
                                aria-labelledby={`${id}_lineitems_header`}
                                data-parent={`#${id}_accordion`}
                            >
                                <div className="CheckoutSummary__LineItems__Items justify-content-center">
                                    {orderSummary.lineItems.map((item) => (
                                        <div
                                            key={`${item.Code}.${item.LineItemId}`}
                                            className="CheckoutSummary__LineItems__Items__Item my-2 container"
                                        >
                                            <div className="row d-flex justify-content-between align-items-center">
                                                <p className="col-6 text-left p-0 d-inline-block text-truncate">
                                                    <Translation
                                                        id="Commerce.Order.Checkout.CheckoutSummary.LineItem.Display.Label"
                                                        params={item}
                                                    />
                                                </p>
                                                <p className="col-2 text-center p-0">
                                                    <Currency amount={item.SalePrice} code={currency} />
                                                </p>
                                                <p className="col-2 text-center p-0">{item.Quantity}</p>
                                                <p className="col-2 text-right p-0">
                                                    <Currency amount={item.Total} code={currency} />
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

CheckoutSummary.propTypes = {
    className: px.string,
    costs: px.arrayOf(px.shape({ label: px.node, value: px.number })),
    summaryTitle: px.node,
    totalLabel: px.node,
    id: px.string,
    currency: px.string,
    orderSummary: types.OrderSummary,
};
