import * as React from 'react';
import px from 'prop-types';
import { Email } from 'Common/utils';
import { ADDRESS as FIELDS } from 'Common/constants/fields';
import { Translation } from 'Common/components/localization';
import Field from '../Field';

export default function DigitalAddressForm({ children, usePlaceholders = false, stretchEmail = false, scope = '' }) {
    const prefix = React.useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const labelProps = React.useCallback(
        (id, params) =>
            usePlaceholders
                ? { placeholder: id, placeholderParams: params }
                : { label: <Translation id={id} params={params} /> },
        [usePlaceholders]
    );

    return (
        <>
            <Field hidden name={`${prefix}${FIELDS.id}`} />
            <Field
                className="col-12 col-lg-6"
                name={`${prefix}${FIELDS.firstName}`}
                {...labelProps('Commerce.Order.Checkout.Address.FirstName.Label')}
                required
                hookAutofill
            />
            <Field
                className="col-12 col-lg-6"
                name={`${prefix}${FIELDS.lastName}`}
                {...labelProps('Commerce.Order.Checkout.Address.LastName.Label')}
                required
                hookAutofill
            />
            <Field
                className={stretchEmail ? 'col-12' : 'col-12 col-lg-6'}
                name={`${prefix}${FIELDS.email}`}
                validate={Email.getValidationError}
                {...labelProps('Commerce.Order.Checkout.Email.Input.Label')}
                required
                hookAutofill
            />
            {children}
        </>
    );
}

DigitalAddressForm.propTypes = {
    children: px.node,
    scope: px.string,
    stretchEmail: px.bool,
    usePlaceholders: px.bool,
};
