import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { ProductTitle as Title } from 'Common/components/product';
import { product } from '~features';

export default function ProductTitle({ productTitle = null, ...props }) {
    const productTitleLocal = useSelector(product.selectors.getChildTitle);

    return <Title {...props} productTitle={productTitle || productTitleLocal} />;
}

ProductTitle.propTypes = {
    productTitle: px.string,
};
