import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { customer, order } from '~features';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { NotificationSubscription } from 'Common/containers/customer';

export default function ProductQuickviewContainer({
    className,
    style,
    Component,
    useRecaptcha,
    googleRecaptchaV3Sitekey,
    viewType,
    ...props
}) {
    const isUserAuthenticated = useSelector(customer.selectors.isLoggedIn);
    const onAddItemToCart = useAction(order.actions.addItem);
    const onAddMultipleToCart = useAction(order.actions.addItems);

    return (
        <div style={style} className={cx(className, 'ProductQuickviewContainer container-fluid', viewType)}>
            <Component
                {...props}
                className={className}
                isUserAuthenticated={isUserAuthenticated}
                NotifyMe={NotificationSubscription}
                googleRecaptchaV3Sitekey={googleRecaptchaV3Sitekey}
                useRecaptcha={useRecaptcha}
                onAddItemToCart={onAddItemToCart}
                onAddMultipleToCart={onAddMultipleToCart}
            />
        </div>
    );
}

ProductQuickviewContainer.propTypes = {
    style: px.object,
    className: px.string,
    Component: px.node,
    useRecaptcha: px.bool,
    googleRecaptchaV3Sitekey: px.string,
    viewType: px.string,
};
