import * as React from 'react';
import px from 'prop-types';

export default function ProductManuals({
    data,
}) {
    
    return (
        <div className=''>
            {data.map((doc, index) => (
                <div className="d-flex" key={index}>
                    <a className="document d-flex" href={doc.Url} target="_blank" rel="noreferrer">
                        <i className="far fa-file-pdf mr-2"></i>
                        <p className='doc-name'>{doc.Url.split('/').slice(-1)}</p>
                    </a>
                    
                </div>
            ))}
        </div>
    )
}

ProductManuals.propTypes = {
    data: px.arrayOf(px.object),
};
