const MILE = { key: 'MILE', value: 'Mile', queryValue: 'Miles' };
const KILOMETER = { key: 'KILOMETER', value: 'Kilometer', queryValue: 'Kilometers' };

export const QUERY_UNIT = {
    [MILE.value]: MILE.queryValue,
    [KILOMETER.value]: KILOMETER.queryValue,
    [MILE.key]: MILE.queryValue,
    [KILOMETER.key]: KILOMETER.queryValue,
    [MILE.queryValue]: MILE.queryValue,
    [KILOMETER.queryValue]: KILOMETER.queryValue,
};

export const UNIT = {
    [MILE.value]: MILE.value,
    [KILOMETER.value]: KILOMETER.value,
    [MILE.key]: MILE.value,
    [KILOMETER.key]: KILOMETER.value,
    [MILE.queryValue]: MILE.value,
    [KILOMETER.queryValue]: KILOMETER.value,
};

export const RADIUS_OPTS = [10, 50, 100, 200];
