import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

export default function HTMLRender({ className, content }) {
    const innerRef = React.useRef();

    React.useEffect(() => {
        innerRef.current.innerHTML = content;
    }, [content]);

    return (
        <div>
            <div className={cx('ContentDisplay', className)} ref={innerRef}>
                Loading...
            </div>
        </div>
    );
}

HTMLRender.propTypes = {
    className: px.string,
    content: px.string,
};
