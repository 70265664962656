import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { CatalogExplore as Explore } from 'Common/components/catalog';
import { catalog } from '~features';
import { useAction } from 'Common/hooks';

export default function CatalogGrid({ gtmListValue, skipChildEnrichment = false, ...props }) {
    const items = useSelector(catalog.selectors.getFilteredProducts);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const loadMore = useAction(catalog.actions.loadMore);

    const onLoadMore = React.useCallback(() => loadMore(skipChildEnrichment), [loadMore, skipChildEnrichment]);

    return (
        <Explore {...props} gtmListValue={gtmListValue} pageInfo={pageInfo} products={items} onLoadMore={onLoadMore} />
    );
}

CatalogGrid.propTypes = {
    gtmListValue: px.string,
    viewDetails: px.string,
    skipChildEnrichment: px.bool,
};
