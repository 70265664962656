import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useViewport } from 'Common/hooks';
import { Product } from 'Common/types';
import { Formatter } from 'Common/utils';
import { Translation } from 'Common/components/localization';
import { ProductGallery, ProductTitle } from 'Common/components/product';

function SparePartDetailsGrid({ sparePartInfo }) {
    return (
        <div className="SparePartsPartial__Main__Details">
            <div className="detail-key">
                <Translation id="SpareParts.Table.PartCode.Label" />
                {':'}
            </div>
            <div>{sparePartInfo?.Code}</div>
            {sparePartInfo?.Price.ListPrice.Amount > 0 ? (
                <>
                    <div className="detail-key">
                        <Translation id="SpareParts.Table.Price.Label" />
                        {':'}
                    </div>
                    <div>
                        {Formatter.currency(
                            sparePartInfo?.Price.ListPrice.Amount,
                            sparePartInfo?.Price.ListPrice.Currency
                        )}
                    </div>
                </>
            ) : null}
        </div>
    );
}

SparePartDetailsGrid.propTypes = {
    sparePartInfo: Product,
};

export default function SparePartsPartial({
    className,
    Gallery = ProductGallery,
    galleryProps = {},
    sparePartInfo,
    sparePartTitle,
}) {
    const viewport = useViewport();
    const isMobile = React.useMemo(() => viewport.is.lt('md'), [viewport]);

    return (
        <div className={cx('SparePartsPartial container-fluid', className)}>
            {!isMobile ? (
                <>
                    <div className="SparePartsPartial__Title">
                        <ProductTitle productTitle={sparePartTitle} useLargeProductTitle={true} />
                    </div>
                    <div className="SparePartsPartial__Main">
                        <Gallery className={cx('col-12', 'col-md-8')} {...galleryProps} />
                        <SparePartDetailsGrid sparePartInfo={sparePartInfo} />
                    </div>
                </>
            ) : (
                <div>
                    <Gallery
                        className={cx('col-12', `col-${galleryProps.desktopBreakpoint ?? 'md'}-5`)}
                        {...galleryProps}
                    />
                    <div className="w-100">
                        <div className="SparePartsPartial__Title">
                            <ProductTitle productTitle={sparePartTitle} useLargeProductTitle={true} />
                        </div>
                        <div className="SparePartsPartial__Main">
                            <SparePartDetailsGrid sparePartInfo={sparePartInfo} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

SparePartsPartial.propTypes = {
    className: px.string,
    Gallery: px.elementType,
    galleryProps: px.objectOf(px.any),
    sparePartInfo: Product,
    sparePartTitle: px.string,
};
