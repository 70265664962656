import px from 'prop-types';
import CartShipment from './CartShipment';
import PaymentMethod from './PaymentMethod';
import Promotion from './Promotion';

export const CartFormShape = {
    Name: px.string,
    OrderNumber: px.string,

    PricesIncludeTax: px.bool,
    CapturedPaymentTotal: px.number,
    HandlingTotal: px.number,
    RequiredPaymentAmount: px.number,
    ShippingTotal: px.number,
    SubTotal: px.number,
    TaxTotal: px.number,
    Total: px.number,

    CouponCodes: px.arrayOf(px.string),
    AvailablePaymentMethods: px.arrayOf(PaymentMethod),
    Promotions: px.arrayOf(Promotion),

    Shipments: px.arrayOf(CartShipment),
};

export default px.shape(CartFormShape);
