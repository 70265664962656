import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { OrderLookup as Lookup } from 'Common/components/customer';
import { customer } from '~features';

export default function OrderLookup({
    orderEmailPlaceholder,
    orderEmailLabelText,
    orderNumberPlaceholder,
    orderNumber,
    orderEmail,
    buttonText,
    rmaPage,
}) {
    const orderDetail = useSelector(customer.selectors.getOrderDetail);
    const lastUpdate = useSelector(customer.selectors.getCustomerOrderLastUpdate);
    const setOrderHistory = useAction(customer.actions.setCustomerOrderHistory);
    const onSearchOrder = useAction(customer.actions.queryOrder);

    React.useEffect(() => {
        if (orderEmail && orderNumber) onSearchOrder(orderNumber, orderEmail);
    }, [orderEmail, orderNumber, onSearchOrder]);
    return (
        <Lookup
            buttonText={buttonText}
            orderDetail={orderDetail}
            setOrderHistory={setOrderHistory}
            orderEmail={orderEmail}
            orderNumber={orderNumber}
            orderNumberPlaceholder={orderNumberPlaceholder}
            orderEmailPlaceholder={orderEmailPlaceholder}
            orderEmailLabelText={orderEmailLabelText}
            rmaPage={rmaPage}
            lastUpdate={lastUpdate}
            onSubmit={onSearchOrder}
        />
    );
}

OrderLookup.propTypes = {
    orderEmailPlaceholder: px.string,
    orderEmailLabelText: px.string,
    orderNumberPlaceholder: px.string,
    orderNumber: px.string,
    orderEmail: px.string,
    buttonText: px.string,
    rmaPage: px.string,
};
