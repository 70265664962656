import * as React from 'react';
import px from 'prop-types';

export const VALID_TAB_CONTENT = {
    Includes: 'Includes',
    Description: 'Description',
    Features: 'Features',
    Specifications: 'Specifications',
    BuyInBulk: 'BuyInBulk',
};

export default function ContentSwitch({
    BuyInBulkTab,
    DescriptionTab,
    displayOnly,
    FeaturesTab,
    IncludesTab,
    onAddItemToCart,
    orderLastUpdate,
    productDefinitions,
    SpecificationsTab,
    specsTableBreakpoint,
    tab,
    totalQty = 0,
    ...props
}) {
    switch (tab.tab) {
        case VALID_TAB_CONTENT.Includes:
            return (
                <IncludesTab
                    typeId={tab.typeId}
                    facetTableData={tab.content}
                    onAddItemToCart={onAddItemToCart}
                    orderLastUpdate={orderLastUpdate}
                    showAddToCart={tab.showAddToCart}
                />
            );

        case VALID_TAB_CONTENT.Description:
            return <DescriptionTab {...props} data={tab.content} />;

        case VALID_TAB_CONTENT.Features:
            return <FeaturesTab {...props} features={tab.content} />;

        case VALID_TAB_CONTENT.Specifications:
            return (
                <SpecificationsTab
                    {...props}
                    specsTableBreakpoint={specsTableBreakpoint}
                    onAddItemToCart={displayOnly ? null : onAddItemToCart}
                    orderLastUpdate={orderLastUpdate}
                    specifications={tab.content}
                    productDefinitions={productDefinitions}
                    totalQty={totalQty}
                />
            );

        case VALID_TAB_CONTENT.BuyInBulk:
            return (
                <BuyInBulkTab
                    {...props}
                    onAddItemToCart={onAddItemToCart}
                    orderLastUpdate={orderLastUpdate}
                    buyInBulkTableData={tab.content}
                    totalQty={totalQty}
                />
            );
    }
}

ContentSwitch.propTypes = {
    tab: px.shape({
        tab: px.string,
        content: px.any,
        typeId: px.string,
        showAddToCart: px.bool,
    }),
    className: px.string,
    id: px.string,
    onAddItemToCart: px.func,
    orderLastUpdate: px.number,
    tabContent: px.arrayOf(px.object),
    DescriptionTab: px.elementType,
    specsTableBreakpoint: px.number,
    productDefinitions: px.arrayOf(px.string),
    BuyInBulkTab: px.elementType,
    FeaturesTab: px.elementType,
    SpecificationsTab: px.elementType,
    displayOnly: px.bool,
    IncludesTab: px.elementType,
    totalQty: px.number,
};
