import * as React from 'react';
import { Form } from 'informed';
import get from 'lodash.get';
import cx from 'classnames';
import px from 'prop-types';
import $ from 'jquery';
import * as types from 'Common/types';
import { useTranslation } from 'Common/hooks';
import { useExpDateFormat } from 'Common/hooks/payment';
import { CreditCard } from 'Common/utils';
import { PAYMENT, ADDRESS } from 'Common/constants/fields';
import { Modal /*, ToolTip */ } from '../ui';
import { Translation } from '../localization';
import PaymentBlock from '../checkout/PaymentBlock';
import { CreditCard as CardForm } from '../forms/Payment/types';

function ScrollToForm() {
    $('html, body').animate({ scrollTop: $('#CustomerPaymentManagerForm').offset().top });
}

function paymentName(pmt) {
    return (
        pmt[PAYMENT.customerName] ||
        [
            get(pmt, `${PAYMENT.address}.${ADDRESS.firstName}`, pmt[ADDRESS.firstName]),
            get(pmt, `${PAYMENT.address}.${ADDRESS.lastName}`, pmt[ADDRESS.lastName]),
        ]
            .filter(Boolean)
            .join(' ')
    );
}

function paymentSort(a, b) {
    if (!a) return 1;
    if (!b) return -1;
    if (a[PAYMENT.isDefault]) return -1;
    if (b[PAYMENT.isDefault]) return 1;

    const aName = paymentName(a);
    const bName = paymentName(b);

    if (aName < bName) return -1;
    if (bName < aName) return 1;
    return 0;
}

export default function CustomerPaymentManager({
    className,
    onFetchPayments,
    onSetDefault,
    onRemovePayment,
    onAddPayment,
    savedPayments,
    paymentMethod,
    useFullBillingAddress,
    defaultCountry,
    siteId,
}) {
    const formApi = React.useRef(null);
    const closeLabel = useTranslation('Commerce.Account.CancelLabel');
    const confirmLabel = useTranslation('Commerce.Account.ConfirmLabel');
    const headerLabel = useTranslation('Commerce.Account.DeleteModalLabel');
    const bodyText = useTranslation('Commerce.Account.DeleteCardModalBodyText');
    const [selectedCard, setSelectedCard] = React.useState(null);
    const lblDefault = useTranslation('Commerce.Account.DefaultPayment.Label');
    const lblRemove = useTranslation('Commerce.Account.DeletePayment.Label');
    const [isSavingCard, setIsSavingCard] = React.useState(false);
    const formatter = useExpDateFormat();

    const payments = React.useMemo(
        () =>
            savedPayments
                ? savedPayments
                      .filter((p) => p?.Id)
                      .map((payment) =>
                          CreditCard.paymentToForm({ payment, method: paymentMethod, formatter, savePayment: true })
                      )
                      .sort(paymentSort)
                : [],
        [savedPayments, paymentMethod, formatter]
    );

    const submitPayment = React.useCallback(
        async ({ values }) => {
            setIsSavingCard(true);
            await onAddPayment(values, paymentMethod);
            setSelectedCard(null);
            setIsSavingCard(false);
        },
        [onAddPayment, paymentMethod, setIsSavingCard]
    );

    const cancelPayment = React.useCallback(() => {
        setIsSavingCard(false);
        setSelectedCard(null);
    }, []);

    const onClick = React.useCallback(() => {
        setSelectedCard('new');
        ScrollToForm();
    }, [setSelectedCard]);

    React.useEffect(() => {
        onFetchPayments();
    }, [onFetchPayments]);

    return (
        <div className={cx('CustomerPaymentManager section', className)}>
            <div className="mt-2 pt-2">
                <button
                    className="btn btn-primary"
                    disabled={selectedCard === 'new' || !paymentMethod?.Options}
                    onClick={onClick}
                >
                    <span>
                        <i className="fas fa-plus" aria-hidden="true" />
                        &nbsp;
                        <strong>
                            <Translation id="Commerce.Account.AddPayment.Label" />
                        </strong>
                    </span>
                </button>
            </div>
            <div className="row mt-2 pt-2">
                {payments.length ? (
                    payments.map((payment) => (
                        <div
                            className="row col-md-12 col-lg-6 mt-2"
                            key={`saved-payment-${payment[PAYMENT.paymentId]}`}
                        >
                            <PaymentBlock
                                className={cx('col-12', { selected: payment[PAYMENT.paymentId] === selectedCard })}
                                showDefault
                                savedPayment={payment}
                                paymentMethod={paymentMethod}
                            />
                            <div className="col-12 mt-1">
                                <button
                                    className="btn btn-sm mr-1 p-1"
                                    onClick={() => onSetDefault(payment[PAYMENT.paymentId])}
                                    disabled={payment.IsDefault}
                                    aria-label={lblDefault}
                                    title={lblDefault}
                                >
                                    {/* <ToolTip
                                        className="paragraph-3 mx-3 mr-sm-2"
                                        tip="Commerce.Account.DefaultPayment.Label"
                                        trigger="hover"
                                        cursor="pointer"
                                    >
                                        <i className="fas fa-check-circle" />
                                    </ToolTip> */}

                                    <span>
                                        <i className="PaymentBlockIcon fas fa-check-circle" />
                                    </span>
                                </button>
                                <button
                                    className="btn btn-sm mr-1 p-1"
                                    data-toggle="modal"
                                    data-target="#deletePaymentMethodModal"
                                    onClick={() => setSelectedCard(payment[PAYMENT.paymentId])}
                                    title={lblRemove}
                                    aria-label={lblRemove}
                                >
                                    {/* <ToolTip
                                        className="paragraph-3 mx-3 mr-sm-2"
                                        tip="Commerce.Account.DeletePayment.Label"
                                        trigger="hover"
                                        cursor="pointer"
                                    >
                                        <i className="fas fa-trash" />
                                    </ToolTip> */}
                                    <span>
                                        <i className="PaymentBlockIcon fas fa-trash" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    ))
                ) : selectedCard !== 'new' ? (
                    <div className="col">
                        <Translation id="Commerce.Account.NoCardsSavedLabel" />
                    </div>
                ) : null}
            </div>
            <div id="CustomerPaymentManagerForm">
                {selectedCard === 'new' ? (
                    <div className="row mt-2 pt-2">
                        <div className="col-12">
                            <Form
                                style={{ width: '100%' }}
                                onSubmit={submitPayment}
                                formApiRef={formApi}
                                initialValues={{
                                    [PAYMENT.address]: { [ADDRESS.country]: defaultCountry ?? null },
                                }}
                            >
                                <div className="row">
                                    <CardForm
                                        autosave
                                        vertical
                                        options={paymentMethod.Options}
                                        useFullBillingAddress={useFullBillingAddress}
                                        // formCtx={formCtx}
                                        siteId={siteId}
                                        defaultCountry={defaultCountry}
                                        connected
                                    />
                                    <div className="Field col-12 mt-2">
                                        <button className="btn btn-primary" type="submit" disabled={isSavingCard}>
                                            <span>
                                                <Translation id="Commerce.Account.SaveLabel" />
                                            </span>
                                        </button>
                                        <button
                                            className="btn btn-secondary ml-1 ml-md-2"
                                            type="button"
                                            disabled={isSavingCard}
                                            onClick={cancelPayment}
                                        >
                                            <span>
                                                <Translation id="Commerce.Account.CancelLabel" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                ) : null}
            </div>
            <Modal
                onConfirm={() => {
                    onRemovePayment(selectedCard);
                    setSelectedCard(null);
                }}
                onClose={() => setSelectedCard(null)}
                closeLabel={closeLabel}
                confirmLabel={confirmLabel}
                header={headerLabel}
                body={bodyText}
                id="deletePaymentMethodModal"
            />
        </div>
    );
}

CustomerPaymentManager.propTypes = {
    className: px.string,
    onFetchPayments: px.func,
    onSetDefault: px.func,
    onRemovePayment: px.func,
    onAddPayment: px.func,
    siteId: px.string,
    savedPayments: px.arrayOf(types.Payment),
    useFullBillingAddress: px.bool,
    defaultCountry: px.string,
    paymentMethod: px.shape({
        DisplayName: px.string,
        Options: px.object,
        PaymentProvider: px.string,
        PaymentType: px.string,
        SavedPaymentInfo: px.array,
        SortOrder: px.number,
        SystemName: px.string,
    }),
};
