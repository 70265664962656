import * as React from 'react';
import px from 'prop-types';
import { BREAKPOINTS } from 'Common/utils/Viewport';
import * as types from 'Common/types';
import { useBEM, useViewport } from 'Common/hooks';
import { InfiniteScroll } from 'Common/components/ui';
import GridCard from './GridCard';

export const DEFAULT_CARD_SIZE = 376;

export function maxRowSize(bp, colSize = DEFAULT_CARD_SIZE) {
    const min = BREAKPOINTS[bp] || 0;

    return Math.max(1, Math.floor(min / (colSize + 24)));
}

export default function CatalogDetailGrid({
    pageInfo = {},
    className,
    colSize = DEFAULT_CARD_SIZE,
    products = [],
    NotifyMe,
    notifyProps,
    onLoadMore,
    gtmListValue,
    onAddItemToCart,
    onAddMultipleToCart,
    googleRecaptchaV3Sitekey,
    checkoutPageLink,
    isUserAuthenticated,
    inputStatusTooltip,
    noBuyNow,
    useRecaptcha,
    singleFacet,
    totalQty = 0,
}) {
    const viewPort = useViewport();
    const handler = React.useRef();
    const isDoneLoading = React.useMemo(() => pageInfo.page * pageInfo.pageSize >= pageInfo.total, [pageInfo]);
    const [b, em] = useBEM('CatalogDetailGrid', className);
    const rowSize = React.useMemo(() => maxRowSize(viewPort.name, colSize), [viewPort.name, colSize]);

    React.useEffect(() => () => clearTimeout(handler.current), []);

    return (
        <div className={b} style={{ '--grid-col-size': `${colSize}px` }}>
            <InfiniteScroll onLoad={onLoadMore} useLoadIndicator={true} isDoneLoading={isDoneLoading}>
                <div className={em('grid')} data-row-size={rowSize}>
                    {products.map((x) => (
                        <React.Fragment key={`ceg-${x.Code}`}>
                            <GridCard
                                className={em('grid__card')}
                                product={x}
                                NotifyMe={NotifyMe}
                                notifyProps={notifyProps}
                                gtmListValue={gtmListValue}
                                isUserAuthenticated={isUserAuthenticated}
                                useRecaptcha={useRecaptcha}
                                googleRecaptchaV3Sitekey={googleRecaptchaV3Sitekey}
                                onAddMultipleToCart={onAddMultipleToCart}
                                onAddItemToCart={onAddItemToCart}
                                inputStatusTooltip={inputStatusTooltip}
                                noBuyNow={noBuyNow}
                                singleFacet={singleFacet}
                                checkoutPageLink={checkoutPageLink}
                                totalQty={totalQty}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    );
}

CatalogDetailGrid.propTypes = {
    className: px.string,
    pageInfo: types.PageInfo,
    products: px.arrayOf(types.Product),
    onLoadMore: px.func,
    colSize: px.number,
    gtmListValue: px.string,
    NotifyMe: px.elementType,
    notifyProps: px.object,
    onAddItemToCart: px.func,
    onAddMultipleToCart: px.func,
    googleRecaptchaV3Sitekey: px.string,
    singleFacet: px.string,
    checkoutPageLink: px.string,
    isUserAuthenticated: px.bool,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    noBuyNow: px.bool,
    totalQty: px.number,
    useRecaptcha: px.bool,
};
