import { Api } from 'Common/utils';

export function searchWithCategory(query) {
    return Api.POST('/search/FindWithCategoryType', { TextQuery: query });
}

export function searchAllContent(query, params = null) {
    return Api.POST('/search/allcontent', query, { params });
}

export function contentSearch(query, page = 1, pageSize = undefined, sort = undefined, facets = []) {
    const params = {};

    for (const f of facets) {
        const selectedValues = f.options.filter((o) => o.selected).map((o) => o.value);

        if (!selectedValues.length) continue;
        params[f.id] = selectedValues;
    }

    return Api.POST(
        '/search/content',
        { TextQuery: query, PageSize: pageSize, Page: page, SortOrder: sort },
        { params }
    );
}

export function topSearches() {
    return Api.GET('/search/topsearches');
}

export function crossSiteSearch(crossSiteSearchData) {
    return Api.POST('/search/CrossSiteSearch', { ...crossSiteSearchData });
}
