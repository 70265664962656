import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useCatalogEntity } from 'Common/hooks';
import { Translation, Currency } from '../localization';
import { NumberInput } from '../ui';

export default function CartLineItem({
    viewport,
    shipment,
    item,
    hasChange,
    isEditable,
    lblAdd,
    lblEdit,
    editingItem,
    lblRemove,
    currency,
    getQty,
    onChangeQty,
    setEditingItem,
    gtmListValue,
    addItem,
    onDelete,
    QuickView,
    OOS,
    statusTooltip,
    fauxQuantity,
}) {
    const [productInfo, variantInfo] = useCatalogEntity(item.ParentCode, item.Code);
    const qty = React.useMemo(() => getQty(shipment, item), [getQty, shipment, item]);
    const dirty = React.useMemo(() => hasChange(shipment, item), [shipment, item, hasChange]);
    const onChange = React.useCallback(
        (num) => onChangeQty(shipment.ShipmentId, item.Code, num),
        [shipment.ShipmentId, item.Code, onChangeQty]
    );

    const onAddItem = React.useMemo(
        () =>
            addItem
                ? async () => {
                      await addItem(item, productInfo, item.Quantity, gtmListValue)();
                      window.location.reload();
                  }
                : null,
        [addItem, item, gtmListValue, productInfo]
    );

    const stockStatus = React.useMemo(
        () => ({
            hasStock: variantInfo?.StockStatus === 'InStock',
        }),
        [variantInfo]
    );

    const onEditItem = React.useCallback(() => setEditingItem(item.Code), [setEditingItem, item]);

    const onDeleteItem = React.useCallback(
        () => onDelete({ shipmentId: shipment.ShipmentId, itemCode: item.Code, faux: OOS }),
        [onDelete, shipment.ShipmentId, item.Code, OOS]
    );

    const onCancelEdit = React.useCallback(() => setEditingItem(null), [setEditingItem]);

    return (
        <div className={cx('row tablerow', OOS ? 'faux-item-bg' : '', { dirty })}>
            {viewport.is.mobile ? (
                <>
                    <div className="col-4 tablecell image p-0">
                        <img
                            className="img-responsive"
                            src={item.DefaultImageUrl || variantInfo?.DefaultImageUrl || productInfo?.DefaultImageUrl}
                            alt={item.DisplayName}
                        />
                    </div>

                    <div className="col-8">
                        <div className="col-12 tablecell name">
                            <span className="subheader">{item.DisplayName}</span>
                        </div>

                        {item.ListPrice > item.SalePrice ? (
                            <div className="col-12 tablecell price">
                                <p className="strike paragraph-2">
                                    <Currency amount={item.ListPrice} code={currency} />
                                </p>
                                <p className="paragraph-2">
                                    <Currency amount={item.SalePrice} code={currency} />
                                </p>
                            </div>
                        ) : (
                            <div className="col-12 tablecell price">
                                <p className="paragraph-2">
                                    <Currency amount={item.SalePrice} code={currency} />
                                </p>
                            </div>
                        )}

                        {!OOS ? (
                            <div className="col-12 tablecell quantity justify-content-start">
                                <span className="paragraph-2">
                                    <Translation id="Commerce.Order.Mobile.Quantity.Label" />
                                </span>
                                {item.IsGift || !isEditable ? (
                                    <p className="paragraph-2 d-inline font-weight-bold">{item.Quantity}</p>
                                ) : (
                                    <NumberInput
                                        min={item.MinQuantity ?? 0}
                                        max={item.MaxQuantity || undefined}
                                        noDecimal
                                        value={qty}
                                        inputMode="numeric"
                                        onChange={onChange}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>

                    {!item.IsGift && !OOS && isEditable ? (
                        <div className="col-12 update-btns">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <div className="row d-flex align-items-center justify-content-end">
                                        {productInfo && onAddItem ? (
                                            <button
                                                className="btn btn-add-to-cart"
                                                title={lblAdd}
                                                aria-label={lblAdd}
                                                disabled={
                                                    !item.Quantity || item.Quantity !== qty || !stockStatus.hasStock
                                                }
                                                onClick={onAddItem}
                                            >
                                                <i className="fas fa-cart-plus" />
                                            </button>
                                        ) : null}
                                        {productInfo?.Children?.length ? (
                                            <button
                                                className="btn btn-edit"
                                                title={lblEdit}
                                                aria-label={lblEdit}
                                                disabled={editingItem === item.Code}
                                                onClick={onEditItem}
                                            >
                                                <i className="fas fa-pen" />
                                            </button>
                                        ) : null}
                                        <button
                                            className="btn btn-del"
                                            title={lblRemove}
                                            aria-label={lblRemove}
                                            onClick={onDeleteItem}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    <div className="col-6 tablecell item">
                        <div className="row">
                            <div className="col-6 image p-0">
                                <img
                                    className="img-responsive"
                                    src={
                                        item.DefaultImageUrl ||
                                        variantInfo?.DefaultImageUrl ||
                                        productInfo?.DefaultImageUrl
                                    }
                                    alt={item.DisplayName}
                                />
                            </div>
                            <div className="col-6 name">
                                <span className="subheader">{item.DisplayName}</span>
                            </div>
                        </div>
                    </div>

                    {item.ListPrice > item.SalePrice ? (
                        <div className="col-2 tablecell price">
                            <p className="strike paragraph-2">
                                <Currency amount={item.ListPrice} code={currency} />
                            </p>
                            <p className="paragraph-2">
                                <Currency amount={item.SalePrice} code={currency} />
                            </p>
                        </div>
                    ) : (
                        <div className="col-2 tablecell price">
                            <p className="paragraph-2">
                                <Currency amount={item.SalePrice} code={currency} />
                            </p>
                        </div>
                    )}

                    {!OOS ? (
                        <>
                            <div className="col-2 tablecell quantity">
                                {item.IsGift || !isEditable ? (
                                    <p className="paragraph-2 d-inline font-weight-bold">{item.Quantity}</p>
                                ) : (
                                    <NumberInput
                                        min={item.MinQuantity ?? 0}
                                        max={item.MaxQuantity || undefined}
                                        noDecimal
                                        value={qty}
                                        onChange={onChange}
                                    />
                                )}
                            </div>
                            {item.ListPrice > item.SalePrice ? (
                                <div className="col-2 tablecell subtotal price">
                                    <p className="strike paragraph-2">
                                        <Currency amount={item.ListPrice * item.Quantity} code={currency} />
                                    </p>
                                    <p className="paragraph-2">
                                        <Currency amount={item.SalePrice * item.Quantity} code={currency} />
                                    </p>
                                </div>
                            ) : (
                                <div className="col-2 tablecell subtotal price">
                                    <p className="paragraph-2">
                                        <Currency amount={item.SalePrice * item.Quantity} code={currency} />
                                    </p>
                                </div>
                            )}
                            <div className="col-12 additional-info">
                                {item.AdditionalInfo
                                    ? item.AdditionalInfo.map((info) => (
                                          <div key={info} className="row">
                                              <p className="mb-0 paragraph-2">{info}</p>
                                          </div>
                                      ))
                                    : null}
                            </div>
                            {!item.IsGift && isEditable ? (
                                <div className="col-12 update-btns">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <div className="row d-flex align-items-center justify-content-end">
                                                {productInfo?.Children?.length && onAddItem ? (
                                                    <button
                                                        className="btn btn-add-to-cart"
                                                        title={lblAdd}
                                                        aria-label={lblAdd}
                                                        disabled={
                                                            !item.Quantity ||
                                                            item.Quantity !== qty ||
                                                            !stockStatus.hasStock
                                                        }
                                                        onClick={onAddItem}
                                                    >
                                                        <i className="fas fa-cart-plus" />
                                                    </button>
                                                ) : null}
                                                {productInfo?.Children?.length ? (
                                                    <button
                                                        className="btn btn-edit"
                                                        title={lblEdit}
                                                        aria-label={lblEdit}
                                                        disabled={editingItem === item.Code}
                                                        onClick={onEditItem}
                                                    >
                                                        <i className="fas fa-pen" />
                                                    </button>
                                                ) : null}
                                                <button
                                                    className="btn btn-del"
                                                    title={lblRemove}
                                                    aria-label={lblRemove}
                                                    onClick={onDeleteItem}
                                                >
                                                    <i className="fas fa-trash-alt" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </>
            )}
            {OOS ? (
                <div className="col-12 col-lg-4 tablecell options pt-lg-0">
                    <div className="row mb-2">
                        <div className="col-12 name">
                            <span className="subheader">
                                {!item.IsGift ? (
                                    <strong>
                                        <Translation id="Commerce.Order.Cart.OutOfStock.Options.Label" />
                                    </strong>
                                ) : null}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 option-line">
                            {!item.IsGift && productInfo?.Children?.length ? (
                                <button
                                    className="btn btn-link p-1"
                                    title={lblEdit}
                                    aria-label={lblEdit}
                                    disabled={editingItem === item.Code}
                                    onClick={onEditItem}
                                >
                                    <i className="fas fa-pen mr-1" aria-hidden="true" />
                                    <Translation id="Commerce.Order.Cart.OutOfStock.PickAlternate.Label" />
                                </button>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 option-line">
                            {!item.IsGift ? (
                                <button
                                    className="btn btn-link p-1"
                                    title={lblRemove}
                                    aria-label={lblRemove}
                                    onClick={onDeleteItem}
                                >
                                    <i className="fas fa-trash-alt mr-1" aria-hidden="true" />
                                    {lblRemove}
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}

            {item.ValidationMessage ? (
                <div className="col-12 h-100">
                    <div className="row h-100 mt-3 mr-5 ml-5 mb-0 p-0 justify-content-center align-items-center alert alert-danger">
                        <p className="mb-0 text-center paragraph-2">{item.ValidationMessage}</p>
                    </div>
                </div>
            ) : null}

            {editingItem === item.Code ? (
                <div className="quickview col-12">
                    <QuickView
                        originalItemCode={item.Code}
                        shipmentId={shipment.ShipmentId}
                        productData={productInfo}
                        variantData={OOS ? null : variantInfo}
                        onClose={onCancelEdit}
                        quantity={OOS ? fauxQuantity : qty}
                        itemStatusTooltip={statusTooltip}
                    />
                </div>
            ) : null}
        </div>
    );
}

CartLineItem.propTypes = {
    viewport: px.shape({ is: px.shape({ lt: px.function, lte: px.function, mobile: px.bool }) }),
    item: types.CartLineItem,
    shipment: types.Shipment,
    getQty: px.func,
    setEditingItem: px.func,
    addItem: px.func,
    hasChange: px.func,
    lblAdd: px.string,
    lblEdit: px.string,
    editingItem: px.string,
    lblRemove: px.string,
    onChangeQty: px.func,
    onDelete: px.func,
    currency: px.string,
    isEditable: px.bool,
    QuickView: px.elementType,
    gtmListValue: px.string,
    OOS: px.bool,
    statusTooltip: px.string,
    fauxQuantity: px.oneOfType([px.string, px.number]),
};
