import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';

export default function ButtonSelector({
    className,
    options = [],
    selectedValues = [],
    title = '',
    onSelect,
    outOfStockToolTip,
    notAvailableToolTip,
    disabledMap = {},
    definition = null,
}) {
    const id = React.useRef(`button_${v4()}`);

    const optionIsDisabled = React.useCallback(
        ({ value } = {}) =>
            disabledMap && (disabledMap === true || disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid),
        [disabledMap]
    );

    const onClick = React.useCallback((value) => (onSelect ? () => onSelect(value) : null), [onSelect]);

    const buttonTitle = React.useCallback(
        (value) => {
            if (disabledMap && (disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid))
                return notAvailableToolTip;
            else if (disabledMap && !disabledMap[value]?.hasStock) return outOfStockToolTip;

            return value;
        },
        [outOfStockToolTip, notAvailableToolTip, disabledMap]
    );

    return (
        <div className={cx('ButtonSelector', className)}>
            <p className="mb-0">{title}</p>
            <div className="button-group mt-2">
                {options.map((option) => (
                    <button
                        type="button"
                        role="button"
                        data-toggle="tooltip"
                        key={option.value}
                        title={buttonTitle(option.value)}
                        name={`${title}_ButtonSelector`}
                        id={`${id.current}${option.value.replace(/\s+/g, '')}`}
                        className={cx('btn selector-btn', {
                            selected: selectedValues.includes(option.value),
                            oos: !disabledMap[option.value]?.hasStock,
                            disabled: !disabledMap[option.value]?.hasStock || optionIsDisabled(option),
                        })}
                        onClick={onClick(option.value)}
                    >
                        <span>{option.label}</span>
                    </button>
                ))}
            </div>
            {definition ? (
                <div className="definition mt-2">
                    <p className="light-sm">{definition}</p>
                </div>
            ) : null}
        </div>
    );
}

ButtonSelector.propTypes = {
    className: px.string,
    options: px.arrayOf(px.shape({ label: px.string, value: px.string })),
    selectedValues: px.arrayOf(px.oneOfType([px.string, px.number])),
    title: px.string,
    onSelect: px.func,
    outOfStockToolTip: px.string,
    notAvailableToolTip: px.string,
    disabledMap: px.objectOf(px.object),
    definition: px.node,
};
