import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { customer } from '~features';
import { OrderDetail as Detail } from 'Common/components/customer';

export default function OrderDetail({ className, rmaPage }) {
    const orderDetail = useSelector(customer.selectors.getOrderDetail);

    return <Detail className={className} orderDetail={orderDetail} rmaPage={rmaPage} />;
}

OrderDetail.propTypes = {
    className: px.string,
    rmaPage: px.string,
};
