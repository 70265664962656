import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Translation } from 'Common/components/localization';
import RMARequestTable from './RMARequestTable';
import CheckoutSummary from '../checkout/CheckoutSummary';
import { useTranslation } from 'Common/hooks';

const TEXT_AREA_CHAR_LIMIT = 500;

export default function RMARequest({
    className,
    customerOrderLastUpdate,
    orderDetail,
    onSubmitReturn,
    rmaReasonCodes,
    rmaRequestSuccess,
}) {
    const customerNoteLbl = useTranslation('Commerce.Order.Return.Notes.Placeholder');
    const summaryTitle = useTranslation('Commerce.Order.Return.Summary.Title');
    const totalLabel = useTranslation('Commerce.Order.Detail.Summary.Total.Label');
    const subtotalLabel = useTranslation('Commerce.Order.Detail.Summary.Subtotal.Label');
    const dirty = React.useRef(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [returnRequestPrices, setReturnRequestPrices] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [textAreaCharacterCount, setTextAreaCharacterCount] = React.useState(0);
    const [returnRequest, setReturnRequest] = React.useState({
        LineItems: [],
        Notes: null,
        OrderNumber: orderDetail.OMSOrderReference,
        OrderEmail: orderDetail.OrderEmail,
    });

    //Memoized Values:
    const hasReturnableItems = React.useMemo(
        () =>
            orderDetail.Forms.reduce(
                (available, form) =>
                    available ||
                    form.Shipments.reduce(
                        (availableShipment, shipment) =>
                            availableShipment ||
                            shipment.LineItems.reduce(
                                (availableItem, item) => availableItem || item.ReturnQuantity < item.Quantity,
                                false
                            ),
                        false
                    ),
                false
            ),
        [orderDetail]
    );

    const total = React.useMemo(
        () =>
            returnRequest.LineItems?.reduce(
                (t, item) => (returnRequestPrices[item.Code] ? t + returnRequestPrices[item.Code] : t),
                0
            ),
        [returnRequestPrices, returnRequest.LineItems]
    );

    const costs = React.useMemo(
        () => [
            {
                label: subtotalLabel,
                key: 'subtotal',
                value: total,
            },
        ],
        [total, subtotalLabel]
    );

    const discounts = React.useMemo(
        () =>
            orderDetail?.Forms?.reduce(
                (arr, form) =>
                    arr.concat(
                        form.Promotions.reduce((promos, p) => {
                            const existing = promos.find(({ id }) => id === p.PromotionGuid);

                            if (existing) {
                                existing.value += p.SavedAmount;
                            } else {
                                promos.push({
                                    value: p.SavedAmount,
                                    label: p.Description || p.CouponCode,
                                    id: p.PromotionGuid,
                                    code: p.CouponCode,
                                });
                            }

                            return promos;
                        }, [])
                    ),
                []
            ) ?? [],
        [orderDetail]
    );

    const isValidReturn = React.useMemo(() => {
        if (isLoading) return false;
        if (returnRequest.LineItems?.length) {
            for (let i = 0; i < returnRequest.LineItems.length; i++) {
                if (returnRequest.LineItems[i].ReasonCode && returnRequest.LineItems[i].Quantity) continue;
                return false;
            }
            return true;
        }
        return false;
    }, [returnRequest, isLoading]);

    // Line Item Updates:
    const onQtyChange = React.useCallback(
        (qty, code, price) => {
            if (returnRequest.LineItems?.find((item) => item.Code === code)) {
                const newItems = returnRequest.LineItems.reduce((acc, item) => {
                    if (item.Code === code) {
                        if (qty === 0) return acc;
                        item.Quantity = qty;
                    }

                    return acc.concat(item);
                }, []);

                setReturnRequestPrices({ ...returnRequestPrices, [code]: price * qty });
                setReturnRequest({ ...returnRequest, LineItems: newItems });
            } else if (qty > 0) {
                setReturnRequestPrices({ ...returnRequestPrices, [code]: price * qty });
                setReturnRequest({
                    ...returnRequest,
                    LineItems: [
                        ...returnRequest.LineItems,
                        {
                            Code: code,
                            Quantity: qty,
                            ReasonCode: null,
                            ReasonText: null,
                        },
                    ],
                });
            }
        },
        [returnRequest, returnRequestPrices]
    );

    const onNotesChange = React.useCallback(
        (note) => {
            setTextAreaCharacterCount(note.length);
            setReturnRequest({
                ...returnRequest,
                Notes: note,
            });
        },
        [returnRequest]
    );

    const onSelectReason = React.useCallback(
        (reason, productCode) => {
            if (returnRequest?.LineItems.find((item) => item.Code === productCode)) {
                const newItems = returnRequest.LineItems.reduce((acc, item) => {
                    if (item.Code === productCode) {
                        item.ReasonCode = reason.Value;
                        item.ReasonText = reason.Text;
                    }

                    return acc.concat(item);
                }, []);

                setReturnRequest({ ...returnRequest, LineItems: newItems });
            } else {
                setReturnRequest({
                    ...returnRequest,
                    LineItems: [
                        ...returnRequest.LineItems,
                        {
                            Code: productCode,
                            Quantity: null,
                            ReasonCode: reason.Value,
                            ReasonText: reason.Text,
                        },
                    ],
                });
            }
        },
        [returnRequest]
    );

    const onSubmit = React.useCallback(() => {
        setIsLoading(true);
        dirty.current = true;
        return onSubmitReturn(returnRequest);
    }, [onSubmitReturn, returnRequest]);

    const createdDate = React.useMemo(() => new Date(orderDetail.Created).toDateString(), [orderDetail.Created]);

    React.useEffect(() => {
        setIsLoading(false);
        if (!rmaRequestSuccess && dirty.current) setShowErrorMessage(true);
    }, [customerOrderLastUpdate, rmaRequestSuccess]);

    return orderDetail ? (
        <div className={cx('RMARequest', className)}>
            <div className="container">
                <div className="row d-flex justify-content-between">
                    <div className="col-12 col-md-6 d-flex align-items-center p-0">
                        <div className="table order-detail-head">
                            <div className="row">
                                <div className="col-4">
                                    <h5 className="d-inline">
                                        <Translation id="Commerce.Order.Return.OrderDisplay.Label" />:
                                    </h5>
                                </div>
                                <div className="col">
                                    <h5 className="d-inline">{`${orderDetail.OrderNumber}`}</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4"></div>
                                <div className="col">
                                    <h5 className="d-inline">{createdDate}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!hasReturnableItems ? (
                <h6>
                    <Translation id="Commerce.Order.Return.NoItemsToReturn.Text" />
                </h6>
            ) : null}
            <RMARequestTable
                currency={orderDetail.Currency}
                Forms={orderDetail.Forms}
                isLoading={isLoading}
                onReasonSelect={onSelectReason}
                onChangeQty={onQtyChange}
                returnRequest={returnRequest}
                rmaReasonCodes={rmaReasonCodes}
            />
            <CheckoutSummary
                costs={costs}
                currency={orderDetail?.Currency}
                orderSummary={{ discounts, total }}
                summaryTitle={summaryTitle}
                totalLabel={totalLabel}
            />
            <div className="customer-notes">
                <p className="paragraph-3 count">{`${textAreaCharacterCount}/${TEXT_AREA_CHAR_LIMIT}`}</p>
                <textarea
                    autoCapitalize="sentences"
                    spellCheck={true}
                    maxLength={TEXT_AREA_CHAR_LIMIT}
                    className="note"
                    rows="2"
                    placeholder={customerNoteLbl}
                    onChange={(e) => onNotesChange(e.target.value.toString())}
                    disabled={isLoading}
                ></textarea>
            </div>
            <button disabled={!isValidReturn} onClick={onSubmit} className="btn btn-md btn-primary my-4">
                <Translation id="Commerce.Order.Return.Submit.Button.Label" />
            </button>
            {showErrorMessage ? (
                <p className="paragraph-2 alert-danger p-2 border border-danger d-table-cell">
                    <Translation id="Commerce.Order.Return.Failure.Message" />
                </p>
            ) : null}
        </div>
    ) : null;
}

RMARequest.propTypes = {
    className: px.string,
    customerOrderLastUpdate: px.number,
    onSubmitReturn: px.func,
    orderDetail: px.object,
    rmaReasonCodes: px.arrayOf(
        px.shape({
            Disabled: px.bool,
            Group: px.object,
            Selected: px.bool,
            Text: px.string,
            Value: px.string,
        })
    ),
    rmaRequestSuccess: px.bool,
};
