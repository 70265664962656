import React, { useEffect, useRef } from 'react';
import px from 'prop-types';

export const withResize = (Component) => {
    function ResizeableComponent({ onResize, ...props }) {
        const ref = useRef(null);

        useEffect(() => {
            const el = ref.current;

            if (el == null) return;
            const observer = new ResizeObserver((entries) => {
                if (!entries.length) return;
                onResize(el);
            });

            observer.observe(el);
            onResize(el);
            return () => {
                observer.unobserve(el);
            };
        }, [onResize]);

        return <Component ref={ref} {...props} />;
    }

    ResizeableComponent.propTypes = {
        onResize: px.func,
    };

    return ResizeableComponent;
};
