import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import * as types from 'Common/types';
import { Drawer } from 'Common/components/ui';
import { ADDRESS } from 'Common/constants/fields';
import { Translation } from 'Common/components/localization';

function Address({ address, ignoreWarnings = false } = {}) {
    const {
        [ADDRESS.line1]: line1,
        [ADDRESS.line2]: line2,
        [ADDRESS.city]: city,
        [ADDRESS.state]: region,
        [ADDRESS.zip]: zip,
        [ADDRESS.country]: country,
        [ADDRESS.isStreetAddress]: isStreet,
    } = React.useMemo(() => address || {}, [address]);

    return (
        <div className="address">
            {!ignoreWarnings && !isStreet ? (
                <div className="alert alert-danger">
                    <Translation id="Commerce.Order.Checkout.ShippingAddressVerification.StreetAddressWarning" />
                </div>
            ) : null}
            <div>{line1}</div>
            {line2 ? <div>{line2}</div> : null}
            <div>
                {city}, {region || ''} {zip}
            </div>
            <div>{country}</div>
        </div>
    );
}

Address.propTypes = { address: types.Address, ignoreWarnings: px.bool };

export default function AddressVerificationDrawer({
    className,
    address,
    verifiedAddresses = [],
    onSelect,
    onCancel,
    open,
    anchor = 'right',
    allowPostal: ignoreWarnings = false,
}) {
    const disabled = React.useMemo(
        () => !ignoreWarnings && verifiedAddresses?.length === 1 && !verifiedAddresses[0].IsStreetAddress,
        [verifiedAddresses, ignoreWarnings]
    );

    const onKeyDown = React.useCallback(
        (a, d = false) =>
            !d && onSelect && (a?.IsStreetAddress !== false || ignoreWarnings)
                ? (e) => {
                      if (e.key === 'Enter') {
                          onSelect(a);
                      }
                  }
                : null,
        [onSelect, ignoreWarnings]
    );

    const onClick = React.useCallback(
        (a, d = false) =>
            !d && onSelect && (a?.IsStreetAddress !== false || ignoreWarnings) ? () => onSelect(a) : null,
        [onSelect, ignoreWarnings]
    );

    React.useEffect(() => {
        if (open && verifiedAddresses.length === 1 && address == null) {
            onSelect(verifiedAddresses[0]);
        }
    }, [address, open, onSelect, verifiedAddresses]);

    return (
        <Drawer anchor={anchor} className={cx('AddressVerificationDrawer', className)} open={open} backdrop>
            <h4>
                <Translation id="Commerce.Order.Checkout.ShippingAddressVerification.Title" />
            </h4>
            <p className="body2">
                <Translation
                    id={
                        !verifiedAddresses?.length
                            ? 'Commerce.Order.Checkout.ShippingAddressVerification.UnverifiedDescription'
                            : 'Commerce.Order.Checkout.ShippingAddressVerification.Description'
                    }
                />
            </p>
            <div className="separator" />
            {address ? (
                <div
                    className={cx('AddressVerificationDrawer__ListItem dotted', { disabled })}
                    role="button"
                    tabIndex="0"
                    onClick={onClick(address, disabled)}
                    onKeyDown={onKeyDown(address, disabled)}
                    style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                >
                    <div className="icon">
                        <i className="fas fa-map-marker-alt" />
                    </div>
                    <Address address={address} ignoreWarnings />
                </div>
            ) : null}
            {verifiedAddresses.map((a, i) => (
                <div
                    key={JSON.stringify(a)}
                    className={cx('AddressVerificationDrawer__ListItem', {
                        disabled: !ignoreWarnings && !a?.IsStreetAddress,
                    })}
                    style={{ cursor: !ignoreWarnings && !a?.IsStreetAddress ? 'not-allowed' : 'pointer' }}
                    role="button"
                    tabIndex={i + 1}
                    onClick={onClick(a)}
                    onKeyDown={onKeyDown(a)}
                >
                    {ignoreWarnings || a.IsStreetAddress ? (
                        <div className="icon green">
                            <i className="fas fa-check" />
                        </div>
                    ) : (
                        <div className="icon red">
                            <i className="fas fa-times" />
                        </div>
                    )}
                    <Address address={a} ignoreWarnings={ignoreWarnings} />
                </div>
            ))}
            <button className="btn btn-lg" onClick={onCancel}>
                <span>
                    <i className="fas fa-caret-left mr-2" aria-hidden="true" />
                    <Translation id="Commerce.Order.Checkout.ShippingAddressVerification.Cancel" />
                </span>
            </button>
        </Drawer>
    );
}

AddressVerificationDrawer.propTypes = {
    className: px.string,
    open: px.bool,
    address: types.Address,
    verifiedAddresses: px.arrayOf(types.Address),
    allowPostal: px.bool,
    onSelect: px.func,
    onCancel: px.func,
    anchor: px.string,
};
