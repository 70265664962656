import { useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { product } from '~features';
import useAction from './useAction';

export const entityCache = {};

export default function useCatalogEntity(productCode, childCode) {
    const didFetch = useRef(false);
    const didFetchChild = useRef(false);

    const fetchProductInfo = useAction(product.actions.fetchProductDetails);

    const productSelector = useCallback((state) => product.selectors.getProductById(state, productCode), [productCode]);
    const variantSelector = useCallback((state) => product.selectors.getProductById(state, childCode), [childCode]);

    const productInfo = useSelector(productSelector);
    const variantInfo = useSelector(variantSelector);

    useEffect(() => {
        if (productCode && !didFetch.current && !productInfo && !entityCache[productCode]) {
            didFetch.current = true;
            entityCache[productCode] = true;
            fetchProductInfo(productCode);
        }
    }, [productCode, productInfo, fetchProductInfo]);

    useEffect(() => {
        if (productInfo && childCode && !didFetchChild.current && !variantInfo && !entityCache[childCode]) {
            didFetchChild.current = true;
            entityCache[childCode] = true;
            fetchProductInfo(childCode);
        }
    }, [fetchProductInfo, childCode, productInfo, variantInfo]);

    return [productInfo, variantInfo];
}
