import React from 'react';
import px from 'prop-types';
import cx from 'classnames';

export default function Stack({ children, dividers, direction = 'column', gap = 0, className, style }) {
    return (
        <div
            style={style}
            className={cx(
                className,
                'Stack',
                dividers && 'Stack--dividers',
                `Stack--direction-${direction}`,
                `Stack--gap-${gap}`
            )}
        >
            {dividers
                ? React.Children.map(children, (el, i) =>
                      i > 0 && el != null ? (
                          <>
                              <div className={cx('Stack__divider', `Stack__divider--direction-${direction}`)}></div>
                              {el}
                          </>
                      ) : (
                          el
                      )
                  )
                : children}
        </div>
    );
}

Stack.propTypes = {
    children: px.arrayOf(px.node),
    direction: px.oneOf(['row', 'column']),
    dividers: px.bool,
    gap: px.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
    style: px.object,
    className: px.string,
};
