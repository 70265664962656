import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useViewport } from 'Common/hooks';
import { Translation } from '../localization';

export default function CatalogQuery({
    className,
    facets = [],
    onClearFacetValue,
    pageInfo = null,
    desktopBreakpoint = 'lg',
    onClearAll,
    showPageInfo = true,
}) {
    const viewport = useViewport();
    const onClearFilter = React.useCallback(
        (facet, value) => () => (onClearFacetValue ? onClearFacetValue(facet, value) : null),
        [onClearFacetValue]
    );

    const values = React.useMemo(
        () =>
            facets.reduce(
                (arr, f) => arr.concat(f.Values.filter((v) => v.Selected).map((v) => ({ facet: f, value: v }))),
                []
            ),
        [facets]
    );

    if (viewport.is.lt(desktopBreakpoint)) {
        return (
            <div className={cx('CatalogQuery', 'Mobile', className)}>
                {showPageInfo && pageInfo ? (
                    <div className="CatalogQuery__label">
                        {pageInfo.count ? (
                            <Translation id="Commerce.CatalogNode.Filters.ShowingItems" params={pageInfo} />
                        ) : (
                            <Translation id="Commerce.CatalogNode.Filters.NoItems" />
                        )}
                    </div>
                ) : null}
                <div className="CatalogQuery__filters">
                    {values?.length
                        ? values.map(({ facet, value }) => (
                              <div className="CatalogQuery__filter" key={`${facet.PropertyName}:${value.Value}`}>
                                  <div className="btn-clear" onClick={onClearFilter(facet, value)}>
                                      <i className="fas fa-times-circle" />
                                  </div>
                                  <span>
                                      {facet.DisplayName}: {value.Label ?? value.Value}
                                  </span>
                              </div>
                          ))
                        : null}
                </div>
                {values?.length ? (
                    <button className="btn btn-link btn-clear-all" onClick={onClearAll}>
                        <span>
                            <Translation id="Commerce.CatalogNode.Filters.ClearFilters" />
                        </span>
                    </button>
                ) : null}
            </div>
        );
    }

    return values?.length ? (
        <div className={cx('CatalogQuery', className)}>
            <section className="container">
                <div className="CatalogQuery__label">
                    <Translation id="Commerce.CatalogNode.Filters.FilterBy" />
                </div>
                <div className="CatalogQuery__filters">
                    {values.map(({ facet, value }) => (
                        <div className="CatalogQuery__filter" key={`${facet.PropertyName}:${value.Value}`}>
                            <div className="btn-clear" onClick={onClearFilter(facet, value)}>
                                <i className="fas fa-times-circle" />
                            </div>
                            <span>
                                {facet.DisplayName}: {value.Label ?? value.Value}
                            </span>
                        </div>
                    ))}
                </div>
                <button className="btn btn-link btn-clear-all" onClick={onClearAll}>
                    <span>
                        <Translation id="Commerce.CatalogNode.Filters.ClearFilters" />
                    </span>
                </button>
            </section>
        </div>
    ) : null;
}

CatalogQuery.propTypes = {
    className: px.string,
    facets: px.arrayOf(types.Facet),
    onClearFacetValue: px.func,
    onClearAll: px.func,
    pageInfo: px.shape({ count: px.number }),
    desktopBreakpoint: px.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    showPageInfo: px.bool,
};
