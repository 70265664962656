import * as React from 'react';
import px from 'prop-types';
import { Translation } from 'Common/components/localization';
import { HTMLRender } from 'Common/components/ui';

export default function Locations({ locations, disclaimerText, locationContent }) {
    const sections = React.useMemo(() => {
        let prev = {};

        return locations?.reduce((acc, l, i) => {
            if (prev.Extended?.Address.County === l.Extended.Address.County) {
                prev = l;
                return acc;
            }
            prev = l;
            return {
                ...acc,
                [i]: l.Extended.Address.County,
            };
        }, {});
    }, [locations]);

    return locations?.length ? (
        <div className="locations_container">
            <HTMLRender content={locationContent} />
            <div className="divider"></div>
            <div className="contact-list-wrap">
                <ul className="locations" id="locationsAccordion">
                    {locations?.map((location, i) => (
                        <React.Fragment key={location.Id}>
                            {Object.keys(sections).length > 1 && sections[i] ? (
                                <h5 className="section">{sections[i]}</h5>
                            ) : null}
                            <li className="location">
                                <div className="row align-items-center preview">
                                    <div className="col-lg-6">
                                        <h6>{location.Name}</h6>
                                        <p className="paragraph-2">
                                            {location.Extended.Address.Line1}, {location.Extended.Address.Locality}{' '}
                                            {location.Extended.Address.County}, {location.Extended.Address.PostalCode}
                                        </p>
                                    </div>
                                    <div className="col-lg-6 text-center text-xl-right">
                                        <div className="inner">
                                            {location.Extended.Phone ? (
                                                <a href={`tel:${location.Extended.Phone.trim()}`} className="call">
                                                    <i className="fas fa-phone mr-1" aria-hidden="true"></i>
                                                    {location.Extended.Phone}
                                                </a>
                                            ) : null}
                                            <button
                                                className="btn btn-blue btn-sm email"
                                                type="button"
                                                id={`${location.Id}_Info`}
                                                data-toggle="collapse"
                                                data-target={`#Dropdown_${location.Id}_Dropdown`}
                                                aria-controls={location.Id}
                                            >
                                                <i className="fas fa-info" aria-hidden="true"></i>{' '}
                                                <Translation id="Dealer.Locator.Location.Info.Button.Text" />
                                            </button>
                                            {location.Extended.Website ? (
                                                <a
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={location.Extended.Website}
                                                    className="btn btn-yellow btn-sm"
                                                >
                                                    <Translation id="Dealer.Locator.Location.Website.Button.Text" />{' '}
                                                    <i className="fas fa-angle-right" aria-hidden="true"></i>
                                                </a>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id={`Dropdown_${location.Id}_Dropdown`}
                                    data-parent="#locationsAccordion"
                                    className="row dropdown px-3 accordion-collapse collapse"
                                    aria-labelledby={`${location.Id}_Info`}
                                >
                                    <p className="mt-3">{location.Extended.Description}</p>
                                    <div className="col-12 col-xl-6 pl-xl-0">
                                        {disclaimerText ? (
                                            <div className="disclaimer">
                                                <p>{disclaimerText}</p>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-12 col-xl-6 pr-xl-0">
                                        {location.Extended.BusinessHours?.length ? (
                                            <div className="table_container">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                <Translation id="Dealer.Locator.Location.BusinessHours.Day.Text" />
                                                            </th>
                                                            <th scope="col">
                                                                <Translation id="Dealer.Locator.Location.BusinessHours.Open.Text" />
                                                            </th>
                                                            <th scope="col">
                                                                <Translation id="Dealer.Locator.Location.BusinessHours.Close.Text" />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {location.Extended.BusinessHours.map((bh) => (
                                                            <tr key={bh.DayOfWeek}>
                                                                <th scope="row">{bh.DayOfWeek}</th>
                                                                <td>
                                                                    {bh.Closed ? (
                                                                        <Translation id="Dealer.Locator.Location.DayOfWeek.Closed" />
                                                                    ) : (
                                                                        bh.OpenTime
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {bh.Closed ? (
                                                                        <Translation id="Dealer.Locator.Location.DayOfWeek.Closed" />
                                                                    ) : (
                                                                        bh.CloseTime
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        </div>
    ) : null;
}

Locations.propTypes = {
    locations: px.arrayOf(px.object),
    disclaimerText: px.string,
    locationContent: px.string,
};
