import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Facet } from 'Common/types';
import { Dropdown } from 'Common/components/ui';
import { toggleFacetValue } from './utils';

export default function SearchDropdownFacet({ className, style, facet, onChange }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleOpen = React.useCallback(() => setIsOpen((val) => !val), []);

    return (
        <Dropdown
            className={cx('SearchDropdownFacet', className)}
            style={style}
            icon={<i className={cx('fas  ml-2 mb-1', `fa-chevron-${isOpen ? 'up' : 'down'}`)} />}
            selectedValue={facet.title}
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            drawerType="relative"
        >
            {facet.options?.map((o, idx) => (
                <button
                    onClick={() => onChange?.(toggleFacetValue(idx, facet))}
                    key={o.value}
                    className={cx('SearchDropdownFacet__option btn btn-sm', o.selected && 'selected')}
                >
                    {o.title}
                </button>
            ))}
        </Dropdown>
    );
}

SearchDropdownFacet.propTypes = {
    className: px.string,
    style: px.object,
    facet: Facet,
    onChange: px.func,
};
