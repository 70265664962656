import { useMemo } from 'react';
import px from 'prop-types';
import { Formatter } from 'Common/utils';

export default function Currency({ code = 'USD', amount = 0, truncate = false, useParens = false, min = -Infinity, max = Infinity }) {
    const formatter = useMemo(() => Formatter.getCurrencyFormatter(code, truncate), [code]);

    // || 0 needed to ensure wonky javascript value of `-0` is never used.
    const value = useMemo(() => Formatter.currency(Math.max(min, Math.min(max, amount || 0)), null, formatter), [
        amount,
        formatter,
        min,
        max,
    ]);

    const formatted = useMemo(() => {
        switch (useParens) {
            case false:
            case 'never':
                return value;
            case true:
            case 'always':
                return `(${value})`;
            case 'negative':
                return amount < 0 ? `(${value})` : value;
            case 'positive':
                return amount >= 0 ? `(${value})` : value;
            case 'zero':
                return amount ? value : `(${value})`;
        }
    }, [amount, value, useParens]);

    return formatted;
}

Currency.propTypes = {
    code: px.string,
    amount: px.oneOfType([px.number, px.arrayOf([px.number])]),
    truncate: px.bool,
    useParens: px.oneOf(['negative', 'always', 'never', 'positive', 'zero', true, false]),
    min: px.number,
    max: px.number,
};
