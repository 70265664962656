import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Translation } from 'Common/components/localization';
import * as types from 'Common/types';
import { FacetFilter, ChildrenSelector } from 'Common/components/ui';

export default function ProductOptions({
    clearFacets,
    className,
    currentChildren,
    disabledFacets,
    facets,
    optionLabel,
    onFacetSelect,
    onSelectChild,
    productChildren,
    productCode,
    selectedChild,
    skuLabel,
    extraFacetProps,
}) {
    const [showClearFacets, setShowClearFacets] = React.useState(false);

    React.useEffect(() => {
        if (
            facets.find((facet) => facet.FacetValues.find((value) => value.Selected)) &&
            facets.length > 1 &&
            productChildren?.length > 1
        )
            setShowClearFacets(true);
        else setShowClearFacets(false);
    }, [facets, productChildren]);

    const autoSelectFacet = React.useCallback(() => {
        for (let facet of facets) {
            if (
                Object.values(disabledFacets[facet.Attribute])?.filter((map) => map.hasStock && !map.isDisabled)
                    ?.length === 1
            ) {
                const value = facet.FacetValues.find(
                    (v) =>
                        disabledFacets[facet.Attribute][v.Value].hasStock &&
                        !disabledFacets[facet.Attribute][v.Value].isDisabled &&
                        !v.Selected
                );

                if (value) {
                    onFacetSelect(facet, value);
                }
            }
        }
    }, [currentChildren, disabledFacets, facets, onFacetSelect, onSelectChild, productChildren, productCode]);

    React.useEffect(() => {
        if (
            !facets?.length &&
            productChildren?.length === 1 &&
            (selectedChild == null || productChildren[0].Code !== selectedChild.Code)
        ) {
            onSelectChild(productChildren[0]);
        } else {
            autoSelectFacet();
        }
    }, [facets, onSelectChild, productChildren, autoSelectFacet, selectedChild]);

    return (
        <div className={cx('ProductOptions container', className)}>
            {facets?.length ? (
                facets.map((facet) => (
                    <FacetFilter
                        key={facet.Attribute}
                        optionLabel={optionLabel}
                        facet={facet}
                        disabled={disabledFacets[facet.Attribute]} //not utilizing disabled facets currently.
                        onSelect={onFacetSelect}
                        extraProps={extraFacetProps}
                    />
                ))
            ) : (
                <ChildrenSelector
                    productChildren={productChildren}
                    selectedChild={selectedChild}
                    onSelect={onSelectChild}
                    optionLabel={optionLabel}
                    skuLabel={skuLabel}
                />
            )}
            {showClearFacets ? (
                <button onClick={clearFacets} className="btn btn-link clear-selection paragraph-3 mt-2 mb-3">
                    <i className="fa fa-times mr-1" aria-hidden />
                    <Translation id="Commerce.Product.ClearFacets.Label" />
                </button>
            ) : null}
        </div>
    );
}

ProductOptions.propTypes = {
    currentChildren: px.arrayOf(types.Product),
    className: px.string,
    clearFacets: px.func,
    disabledFacets: px.object,
    facets: px.arrayOf(px.object),
    optionLabel: px.string,
    onFacetSelect: px.func,
    onSelectChild: px.func,
    productChildren: px.arrayOf(types.Product),
    productCode: px.string,
    selectedChild: px.object,
    skuLabel: px.string,
    extraFacetProps: px.object,
};
