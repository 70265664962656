import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { OrderSummary } from 'Common/types';
import { Translation, Currency } from '../localization';
import { Icon } from '../ui';

const IMG_MAP = {
    AmazonPay: { name: 'amazon-pay-large', sheet: 'payment', label: 'AmazonPay' },
    GooglePay: { name: 'google-pay-large', sheet: 'payment', label: 'GooglePay' },
    ApplePay: { name: 'apple-pay-large', sheet: 'payment', label: 'ApplePay' },
    PayPal: { name: 'paypal-large', sheet: 'payment', label: 'PayPal' },
};

export default function CartSummary({
    className,
    checkoutLink,
    availablePaymentTypes = [],
    paymentTypeRowHeight = 42,
    paymentTypeCellPadding = 8,
    paymentsPerRow = 3,
    bottomSeparator,
    cartSummaryData,
    currency = 'gbp',
    disabled = false,
}) {
    const [expandPayments, setExpandPayments] = React.useState(false);

    const payments = React.useMemo(() => availablePaymentTypes.map((p) => IMG_MAP[p]).filter(Boolean), [
        availablePaymentTypes,
    ]);

    const rows = React.useMemo(() => Math.ceil(payments.length / paymentsPerRow), [payments, paymentsPerRow]);
    const paymentWidth = React.useMemo(() => `${Math.floor(100 / paymentsPerRow)}%`, [paymentsPerRow]);

    const paymentsHeight = React.useMemo(
        () => `${(expandPayments ? rows : 1) * (paymentTypeRowHeight + 2 * paymentTypeCellPadding)}px`,
        [expandPayments, rows, paymentTypeRowHeight, paymentTypeCellPadding]
    );

    return (
        <div className={cx('CartSummary', className)}>
            <div className="sub">
                <span>
                    <Translation id="Commerce.Order.Subtotal.Label" />
                </span>
                {disabled ? (
                    <span />
                ) : (
                    <span>
                        <Currency code={currency} amount={cartSummaryData.subtotal} min={0} />
                    </span>
                )}
            </div>
            {cartSummaryData.handling > 0 ? (
                <div className="sub">
                    <span>
                        <Translation id="Commerce.Order.Checkout.CheckoutSummary.Handling.Label" />
                    </span>
                    {disabled ? (
                        <span />
                    ) : (
                        <span>
                            <Currency code={currency} amount={cartSummaryData.handling} min={0} />
                        </span>
                    )}
                </div>
            ) : null}
            <div className="sub">
                <span>
                    <Translation id="Commerce.Order.Shipping.Label" />
                </span>
                {disabled ? (
                    <span />
                ) : (
                    <span>
                        <Currency code={currency} amount={cartSummaryData.shippingSubTotal} min={0} />
                    </span>
                )}
            </div>
            {cartSummaryData.shippingDiscount > 0 ? (
                <div className="sub">
                    <span>
                        <Translation id="Commerce.Order.Checkout.CheckoutSummary.ShippingDiscount.Label" />
                    </span>
                    {disabled ? (
                        <span />
                    ) : (
                        <span>
                            <Currency
                                amount={cartSummaryData.shippingDiscount * -1}
                                code={currency}
                                max={0}
                                useParens="negative"
                            />
                        </span>
                    )}
                </div>
            ) : null}
            <div className="sub">
                <span>
                    <Translation id="Commerce.Order.Tax.Label" />
                </span>
                {disabled ? (
                    <span />
                ) : (
                    <span>
                        <Currency code={currency} amount={cartSummaryData.tax} min={0} />
                    </span>
                )}
            </div>
            {cartSummaryData.orderDiscount ? (
                <div className="sub">
                    <span>
                        <Translation id="Commerce.Order.Checkout.CheckoutSummary.Discount.Label" />
                    </span>
                    {disabled ? (
                        <span />
                    ) : (
                        <span>
                            <Currency
                                amount={cartSummaryData.orderDiscount * -1}
                                code={currency}
                                max={0}
                                useParens="negative"
                            />
                        </span>
                    )}
                </div>
            ) : null}
            {cartSummaryData.giftCardDiscount ? (
                <div className="sub">
                    <span>
                        <Translation id="Commerce.Order.Checkout.CheckoutSummary.GiftCardTotal.Label" />
                    </span>
                    {disabled ? (
                        <span />
                    ) : (
                        <span>
                            <Currency
                                amount={cartSummaryData.giftCardDiscount * -1}
                                code={currency}
                                useParens="negative"
                                max={0}
                            />
                        </span>
                    )}
                </div>
            ) : null}
            <div className="separator" />
            <div className="mb-3">
                <p className="body2">
                    <Translation id="Commerce.Order.Total.Label" />
                </p>
                {disabled ? (
                    <span />
                ) : (
                    <p className="body2">
                        <Currency
                            amount={cartSummaryData.totalSansGiftCards ?? cartSummaryData.total}
                            code={currency}
                            min={0}
                        />
                    </p>
                )}
            </div>
            <a role="button" href={disabled ? '#' : checkoutLink} className="btn btn-primary mb-4" disabled={disabled}>
                <span>
                    <Translation id="Commerce.Order.Button.Checkout.Label" />
                </span>
            </a>
            {payments.length ? (
                <>
                    <p className="sub text-center">
                        <Translation id="Commerce.Order.Cart.Summary.BuyWith.Label" />
                    </p>
                    <div className="payment-method-container" style={{ maxHeight: paymentsHeight }}>
                        <div className="payment-method-contents d-flex flex-row justify-content-center align-items-center flex-wrap">
                            {payments.map((p) => (
                                <Icon
                                    {...p}
                                    key={p.label}
                                    style={{
                                        width: paymentWidth,
                                        maxWidth: paymentWidth,
                                        height: paymentTypeRowHeight + 2 * paymentTypeCellPadding,
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    {rows > 1 ? (
                        expandPayments ? (
                            <button
                                role="button"
                                className="btn btn-toggle-payments btn-link"
                                onClick={() => setExpandPayments(false)}
                            >
                                <i className="fas fa-times" aria-hidden="true" />
                                <Translation id="Commerce.Order.Cart.Summary.CollapsePayments.Label" />
                            </button>
                        ) : (
                            <button
                                role="button"
                                className="btn btn-toggle-payments btn-link"
                                onClick={() => setExpandPayments(true)}
                            >
                                <i className="fas fa-plus" aria-hidden="true" />
                                <Translation id="Commerce.Order.Cart.Summary.ExpandPayments.Label" />
                            </button>
                        )
                    ) : null}
                </>
            ) : null}
            {bottomSeparator ? <div className="separator" /> : <br />}
        </div>
    );
}

CartSummary.propTypes = {
    className: px.string,
    properties: px.objectOf(px.string),
    bottomSeparator: px.bool,
    checkoutLink: px.string,
    subtotal: px.number,
    promo: px.number,
    tax: px.number,
    currency: px.string,
    shipping: px.number,
    disabled: px.bool,
    availablePaymentTypes: px.arrayOf(px.string),
    paymentTypeRowHeight: px.number,
    paymentTypeCellPadding: px.number,
    paymentsPerRow: px.number,
    cartSummaryData: OrderSummary,
};
