import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import * as PRODUCT from '~config/product';
import { Formatter, ProductImage } from 'Common/utils';
import { useLoading, useViewport } from 'Common/hooks';
import { InfiniteScroll, Spinner } from 'Common/components/ui';
import { Translation } from 'Common/components/localization';
import CatalogCard from './CatalogCard';
import CatalogCTA from '../CatalogCTA';
import CatalogQuickView from '../CatalogQuickView';
import { ProductInfo } from 'Common/models';

function priceProps(price) {
    const retail = [price.MinListPrice.Amount, price.MaxListPrice.Amount];
    const sale = [price.MinSalePrice.Amount, price.MaxSalePrice.Amount];
    const retailStr = retail ? Formatter.currency(retail, price.ListPrice.Currency) : '';
    const saleStr = sale ? Formatter.currency(sale, price.SalePrice.Currency) : '';

    if (!retail[1] && !sale[1]) return null;
    if ((retail[1] && !sale[1]) || retailStr === saleStr) return { price: retailStr };
    if (sale[1] && !retail[1]) return { price: saleStr };
    return { price: retailStr, reducedPrice: saleStr };
}

export default function CatalogGrid({
    isUserAuthenticated = false,
    Card = CatalogCard,
    QuickView = CatalogQuickView,
    openQuickOnSelectProduct = false,
    CTA = CatalogCTA,
    checkoutPageLink,
    pageInfo = {},
    query = '',
    ctaType = 'Default',
    ctaModel = null,
    ctaTypeMobile,
    ctaModelMobile,
    desktopBreakpoint = 'lg',
    className,
    products = [],
    onLoadMore,
    loadArgs = [],
    loadOnArgsChange = false,
    productProps = {},
    storeLocatorLink,
    gtmListValue,
    onAddItemToCompare,
    itemStatusTooltip,
    useCompare = false,
}) {
    const viewport = useViewport();
    const [isLoading, load] = useLoading();
    const [quick, setQuick] = React.useState(null);
    const quickViewCardRef = React.useRef(null);
    const curatedProducts = React.useMemo(
        () =>
            viewport.is.gt('md') && CTA && ctaModel && pageInfo.next // make sure at least 2 products otherwise no need to curate
                ? products.slice(0, products.length - 1)
                : products,
        [products, CTA, ctaModel, viewport, pageInfo]
    );

    const paginationData = React.useMemo(
        () => ({
            ...pageInfo,
            next: pageInfo.next
                ? {
                      from: ctaModel ? pageInfo.next.from - 1 : pageInfo.next.from,
                      to: ctaModel ? pageInfo.next.to - 1 : pageInfo.next.to,
                  }
                : null,
        }),
        [ctaModel, pageInfo]
    );

    const openQuick = React.useCallback(
        (product) => (e, ref) => {
            setQuick({ ...product });
            quickViewCardRef.current = ref.current;
        },
        []
    );

    const clearQuick = React.useCallback(() => {
        setQuick(null);
        quickViewCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        quickViewCardRef.current = null;
    }, []);

    const cardProps = React.useMemo(
        () =>
            curatedProducts
                .map((product, i) =>
                    product
                        ? {
                              className: `col border-col col-6 col-${desktopBreakpoint}-4 px-2`,
                              code: product.Code,
                              key: `${product.Code}-${i}`,
                              title: product.DisplayName,
                              rating: product.Rating,
                              reviewProviderEntityId: product.ReviewProviderEntityId,
                              onSelectProduct: openQuickOnSelectProduct ? openQuick(product) : null,
                              productUrl: product.ContentUrl,
                              defaultImage: ProductImage.getGlamOrDefaultImageUrl(
                                  product.CatalogMedia,
                                  product.DefaultImageUrl,
                                  `format=png&width=430&height=430${
                                      PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                                  }`
                              ),

                              secondaryImage: ProductImage.getSecondaryImageUrl(
                                  product.GalleryMedia,
                                  product.DefaultImageUrl,
                                  `format=png&width=430&height=430${
                                      PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                                  }`
                              ),
                              flag: product.PrimaryMarketingTag,
                              marketingBadges:
                                  product.MarketingBadges && product.MarketingBadges.length
                                      ? [...new Set(product.MarketingBadges.map((badge) => badge.toUpperCase()))]
                                      : null,
                              productLinks:
                                  ProductInfo.isDisplayOnly(product) && PRODUCT.USE_RETAILER
                                      ? [
                                            {
                                                label: <Translation id="Commerce.Product.FindStockist" />,
                                                key: 'storeLocatorLink',
                                                action: storeLocatorLink,
                                            },
                                        ]
                                      : [
                                            {
                                                label: (
                                                    <Translation
                                                        id={
                                                            ProductInfo.isDisplayOnly(product)
                                                                ? 'Commerce.CatalogNode.Card.DisplayOnlyQuickView'
                                                                : 'Commerce.CatalogNode.Card.QuickView'
                                                        }
                                                    />
                                                ),
                                                key: 'quickViewLink',
                                                icon: 'fas fa-eye',
                                                action: openQuick(product),
                                            },
                                            ...(onAddItemToCompare && useCompare
                                                ? [
                                                      {
                                                          label: <Translation id="Commerce.Product.Compare" />,
                                                          key: 'compareLink',
                                                          icon: 'fa fa-chart-bar',
                                                          action: () => onAddItemToCompare(product.Code),
                                                      },
                                                  ]
                                                : []),
                                        ],
                              ...priceProps(product.Price),
                          }
                        : null
                )
                .filter(Boolean),
        [
            curatedProducts,
            desktopBreakpoint,
            openQuick,
            openQuickOnSelectProduct,
            onAddItemToCompare,
            storeLocatorLink,
            useCompare,
        ]
    );

    const quickIdx = React.useMemo(() => {
        const mobile = viewport.is.lt(desktopBreakpoint);
        const rowSize = mobile ? 2 : 3;
        const ctaShown = !mobile && ctaModel && CTA;
        let idx = quick ? cardProps.findIndex((p) => p.code === quick.Code) : -1;

        if (idx > -1 && ctaShown) {
            idx += 1;
        }

        if (idx > -1) {
            return rowSize * (1 + Math.floor(idx / rowSize)) - (ctaShown ? 1 : 0);
        }
        return -1;
    }, [quick, cardProps, ctaModel, CTA, viewport, desktopBreakpoint]);

    const loadMore = React.useCallback(async () => {
        await load(() => onLoadMore(false, ...loadArgs));
    }, [load, onLoadMore, loadArgs]);

    React.useEffect(() => {
        async function callLoad() {
            await load(() => onLoadMore(true, ...loadArgs));
        }
        if (loadOnArgsChange) {
            callLoad();
        }
    }, [load, onLoadMore, loadArgs, loadOnArgsChange]);

    return (
        <InfiniteScroll useLoadIndicator isDoneLoading={!paginationData.next} isLoading={isLoading} onLoad={loadMore}>
            <div className={cx('CatalogGrid container d-flex flex-column justify-content-center w-100', className)}>
                <div className="row">
                    {CTA && ctaModel ? (
                        <CTA
                            className={`col border-col col-4 d-none d-${desktopBreakpoint}-flex`}
                            type={ctaType}
                            model={ctaModel}
                        />
                    ) : null}
                    {Card && cardProps?.length ? (
                        cardProps.map(({ key, ...props } = {}, i) =>
                            key ? (
                                <React.Fragment key={key}>
                                    {QuickView && quick && i === quickIdx ? (
                                        <QuickView
                                            className="col-12"
                                            onClose={clearQuick}
                                            productData={quick}
                                            productProps={productProps}
                                            checkoutPageLink={checkoutPageLink}
                                            isUserAuthenticated={isUserAuthenticated}
                                            gtmListValue={gtmListValue}
                                            itemStatusTooltip={itemStatusTooltip}
                                        />
                                    ) : null}
                                    <Card {...props} />
                                </React.Fragment>
                            ) : null
                        )
                    ) : isLoading ? (
                        <div className="w-100 d-flex justify-content-center">
                            <Spinner />
                        </div>
                    ) : (
                        <h6 className="p-3">
                            <Translation id="Search.No.Results.Message" params={{ query }} />
                        </h6>
                    )}
                    {QuickView && quick && quickIdx >= cardProps.length ? (
                        <QuickView
                            className="col-12"
                            onClose={clearQuick}
                            productData={quick}
                            productProps={productProps}
                            checkoutPageLink={checkoutPageLink}
                            isUserAuthenticated={isUserAuthenticated}
                            gtmListValue={gtmListValue}
                            itemStatusTooltip={itemStatusTooltip}
                        />
                    ) : null}
                </div>
                {CTA && ctaModel ? (
                    <div className="row">
                        <CTA
                            className={`col border-col col-12 d-flex d-${desktopBreakpoint}-none`}
                            type={ctaTypeMobile || ctaType}
                            model={ctaModelMobile || ctaModel}
                        />
                    </div>
                ) : null}
            </div>
        </InfiniteScroll>
    );
}

CatalogGrid.propTypes = {
    className: px.string,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    openQuickOnSelectProduct: px.bool,
    CTA: px.elementType,
    Card: px.elementType,
    QuickView: px.elementType,
    lastUpdate: px.number,
    checkoutPageLink: px.string,
    ctaModel: px.objectOf(px.any),
    ctaType: px.string,
    ctaTypeMobile: px.string,
    ctaModelMobile: px.objectOf(px.any),
    products: px.arrayOf(types.Product),
    onLoadMore: px.func,
    onAddToCart: px.func,
    onBuyNow: px.func,
    productProps: px.object,
    pageInfo: types.PageInfo,
    query: px.string,
    desktopBreakpoint: px.string,
    loadOnArgsChange: px.bool,
    loadArgs: px.arrayOf(px.any),
    storeLocatorLink: px.string,
    gtmListValue: px.string,
    onAddItemToCompare: px.func,
    itemStatusTooltip: px.oneOfType([px.bool, px.string]),
    useCompare: px.bool,
};
