import { Api } from 'Common/utils';

export function fetchLocationsByBrand(brand, categories) {
    const query = Object.assign({ brand }, categories?.length ? { categories } : undefined);

    return Api.GET('/StoreLocation/GetByBrand', query);
}

export function getLocations(query) {
    return Api.POST('/StoreLocation/GetLocations', query);
}
